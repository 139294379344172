import { SocialMediaLinks } from "./types"

export type CryptoUser = {
  wallet_address: `0x${string}`
  lowercase_wallet_address: `0x${string}`
  verified: boolean
  nonce?: string // Used to verify that the user owns this wallet, when logging in.
}

export type EmailUser = {
  email: string
  password?: string // User's password isn't set if they subscribe to a newsletter, but not verify yet.
  resetPasswordUUID?: string
  // True if user has a password, false if user password hasn't been set yet.
  // This is a computed field set by the API when it retrieves a user record, not something saved in the DB.
  // Useful when the FE needs to know if the user has a password set without
  // actually returning the password for security reasons.
  hasPassword: boolean
}

export type UnstoppableDomainsUser = {
  usernameUnstoppable: string // The Unstoppable Domains unique address (e.g. `ryan123.wallet`).
  email: string // The proxy email address Unstoppable Domains provides us with. The user's actual email address remains private.
}

// Contains base user fields that any user type should have. This contains fields that are not private,
// so can be returned in public user responses.
export interface BaseUser {
  id: string
}

// Contains additional, optional fields for a user specific to their author bio.
// This corresponds to actual DB fields in Firestore.
export interface UserAuthorDetails {
  // The author's name. This used to be the `author_name` field on the `Blog` type.
  authorName?: string
  // A sentence or paragraph in which the author describes themselves.
  authorBio?: string
  // User's avatar/profile picture.
  avatar_url?: string
  // Optional links to social media.
  social?: SocialMediaLinks
}

// We can include the optional lowercase_wallet_address here as that's public information.
export interface UserAuthorWalletAddress
  extends Pick<
    Partial<CryptoUser>,
    "wallet_address" | "lowercase_wallet_address"
  > {}

// Base user fields that any user should have in common.
// This contains public-friendly fields that can be returned in any public user responses.
export interface BaseUserPublic extends BaseUser, UserAuthorDetails {
  // The author's wallet address.
  wallet_address?: `0x${string}`
  // The author's wallet address (lowercased).
  lowercase_wallet_address?: `0x${string}`
}

// Base user fields that any user (whether registered via email and password, wallet, etc.) should have in common.
// This contains internal-only fields that should not be in any public user responses.
export interface BaseUserInternal extends BaseUser, UserAuthorDetails {
  createdAt: number
  unsubscribed?: boolean // Unsubscribed from ONLY promotional emails. Not any subscriptions.
  stripe_customer_id?: string // When a user is a customer (i.e. paying to subscribe to another users blog), this will be their Stripe Platform customer ID.
  // When a user chooses a new email address, we generate a UUID and store it here.
  // We also store their new email address here.
  updateEmailUUID?: string
  updateEmailNewEmail?: string

  /**
   * If the user has been disabled (ie, due to abuse).
   * Note that if the user is disabled, we also set all their posts to 'ugc': 'false", so that
   * they are sufficiently excluded from the 'discover' page.
   */
  disabled?: boolean

  /**
   * The wallet address for the creator referrer, that referred this user to Paragraph.
   * This should ALWAYS be lowercase.
   */
  referrerWalletAddress?: string
}

export type User = BaseUserInternal &
  ((CryptoUser | EmailUser | UnstoppableDomainsUser) | (CryptoUser & EmailUser))

export interface UserParamsUnstoppableDomainsReq {
  usernameUnstoppable: string // The Unstoppable Domains unique address (e.g. `ryan123.wallet`)
  email?: string // The proxy email address Unstoppable Domains provides us with. The user's actual email address remains private.
}

export interface UserParamsUnstoppableDomainsRes {
  id: string // The database ID of the newly created user object.
  usernameUnstoppable?: string // The Unstoppable Domains unique address (e.g. `ryan123.wallet`)
}

export interface UserParamsEmail {
  email: string
  password: string

  /**
   * The wallet address that referred this user to Paragraph.
   *
   */
  referrerWalletAddress?: string
}

export interface UserParamsWallet {
  wallet_address: string
}

// User params, ONLY when logging in or registering
export type UserParams =
  | UserParamsEmail
  | UserParamsWallet
  | UserParamsUnstoppableDomainsReq

export const emptyUser: User = {
  id: "",
  email: "",
  password: "",
  hasPassword: false,
  createdAt: Date.now(),
}

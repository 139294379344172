import { Note } from "@types"

import {
  EnrichedGatingRule,
  EnrichedGatingRuleGroup,
  GatingRequirementMembership,
} from "@/types/gatingRules"

import ConnectWalletBtn from "@/components/ConnectWalletButton"
import useUser from "hooks/useUser"
import Banner from "../Banner"
import { useState } from "react"
import { useSelector } from "react-redux"
import { selectTokens } from "features/blogSlice"
import GatingRuleShortSummaryRowSubscription from "./GatingRuleShortSummaryRowSubscription"
import GatingRuleShortSummaryRowToken from "./GatingRuleShortSummaryRowToken"
import Placeholder from "components/Placeholder"
import { isTokenGate } from "@/util/gatingRules"
import { GetTokenById } from "./ReaderGatesHelperFunctions"
import useColor from "hooks/useColor"
import clsx from "clsx"
import { selectUpsellMemberships } from "features/membershipSlice"

interface subscribeBlockProps {
  note: Note
  gatingRuleGroups: EnrichedGatingRuleGroup[]
  isEmbed?: boolean
}

export default function GatingRulesShortSummary(props: subscribeBlockProps) {
  const user = useUser()
  const [isError, setIsError] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const tokens = useSelector(selectTokens)
  const color = useColor(false)
  const memberships = useSelector(selectUpsellMemberships)

  return (
    <div>
      <div
        className={clsx(
          "rounded-md divide-y divide-gray-200 pt-2 pb-1 px-1",
          color.isDarkTheme
            ? "shadow-dark-gray"
            : "shadow-2xl border border-gray-200"
        )}
        style={{
          backgroundColor: color.secondary || "white",
        }}
      >
        <div className="">
          <div className="flex flex-col place-items-center">
            <h3
              className="text-base leading-6 font-medium"
              style={{ color: color.fontColor || "text-gray-900" }}
            >
              {`You have access to this exclusive ${
                props.isEmbed ? "content" : "post"
              }.`}
            </h3>
          </div>
          {/* Loading placeholder. */}
          {!props.gatingRuleGroups && (
            <div className="p-4 mt-2">
              <Placeholder />
            </div>
          )}
          <div className="mt-2">
            <div>
              <div
                role="list"
                className="flex flex-col divide-y-8 divide-transparent overflow-auto max-h-52 md:max-h-96"
                style={{
                  borderColor: color.secondary || "white",
                }}
              >
                {props.gatingRuleGroups &&
                  tokens &&
                  props.gatingRuleGroups.map((group, index) => (
                    <div key={group.id}>
                      <div
                        className="rounded-md"
                        style={{
                          backgroundColor: color.secondary || "white",
                        }}
                      >
                        <div
                          className="pb-0.5 pl-2 pr-3 md:px-4 rounded-md border border-primary-50 shadow-md m-0.5"
                          style={{
                            backgroundColor: color.secondary || "white",
                          }}
                        >
                          {group.enrichedGatingRules
                            .sort(
                              (
                                gr1: EnrichedGatingRule,
                                gr2: EnrichedGatingRule // This sorts by gate type so that subscriptions are consistently at the top and tokens below.
                              ) =>
                                gr1.gatingRequirement.gateType <
                                gr2.gatingRequirement.gateType
                                  ? -1
                                  : 1
                            ) // TODO: Add a secondary sort to ensure we have purchased gates at the top and unpurchased at the bottom (like a check-list you have to go through)
                            // TODO: Add a tertiary sort by alphabetical order to ensure consistency.
                            .map((gr: EnrichedGatingRule, index: number) => (
                              <div key={gr.id}>
                                {gr.gatingRequirement.gateType ==
                                  "MEMBERSHIP" && (
                                  <GatingRuleShortSummaryRowSubscription
                                    gatingRule={gr}
                                    membership={memberships?.find(
                                      (m) =>
                                        m.id ==
                                        (
                                          gr.gatingRequirement as GatingRequirementMembership
                                        ).membershipId
                                    )}
                                    setIsError={setIsError}
                                    setError={setError}
                                  />
                                )}
                                {isTokenGate(gr) && (
                                  <GatingRuleShortSummaryRowToken
                                    gatingRule={gr}
                                    token={GetTokenById(gr, tokens)}
                                    setIsError={setIsError}
                                    setError={setError}
                                  />
                                )}
                                {index <
                                  group.enrichedGatingRules.length - 1 && (
                                  <div className="border-primary-50 border-b border-b-1 mx-4 mb-1"></div>
                                )}
                              </div>
                            ))}
                        </div>
                      </div>
                      {index < props.gatingRuleGroups.length - 1 && (
                        <div className="relative flex justify-center mt-2.5">
                          <div className="absolute top-2.5 z-0 border-b border-dotted border-b-1 border-primary-50 w-48"></div>
                          <div
                            className="z-10 w-12 flex justify-center text-sm"
                            style={{
                              backgroundColor: color.secondary || "white",
                              color: color.fontColor || "text-gray-900",
                            }}
                          >
                            or
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
            {props.gatingRuleGroups
              ?.flatMap((group) => group.enrichedGatingRules)
              .some((g) => isTokenGate(g)) &&
              !user.cryptoData?.isConnected && (
                <div className="flex justify-center">
                  <p
                    className="mt-4 text-sm max-w-md"
                    style={{ color: color.fontColor || "text-gray-500" }}
                  >
                    Connect your wallet to purchase other tokens.
                  </p>
                </div>
              )}

            {isError && error?.message && (
              <div className="mt-2">
                <Banner type="error" text={error.message} />
              </div>
            )}
            {!user.cryptoData?.isConnected &&
              props.gatingRuleGroups
                ?.flatMap((group) => group.enrichedGatingRules)
                .some((g) => isTokenGate(g)) && (
                <div className="flex justify-center items-center sm:ml-4 mt-4 sm:flex-shrink-0 sm:w-auto mb-1">
                  <ConnectWalletBtn />
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  )
}

import { useEffect, useState } from "react"
import ContentLoader from "react-content-loader"
import { useSelector } from "react-redux"
import { RootState } from "store"

export function HighlightLoader(props: any) {
  return (
    <ContentLoader
      speed={2}
      width={props?.width || 400}
      height={props?.width || 460}
      viewBox="0 0 400 460"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      uniqueKey="highlight-loader"
      {...props}
    >
      <circle cx="110" cy="395" r="15" />
      <rect x="137" y="382" rx="2" ry="2" width="140" height="10" />
      <rect x="137" y="398" rx="2" ry="2" width="140" height="10" />
      <rect x="44" y="60" rx="2" ry="2" width="308" height="308" />
    </ContentLoader>
  )
}

export function CollectorLoader(props: any) {
  return (
    <ContentLoader
      speed={2}
      width={476}
      height={50}
      viewBox="0 0 476 50"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <circle cx="20" cy="20" r="20" />
      <rect x="50" y="12" rx="3" ry="3" width="546" height="16" />
    </ContentLoader>
  )
}

export function AvatarLoader(props: any) {
  return (
    <ContentLoader
      speed={2}
      width={100}
      height={50}
      viewBox="0 0 100 50"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      uniqueKey="avatar-loader"
      {...props}
    >
      <rect x="48" y="18" rx="3" ry="3" width="88" height="6" />
      <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
      <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
      <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
      <circle cx="20" cy="20" r="20" />
    </ContentLoader>
  )
}

export default function Placeholder(props: any) {
  const { foregroundColor, backgroundColor } = useColorMode()

  const [client, setClient] = useState(false)

  useEffect(() => {
    setClient(true)
  }, [])

  if (!client) return null

  return (
    <ContentLoader
      {...props}
      speed={2}
      width={props?.width || 340}
      height={props?.height || 84}
      viewBox="0 0 340 84"
      foregroundColor={foregroundColor}
      backgroundColor={backgroundColor}
      uniqueKey="placeholder-loader"
    >
      <rect x="0" y="0" rx="3" ry="3" width="67" height="11" />
      <rect x="76" y="0" rx="3" ry="3" width="140" height="11" />
      <rect x="127" y="48" rx="3" ry="3" width="53" height="11" />
      <rect x="187" y="48" rx="3" ry="3" width="72" height="11" />
      <rect x="18" y="48" rx="3" ry="3" width="100" height="11" />
      <rect x="0" y="71" rx="3" ry="3" width="37" height="11" />
      <rect x="18" y="23" rx="3" ry="3" width="140" height="11" />
      <rect x="166" y="23" rx="3" ry="3" width="173" height="11" />
    </ContentLoader>
  )
}

export function TitlePlaceholder(props: any) {
  const { foregroundColor, backgroundColor } = useColorMode()

  const [client, setClient] = useState(false)

  useEffect(() => {
    setClient(true)
  }, [])

  if (!client) return null

  return (
    <ContentLoader
      speed={2}
      width={340}
      height={84}
      viewBox="0 0 340 84"
      foregroundColor={foregroundColor}
      backgroundColor={backgroundColor}
      uniqueKey="title-placeholder-loader"
      {...props}
    >
      <rect x="0" y="0" rx="3" ry="3" width="67" height="11" />
      <rect x="76" y="0" rx="3" ry="3" width="140" height="11" />
      <rect x="127" y="48" rx="3" ry="3" width="53" height="11" />
      <rect x="18" y="48" rx="3" ry="3" width="100" height="11" />
      <rect x="18" y="23" rx="3" ry="3" width="140" height="11" />
      <rect x="166" y="23" rx="3" ry="3" width="173" height="11" />
    </ContentLoader>
  )
}

export function PostPlaceholder(props: any) {
  const { foregroundColor, backgroundColor } = useColorMode()
  const [client, setClient] = useState(false)

  useEffect(() => {
    setClient(true)
  }, [])

  if (!client) return null

  return (
    <ContentLoader
      width={850}
      height={1500}
      viewBox="0 0 850 1500"
      foregroundColor={foregroundColor}
      backgroundColor={backgroundColor}
      uniqueKey={"post-placeholder-" + foregroundColor + backgroundColor}
      key={"post-placeholder-" + foregroundColor + backgroundColor}
      {...props}
    >
      <rect x="6" y="12" rx="4" ry="4" width="417" height="29" />
      <rect x="6" y="50" rx="4" ry="4" width="67" height="15" />
      <rect x="127" y="105" rx="4" ry="4" width="147" height="15" />
      <circle cx="739" cy="109" r="9" />
      <circle cx="765" cy="109" r="9" />
      <rect x="217" y="157" rx="4" ry="4" width="433" height="291" />
      <rect x="359" y="457" rx="4" ry="4" width="150" height="10" />
      <rect x="48" y="515" rx="4" ry="4" width="720" height="15" />
      <rect x="49" y="547" rx="4" ry="4" width="598" height="15" />
      <rect x="48" y="581" rx="4" ry="4" width="720" height="15" />
      <rect x="49" y="612" rx="4" ry="4" width="520" height="15" />
      <rect x="48" y="652" rx="4" ry="4" width="720" height="15" />
      <rect x="48" y="684" rx="4" ry="4" width="598" height="15" />
      <rect x="48" y="718" rx="4" ry="4" width="720" height="15" />
      <rect x="49" y="748" rx="4" ry="4" width="419" height="15" />
      <circle cx="713" cy="810" r="9" />
      <circle cx="739" cy="810" r="9" />
      <rect x="41" y="836" rx="4" ry="4" width="720" height="3" />
      <rect x="122" y="880" rx="4" ry="4" width="320" height="11" />
      <circle cx="79" cy="900" r="26" />
      <rect x="135" y="901" rx="4" ry="4" width="120" height="10" />
      <rect x="123" y="949" rx="4" ry="4" width="320" height="11" />
      <circle cx="80" cy="969" r="26" />
      <rect x="136" y="970" rx="4" ry="4" width="120" height="10" />
      <rect x="124" y="1021" rx="4" ry="4" width="320" height="11" />
      <circle cx="81" cy="1041" r="26" />
      <rect x="137" y="1042" rx="4" ry="4" width="120" height="10" />
      <rect x="125" y="1090" rx="4" ry="4" width="320" height="11" />
      <circle cx="82" cy="1110" r="26" />
      <rect x="138" y="1111" rx="4" ry="4" width="120" height="10" />
    </ContentLoader>
  )
}

function useColorMode() {
  const colorMode = useSelector((state: RootState) => state.page.colorMode)

  const foregroundColor = colorMode === "dark" ? "#4b5563" : "#ffffff"
  const backgroundColor = colorMode === "dark" ? "#6b7280" : "#eaeced"

  return {
    foregroundColor,
    backgroundColor,
  }
}

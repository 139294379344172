"use client"
import {
  createConfig,
  http,
  cookieStorage,
  createStorage,
  fallback,
} from "wagmi"

import { getDefaultConfig } from "connectkit"

import {
  mainnet,
  polygon,
  sepolia,
  polygonMumbai,
  optimism,
  optimismGoerli,
  base,
  zora,
  zoraTestnet,
  baseSepolia,
} from "wagmi/chains"
import { Chain, type Transport } from "viem"

import { isMaster } from "@/util/constants/server"

// API key for Ethereum node
// Two popular services are Infura (infura.io) and Alchemy (alchemy.com)
export const infuraId = "84566032b8074828961a55373c793e52"

export const alchemyId = "h0Uc6vitnXs8a5DoS3EZm6SBbsplkUqj"

const isProd = isMaster || process.env.NEXT_PUBLIC_FORCE_PROD_CHAINS

/**
 * WalletConnect v2 project ID
 */
const projectId = "1c3b64d40de34f18b135f597aae251fa"

// Define specific chain IDs for production and testing
type ProdChainIds =
  | typeof mainnet.id
  | typeof polygon.id
  | typeof optimism.id
  | typeof base.id
  | typeof zora.id
type TestChainIds =
  | typeof sepolia.id
  | typeof polygonMumbai.id
  | typeof optimismGoerli.id
  | typeof baseSepolia.id
  | typeof zoraTestnet.id

const prodTransports: Record<ProdChainIds, Transport> = {
  [mainnet.id]: fallback([
    http(
      "https://eth-mainnet.g.alchemy.com/v2/h0Uc6vitnXs8a5DoS3EZm6SBbsplkUqj"
    ),
    http("https://mainnet.infura.io/v3/84566032b8074828961a55373c793e52"),
  ]),
  [polygon.id]: fallback([
    http(
      "https://polygon-mainnet.infura.io/v3/84566032b8074828961a55373c793e52"
    ),
    http(
      "https://polygon-mainnet.g.alchemy.com/v2/h0Uc6vitnXs8a5DoS3EZm6SBbsplkUqj"
    ),
  ]),
  [optimism.id]: fallback([
    http(
      "https://opt-mainnet.g.alchemy.com/v2/h0Uc6vitnXs8a5DoS3EZm6SBbsplkUqj"
    ),
    http(
      "https://optimism-mainnet.infura.io/v3/84566032b8074828961a55373c793e52"
    ),
  ]),
  [base.id]: fallback([
    http(
      "https://base-mainnet.g.alchemy.com/v2/h0Uc6vitnXs8a5DoS3EZm6SBbsplkUqj"
    ),
    http("https://base-mainnet.infura.io/v3/84566032b8074828961a55373c793e52"),
  ]),
  [zora.id]: fallback([
    http(
      "https://zora-mainnet.g.alchemy.com/v2/h0Uc6vitnXs8a5DoS3EZm6SBbsplkUqj"
    ),
  ]),
}

const testTransports: Record<TestChainIds, Transport> = {
  [sepolia.id]: fallback([
    http("https://sepolia.infura.io/v3/84566032b8074828961a55373c793e52"),
    http(
      "https://eth-sepolia.g.alchemy.com/v2/h0Uc6vitnXs8a5DoS3EZm6SBbsplkUqj"
    ),
  ]),
  [polygonMumbai.id]: http(),
  [optimismGoerli.id]: http(),
  [baseSepolia.id]: http(),
  [zoraTestnet.id]: http(),
}

export const transports = isProd ? prodTransports : testTransports

type ChainType = readonly [Chain, ...Chain[]]

export const chains: ChainType = isProd
  ? [mainnet, polygon, optimism, base, zora]
  : [sepolia, polygonMumbai, optimismGoerli, baseSepolia, zoraTestnet]

type GetConfigProps = {
  enableSSR: boolean
}

export function getConfig(props?: GetConfigProps) {
  let config = getDefaultConfig({
    chains,
    transports,

    // Required API Keys
    walletConnectProjectId: projectId,

    // Required App Info
    appName: "Paragraph",

    // Optional App Info
    appDescription: "A new home for great writing.",
    appUrl: "https://paragraph.xyz", // your app's url
    appIcon: "https://paragraph.xyz/branding/logo_no_text.png", // your app's icon, no bigger than 1024x1024px (max. 1MB)
  })

  if (props?.enableSSR) {
    config = {
      ...config,
      ssr: true,
      storage: createStorage({
        storage: cookieStorage,
      }),
    }
  }

  return createConfig(config)
}

import { selectBlog } from "features/blogSlice"
import { selectCurrentNote } from "features/noteSlice"
import { useSelector } from "react-redux"
import { getChainId } from "@/util/crypto"

/**
 * Get the 'initial chain ID' that's used for RainbowKit.
 *
 * We need to set this to the chain ID of the post or blog
 * we're viewing, so users can connect to the right chain
 * without being immediately prompted to switch.
 */
export default function useGetInitialChain() {
  const blog = useSelector(selectBlog)
  const note = useSelector(selectCurrentNote)

  if (note?.highlightsChain) {
    return getChainId(note.highlightsChain)
  }

  if (blog.highlightsChain) {
    return getChainId(blog.highlightsChain)
  }

  return
}

import { isMaster } from "@/util/constants/server"
import { Subscription } from "@types"
import { createCustomerPortalSession } from "api_routes/stripe"
import Button from "components/Button"
import Spinner from "components/Spinner"
import { useState } from "react"

export default function ManageSection({
  subscription,
}: {
  subscription?: Subscription
}) {
  const [isLoading, setIsLoading] = useState(false)

  if (!subscription) return null

  let onClick
  if (subscription.planType === "stripe-subscription")
    onClick = async () => {
      if (isLoading) return

      setIsLoading(true)

      const portalUrl = await createCustomerPortalSession(subscription.id)
      if (portalUrl) window.open(portalUrl, "_blank", "noopener,noreferrer")

      setIsLoading(false)
    }

  if (subscription.planType === "loop-subscription")
    onClick = () =>
      window.open(
        isMaster
          ? "https://my.loopcrypto.xyz/"
          : "https://demo.my.loopcrypto.xyz/",
        "_blank",
        "noopener,noreferrer"
      )

  if (!onClick) return null

  return (
    <Button type="button" className="mt-4" onClick={onClick}>
      <div className="flex justify-center relative px-2">
        Manage Payment Info
        {isLoading && (
          <div className="absolute -right-5 top-0.5">
            <Spinner height={4} width={4} />
          </div>
        )}
      </div>
    </Button>
  )
}

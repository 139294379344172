import Button from "components/Button"
import { MutableRefObject } from "react"
import { CanChangeStep, FindStep } from "../GateModalHelperFunctions"
import { GateModalStep } from "../GateModalSteps"

type Props = {
  currentStep: GateModalStep
  steps: GateModalStep[]
  loading: boolean
  cancelButtonRef: MutableRefObject<null>
  mintOrImportToken: string
  changeStep: (id: string, proposedSteps?: GateModalStep[]) => void
  selectedGateType: string | undefined
  userHasWallet: boolean
  selectedExistingParagraphTokenId: string
}

export default function NextBtn(props: Props) {
  return (
    <Button
      loading={props.loading}
      replaceClassName={`inline-flex justify-center w-full px-4 py-2 mt-3 sm:mt-0 text-base font-medium 
                         text-white bg-blue-600 border border-transparent rounded-md shadow-sm 
                         hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 
                         focus:ring-blue-500 sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed`}
      disabled={
        !CanChangeStep(
          FindStep("NEXT", props.currentStep, props.steps),
          props.steps,
          props.currentStep,
          props.selectedGateType,
          props.userHasWallet,
          props.mintOrImportToken,
          props.selectedExistingParagraphTokenId
        )
      }
      text={"Next"}
      onClick={() =>
        props.changeStep(FindStep("NEXT", props.currentStep, props.steps))
      }
    />
  )
}

"use client"
import { Node } from "@tiptap/core"
import { Editor, NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react"
import { selectUsersBlog } from "features/blogSlice"
import { selectLatestCurrentNote } from "features/noteSlice"
import useNoteUrl from "hooks/useNoteUrl"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import CollectButton from "../../Collectibles/CollectPostPopoverBtn"

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    collectButton: {
      setCollectButton: () => ReturnType
    }
  }
}

export default Node.create({
  name: "collectButton",
  group: "block",

  addAttributes() {
    return {
      href: {
        default: null,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ]
  },

  addCommands() {
    return {
      setCollectButton:
        () =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
          })
        },
    }
  },

  renderHTML({ HTMLAttributes }) {
    const html = `<a class="email-subscribe-button" href="${HTMLAttributes.href}">Collect</a>`

    return ["div", { "data-type": this.name, class: "center-contents", html }]
  },

  addNodeView() {
    return ReactNodeViewRenderer(CollectButtonComponent)
  },
})

type Props = {
  editor: Editor | null
  node: any
  updateAttributes: any
}

function CollectButtonComponent({
  editor,
  node,
  updateAttributes,
}: Props): JSX.Element {
  const blog = useSelector(selectUsersBlog)
  const note = useSelector(selectLatestCurrentNote)
  const { fullNoteUrl } = useNoteUrl({ blog, note, forceSlug: true })
  console.log(blog, note, fullNoteUrl)

  useEffect(() => {
    // Don't update if the href is already set as this creates a new draft unnecessarily.
    if (node.attrs.href) return

    updateAttributes({ href: fullNoteUrl })
  }, [fullNoteUrl, updateAttributes])

  return (
    <NodeViewWrapper>
      <div className="flex justify-center my-5">
        <CollectButton
          disabled={editor && editor.isEditable ? true : false}
          hideAvatar
        />
      </div>
    </NodeViewWrapper>
  )
}

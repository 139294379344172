import { axios, params } from "./resolve"
import { AxiosResponse } from "axios"

import { ErrorResponse, SuccessResponse } from "@types"

import { GetTeamMemberResp, TeamMember, TeamPermissionID } from "@/types/teams"

const API = "/blogs"

export async function getTeamMembers(blogId: string) {
  const res: AxiosResponse<GetTeamMemberResp[]> = await axios.get(
    `${API}/${blogId}/teammembers`,
    params
  )

  return res.data
}

export async function addTeamMember(teammember: TeamMember) {
  const res: AxiosResponse<ErrorResponse | null> = await axios.post(
    `${API}/teammember`,
    { teammember },
    params
  )

  return res.data
}

export async function updateTeamMemberPermissions(
  teamMemberId: string,
  permissions: TeamPermissionID[]
) {
  const res: AxiosResponse<ErrorResponse | SuccessResponse> = await axios.put(
    `${API}/teammember/${teamMemberId}/permissions`,
    { permissions },
    params
  )

  return res.data
}

export async function updateTeamMemberSettings(
  teamMemberId: string,
  teamMemberFieldsToUpdate: Pick<TeamMember, "orderNotesInDashboardBy">
) {
  const res: AxiosResponse<ErrorResponse | SuccessResponse> = await axios.put(
    `${API}/teammember/${teamMemberId}/settings`,
    { teamMemberFieldsToUpdate },
    params
  )

  return res.data
}

export async function deleteTeamMember(id: string) {
  const res: AxiosResponse<ErrorResponse | null> = await axios.delete(
    `${API}/teammember/${id}`,
    params
  )

  return res.data
}

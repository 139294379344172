import { useSelector } from "react-redux"

import { Membership } from "@types"
import { selectUpsellMemberships } from "features/membershipSlice"

import {
  hasPaidMemberships,
  subscriptionIsPaid,
} from "components/Memberships/SharedFunctions"
import { selectActiveSubscription } from "features/userSlice"
import useUser from "./useUser"

/**
 * Misc helper functionality for the SubscribeModal states.
 */
export default function useSubscribeModalState() {
  const memberships = useSelector(selectUpsellMemberships) as
    | Membership[]
    | undefined

  const subscription = useSelector(selectActiveSubscription)
  const user = useUser()
  const blogHasPaidMemberships = hasPaidMemberships(memberships)

  const userHasPaidSub = subscriptionIsPaid(subscription, memberships)

  let userNeedsToConnectWallet = false
  if (!user.hasWallet && subscription) {
    userNeedsToConnectWallet = true
  }

  let userCanUpgrade = false

  if (subscription && blogHasPaidMemberships && !userHasPaidSub) {
    userCanUpgrade = true
  }

  console.log(
    "User paid subscritpion stuff ahh",
    userHasPaidSub,
    subscription,
    memberships
  )

  return {
    userNeedsToConnectWallet,
    userCanUpgrade,
    userHasPaidSub,
    userHasActiveSubscription: !!subscription,
    blogHasPaidMemberships,
  }
}

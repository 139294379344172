import { Membership, MembershipDiscount, Plan } from "@types"
import clsx from "clsx"
import { Frequency } from "../SharedTypes"

type Props = {
  membership: Membership
  frequency: Frequency
  discounts: MembershipDiscount[]
}

export default function NameSection({
  membership,
  frequency,
  discounts,
}: Props) {
  const plan = membership.plans?.find((p) => p.period == frequency.id)
  const discountedPrice = discounts.find(
    (discount) =>
      discount.planId === plan?.stripePriceId ||
      discount.planId === plan?.loopItemId
  )?.discountedPrice

  const trialDays = discounts.find(
    (discount) =>
      discount.planId === plan?.stripePriceId ||
      discount.planId === plan?.loopItemId
  )?.trialDays

  const MembershipTextMobile = ({ plan }: { plan: Plan }) => {
    if (plan.price === 0) {
      return <>Free</>
    }

    return (
      <div className={discountedPrice || trialDays ? "line-through" : ""}>
        ${plan.price} / {plan.period}
      </div>
    )
  }

  return (
    <h3
      id={membership.id}
      className={clsx(
        membership.isRecommended
          ? "text-primary-900"
          : "text-custom-900 dark:text-white",
        "text-sm sm:text-lg "
      )}
    >
      <div className="font-semibold leading-8">
        {membership.name || "VIP Membership"} {/* Placeholder name. */}
      </div>
      <div className="flex sm:hidden text-custom-400">
        {membership?.plans?.[0] && (
          <MembershipTextMobile plan={membership.plans[0]} />
        )}
      </div>
    </h3>
  )
}

import { CompletedCollectible } from "@/types/highlights"

import CollectButton from "../../CollectButton"
import useMintExistingCollectible from "@/hooks/collectibles/useMintExistingCollectible"

export default function CollectExistingCollectibleBtn(props: {
  btnText?: string
  highlight: CompletedCollectible
}) {
  const { write, pending, isTxnConfirmed, txnHash, error } =
    useMintExistingCollectible(props.highlight)

  return (
    <CollectButton
      isLoading={pending}
      isSuccess={isTxnConfirmed}
      onClick={write}
      btnText={props.btnText}
      error={error}
      desiredChain={props.highlight.chain}
      collectibleText={props.highlight.text}
    />
  )
}

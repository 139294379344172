import color from "color"

export const isDark = (_color: string) => color(_color).luminosity() < 0.5

export const primaryText = (_color: string) =>
  isDark(_color) ? "#ffffff" : "#0f172a"

// Each step, colors change by this amount.
// Smaller number = more drastic chagne.
const COLOR_FACTOR = 30

// Generates a list of colors, following Tailwind color steps e.g. (colorName500).
// If customColor is not present, falls back to fallbackColor (default color to use).
// Generate the spectrum of colors using some arbitrary color manipulation.
export const generateColors = (
  customColor: string | null | undefined,
  fallbackColor: string,
  idx: number,
  keyPrefix: string
) => {
  let newColor = fallbackColor

  if (customColor && idx < 7) {
    newColor = color(customColor)
      .fade(1 - (idx + 1) / 10)
      .hsl()
      .string()
  } else if (customColor && idx > 7) {
    newColor = color(customColor)
      .lighten((5 - idx) / COLOR_FACTOR)
      .hsl()
      .string()
    //.hex()
  } else if (customColor && idx == 7) {
    newColor = customColor
  }

  const keyNumber = idx === 0 ? 50 : idx * 100

  return { [`${keyPrefix}${keyNumber}`]: newColor }
}

const SLATE = [
  "#f8fafc",
  "#f1f5f9",
  "#e2e8f0",
  "#cbd5e1",
  "#94a3b8",
  "#64748b",
  "#475569", // Leading text, text-slate-600
  "#334155", // Default prose body color, text-slate-700
  "#1e293b",
  "#0f172a", // Prose bold and headings; text-slate-900
]

/**
 * Generates a list of colors, following Tailwind color steps e.g. (colorName500).
 */
export const colorGeneration = (key: string, fontColor?: string | null) =>
  SLATE.map((c, idx) => generateColors(fontColor, c, idx, key)).reduce(
    (acc, cur) => ({ ...acc, ...cur }),
    {}
  )

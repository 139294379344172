import { EnrichedGatingRule, Token } from "@/types/gatingRules"

import {
  InformationCircleIcon,
  LockClosedIcon,
  LockOpenIcon,
  ShoppingCartIcon,
} from "@heroicons/react/24/outline"

import { useAccount } from "wagmi"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { isTokenGate } from "@/util/gatingRules"
import {
  GetNetworkNameAndRootURL,
  GetTokenNameAndCountDisplayString,
} from "./ReaderGatesHelperFunctions"
import clsx from "clsx"
import useColor from "hooks/useColor"
import Button from "components/Button"
import { useModal } from "connectkit"

type Props = {
  gatingRule: EnrichedGatingRule
  token: Token | undefined
  setIsError: Dispatch<SetStateAction<boolean>>
  setError: Dispatch<SetStateAction<Error | null>>
}

export default function GatingRuleShortSummaryRowToken({
  gatingRule,
  token,
  setIsError,
  setError,
}: Props) {
  const account = useAccount()
  const networkScanner = GetNetworkNameAndRootURL(token)
  const { setOpen } = useModal()

  console.log("GatingRules: token is", token)

  const [isPurchaseClicked, setIsPurchaseClicked] = useState(false)

  const color = useColor(false)

  // If the user clicked Purchase, either log them in OR trigger the buy flow.
  useEffect(() => {
    if (!isPurchaseClicked) return

    // If the wallet's not connected,
    // open the RainbowKit connect modal (when it's ready).
    if (!account.isConnected && setOpen) {
      setOpen(true)
      return
    }

    setIsPurchaseClicked(false)
  }, [account.isConnected, isPurchaseClicked, setOpen])

  return (
    <div className="rounded-lg focus:outline-none w-84 md:w-96 md:py-2">
      <div className="flex items-center">
        {/* Lock icons. First column. */}
        <div className="my-auto">
          {gatingRule.hasAccess && (
            <span className="rounded-lg inline-flex px-2">
              <LockOpenIcon
                className="w-4 h-4 md:w-5 md:h-5 text-green-500"
                aria-hidden="true"
              />
            </span>
          )}
          {!gatingRule.hasAccess && (
            <span className="rounded-lg inline-flex px-2">
              <LockClosedIcon
                className="w-4 h-4 md:w-5 md:h-5 text-red-500"
                aria-hidden="true"
              />
            </span>
          )}
        </div>
        {/* Everything else. Second column. */}
        <div className="flex flex-col ml-3 w-full">
          {/* Token name and count remaining. First row. */}
          <div className="w-full flex justify-between items-center my-auto h-8">
            <p
              className="text-sm font-medium"
              style={{ color: color.fontColor || "text-gray-900" }}
            >
              {GetTokenNameAndCountDisplayString(gatingRule, token)}
            </p>
          </div>
          {/* Purchase buttons. Second row. */}
          {!gatingRule.hasAccess && (
            <div
              className={clsx("w-full flex items-center pb-1", "justify-end")}
            >
              <span className="flex">
                {!gatingRule.hasAccess && isTokenGate(gatingRule) && token && (
                  <div>
                    <a
                      href={`${networkScanner.rootURL}/address/${token?.onChainData.contractAddress}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button
                        type="button"
                        className="py-1.5 px-2 sm:col-start-2"
                        trackingData={{
                          event: "viewed information about token from summary",
                          network: networkScanner.name,
                          token: token.onChainData.name,
                        }}
                      >
                        <InformationCircleIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </Button>
                    </a>
                    {token.onChainData.learnMoreUrl && (
                      <a
                        href={`${token.onChainData.learnMoreUrl}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button
                          type="button"
                          className="ml-2 pb-1 pt-1.5 px-2 sm:col-start-2 disabled:cursor-not-allowed"
                          trackingData={{
                            event: "purchased off-paragraph token from summary",
                            network: networkScanner.name,
                            token: token.onChainData.name,
                          }}
                        >
                          <ShoppingCartIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </Button>
                      </a>
                    )}
                  </div>
                )}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

import { selectBlog } from "features/blogSlice"
import { useSelector } from "react-redux"

import color from "color"

import { colorGeneration, isDark, primaryText } from "@/util/color"

const DEFAULT_PRIMARY_COLOR = "#3b82f6"
const DEFAULT_SECONDARY_COLOR = "#fff"

export default function useColor(defaultColor?: boolean) {
  const _blog = useSelector(selectBlog)

  // If we want the default color, just set this to undefined.
  const blog = defaultColor ? undefined : _blog

  const primaryColor = blog?.primary_color || DEFAULT_PRIMARY_COLOR
  const secondaryColor = blog?.secondary_color || DEFAULT_SECONDARY_COLOR
  const fontColor = blog?.font_color

  const fontColors = colorGeneration("fontColor", fontColor)
  const bgColors = colorGeneration("bgColor", secondaryColor)
  const primaryColors = colorGeneration("primaryColor", primaryColor)

  const isPrimaryDark = isDark(primaryColor)
  // This is needed because even a standard blue CTA color is only a luminosity of 0.29.
  const isPrimaryVeryDark = color(primaryColor).luminosity() < 0.2
  const isSecondaryDark =
    secondaryColor && color(secondaryColor).luminosity() < 0.5
  const isSecondaryVeryDark =
    secondaryColor && color(secondaryColor).luminosity() < 0.5
  const isDarkTheme = (isPrimaryDark && isSecondaryDark) || isSecondaryVeryDark

  // Similarity judgments between different colors.
  // 1 is the same color, and 21 is the complete opposite (e.g. black and white).
  // This is used to figure out if e.g. a chosen background and font color are too similar and need to be contrasted more.
  const similarityBetweenPrimaryAndFontColor =
    fontColor && color(primaryColor).contrast(color(fontColor))
  const isPrimaryAndFontColorSimilar =
    similarityBetweenPrimaryAndFontColor &&
    similarityBetweenPrimaryAndFontColor < 5

  return {
    primary: primaryColor,
    primaryText: primaryText(primaryColor),
    secondary: secondaryColor,
    fontColor,

    // Approximate judgements of the darkness of the theme.
    isPrimaryDark,
    isPrimaryVeryDark,
    isSecondaryDark,
    isSecondaryVeryDark,
    isDarkTheme,

    // Similarity judgments between different colors.
    isPrimaryAndFontColorSimilar,

    // These are used in PublicLayout to set CSS variables.
    // Then, the CSS variables are used directly in CSS.
    ...fontColors,
    ...bgColors,
    ...primaryColors,
  }
}

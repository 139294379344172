// Returns true if it's a valid string.
export function isValidString(str: string): boolean {
  return str && str != "" ? true : false
}

// Returns true if there's sufficient wei.
export function isValidMinimumWei(quantity: number): boolean {
  return quantity && quantity > 0.000000000000000001 ? true : false
}

// Returns true if there's at least 1.
export function isValidMinimumInteger(quantity: number): boolean {
  return quantity && quantity > 0 && Number.isInteger(quantity) ? true : false
}

export function isValidHttpUrl(str: string): boolean {
  let url
  try {
    url = new URL(str)
  } catch (_) {
    return false
  }

  return url.protocol === "http:" || url.protocol === "https:"
}

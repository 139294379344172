import { isSubscriptionToMembershipExpired } from "@/util/memberships"
import { Membership } from "@types"
import { isFreeMembership } from "components/Memberships/SharedFunctions"
import { selectSubscription } from "features/userSlice"
import { useSelector } from "react-redux"

export default function useMembershipSubscriptionInfo(
  membership: Membership,
  memberships?: Membership[]
) {
  const subscription = useSelector(selectSubscription) || undefined
  const hasPaidMembership = memberships?.find(
    (m) => m.id === subscription?.membershipId && !isFreeMembership(m)
  )

  const isPaidMembership = !isFreeMembership(membership)
  const hasActiveSubscription = subscription?.status == "ACTIVE"

  const isFreeAndActivePlan =
    (membership.plans?.some((t) => t.price == 0) ||
      membership.plans?.length == 0) &&
    hasActiveSubscription

  const isSubscribedToThisMembership =
    membership.id === subscription?.membershipId

  const hasActiveSubscriptionToMembership =
    hasActiveSubscription && isSubscribedToThisMembership

  const isExpired = isSubscriptionToMembershipExpired(membership, subscription)
  const isPastDue = subscription?.status === "PAST_DUE"

  return {
    subscription,
    hasPaidMembership,
    isPaidMembership,
    hasActiveSubscription,
    isFreeAndActivePlan,
    isSubscribedToThisMembership,
    hasActiveSubscriptionToMembership,
    isExpired,
    isPastDue,
  }
}

import { Editor, Range } from "@tiptap/core"
import { handlePrompt } from "components/Editor/EmptyState/utils"
import { analytics } from "../hooks/useAnalytics"

export const generateContinuedWriting = (
  editor: Editor,
  range: Range,
  type: "sentence" | "paragraph"
) => {
  // Grab the current title & subtitle
  const title =
    (window.document.getElementById("title") as HTMLInputElement)?.value || ""
  const subtitle =
    (window.document?.getElementById("subtitle") as HTMLInputElement)?.value ||
    ""

  const textBefore = editor.state.doc.textBetween(0, range.from, "\n")

  // Calculate new end including the injected '\n's (ignoring text nodes)
  let nodeCount = 0
  editor.state.doc.descendants((node) => {
    if (node.type.name !== "text") nodeCount++
  })

  const textAfter = editor.state.doc.textBetween(
    range.from,
    editor.state.doc.textContent.length + nodeCount,
    "\n"
  )

  const input = `${textBefore}<INSERT> ${textAfter}`
  handlePrompt(editor, null, analytics.track, type, input, { title, subtitle })
}

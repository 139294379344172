import { GetNoteRespFullOrPreview } from "@types"

// TODO: Change this
export const sortByDate = (
  noteArray?: Array<GetNoteRespFullOrPreview>,
  filterType?: "updatedAt" | "publishedAt"
) => {
  if (!noteArray) return []

  return noteArray.sort((firstEl, secondEl) => {
    filterType ||= "updatedAt"

    if (!secondEl[filterType]) return -Number.MAX_SAFE_INTEGER

    // @ts-ignore
    return secondEl[filterType] - (firstEl[filterType] || 0)
  })
}

import { Dialog } from "@headlessui/react"
import { ArrowUpRightIcon } from "@heroicons/react/24/solid"
import ManageTokens from "components/ManageTokens"
import { selectUsersTokens } from "features/blogSlice"
import { useSelector } from "react-redux"
import clsx from "clsx"
import { SharedProps } from "./SharedProps"

type tokenAction = {
  id: "IMPORT" | ""
  name: string
  description: string
}

const tokenActions: tokenAction[] = [
  {
    id: "IMPORT",
    name: "Import",
    description:
      "Import an existing ERC20 token or NFT. We support Ethereum, Polygon, and other top networks.",
  },
]

export default function GateModalStepChooseToken(props: SharedProps) {
  const selectedGateGroup = props.existingGateGroups?.find(
    (g) => g.id == props.selectedGateGroupId
  )

  const tokens = useSelector(selectUsersTokens)
  console.log("selected by choose token tab modal TOKENS ARE", tokens)

  return (
    <div className="w-80 sm:w-full">
      <Dialog.Title
        as="h3"
        className="text-2xl font-bold text-center text-gray-900 leading-6"
      >
        Choose a token
      </Dialog.Title>
      <div className="mt-6">
        <div>
          {tokens && tokens.length > 0 && (
            <div>
              <Dialog.Title
                as="h4"
                className="text-m font-medium text-gray-900 mt-4"
              >
                Choose an existing token
              </Dialog.Title>
              <div className="overflow-hidden p-1 mt-2">
                <ManageTokens
                  hideAddTokenButton={true}
                  hideEditAndDeleteTokenButton={true}
                  hideSelectButton={false}
                  selectedId={props.selectedExistingParagraphTokenId}
                  setSelectedExistingParagraphTokenId={
                    props.setSelectedExistingParagraphTokenId
                  }
                  tokenIdsToDisable={selectedGateGroup?.enrichedGatingRules?.flatMap(
                    (gr) => {
                      return (
                        ("tokenId" in gr.gatingRequirement &&
                          gr.gatingRequirement.tokenId) ||
                        ""
                      )
                    }
                  )}
                />
              </div>
            </div>
          )}
          <Dialog.Title
            as="h4"
            className="text-m font-medium text-gray-900 mt-4"
          >
            Import a token
          </Dialog.Title>
          <div className="mt-2 grid grid-cols-1">
            {tokenActions.map((action) => (
              <div
                key={action.id}
                className={clsx(
                  "group hover:ring-blue-500 ring-2 shadow-sm shadow-gray-400 outline-none rounded-md p-4 m-2 cursor-pointer",
                  props.mintOrImportToken == action.id
                    ? "ring-blue-500"
                    : "ring-gray-200"
                )}
                onClick={() => {
                  props.setMintOrImportToken(action.id)
                }}
              >
                <span
                  className="pointer-events-none flex justify-end"
                  aria-hidden="true"
                >
                  <ArrowUpRightIcon
                    className={`h-5 w-5  group-hover:text-blue-600 ${
                      props.mintOrImportToken == action.id
                        ? "text-blue-600"
                        : "text-gray-400"
                    }`}
                  />
                </span>
                <div className="flex flex-1">
                  <div className="flex flex-col">
                    <div className="block text-base font-medium text-gray-900">
                      {action.name} Token
                    </div>
                    <div className="mt-1 flex items-center text-sm text-gray-500">
                      {action.description}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

import GateModalInnerSettings from "./GateModalInnerSettings"

import { Fragment, useEffect, useRef, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import Confetti from "react-confetti"
import { GatingContentType } from "@/types/gatingRules"

interface props {
  open: boolean
  setOpen: (val: boolean) => void
  gateContentType: GatingContentType
  embedId?: string // Optional. ID of the embed you want to edit.
  callbackSetIsActive?: (isActive: boolean) => void // Optional. Callback function that gets fed an active state depending on if gating rules exist.
}

export default function GateModal({
  open,
  setOpen,
  gateContentType,
  embedId, // Optional. ID of the embed you want to edit.
  callbackSetIsActive, // Optional. Callback function that gets fed an active state depending on if gating rules exist.
}: props) {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const cancelButtonRef = useRef(null)

  useEffect(() => {
    if (open) {
      setIsSubmitted(false)
    }
  }, [open])

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 z-50 overflow-y-auto top-28 md:top-16 lg:top-auto"
          initialFocus={cancelButtonRef}
          open={open}
          onClose={setOpen}
        >
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            {isSubmitted && <Confetti />}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block px-4 pt-5 pb-4 text-left align-bottom bg-white rounded-lg shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                {!isSubmitted && (
                  <GateModalInnerSettings
                    setOpen={setOpen}
                    gateContentType={gateContentType}
                    embedId={embedId}
                    callbackSetIsActive={callbackSetIsActive}
                  />
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

import {
  Fragment,
  useRef,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  ReactNode,
} from "react"

import { Dialog, Transition } from "@headlessui/react"
import { selectBlog, selectCommunities } from "features/blogSlice"
import { useSelector } from "react-redux"
import { useAppDispatch } from "store"

import {
  getUsersSubscriptionToBlog,
  selectActiveSubscription,
} from "features/userSlice"
import useUser from "hooks/useUser"

import InnerModalSkeleton from "components/SubscribeModal/InnerModalSkeleton"
import { XMarkIcon } from "@heroicons/react/24/outline"
import { Membership, MembershipWithNFT, BlogRecommendation } from "@types"
import ViewMemberships from "components/Memberships/ViewMemberships"
import clsx from "clsx"
import {
  getUpsellMemberships,
  selectUpsellMemberships,
} from "features/membershipSlice"

import InnerModalCommunityOptOuts from "./CommunityOptOuts"

import {
  hasPaidMemberships,
  isFreeMembership,
} from "components/Memberships/SharedFunctions"
import Button from "components/Button"
import ShareButtons from "components/ShareButtons"
import useNoteUrl from "hooks/useNoteUrl"
import { EnrichedGatingRuleGroup } from "@/types/gatingRules"
import { detectMembershipsToDisableDueToInsufficientPermissions } from "components/ReaderGates/ReaderGate"
import { EmailAndWallet } from "./EmailAndWallet/EmailAndWallet"
import WalletPrompt from "./EmailAndWallet/WalletPrompt"
import { useAnalytics } from "use-analytics"
import useSubscribeModalState from "hooks/useSubscribeModalState"
import ClaimNFTButton from "components/Memberships/MembershipCard/ClaimNFTButton"
import { ArrowRightIcon } from "@heroicons/react/24/solid"
import { openseaUrlFromMembership } from "util/format"
import { getRecommendations } from "api_routes/blogs"
import Placeholder from "components/Placeholder"
import UserAvatar from "components/UserAvatar"
import SubscribeButtonInline from "components/SubscribeButtonInline"

interface modelProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

/**
 * This is a finite state machine that controls the modal.
 */
export type ModalState = "SUBSCRIBE" | "MEMBERSHIPS" | "COMMUNITIES" | "SUCCESS"

function SubscribeModalWrapper({
  open,
  setOpen,
  initialFocusRef,
  children,
  animationEndCallback,
  triggeredOnScroll,
  isHideXButton,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  initialFocusRef: any
  children: ReactNode
  animationEndCallback?: () => void
  triggeredOnScroll?: boolean
  isHideXButton?: boolean
}) {
  return (
    <Transition.Root
      show={open}
      as={Fragment}
      afterLeave={animationEndCallback}
    >
      <Dialog
        as="div"
        static
        className="relative z-50 overflow-y-auto"
        initialFocus={initialFocusRef}
        open={open}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-0 text-center sm:items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={clsx(
                  "relative transform overflow-hidden text-left shadow-xl w-full px-4 sm:px-0 bg-white"
                )}
              >
                <div className="absolute top-0 right-0 pt-4 pr-4 block">
                  {!isHideXButton && (
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  )}
                </div>

                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export function SubscribeModalContents({
  setOpen,
  defaultState,
  gatingRuleGroups,
  initialPopup,
  appearOnScroll,
  doNotSubscribeImmediately,
  emailPrefilled,
  fadeInClickRef,
}: Omit<modelProps, "open"> & {
  initialPopup?: boolean
  /**
   * Whether we want to skip directly to Memberships.
   * This is used in eg the after-gate, to not display the initial Subscribe Now.
   */
  defaultState?: ModalState
  /**
   * If defaultState === "MEMBERSHIPS" we want to pass in the gatingRuleGroups
   * so we highlight the right memberships that grant/don't grant access.
   */
  gatingRuleGroups?: EnrichedGatingRuleGroup[]

  appearOnScroll?: boolean

  /**
   * Pass this in as true if you want to not immediately subscribe the user
   * when the modal is opened, for instance when the modal contents is actually
   * being used as an embedded component.
   */
  doNotSubscribeImmediately?: boolean

  /**
   * If this is set, we will prefill the email field with this value.
   */
  emailPrefilled?: string

  /**
   * This is used to detect clicks outside the fade-in scroll post modal to close it.
   */
  fadeInClickRef?: React.Ref<any>
}) {
  const initialFocusRef = useRef(null)
  const subscription = useSelector(selectActiveSubscription)
  const communities = useSelector(selectCommunities)
  const memberships = useSelector(selectUpsellMemberships) as
    | Membership[]
    | undefined
  const [currentState, setCurrentState] = useState<ModalState>(
    defaultState || "SUBSCRIBE"
  )
  const user = useUser()

  const { track } = useAnalytics()
  const [membershipPurchased, setMembershipPurchased] = useState<
    Membership | MembershipWithNFT | undefined
  >()

  const blogHasPaidMemberships = hasPaidMemberships(memberships)

  const { userCanUpgrade } = useSubscribeModalState()

  /**
   * Update the membershipPurchased if the memberships change.
   * This is used to update the membershipPurchased if the user
   * lazy mints an NFT, and the membership now has a contractAddress in it.
   */
  useEffect(() => {
    if (!membershipPurchased) return

    const updatedMembership = memberships?.find(
      (m) => m.id === membershipPurchased.id
    )
    if (!updatedMembership) return

    setMembershipPurchased(updatedMembership)
  }, [memberships])

  if (currentState === "SUBSCRIBE") {
    return handleSubscribeState(doNotSubscribeImmediately)
  } else if (currentState === "MEMBERSHIPS") {
    return handleMembershipState()
  } else if (currentState === "COMMUNITIES") {
    return handleCommunitiesState()
  } else {
    return renderSuccessState()
  }

  /**
   * Multiple possible states:
   * 1. User not logged in. Show CollectEmail if blog has paid memberships, otherwise show CollectEmailAndWallet.
   * 2. User logged in, but no email (no matter if they have a subscription). Show CollectEmail.
   * 3. User logged in, but no wallet (no matter if they have a subscription). Show CollectEmailAndWallet only if blog has NO paid memberships.
   * 4. User logged in, has email and wallet but no subscription. Show CollectEmail and it will subscribe them then redirect.
   * 5. User logged in, has email and wallet and subscription. Redirect.
   * @param doNotSubscribeImmediately Pass this in as true if you want to not immediately subscribe the user
   *                                  when the modal is opened, for instance when the modal contents is actually
   *                                  being used as an embedded component.
   */
  function handleSubscribeState(doNotSubscribeImmediately?: boolean) {
    const userSubscribedWithEmailAndWallet =
      subscription && user.hasEmailAndWallet

    // If the user is already subscribed with an email an  wallet,
    // OR, if the user is subscribed and the blog has paid memberships,
    // move to the memberships state automatically.
    if (
      userSubscribedWithEmailAndWallet ||
      (blogHasPaidMemberships && subscription)
    ) {
      console.log("User already has email and wallet and subscription")
      setCurrentState("MEMBERSHIPS")
      return null
    }

    return (
      <div className="max-w-lg mx-auto" ref={fadeInClickRef}>
        <EmailAndWallet
          appearOnScroll={appearOnScroll}
          initialPopup={initialPopup}
          initialFocusRef={initialFocusRef}
          continueCallback={() => {
            setCurrentState("MEMBERSHIPS")
          }}
          setOpen={setOpen}
          doNotSubscribeImmediately={doNotSubscribeImmediately}
          emailPrefilled={emailPrefilled}
        />
      </div>
    )
  }

  function handleMembershipState() {
    console.log("handleMembershipState", userCanUpgrade, user)

    if (user.loggedIn && !userCanUpgrade) {
      setCurrentState("COMMUNITIES")
      return null
    }
    const membershipsToDisable =
      detectMembershipsToDisableDueToInsufficientPermissions(
        memberships || [],
        gatingRuleGroups || []
      )
    return (
      <div ref={fadeInClickRef}>
        <InnerModalSkeleton
          title="Choose your membership level"
          body={
            <InnerModalMembershipUpsell
              setState={setCurrentState}
              membershipsToDisable={membershipsToDisable}
              setMembershipPurchased={setMembershipPurchased}
            />
          }
        />
      </div>
    )
  }

  function handleCommunitiesState() {
    if (!communities || communities.length === 0) {
      setCurrentState("SUCCESS")
      return null
    }
    return (
      <div ref={fadeInClickRef}>
        <InnerModalSkeleton
          title="Choose which newsletters to receive."
          subtitle="Customize your subscription."
          body={
            <div className="max-w-2xl mx-auto">
              <InnerModalCommunityOptOuts
                subscription={subscription}
                communities={communities}
                setOpen={setOpen}
                setState={setCurrentState}
              />
            </div>
          }
        />
      </div>
    )
  }

  function renderSuccessState() {
    track("subscribe modal success view", {
      membershipPurchased,
      appearOnScroll,
      initialPopup,
    })

    return (
      <div>
        <MintOrConnectWallet
          membership={membershipPurchased}
          setOpen={setOpen}
          fadeInClickRef={fadeInClickRef}
        />
      </div>
    )
  }
}

/**
 * Display a button that prompts the user to either mint or connect their wallet.
 * Note that this is ONLY for blogs that have paid memberships.
 * For free memberships only, we prompt them to connect their wallet much earlier in the flow.
 */
function MintOrConnectWallet(props: {
  membership?: Membership | MembershipWithNFT
  setOpen: Dispatch<SetStateAction<boolean>>
  fadeInClickRef?: React.Ref<any>
}) {
  const user = useUser()
  const blog = useSelector(selectBlog)
  const { fullNoteUrl } = useNoteUrl({ blog })
  const subscription = useSelector(selectActiveSubscription)
  const userOwnsNFT = !!subscription?.tokenId

  const [claimState, setClaimState] = useState<
    "CLAIM" | "CONNECT_WALLET" | "SUCCESS"
  >("CLAIM")
  const [subscribeFlowState, setSubscribeFlowState] = useState<
    "CLAIM" | "CONNECT_WALLET" | "RECOMMENDATIONS" | "DONE"
  >(
    !userOwnsNFT && props.membership?.hasNFT
      ? "CLAIM"
      : !user.hasWallet && props.membership?.hasNFT
      ? "CONNECT_WALLET"
      : blog.recommendations
      ? "RECOMMENDATIONS"
      : "DONE"
  )

  console.log("SUBSCRIBE FLOW STATE: ", subscribeFlowState)

  const [recommendations, setRecommendations] = useState<BlogRecommendation[]>()

  useEffect(() => {
    if (!blog.recommendations || recommendations) return

    console.log("Fetching recommendations")

    const fetchRecommendationData = async () => {
      // Trigger loading state
      setRecommendations(undefined)
      const resp = await getRecommendations(blog.id)
      if ("msg" in resp) setRecommendations([])
      else setRecommendations(resp)
    }

    fetchRecommendationData()
  }, [blog.id, blog.recommendations, recommendations])

  const Wrapper = ({
    children,
    title,
    subtitle,
    shareText,
    noMargin = false,
    fadeInClickRef,
  }: {
    children?: ReactNode
    title: string
    subtitle: string | React.ReactNode
    shareText?: string
    continueReading?: boolean
    continueToRecommendations?: boolean
    continueToFinal?: boolean
    noMargin?: boolean
    fadeInClickRef?: React.Ref<any>
  }) => {
    return (
      <InnerModalSkeleton
        title={title}
        subtitle={subtitle}
        className="max-w-md justify-center mx-auto space-y-8 p-8"
        fadeInClickRef={fadeInClickRef}
        body={
          <div
            className={`mt-4 flex justify-center flex-col space-y-8 max-w-md ${
              noMargin ? "" : "mx-auto "
            }`}
          >
            {children}

            {shareText && (
              <ShareButtons
                socialMediaShareText={shareText}
                url={fullNoteUrl}
              />
            )}
            {subscribeFlowState === "CLAIM" && (
              <Button
                onClick={() =>
                  recommendations
                    ? setSubscribeFlowState("RECOMMENDATIONS")
                    : setSubscribeFlowState("DONE")
                }
                replaceClassName="text-xs w-32 mx-auto text-gray-500 flex flex-row justify-center"
              >
                Continue <ArrowRightIcon className="h-4 w-4 ml-1" />
              </Button>
            )}
            {subscribeFlowState === "RECOMMENDATIONS" && (
              <Button
                onClick={() => setSubscribeFlowState("DONE")}
                replaceClassName="text-xs w-32 mx-auto text-gray-500 flex flex-row justify-center"
              >
                Continue <ArrowRightIcon className="h-4 w-4 ml-1" />
              </Button>
            )}
            {subscribeFlowState === "DONE" ? (
              <Button
                onClick={() => props.setOpen(false)}
                replaceClassName="text-xs w-32 mx-auto text-gray-500 flex flex-row justify-center"
              >
                Continue Reading <ArrowRightIcon className="h-4 w-4 ml-1" />
              </Button>
            ) : null}
          </div>
        }
      />
    )
  }

  // User collected and subscribe flow done -> prompt to share on social media.
  if (userOwnsNFT && subscribeFlowState === "DONE") {
    return (
      <Wrapper
        title={`You're all set! 🎉`}
        subtitle={
          <div className="space-y-3">
            <div className="flex flex-col space-y-3">
              <div>Thanks for becoming a member!</div>
              {props.membership && "contractAddress" in props.membership && (
                <a
                  href={openseaUrlFromMembership(
                    props.membership,
                    subscription
                  )}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button type="button" text="View NFT On OpenSea" />
                </a>
              )}
            </div>
            <div> Share your new membership with your friends on:</div>
          </div>
        }
        shareText={`I just became a member of ${blog.name} on Paragraph! 🎉`}
        fadeInClickRef={props.fadeInClickRef}
      />
    )
  }

  // Wallet connected and membership has NFT -> prompt to claim NFT
  if (subscribeFlowState === "CLAIM" && props.membership) {
    return (
      <Wrapper
        title={`Just one more step!`}
        subtitle={`Just one more step to claim your ${props.membership.name} NFT.`}
        fadeInClickRef={props.fadeInClickRef}
      >
        <ClaimNFTButton
          membership={props.membership}
          claimState={claimState}
          setClaimState={setClaimState}
        />
      </Wrapper>
    )
  }

  // No wallet connected but membership has NFT -> prompt to connect wallet
  if (subscribeFlowState === "CONNECT_WALLET" && props.membership) {
    return (
      <Wrapper
        title={`You're all set! 🎉`}
        subtitle={`Thanks for becoming a member!`}
        fadeInClickRef={props.fadeInClickRef}
      >
        <div className="flex flex-col border-gray-150 rounded-xl border max-w-md">
          <WalletPrompt
            title={`Claim your ${props.membership.name} NFT!`}
            description={`Connect your wallet to unlock benefits, rewards, airdrops and more.`}
          />
        </div>
      </Wrapper>
    )
  }

  // Blog has recommendations -> show recommendations
  if (subscribeFlowState === "RECOMMENDATIONS") {
    return (
      <Wrapper
        title="Recommendations"
        subtitle={`${blog.name} recommends these other newsletters.`}
        noMargin
        continueReading
        fadeInClickRef={props.fadeInClickRef}
      >
        {!recommendations ? (
          <div className="mx-auto">
            <Placeholder />
          </div>
        ) : (
          <ol>
            {recommendations.map((r) => (
              <li key={r.id} className="mb-8 space-y-2">
                <div className="flex flex-row justify-between">
                  <div className="flex items-center gap-4">
                    <a href={r.url} target="_blank">
                      <UserAvatar url={r.logo_url} avatarType="blog" />
                    </a>
                    <a href={r.url} target="_blank">
                      <p className="font-bold text-xl">{r.name}</p>
                    </a>
                  </div>
                  <SubscribeButtonInline
                    blog={{ id: r.id, lowercase_url: r.lowercase_url }}
                    referrer={blog}
                  />
                </div>
                {r.reason ? (
                  <p className="mt-1 text-gray-500 italic">
                    &quot;{r.reason}&quot;
                  </p>
                ) : r.summary ? (
                  <p className="mt-1 text-gray-500">{r.summary}</p>
                ) : null}
              </li>
            ))}
          </ol>
        )}
      </Wrapper>
    )
  }

  // Wallet connected but nothing else left to do.
  if (user.hasWallet && subscribeFlowState === "DONE") {
    return (
      <Wrapper
        title={`You're all set! 🎉`}
        subtitle={
          <div className="space-y-3">
            <div>Thanks for subscribing!</div>
            <div> Share your new subscription on:</div>
          </div>
        }
        shareText={`I just subscribed to ${blog.name} on Paragraph! 🎉`}
        fadeInClickRef={props.fadeInClickRef}
      />
    )
  }

  // No wallet connected and membership has no NFT -> just prompt to connect wallet
  return (
    <Wrapper
      title={`You're all set! 🎉`}
      subtitle={`Thanks for becoming a member! Connect your wallet to unlock benefits, rewards, airdrops and more!`}
      continueToRecommendations
      fadeInClickRef={props.fadeInClickRef}
    >
      <div className="flex flex-col border-gray-150 rounded-xl border max-w-md">
        <WalletPrompt />
      </div>
    </Wrapper>
  )
}

export default function SubscribeModal({
  open,
  setOpen,
  defaultState,
  gatingRuleGroups,
  initialPopup,
  triggeredOnScroll,
  onAnimationEnd,
  isHideXButton,
  doNotSubscribeImmediately,
  emailPrefilled,
}: modelProps & {
  /** Whether this is the initial popup that we show to users,
   * or if this is explicitly triggered by users clicking the subscribe modal.
   *
   * If this is the initial popup, we show different text and never auto-subscribe the user.
   */
  initialPopup?: boolean
  /**
   * Whether this modal was triggered by the user scrolling down the page.
   */
  triggeredOnScroll?: boolean
  defaultState?: ModalState
  gatingRuleGroups?: EnrichedGatingRuleGroup[]
  onAnimationEnd?: () => void
  isHideXButton?: boolean
  /**
   * Pass this in as true if you want to not immediately subscribe the user
   * when the modal is opened, for instance when the modal contents is actually
   * being used as an embedded component.
   */
  doNotSubscribeImmediately?: boolean
  /**
   * If this is set, we will prefill the email field with this value.
   */
  emailPrefilled?: string
}) {
  const initialFocusRef = useRef(null)
  const dispatch = useAppDispatch()
  const blog = useSelector(selectBlog)
  const memberships = useSelector(selectUpsellMemberships)

  const { track } = useAnalytics()

  useEffect(() => {
    if (!blog.id) return
    if (memberships && memberships.length > 0) return
    console.log("Getting upsell memberships...")
    dispatch(getUpsellMemberships(blog.id, null))
  }, [dispatch, blog.id, memberships])

  useEffect(() => {
    dispatch(getUsersSubscriptionToBlog(blog.id))
  }, [dispatch, blog.id])

  useEffect(() => {
    if (!open) return
    track("subscribe modal opened", { triggeredOnScroll, initialPopup })
  }, [open])

  return (
    <SubscribeModalWrapper
      open={open}
      setOpen={setOpen}
      initialFocusRef={initialFocusRef}
      animationEndCallback={onAnimationEnd}
      triggeredOnScroll={triggeredOnScroll}
      isHideXButton={isHideXButton}
    >
      <SubscribeModalContents
        setOpen={setOpen}
        defaultState={defaultState}
        gatingRuleGroups={gatingRuleGroups}
        initialPopup={initialPopup}
        doNotSubscribeImmediately={doNotSubscribeImmediately}
        emailPrefilled={emailPrefilled}
      />
    </SubscribeModalWrapper>
  )
}

function InnerModalMembershipUpsell({
  setState,
  membershipsToDisable,
  setMembershipPurchased,
}: {
  setState: Dispatch<SetStateAction<ModalState>>
  membershipsToDisable?: string[]
  setMembershipPurchased: Dispatch<
    SetStateAction<Membership | MembershipWithNFT | undefined>
  >
}) {
  const { track } = useAnalytics()

  useEffect(() => {
    track("subscribe modal memberships upsell viewed")
  }, [])

  function handleBuyOrContinueFreeMembership(membership: Membership) {
    if (!isFreeMembership(membership)) {
      setMembershipPurchased(membership)
    }
    setState("COMMUNITIES")
    console.log("Callback proceeding")
  }

  return (
    <div className="mb-6">
      <div className="flex mt-10 justify-center">
        <ViewMemberships
          selectAppropriateBlog={selectBlog}
          selectMemberships={selectUpsellMemberships}
          isAuthorView={false}
          isOnUpsellPage={true}
          buyCallback={handleBuyOrContinueFreeMembership}
          hideCancelButton={true}
          disabledDueToInsufficientPermissions={membershipsToDisable}
          isOnDedicatedMembershipsPage={false}
        />
      </div>
    </div>
  )
}

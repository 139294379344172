import { Node, nodePasteRule } from "@tiptap/core"
import { Editor, NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react"

export const YOUTUBE_REGEX =
  /^(https?:\/\/)?(www\.|music\.)?(youtube\.com|youtu\.be)(.+)?$/
export const YOUTUBE_REGEX_GLOBAL =
  /^(https?:\/\/)?(www\.|music\.)?(youtube\.com|youtu\.be)(.+)?$/g
// https://regex101.com/r/mKvdcn/1
export const YOUTUBE_ID_REGEX =
  /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(live\/)|(embed\/)|(watch\?))\??(v=)?([^#\&\?]*).*/

const extractVideoID = (url: string) => {
  const match = url.match(YOUTUBE_ID_REGEX)
  console.log(match)
  if (match && match[9]?.length == 11) {
    return match[9]
  } else {
    return
  }
}

export interface YoutubeOptions {
  HTMLAttributes: Record<string, any>
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    youtube: {
      setYoutubeVideo: (options: {
        src: string
        alt?: string | undefined
        title?: string
        blurDataURL?: string
        float?: "left" | "none" | "right"
      }) => ReturnType
    }
  }
}

export default Node.create<YoutubeOptions>({
  name: "youtube",
  group: "block",
  draggable: true,
  atom: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  addAttributes() {
    return {
      videoId: { default: null },
      alt: { default: null },
      title: { default: null },
      blurDataURL: { default: null },
      float: { default: "none" },
    }
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ]
  },

  addCommands() {
    return {
      setYoutubeVideo:
        (options) =>
        ({ commands }) => {
          const videoId = extractVideoID(options.src)
          if (!videoId) return false
          return commands.insertContent({
            type: this.name,
            attrs: { ...options, videoId },
          })
        },
    }
  },

  addPasteRules() {
    return [
      nodePasteRule({
        find: YOUTUBE_REGEX_GLOBAL,
        type: this.type,
        getAttributes: (match) => {
          if (!match.input) return false
          const videoId = extractVideoID(match.input)
          if (!videoId) return false
          return { videoId }
        },
      }),
    ]
  },

  renderHTML({ HTMLAttributes }) {
    const { videoId } = HTMLAttributes

    const html = `
      <div class="youtube-player" data-id="${videoId}" style="background-image: url('https://i.ytimg.com/vi/${videoId}/hqdefault.jpg'); background-size: cover; background-position: center">
        <a href="https://www.youtube.com/watch?v=${videoId}">
          <img src="{{DOMAIN}}/editor/youtube/play.png" class="play"/>
        </a>
      </div>`

    return ["div", { "data-type": this.name, videoId, html }]
  },

  addNodeView() {
    return ReactNodeViewRenderer(YoutubeComponent)
  },
})

type Props = {
  editor: Editor
  node: any
}

const YoutubeComponent = ({ editor, node }: Props): JSX.Element => {
  const { videoId, title } = node.attrs
  const html = `<lite-youtube class='embed rounded-md' videoid="${videoId}" id="youtube-${videoId}" style="background-image: url('https://i.ytimg.com/vi/${videoId}/hqdefault.jpg');">
        <a href="https://youtube.com/watch?v=${videoId}" class="lty-playbtn" title="Play Video">
          <span class="lyt-visually-hidden">Play ${title || "Video"}</span>
        </a>
      </lite-youtube>`

  return (
    <NodeViewWrapper className="react-component my-5" data-drag-handle>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </NodeViewWrapper>
  )
}

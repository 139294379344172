"use client"
import UnderLine from "@tiptap/extension-underline"
import Commands from "components/Editor/Suggestion/commands"
import { getSuggestionItems } from "components/Editor/Suggestion/items"
import renderItems from "components/Editor/Suggestion/renderItems"
import { Placeholder } from "@tiptap/extension-placeholder"
import Focus from "@tiptap/extension-focus"
import TaskItem from "@tiptap/extension-task-item"
import TaskList from "@tiptap/extension-task-list"
import TextAlign from "@tiptap/extension-text-align"
import Table from "@tiptap/extension-table"
import TableRow from "@tiptap/extension-table-row"
import TableCell from "@tiptap/extension-table-cell"
import TableHeader from "@tiptap/extension-table-header"
import Link from "components/Editor/Link"
import Image from "components/Editor/Image"
import Youtube from "components/Editor/Youtube"
import HighlightEmbed from "components/Editor/HighlightNFTEmbed/HighlightNFTEmbed"
import TwitterNode from "components/Editor/Twitter/TwitterNode"
import Callout from "components/Editor/Callout"
import Highlight from "components/Editor/Highlight"
import TrailingNode from "components/Editor/TrailingNode"
import StarterKit from "@tiptap/starter-kit"
import Code from "components/Editor/Code"
import Figure from "components/Editor/Figure/Figure"
import FigureCaption from "components/Editor/Figure/FigureCaption"
import ImageLink from "components/Editor/ImageLink"
import SubscribeButton from "components/Editor/SubscribeButton"
import Embedly from "components/Editor/Embedly"
import AfterGate from "components/Editor/AfterGate"
import ShareButton from "components/Editor/ShareButton"
import EmptyState from "components/Editor/EmptyState"
import AskAi from "components/Editor/AskAi/AskAi"
import CollectButton from "components/Editor/CollectButton"
import HorizontalRule from "components/Editor/HorizontalRule"
import CustomButton from "components/Editor/CustomButton"
import ConnectWalletButton from "components/Editor/ConnectWalletButton"
import Collapsible from "components/Editor/Collapsible"
import {
  CollapsibleContent,
  CollapsibleSummary,
} from "components/Editor/Collapsible/Collapsible"
import Emoji from "@tiptap-pro/extension-emoji"
import Mathematics from "@tiptap-pro/extension-mathematics"
import suggestion from "components/Editor/Emoji/suggestion"
import Zora from "components/Editor/Zora"
import SoundXyz from "components/Editor/SoundXyz"
import Layer3Quest from "components/Editor/Layer3Quest"
import CodeBlock from "components/Editor/CodeBlock"
import { createLowlight, common } from "lowlight"
// @ts-ignore
import { solidity } from "highlightjs-solidity"

export const lowlight = createLowlight(common)
lowlight.register({ solidity })

import { HeadingWithId } from "components/Editor/Heading/Heading"

export const extensions = [
  EmptyState,
  StarterKit.configure({
    codeBlock: false,
    code: false,
    horizontalRule: false,
    heading: false,
  }),
  HeadingWithId,
  CodeBlock.configure({
    lowlight,
    HTMLAttributes: {
      class: "not-prose",
    },
  }),
  Table.configure({
    resizable: true,
    lastColumnResizable: false,
  }),
  TableRow,
  TableHeader,
  TableCell,
  HorizontalRule,
  Image,
  ImageLink,
  Figure,
  FigureCaption,
  Code,
  AskAi,
  Link.configure({
    openOnClick: false,
    HTMLAttributes: {
      class: "dont-break-out",
      rel: "noopener noreferrer nofollow ugc",
    },
  }),
  UnderLine,
  HighlightEmbed,
  Focus.configure({
    mode: "deepest",
  }),
  TwitterNode,
  TaskItem.configure({
    HTMLAttributes: {
      class: "task-list-item",
    },
  }),
  TaskList.configure({
    HTMLAttributes: {
      class: "task-list",
    },
  }),
  TextAlign.configure({
    types: ["heading", "paragraph"],
  }),
  // Note: Stop the default Youtube embed from intercepting the pasteRule
  Youtube.extend({ priority: 1000 }),
  Callout,
  Highlight,
  Zora,
  SoundXyz,
  Layer3Quest,
  SubscribeButton,
  ShareButton,
  CollectButton,
  CustomButton,
  ConnectWalletButton,
  TrailingNode,
  Embedly,
  AfterGate,
  Collapsible.configure({
    persist: true,
    HTMLAttributes: {
      class: "details",
      openClassName: "is-open",
    },
  }),
  CollapsibleSummary,
  CollapsibleContent,
  Emoji.configure({
    HTMLAttributes: {
      class: "emoji",
    },
    enableEmoticons: true,
    suggestion,
  }),
  Mathematics.configure({
    regex: /\$\$([^\$]*)\$\$/gi,
  }),
  Placeholder.configure({
    placeholder: ({ node }) => {
      if (node.type.name === "heading")
        return `Heading ${node.attrs.level || ""}`
      if (node.type.name === "taskItem") return `To-do`
      if (node.type.name === "textInput") return node.attrs.placeholder
      return `Type '/' for commands or 'space' for AI...`
    },
  }),

  Commands.configure({
    suggestion: {
      // @ts-ignore
      items: getSuggestionItems,
      render: renderItems,
    },
  }),
]

import { useState } from "react"
import { Blog, BlogSubscriptionAndMembership, Membership, Plan } from "@types"
import { useAppDispatch } from "store"
import { handleMembershipCheckoutForExistingPaymentMethod } from "../SharedFunctions"
import { StripePaymentForm } from "util/stripe"
import { User } from "@/types/users"
import { StripePaymentMethodObj } from "@/types/stripe"
import Spinner from "components/Spinner"
import { useSelector } from "react-redux"
import { selectCurrentNote } from "features/noteSlice"

export default function StripePayment({
  membership,
  selectedPlan,
  subscriptions,
  coupon,
  blog,
  user,
  stripePaymentMethods,
  successCallback,
}: {
  membership: Membership
  selectedPlan: Plan
  subscriptions: BlogSubscriptionAndMembership[]
  coupon: string | null
  blog: Blog
  user: User
  stripePaymentMethods: StripePaymentMethodObj[]
  successCallback?: () => void
}) {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const noteId = useSelector(selectCurrentNote)?.id || null

  // Subscription exists.
  const isExistingSub =
    subscriptions.find(
      (sub) => sub.subscription.membershipId === membership.id
    ) !== undefined

  // Subscription exists but isn't active.
  const isSubUnsubscribed =
    subscriptions.find((sub) => sub.subscription.membershipId === membership.id)
      ?.subscription.status === "UNSUBSCRIBED"

  if (membership.status !== "ACTIVE") {
    console.warn(
      "Membership not active (set inactive by author), so unable to checkout."
    )
    return null
  }

  if (isExistingSub && !isSubUnsubscribed) {
    console.warn(
      "Subscription already exists and is active, so unable to checkout."
    )

    return (
      <div className="mt-2 mb-8 border border-gray-300 rounded-lg w-100">
        <div className="flex flex-col">
          <div className="flex justify-center items-center w-96">
            <div className="text-lg text-gray-600 text-center my-24">
              Thanks for subscribing!
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (!selectedPlan || !selectedPlan.stripePriceId || !blog.stripe_account_id) {
    console.warn("Membership not properly configured, so unable to checkout.")
    return null
  }

  return (
    <div className="mt-2 mb-8 pt-4 border border-gray-300 rounded-lg w-100">
      <div className="flex flex-col">
        <div className="max-h-56 overflow-y-auto">
          {/* Previously used payment methods, clicking which will check out too. */}
          {stripePaymentMethods &&
            stripePaymentMethods.map((paymentMethod) => {
              return (
                <div
                  onClick={async () => {
                    setIsLoading(true)

                    await handleMembershipCheckoutForExistingPaymentMethod(
                      selectedPlan,
                      blog,
                      noteId,
                      user,
                      membership,
                      coupon,
                      dispatch,
                      successCallback,
                      paymentMethod
                    )

                    setIsLoading(false)
                  }}
                  key={paymentMethod.method.id}
                  className={`credit-card ${paymentMethod.method.brand} selectable`}
                >
                  {isLoading ? (
                    <div className="flex justify-center mt-2">
                      <Spinner width={4} height={4} />
                    </div>
                  ) : (
                    <>
                      <div className="credit-card-last4">
                        {paymentMethod.method.last4}
                      </div>
                      <div className="credit-card-expiry">
                        {paymentMethod.method.exp_month}/
                        {paymentMethod.method.exp_year}
                      </div>
                    </>
                  )}
                </div>
              )
            })}
        </div>
        {/* Payment form for new credit cards. */}
        <StripePaymentForm
          // ignoring because there is an undefined check above already, but typescript doesn't seem to know that.
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          blogId={blog.id}
          membership={membership.id}
          planType={
            selectedPlan.period ? "stripe-subscription" : "stripe-payment"
          }
          coupon={coupon}
          plan={selectedPlan.stripePriceId!}
          successCallback={successCallback}
          isLoadingExistingPayment={isLoading}
        />
      </div>
    </div>
  )
}

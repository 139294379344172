import { Dispatch, Fragment, SetStateAction, useState } from "react"

import { Blog, Membership } from "@types"
import Button from "../../../Button"
import { Frequency } from "../../SharedTypes"
import { useRouter } from "next/router"
import { isSubscribedToThisMembershipAndFrequency } from "../../SharedFunctions"
import { User } from "@/types/users"
import InstantCheckoutConfirmationDialogue from "./InstantCheckoutConfirmationDialogue"
import { Props } from "../SharedTypes"
import useMembershipCardButtonStyles from "hooks/useMembershipCardButtonStyle"
import useMembershipSubscriptionInfo from "hooks/useMembershipSubscriptionInfo"
import ButtonText from "./ButtonText"
import useIsUsersOwnBlog from "hooks/useIsUsersOwnBlog"
import { Menu, Transition } from "@headlessui/react"
import { IoChevronForward } from "react-icons/io5"
import { createLoopCheckoutSession } from "api_routes/loop"
import { useSelector } from "react-redux"
import { selectCurrentNote } from "features/noteSlice"

/** Buy or edit button (depending on whether it's the reader or author view). */
export default function CTAButton({
  currentActionItem,
  setCurrentActionItem,
  props,
  frequency,
  isDisabledDueToInsufficientPermissions,
  isAtLeastOneFreePlan,
  blog,
  user,
  memberships,
  isOnUpsellPage,
  isFormPreview,
  coupon,
  loopOpenWidget,
}: {
  currentActionItem: string
  setCurrentActionItem: Dispatch<SetStateAction<string>>
  props: Props
  frequency: Frequency
  isDisabledDueToInsufficientPermissions: boolean
  isAtLeastOneFreePlan: boolean
  blog: Blog
  user: User
  memberships: Membership[]
  isOnUpsellPage: boolean
  isFormPreview: boolean
  coupon: string | null
  loopOpenWidget(
    itemId: string,
    options?:
      | {
          refId?: string | undefined
        }
      | undefined
  ): void
}) {
  const {
    hideActionButton,
    membership,
    isAuthorView,
    isOnDedicatedMembershipsPage,
    buyCallback,
  } = props

  const router = useRouter()
  const [showInstantCheckoutDialogue, setShowInstantCheckoutDialogue] =
    useState(false)

  const {
    subscription,
    hasPaidMembership,
    isPaidMembership,
    hasActiveSubscription,
    isFreeAndActivePlan,
    isSubscribedToThisMembership,
    isExpired,
    isPastDue,
  } = useMembershipSubscriptionInfo(membership, memberships)

  const isSubscribedToThisFrequency = isSubscribedToThisMembershipAndFrequency(
    frequency,
    subscription,
    membership
  )

  const buttonStyles = useMembershipCardButtonStyles(
    membership,
    memberships,
    isDisabledDueToInsufficientPermissions,
    currentActionItem,
    isSubscribedToThisFrequency,
    isPastDue,
    isAuthorView,
    isFormPreview,
    isOnUpsellPage
  )

  console.log(
    buttonStyles,
    isFreeAndActivePlan,
    subscription,
    !isSubscribedToThisMembership,
    isOnDedicatedMembershipsPage
  )

  const noteId = useSelector(selectCurrentNote)?.id || null

  const isUsersOwnBlog = useIsUsersOwnBlog()

  if (hideActionButton) return null

  const loopItemId = membership.plans.find(
    (p) => p.period === frequency.id
  )?.loopItemId

  const stripePriceId = membership.plans.find(
    (p) => p.period === frequency.id
  )?.stripePriceId

  return (
    <div className="flex-none flex justify-center w-full">
      {!showInstantCheckoutDialogue &&
        (!isAuthorView && stripePriceId && loopItemId ? (
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button
                aria-describedby={membership.id}
                className={buttonStyles}
                disabled={
                  !!currentActionItem ||
                  (isPastDue && subscription?.membershipId === membership.id) ||
                  isDisabledDueToInsufficientPermissions ||
                  (hasActiveSubscription &&
                    isSubscribedToThisFrequency &&
                    !isUsersOwnBlog &&
                    !isOnUpsellPage) ||
                  isExpired === "EXPIRED" ||
                  (isFreeAndActivePlan &&
                    !isUsersOwnBlog &&
                    isSubscribedToThisMembership &&
                    !isOnUpsellPage)
                }
              >
                <ButtonText
                  isAuthorView={isAuthorView}
                  isFormPreview={isFormPreview}
                  isDisabledDueToInsufficientPermissions={
                    isDisabledDueToInsufficientPermissions
                  }
                  isLoading={membership.id === currentActionItem}
                  isAtLeastOneFreePlan={isAtLeastOneFreePlan}
                  isOnDedicatedMembershipsPage={isOnDedicatedMembershipsPage}
                  isSubscribedToThisFrequency={isSubscribedToThisFrequency}
                  isPastDue={isPastDue}
                  isOnUpsellPage={isOnUpsellPage}
                  membership={membership}
                  memberships={memberships}
                  showChevron={true}
                />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute left-2 z-10 mt-1 w-[12.5rem] origin-top rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="">
                  {stripePriceId && (
                    <Menu.Item>
                      {() => (
                        <Button
                          onClick={async () => {
                            // If user already has a paid membership, it's a Loop subscription, and they're switching to another paid membership,
                            // advise them that they need to cancel the existing membership first
                            if (
                              hasPaidMembership &&
                              isPaidMembership &&
                              subscription?.planType === "loop-subscription"
                            ) {
                              alert(
                                "In order to switch between paid memberships, you must explicitly cancel the existing one or downgrade to free first."
                              )
                              return
                            }

                            // If user already has a paid membership and they're switching to another paid membership,
                            // then show the instant checkout dialogue as we already have a payment method on file.
                            if (hasPaidMembership && isPaidMembership) {
                              if (!coupon) setShowInstantCheckoutDialogue(true)
                              else
                                alert(
                                  "You cannot use discounts when switching paid plans"
                                )
                              return
                            }

                            setCurrentActionItem(membership.id)

                            // Call callback.
                            // (This will handle buy membership or if already a free member, continue to opt-outs/claiming NFT.)
                            await buyCallback?.(
                              membership,
                              frequency.id,
                              "stripe-subscription"
                            )

                            setCurrentActionItem("")
                          }}
                          replaceClassName="w-full text-gray-700 hover:bg-gray-100 hover:text-gray-900 block px-4 py-2 text-sm font-medium"
                        >
                          <div className="flex justify-between items-center">
                            Subscribe with USD
                            <IoChevronForward />
                          </div>
                        </Button>
                      )}
                    </Menu.Item>
                  )}
                  {blog.loop_enabled && loopItemId && (
                    <Menu.Item>
                      {() => (
                        <Button
                          onClick={async () => {
                            console.log("Loop buy button clicked")

                            // If user already has a paid membership and they're switching to another paid membership,
                            // advise them that they need to cancel the existing membership first
                            if (hasPaidMembership && isPaidMembership) {
                              alert(
                                "In order to switch between paid memberships, you must explicitly cancel the existing one or downgrade to free first."
                              )
                              return
                            }

                            setCurrentActionItem(membership.id)

                            // If user is logged out, call callback to prompt user to register/login and be redirected to purchase page
                            // else, handle crypto checkout
                            if (!user.id)
                              await buyCallback?.(
                                membership,
                                frequency.id,
                                "loop-subscription"
                              )
                            else {
                              const refId = await createLoopCheckoutSession(
                                blog.id,
                                noteId,
                                membership.id,
                                loopItemId,
                                blog.loop_entity_id || "",
                                blog.loop_wallet_address || "",
                                coupon
                              )
                              loopOpenWidget(loopItemId, { refId })
                            }

                            setCurrentActionItem("")
                          }}
                          replaceClassName="w-full text-gray-700 hover:bg-gray-100 hover:text-gray-900 block px-4 py-2 text-sm font-medium"
                        >
                          <span className="flex justify-between items-center">
                            Subscribe with Crypto
                            <IoChevronForward />
                          </span>
                        </Button>
                      )}
                    </Menu.Item>
                  )}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        ) : (
          <Button
            disabled={
              (isSubscribedToThisFrequency || // Disable if we're already subscribed to this frequency.
                (isPastDue && subscription?.id === membership.id)) && // OR we are past due on a payment to this membership
              !isAuthorView && // And we're not looking at it from the settings.
              !isFreeAndActivePlan // And we're not on a free plan. On the free plan we should just be able to click "Continue".
            }
            className="mt-4"
            replaceClassName={buttonStyles}
            onClick={async () => {
              console.log(
                "Edit membership clicked",
                isAuthorView,
                membership.id
              )
              // If author view, redirect to edit membership
              if (isAuthorView) {
                router.push(
                  `/settings/publication/memberships/${membership.id}`
                )
                return
              }

              // If loopItemId defined -> Loop checkout
              if (loopItemId) {
                // If user already has a paid membership and they're switching to another paid membership,
                // advise them that they need to cancel the existing membership first
                if (hasPaidMembership && isPaidMembership) {
                  alert(
                    "In order to switch between paid memberships, you must explicitly cancel the existing one or downgrade to free first."
                  )
                  return
                }

                setCurrentActionItem(membership.id)

                // If user is logged out, call callback to prompt user to register/login and be redirected to purchase page
                // else, handle crypto checkout
                if (!user.id)
                  await buyCallback?.(
                    membership,
                    frequency.id,
                    "loop-subscription"
                  )
                else {
                  const refId = await createLoopCheckoutSession(
                    blog.id,
                    noteId,
                    membership.id,
                    loopItemId,
                    blog.loop_entity_id || "",
                    blog.loop_wallet_address || "",
                    coupon
                  )
                  loopOpenWidget(loopItemId, { refId })
                }

                setCurrentActionItem("")
                return
              }

              // Else, it is Stripe checkout or free sub

              // If user already has a paid membership, it's a Loop subscription, and they're switching to another paid membership,
              // advise them that they need to cancel the existing membership first
              if (
                hasPaidMembership &&
                isPaidMembership &&
                subscription?.planType === "loop-subscription"
              ) {
                alert(
                  "In order to switch between paid memberships, you must explicitly cancel the existing one or downgrade to free first."
                )
                return
              }

              // If user already has a paid membership and they're switching to another paid membership,
              // then show the instant checkout dialogue as we already have a payment method on file.
              if (hasPaidMembership && isPaidMembership) {
                if (!coupon) setShowInstantCheckoutDialogue(true)
                else alert("You cannot use discounts when switching paid plans")
                return
              }

              setCurrentActionItem(membership.id)

              // Call callback.
              // (This will handle buy membership or if already a free member, continue to opt-outs/claiming NFT.)
              await buyCallback?.(
                membership,
                frequency.id,
                "stripe-subscription"
              )

              setCurrentActionItem("")
            }}
            text={
              <ButtonText
                isAuthorView={isAuthorView}
                isFormPreview={isFormPreview}
                isDisabledDueToInsufficientPermissions={
                  isDisabledDueToInsufficientPermissions
                }
                isAtLeastOneFreePlan={isAtLeastOneFreePlan}
                isLoading={membership.id === currentActionItem}
                isOnDedicatedMembershipsPage={isOnDedicatedMembershipsPage}
                isSubscribedToThisFrequency={isSubscribedToThisFrequency}
                isPastDue={isPastDue}
                isOnUpsellPage={isOnUpsellPage}
                membership={membership}
                memberships={memberships}
                type={loopItemId ? "loop" : "stripe"}
              />
            }
          />
        ))}
      {showInstantCheckoutDialogue && (
        <InstantCheckoutConfirmationDialogue
          isButtonLoading={!!currentActionItem}
          setCurrentActionItem={setCurrentActionItem}
          showInstantCheckoutDialogue={showInstantCheckoutDialogue}
          setShowInstantCheckoutDialogue={setShowInstantCheckoutDialogue}
          membership={membership}
          frequency={frequency}
          blog={blog}
          user={user}
        />
      )}
    </div>
  )
}

// This component displays the Done, Cancel, Back, and Enable Gate buttons.

import clsx from "clsx"
import CreateGateBtn from "./CreateGateBtn"
import DoneBtn from "./DoneBtn"
import BackBtn from "./BackBtn"
import NextBtn from "./NextBtn"
import { SharedProps } from "../SharedProps"

export default function ButtonController(props: SharedProps) {
  const currentStepIs = (stepName: string) => props.currentStep.id === stepName

  const hasGateGroups =
    props.existingGateGroups && props.existingGateGroups.length > 0
  const hasMoreThanOneStep = props.steps && props.steps.length > 1

  const showCreateGateBtn =
    currentStepIs("SETTINGS") || currentStepIs("CHOOSE_MEMBERSHIP")

  const showDoneBtn =
    currentStepIs("GROUP") || (currentStepIs("GATE_TYPE") && !hasGateGroups)

  const showBackBtn =
    // When there's just a single step, hide the back button.
    (currentStepIs("GATE_TYPE") && hasGateGroups) ||
    (!currentStepIs("GATE_TYPE") && hasMoreThanOneStep)

  const showNextBtn =
    !currentStepIs("SETTINGS") &&
    !currentStepIs("GATE_TYPE") &&
    !currentStepIs("GROUP") &&
    !currentStepIs("CHOOSE_MEMBERSHIP")

  return (
    <div
      className={clsx(
        "mt-5 sm:mt-6 grid gap-3",
        props.currentStep.id == "SETTINGS" // When we're at the settings screen and there's no back button, center the cancel button.
          ? "grid-cols-2 grid-flow-row-dense"
          : props.currentStep.id == "GATE_TYPE" ||
            props.currentStep.id == "GROUP"
          ? "grid-cols-1 px-2 justify-items-center"
          : "grid-cols-2 px-2 justify-items-center grid-flow-row-dense"
      )}
    >
      {showCreateGateBtn && <CreateGateBtn {...props} />}

      {showDoneBtn && <DoneBtn {...props} />}

      {showBackBtn && <BackBtn {...props} />}

      {showNextBtn && <NextBtn {...props} />}
    </div>
  )
}

import { Token } from "@/types/gatingRules"
import { BalanceResp } from "@types"
import { getBalances } from "api_routes/crypto"
import { selectCurrentTransactionHash, selectUser } from "features/userSlice"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

export default function useBalances(
  tokensToFetchBalancesFor: Token[],
  requestCameFrom: string
) {
  const user = useSelector(selectUser)
  const currentTransactionHash = useSelector(selectCurrentTransactionHash)
  const [balances, setBalances] = useState<BalanceResp[]>([])

  const refreshBalances = async () => {
    // Only retrieve balances for logged in users.
    if (tokensToFetchBalancesFor.length < 1 || !user?.id) {
      console.log(
        "GatingRules: - useBalances. Not running refreshBalances() as not a logged in user or 0 tokens.",
        requestCameFrom
      )
      return
    }

    console.log(
      "GatingRules: - useBalances. Running refreshBalances() for a logged in user with more than 0 tokens.",
      requestCameFrom
    )

    const tokenBalances = await getBalances(
      tokensToFetchBalancesFor.map((t) => t.id)
    )

    setBalances(tokenBalances)
  }

  useEffect(() => {
    refreshBalances()
  }, [user?.id, currentTransactionHash, tokensToFetchBalancesFor])

  return balances
}

import clsx from "clsx"
import { Membership } from "@types"
import Spinner from "components/Spinner"
import { BtnDetails as Btns } from "./ButtonTypes"
import useMembershipSubscriptionInfo from "hooks/useMembershipSubscriptionInfo"
import { IoChevronDownSharp } from "react-icons/io5"

export default function CTAButtonText({
  isAuthorView,
  isDisabledDueToInsufficientPermissions,
  isAtLeastOneFreePlan,
  isLoading,
  isOnDedicatedMembershipsPage,
  isSubscribedToThisFrequency,
  isPastDue,
  isOnUpsellPage,
  isFormPreview,
  membership,
  memberships,
  showChevron,
  type,
}: {
  isAuthorView: boolean
  isDisabledDueToInsufficientPermissions: boolean
  isAtLeastOneFreePlan: boolean
  isLoading: boolean
  isOnDedicatedMembershipsPage: boolean
  isSubscribedToThisFrequency: boolean
  isPastDue: boolean
  isOnUpsellPage: boolean
  isFormPreview: boolean
  membership: Membership
  memberships: Membership[]
  showChevron?: boolean
  type?: "stripe" | "loop"
}) {
  const {
    subscription,
    hasActiveSubscription,
    isFreeAndActivePlan,
    isSubscribedToThisMembership,
    hasActiveSubscriptionToMembership,
    isExpired,
  } = useMembershipSubscriptionInfo(membership, memberships)

  const hasSubscription = subscription !== undefined

  if (isAuthorView) {
    return <CreateBtn {...Btns["EDIT_MEMBERSHIP"]} isLoading={isLoading} />
  }

  if (isFormPreview) {
    return (
      <CreateBtn
        {...Btns["SUBSCRIBE"]}
        isLoading={isLoading}
        showChevron={showChevron}
        type={type}
      />
    )
  }

  if (isDisabledDueToInsufficientPermissions) {
    return <CreateBtn {...Btns["INSUFFICIENT_ACCESS"]} isLoading={isLoading} />
  }

  if (isExpired === "EXPIRED") {
    return <CreateBtn {...Btns["EXPIRED"]} isLoading={isLoading} />
  }

  if (isPastDue && isSubscribedToThisMembership) {
    return <CreateBtn {...Btns["PAST_DUE"]} isLoading={isLoading} />
  }

  if (
    !isFreeAndActivePlan &&
    isSubscribedToThisMembership &&
    isOnDedicatedMembershipsPage &&
    !isSubscribedToThisFrequency &&
    (isExpired === "ACTIVE" || isExpired === "NOT_APPLICABLE")
  ) {
    return (
      <CreateBtn
        {...Btns["CHANGE_BILLING_FREQUENCY"]}
        isLoading={isLoading}
        showChevron={showChevron}
      />
    )
  }

  if (
    isFreeAndActivePlan &&
    isSubscribedToThisMembership &&
    isOnDedicatedMembershipsPage
  ) {
    return <CreateBtn {...Btns["SUBSCRIBED"]} isLoading={isLoading} />
  }

  if (
    isFreeAndActivePlan &&
    hasSubscription &&
    !isSubscribedToThisMembership &&
    isOnDedicatedMembershipsPage
  ) {
    return <CreateBtn {...Btns["DOWNGRADE_FOR_FREE"]} isLoading={isLoading} />
  }

  if (
    isFreeAndActivePlan &&
    isSubscribedToThisMembership &&
    !isOnDedicatedMembershipsPage
  ) {
    return <CreateBtn {...Btns["CONTINUE"]} isLoading={isLoading} />
  }

  if (isAtLeastOneFreePlan) {
    return <CreateBtn {...Btns["JOIN_FOR_FREE"]} isLoading={isLoading} />
  }

  if (hasActiveSubscription && !isSubscribedToThisMembership) {
    if (isOnUpsellPage) {
      return (
        <CreateBtn
          {...Btns["SUBSCRIBE"]}
          isLoading={isLoading}
          showChevron={showChevron}
          type={type}
        />
      )
    } else {
      return (
        <CreateBtn
          {...Btns["CHANGE_SUBSCRIPTION"]}
          isLoading={isLoading}
          showChevron={showChevron}
        />
      )
    }
  }

  if (hasActiveSubscriptionToMembership) {
    return <CreateBtn {...Btns["SUBSCRIBED"]} isLoading={isLoading} />
  }

  return (
    <CreateBtn
      {...Btns["SUBSCRIBE"]}
      isLoading={isLoading}
      showChevron={showChevron}
      type={type}
    />
  )
}

/**
 * Creates a button with a spinner if isLoading is true.
 * @param param0 Supply isLoading, obtained from some state, and BtnDetails, obtained from the BtnDetails object.
 * @returns A button with a spinner if isLoading is true.
 */
function CreateBtn({
  text,
  isLoading,
  px,
  spinnerRight,
  showChevron,
  type,
}: {
  isLoading?: boolean
  showChevron?: boolean
  type?: "stripe" | "loop"
} & Btns) {
  return (
    <div
      className={clsx(
        "flex justify-center relative",
        px !== undefined ? `px-${px}` : "px-"
      )}
    >
      <span className="flex items-center gap-2">
        {text}
        {type === "loop" && " with Crypto"}
        {showChevron && <IoChevronDownSharp />}
      </span>
      {isLoading && (
        <div className={clsx("absolute top-1", spinnerRight || "")}>
          <Spinner height={4} width={4} />
        </div>
      )}
    </div>
  )
}

import { CheckIcon, ExclamationCircleIcon } from "@heroicons/react/24/solid"
import { ContractMetadataResp } from "@types"

import { getContractMetadata } from "api_routes/crypto"
import { selectUsersTokens } from "features/blogSlice"
import { useSelector } from "react-redux"
import clsx from "clsx"
import { isValidHttpUrl, isValidMinimumWei } from "./ValidationHelpers"
import { SharedProps } from "../SharedProps"
import { useEffect, useState } from "react"

export default function ImportTokenDetails(props: SharedProps) {
  // Array of existing tokens for this blog, used to dedupe any new tokens coming in.
  const existingTokens = useSelector(selectUsersTokens)
  const [isMinQuantityAltered, setIsMinQuantityAltered] = useState(false)
  const [isUrlAltered, setIsUrlAltered] = useState(false)

  const [contract, setContract] = useState(
    props.importTokenData.contractAddress || ""
  )
  const [contractData, setContractData] = useState<
    ContractMetadataResp | undefined
  >()
  const [duplicateContractAddress, setDuplicateContractAddress] =
    useState(false)

  useEffect(() => {
    if (contractData?.success) {
      props.setImportTokenData({
        ...props.importTokenData,
        contractAddress: contract,
        name: contractData.name,
        network: contractData.network,
      })
    }
  }, [contractData, contract, props.minQuantity])

  // Verify that the contract address is to a valid ERC20 token.
  useEffect(() => {
    if (!contract) {
      setContractData(undefined)
      setDuplicateContractAddress(false)

      return
    }

    // But before that, make sure we're not adding a contract we've already added before.
    // Check for uniqueness along contract address and network.
    if (
      existingTokens?.some((t) => t.onChainData.contractAddress == contract)
    ) {
      setContractData(undefined)
      setDuplicateContractAddress(true)

      return
    }

    getContractMetadata(contract).then((val) => {
      setContractData(val)
      setDuplicateContractAddress(false)
    })
  }, [contract])

  return (
    <div>
      {/* <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-lg font-medium text-gray-900 leading-6"
          >
            Edit {membership.name}
          </Dialog.Title>
        </div> */}

      <div className="mt-5 text-gray-500 text-sm">
        Enter the contract address of the token for this requirement. This can
        be any ERC20 token or ERC721 (NFT) on any of the top chains.{" "}
        <a
          href="https://docs.paragraph.xyz/docs/token-gated-content"
          className="text-blue-500 font-medium hover:underline"
          target="_blank"
          rel="noreferrer"
        >
          See our list of supported chains here.
        </a>
      </div>

      <input
        type="text"
        name="token"
        id="token"
        defaultValue={contract}
        autoComplete="token"
        placeholder="0xe182a80e76b1cf17d0eb018d563823357f1ae296"
        className={clsx(
          "mt-2 block w-full rounded-md sm:text-sm",
          contractData && !contractData.success
            ? "border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500"
            : "border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
        )}
        onChange={async (e) => {
          setContract(e.target.value)
          if (contractData && contractData.success) {
            props.setImportTokenData({
              ...props.importTokenData,
              name: e.target.value,
            })
          }
        }}
      />

      <div className="mt-2 text-gray-500 text-sm">
        {contractData && contractData.success ? (
          <div className="flex">
            <CheckIcon className="inline w-5 h-5 text-green-500 mx-1" />
            {contractData.name}
          </div>
        ) : (
          contractData &&
          !contractData.success && (
            <div className="flex">
              <ExclamationCircleIcon
                className="inline h-5 w-5 text-red-500 mx-1"
                aria-hidden="true"
              />
              Invalid contract. Please try again.
            </div>
          )
        )}
        {duplicateContractAddress && (
          <div className="flex">
            <ExclamationCircleIcon
              className="inline h-5 w-5 text-red-500 mx-1"
              aria-hidden="true"
            />
            <span className="">You already have this token imported.</span>
          </div>
        )}
      </div>

      <div className="relative">
        <div className="mt-5 text-gray-500 text-sm">
          What's the minimum number of tokens the user must hold for access?
        </div>

        <input
          type="text"
          name="min-quantity"
          id="min-quantity"
          autoComplete="min-quantity"
          placeholder="1"
          defaultValue={props.minQuantity}
          className={clsx(
            "mt-2 block w-full rounded-md sm:text-sm",
            isMinQuantityAltered && !isValidMinimumWei(props.minQuantity)
              ? "border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500"
              : "border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
          )}
          onChange={async (e) => {
            props.setMinQuantity(parseFloat(e.target.value))
            setIsMinQuantityAltered(true)
          }}
        />

        {isMinQuantityAltered && !isValidMinimumWei(props.minQuantity) && (
          <div className="pointer-events-none absolute inset-y-0 top-5 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}

        {isMinQuantityAltered && !isValidMinimumWei(props.minQuantity) && (
          <p className="mt-2 text-sm text-red-600" id="min-quantity-error">
            Please enter at least 1 Wei.
          </p>
        )}
      </div>

      <div className="relative">
        <div className="mt-5 text-gray-500 text-sm">
          Optionally, enter a URL where readers can purchase your token.
        </div>

        <input
          type="text"
          name="url"
          id="url"
          autoComplete="url"
          defaultValue={props.importTokenData.learnMoreUrl}
          placeholder="https://opensea.io/collection/paragraph"
          className={clsx(
            "mt-2 block w-full rounded-md sm:text-sm",
            isUrlAltered &&
              !isValidHttpUrl(props.importTokenData.learnMoreUrl || "")
              ? "border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500"
              : "border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
          )}
          onChange={async (e) => {
            props.setImportTokenData({
              ...props.importTokenData,
              learnMoreUrl: e.target.value,
            })
            setIsUrlAltered(true)
          }}
        />

        {isUrlAltered &&
          !isValidHttpUrl(props.importTokenData.learnMoreUrl || "") && (
            <div className="pointer-events-none absolute inset-y-0 top-7 right-0 flex items-center pr-3">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
      </div>
    </div>
  )
}

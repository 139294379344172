import axios from "axios"
const endpoint = "https://api.highlight.xyz:8080"
const query = `query ExternalApiGetPublicCollection($collectionId: String!) {
    getPublicCollection(collectionId: $collectionId) {
        id
        name
        editions {
            name
            description
            image
            onChainImage
        }
    }
}`

// Given a collection ID, we query the Highlight endpoint
// to fetch the image.
// This is required to store alongside the TipTap node,
// so we can render the image in the email.
export const getHighlightImg = async (collectionId: string) => {
  const resp = await axios.post(
    endpoint,
    JSON.stringify({ query, variables: { collectionId } }),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  )

  return resp?.data?.data?.getPublicCollection?.editions?.[0]?.image
}

import { Dialog, Transition } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/outline"
import useNoteUrl from "hooks/useNoteUrl"
import { useSelector } from "react-redux"
import { selectCurrentNote, selectLatestCurrentNote } from "features/noteSlice"
import { selectBlog } from "features/blogSlice"
import { useRef } from "react"
import ShareButtons from "components/ShareButtons"
import useUser from "hooks/useUser"

interface modelProps {
  open: boolean
  setOpen: (val: boolean) => void
}

export default function ShareModal({ open, setOpen }: modelProps) {
  const cancelButtonRef = useRef(null)
  const blog = useSelector(selectBlog)
  const noteForPreview = useSelector(selectLatestCurrentNote)
  const noteForPublish = useSelector(selectCurrentNote)
  const note = noteForPreview ? noteForPreview : noteForPublish

  let { fullNoteUrl } = useNoteUrl({ blog, note })

  const user = useUser()
  let exampleReferralUrl = fullNoteUrl + "?referrer=0x1234567890"

  if ("wallet_address" in user && user.wallet_address) {
    fullNoteUrl = fullNoteUrl + "?referrer=" + user.wallet_address
    exampleReferralUrl = fullNoteUrl
  }

  return (
    <div>
      <Transition.Root show={open}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 z-50 overflow-y-auto"
          initialFocus={cancelButtonRef}
          open={open}
          onClose={setOpen}
        >
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              className="fixed inset-0"
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              enter="ease-out duration-300"
              className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom bg-white rounded-lg shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div>
                <button
                  type="button"
                  className="absolute text-gray-400 top-4 right-4 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                </button>
                <div>
                  <div className="mt-3 mb-6 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-3xl font-bold text-gray-900 leading-6"
                    >
                      Share this post
                    </Dialog.Title>
                    <div className="mt-3 mb-4 text-center sm:mt-5 text-gray-500 text-lg">
                      Earn rewards by sharing this post and helping {blog.name}{" "}
                      grow.{" "}
                    </div>
                    <div className="mt-3 mb-4 text-center sm:mt-5 text-gray-500 text-sm">
                      <>
                        Share this post with your wallet address in the URL, and
                        you'll be rewarded for any new readers you bring to{" "}
                        {blog.name}.
                        <div className="block mt-2 font-mono bg-gray-100 rounded-md p-4 text-xs sm:text-sm break-all">
                          {exampleReferralUrl}
                        </div>
                      </>
                    </div>
                    <span className="text-sm text-gray-500">
                      <a
                        href="https://paragraph.xyz/referral-rewards"
                        target="_blank"
                        className="text-blue-500 font-bold text-sm"
                      >
                        Learn more
                      </a>{" "}
                      about Paragraph Referral Rewards.
                    </span>
                  </div>
                </div>

                <div className="flex justify-center mt-3 space-x-6">
                  <ShareButtons
                    url={fullNoteUrl}
                    socialMediaShareText={note?.title}
                  />
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"></div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  )
}

import { useEffect, useState } from "react"
import { subscribeUserToBlog } from "api_routes/blogs"
import { Blog } from "@types"
import { getUsersSubscriptionToBlog } from "api_routes/user"
import { useAnalytics } from "hooks/useAnalytics"
import { CryptoUser } from "@/types/users"
import useUser from "./useUser"

interface Props {
  blog?: Pick<Blog, "id" | "lowercase_url">
  referrer?: Blog
}

// Variation of the regular SubscribeButtonComponent
// that only supports inline subscribing to a specific blog.
export default function useInlineSubscribe({ blog, referrer }: Props) {
  let defaultIsSubscribed = null
  const user = useUser()

  if (!blog || !user.loggedIn) {
    defaultIsSubscribed = false
  }

  const [isUserSubscribed, setIsUserSubscribed] = useState<null | boolean>(
    defaultIsSubscribed
  )

  const [loading, setLoading] = useState(false)
  const { track } = useAnalytics()

  useEffect(() => {
    async function run() {
      if (!blog || !blog?.id || !user.loggedIn) return

      const subscription = await getUsersSubscriptionToBlog(blog.id)
      if (
        !subscription ||
        "msg" in subscription ||
        (subscription && subscription.status === "UNSUBSCRIBED")
      ) {
        setIsUserSubscribed(false)
        return
      }

      setIsUserSubscribed(true)
    }

    run()
  }, [blog?.id])

  const subscribeUser = async () => {
    if (!blog || isUserSubscribed) return
    setLoading(true)
    await subscribeUserToBlog({
      lowercaseBlogUrl: blog.lowercase_url,
      blogId: blog.id,
      skipWelcomeEmail: true,
      referrer: {
        id: blog.id,
        wallet:
          referrer?.collectibleWalletAddress ||
          (referrer?.user as CryptoUser)?.lowercase_wallet_address,
      },
    })
    if (referrer) {
      track("user subscribed as a result of recommend subscribe button", {
        subscribedBlog: blog,
        referrer,
      })
    } else {
      track("user subscribed as a result of inline subscribe button", {
        subscribedBlog: blog,
      })
    }
    setLoading(false)
    setIsUserSubscribed(true)
  }

  return {
    isUserSubscribed,
    loading,
    subscribeUser,
    ableToSubscribe: !!blog && !!user.loggedIn,
  }
}

import { useEffect, useState } from "react"
import SubscribeModal, { ModalState } from "components/SubscribeModal/Modal"
import Button from "components/Button"
import { useAnalytics } from "use-analytics"
import { useSelector } from "react-redux"
import { selectActiveSubscription } from "features/userSlice"
import useIsUsersOwnBlog from "hooks/useIsUsersOwnBlog"

import { selectUpsellMemberships } from "features/membershipSlice"
import {
  hasPaidMemberships,
  subscriptionIsPaid,
} from "./Memberships/SharedFunctions"
import { EnrichedGatingRuleGroup } from "@/types/gatingRules"
import useSubscribeModalState from "hooks/useSubscribeModalState"
import useShowInitialSubscribeModal from "hooks/useShowInitialSubscribeModal"

interface Props {
  className?: string
  replaceClassName?: string
  isEditable?: boolean
  defaultState?: ModalState
  gatingRuleGroups?: EnrichedGatingRuleGroup[]
  useSecondaryTheme?: boolean
  /**
   * If this is passed in, we will override the text of the button.
   */
  overrideText?: string
}

export default function SubscribeButton({
  replaceClassName,
  className,
  isEditable,
  defaultState,
  gatingRuleGroups,
  useSecondaryTheme = false,
  overrideText,
}: Props) {
  const [subscribeModalOpen, setSubscribeModalOpen] = useState(false)
  const subscription = useSelector(selectActiveSubscription)
  const isUsersOwnBlog = useIsUsersOwnBlog()
  const [loading, setLoading] = useState(false)
  const memberships = useSelector(selectUpsellMemberships)

  const { track } = useAnalytics()

  let text = "Subscribe"
  let disabled = false

  const blogHasPaidMemberships = hasPaidMemberships(memberships)
  const userHasPaidSub = subscriptionIsPaid(subscription, memberships)
  const { userCanUpgrade, userNeedsToConnectWallet } = useSubscribeModalState()

  // If a user is first landing on this page, we want to show
  // them the subscribe modal immediately.
  const {
    shouldShow,
    loading: isLoading,
    dontShowAgain,
  } = useShowInitialSubscribeModal("on-visit")

  const [showPopupOnVisit, setShowPopupOnVisit] = useState(false)

  if (isUsersOwnBlog) {
    disabled = true
  } else if (userCanUpgrade) {
    text = "Upgrade to paid"
  } else if (userNeedsToConnectWallet) {
    text = "Connect wallet"
  } else if (subscription && (!blogHasPaidMemberships || userHasPaidSub)) {
    console.log(
      "Disabling subscribe button",
      blogHasPaidMemberships,
      userHasPaidSub,
      memberships
    )
    text = "Subscribed"
    disabled = true
  }

  useEffect(() => {
    console.log("Checking if we should show subscribe moadl on visit", {
      shouldShow,
      isLoading,
      showPopupOnVisit,
    })
    // Wait until we've fully loaded before deciding if we should
    // show the moadl on initial visit.
    if (isLoading || !shouldShow) return
    if (!shouldShow) return
    console.log(
      "Setting show popup on visit",
      subscribeModalOpen,
      isLoading,
      shouldShow
    )
    // Set this first, before we open the modal, so that we don't auto-subscribe the user.
    setShowPopupOnVisit(true)
  }, [shouldShow, isLoading])

  useEffect(() => {
    if (!showPopupOnVisit) return
    console.log(
      "showPopupOnVisit",
      showPopupOnVisit,
      subscribeModalOpen,
      isLoading,
      shouldShow
    )
    setSubscribeModalOpen(true)
    dontShowAgain()

    track("subscribe modal opened automatically on first visit")
  }, [showPopupOnVisit])

  // For editing view, leave button enabled and with default text
  if (isEditable) {
    disabled = false
    text = "Subscribe"
  }

  useEffect(() => {
    if (!subscribeModalOpen) return

    setLoading(false)
  }, [subscribeModalOpen])

  return (
    <>
      <Button
        type="button"
        replaceClassName={replaceClassName}
        className={className}
        onClick={() => {
          // For editing view, disabled modal
          if (isEditable) return
          setLoading(true)
          setSubscribeModalOpen(true)
        }}
        text={overrideText || text}
        disabled={disabled}
        loading={loading}
        useSecondaryTheme={useSecondaryTheme}
      />

      <SubscribeModal
        initialPopup={showPopupOnVisit}
        // Whenever the animation finishes running when the user
        // closes the modal, we want to set the showPopupOnVisit
        // state to false so that the modal doesn't pop up again.
        //
        // We need to do it after the animation runs so we don't get
        // text flashes in the modal.
        onAnimationEnd={() => {
          console.log("Animation end!", subscribeModalOpen)
          if (subscribeModalOpen) return

          setShowPopupOnVisit(false)
        }}
        open={subscribeModalOpen}
        setOpen={setSubscribeModalOpen}
        defaultState={defaultState}
        gatingRuleGroups={gatingRuleGroups}
      />
    </>
  )
}

import { FarcasterFrameElement } from "@/types/farcaster"
import { Blog } from "@types"

/**
 * Returns the base domain for a blog, which is used in all frames.
 */
export function baseDomain(blog?: Pick<Blog, "custom_domain">) {
  const domain =
    process.env.NEXT_PUBLIC_FORCE_DOMAIN ||
    process.env.FORCE_DOMAIN ||
    (process.env.NEXT_PUBLIC_VERCEL_URL
      ? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
      : "") ||
    (blog?.custom_domain
      ? `https://${blog?.custom_domain}`
      : "https://paragraph.xyz")

  return domain
}

/**
 * Gets the "read inline" image for a post, used on Farcaster frames.
 */
export function getReadImage({
  content,
  blog,
}: {
  content: FarcasterFrameElement[]
  blog?: Blog
}): string {
  const encodedContent = encodeURIComponent(JSON.stringify(content) || "{}")
  const encodedFontColor = encodeURIComponent(blog?.font_color || "")
  const encodedBgColor = encodeURIComponent(blog?.secondary_color || "")
  const encodedPrimaryColor = encodeURIComponent(blog?.primary_color || "")

  const domain = blog ? baseDomain(blog) : "https://paragraph.xyz"
  const share_img = `${domain}/api/read_img_2?content=${encodedContent}&fontColor=${encodedFontColor}&bgColor=${encodedBgColor}&primaryColor=${encodedPrimaryColor}`
  return share_img
}

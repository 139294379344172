import { useEffect } from "react"
import Analytics from "analytics"
import { Router, useRouter } from "next/router"
import { defaultUser, selectSubscription, selectUser } from "features/userSlice"
import googleTagManager from "@analytics/google-tag-manager"

import amplitudePlugin from "@analytics/amplitude"

import { useAnalytics as _useAnalytics } from "use-analytics"
// import { Revenue } from "@amplitude/analytics-types"

import { getActiveBlogId } from "util/cookies"

import * as Sentry from "@sentry/nextjs"

import { absoluteAPI } from "api_routes/resolve"
import { useSelector } from "react-redux"
import { selectBlog, selectUsersBlog } from "features/blogSlice"
import {
  selectCurrentNoteId,
  selectCurrentNote,
  selectLatestCurrentNote,
} from "features/noteSlice"
import { Note } from "@types"
import { User } from "@/types/users"

const isBrowser = typeof window !== "undefined"
const isProd =
  process.env.NEXT_PUBLIC_VERCEL_ENV === "production" ||
  process.env.NEXT_PUBLIC_FORCE_PROD

const AMPLITUDE_KEY = isProd
  ? "5b9bf00b987cbea59d468256dfa811bc"
  : "d7c23b6953102806c5d5331dcd65bdcc"

const url = new URL(absoluteAPI)
const apiWithoutProtocol = url.host + url.pathname

const IGNORED_ROUTES = ["/[blogname]/subscribe"]

export const analytics =
  isBrowser &&
  (process.env.NEXT_PUBLIC_VERCEL_ENV === "production" ||
    process.env.NEXT_PUBLIC_FORCE_AMPLITUDE === "true")
    ? Analytics({
        app: "paragraph",
        // debug: true,
        plugins: [
          {
            name: "logger",
            page: ({ payload }: any) => {
              console.log("page", payload)
            },
          },
          googleTagManager({
            containerId: ["GTM-WJDSFLF"],
            debug: process.env.NEXT_PUBLIC_VERCEL_ENV !== "production",
          }),

          /*
                    googleAnalytics({
                      measurementIds: ["G-2J2JGELLMY"],
                    }),
           */

          amplitudePlugin({
            // debug: true,
            apiKey: AMPLITUDE_KEY,
            customScriptSrc: "https://paragraph.xyz/js/amp8.1.0.gz.js",
            // See options at https://bit.ly/3dRdZnE
            options: {
              // debug: true,
              batchEvents: false,
              includeUtm: true,
              includeReferrer: true,
              forceHttps: false,
              apiEndpoint: apiWithoutProtocol + "/amp",
            },
          }),
        ],
      })
    : Analytics({})

export const useTrackPageAnalytics = () => {
  const router = useRouter()

  useEffect(() => {
    if (!isProd) return
    if (IGNORED_ROUTES.includes(router.pathname)) return

    if (defaultUser().id) {
      analytics.identify(defaultUser().id)
    }

    // Fire initial page view
    analytics.page()
  }, [router.pathname])

  useEffect(() => {
    // Fire page views on routing
    const handleRouteChange = (url: string) => {
      if (!isProd) return
      if (IGNORED_ROUTES.includes(url)) return
      console.log("Route change!", url)

      // We need to wrap it in a rAF to ensure the correct data is sent to Segment
      // https://github.com/zeit/next.js/issues/6025
      requestAnimationFrame(() => {
        analytics.page()
      })
    }

    Router.events.on("routeChangeComplete", handleRouteChange)
    return () => Router.events.off("routeChangeComplete", handleRouteChange)
  }, [])

  return analytics
}

export const useAnalytics = () => {
  const _analytics = _useAnalytics()

  const user = useSelector(selectUser)
  const publicBlog = useSelector(selectBlog)
  const usersBlog = useSelector(selectUsersBlog)
  const subscription = useSelector(selectSubscription)
  const currentNoteId = useSelector(selectCurrentNoteId)
  const latestCurrentNote = useSelector(selectLatestCurrentNote)
  const currentNote = useSelector(selectCurrentNote) as Note | undefined

  /*
  useEffect(() => {
    console.log("Initial load: identifying user:", user.id)
    if (user.id) {
      _analytics.identify(user.id)
    }
  }, [])
     */

  useEffect(() => {
    if (!user?.id) return

    const newUser: User = { ...user, password: undefined }

    console.log("Identifying user...", newUser)

    analytics?.identify(user.id, newUser)
    Sentry.setUser({ ...newUser, id: user.id })
  }, [user, _analytics.ready])

  // @ts-ignore
  const track = (eventName: string, payload?: Record<string, unknown>) => {
    const breadcrumbData = {
      message: eventName,
      data: payload,
      timestamp: Date.now(),
    }

    console.log("Adding Sentry breadcrumb...", breadcrumbData)

    Sentry.addBreadcrumb(breadcrumbData)

    return _analytics.track(eventName, {
      ...payload,
      publicBlog: publicBlog?.id ? publicBlog : null,
      isUserSubscribed: !!subscription,
      currentNoteId,
      impersonatedBlogId: getActiveBlogId(),
      usersBlog,
      currentlyActiveBlogId: getActiveBlogId() || usersBlog?.id || null,
      post: {
        title: currentNote?.title,
        id: currentNote?.id,
        createdAt: currentNote?.createdAt,
        published: currentNote?.published,
      },

      latestCurrentPost: {
        title: latestCurrentNote?.title,
        id: latestCurrentNote?.id,
        createdAt: latestCurrentNote?.createdAt,
        published: latestCurrentNote?.published,
      },

      window: {
        url: window.location.href,
        pathname: window.location.pathname,
        search: window.location.search,
        hash: window.location.hash,
        origin: window.location.origin,
      },
    })
  }

  const identify = (data: { [val: string]: boolean | string | number }) => {
    if (!user.id) return

    return _analytics.identify(user.id, data)
  }

  /**
   * Tracks revenue.
   * @param revenue A Revenue object with price, revenue type, etc. set.
   * @param eventOptions Anything else you want to log.
   * @returns Returns the result of the logRevenueV2 function.
   * Example:
   *     
   * The `@analytics/amplitude` package doesn't expose the Revenue API in the same way as the track API.
   * But it does exist on the browser window.
   * @ts-ignore
   * const revenueEvent = new window.amplitude.Revenue()
       .setProductId(`paragraph-minted-token-for-blog-${blog.id}`)
       .setPrice(token.maticPrice)
       .setQuantity(1)
       .setRevenueType(`token gate`)
       // .setRevenue(revenueNum) // Note: setRevenue doesn't exist on Revenue()!
       .setEventProperties({ blog: blog || {} })
       
   * revenue(revenueEvent)
   */

  /* Not currently in use.
  const revenue = (revenue: Revenue, eventOptions?: any): any => {
    // The `@analytics/amplitude` package doesn't expose the Revenue API in the same way as the track API.
    // But it does exist on the browser window.
    if (
      !user.id ||
      typeof window === "undefined" ||
      !("amplitude" in window) ||
      // @ts-ignore
      !("Revenue" in window.amplitude) ||
      !("logRevenueV2" in window.amplitude)
    ) {
      console.error("Unable to find amplitude in window.")
      return
    }

    console.log("About to log amplitude revenue!")
    // @ts-ignore
    return window.amplitude.logRevenueV2(revenue, {
      user_id: user.id,
      ...eventOptions,
    })
  }
  */

  // TODO: Fill in default analytics information for all events, using selectors

  // @ts-ignore
  _analytics.logEvent = _analytics.track
  return { ..._analytics, track, identify /* , revenue */ }
}

import { Membership, Subscription } from "@types"
import { subscribeToMembership } from "api_routes/memberships"
import { getUsersSubscriptionToBlog, refreshUser } from "features/userSlice"
import Button from "components/Button"
import Spinner from "components/Spinner"
import { Dispatch, SetStateAction } from "react"
import { useAppDispatch } from "store"
import {
  isSubscribedToThisMembershipAndFrequency,
  getMembershipSubscribedToFrequency,
  pollForSubscriptionReactivationStatus,
} from "../SharedFunctions"
import { Frequency, frequencies } from "../SharedTypes"
import { useSelector } from "react-redux"
import { selectCurrentNote } from "features/noteSlice"

/**
 * Allows a reader to resubscribe to a membership that they previously cancelled.
 * @returns
 */
export default function ResubscribeButton({
  frequency,
  membership,
  subscription,
  isLoading,
  setCurrentActionItem,
}: {
  frequency: Frequency
  membership: Membership
  subscription?: Subscription
  isLoading: boolean
  setCurrentActionItem: Dispatch<SetStateAction<string>>
}) {
  const dispatch = useAppDispatch()

  const noteId = useSelector(selectCurrentNote)?.id || null

  const isSubscribedToThisMembershipAndFrequencyConst =
    isSubscribedToThisMembershipAndFrequency(
      frequency,
      subscription,
      membership
    )
  const frequencySubscribedAt = getMembershipSubscribedToFrequency(
    subscription,
    membership
  )
  const frequencySubscribedAtDisplay = frequencies.find(
    (f) => frequencySubscribedAt && f.id === frequencySubscribedAt.period
  )?.resubscribeToLabel

  if (
    !subscription ||
    subscription.status !== "ACTIVE" ||
    subscription.membershipId !== membership.id ||
    !("toBeUnsubscribedAt" in subscription) ||
    subscription.toBeUnsubscribedAt === undefined
  )
    return null

  return (
    <Button
      type="button"
      className="mt-4"
      onClick={async () => {
        if (isLoading) return

        setCurrentActionItem(membership.id)

        await subscribeToMembership(
          subscription.blogId,
          noteId,
          "",
          subscription.membershipId,
          subscription.planType,
          subscription.planId,
          subscription.couponId || null
        )

        // Then retrieve the updated subscription status.
        await pollForSubscriptionReactivationStatus(
          subscription.blogId,
          subscription.membershipId,
          dispatch
        )

        await Promise.all([
          dispatch(getUsersSubscriptionToBlog(subscription.blogId)),
          dispatch(refreshUser()),
        ])

        setCurrentActionItem("")
      }}
    >
      <div className="flex justify-center relative px-2">
        Resubscribe
        {!isSubscribedToThisMembershipAndFrequencyConst && (
          <> to {frequencySubscribedAtDisplay} plan</>
        )}
        {isLoading && (
          <div className="absolute -right-5 top-0.5">
            <Spinner height={4} width={4} />
          </div>
        )}
      </div>
    </Button>
  )
}

import { Membership, Blog } from "@types"
import AreYouSureConfirmationDialogue from "components/AreYouSureConfirmationDialogue"
import { Dispatch, SetStateAction } from "react"
import { useAppDispatch } from "store"
import { handleMembershipCheckoutForExistingPaymentMethod } from "../../SharedFunctions"
import { Frequency } from "../../SharedTypes"
import { User } from "@/types/users"
import { useSelector } from "react-redux"
import { selectCurrentNote } from "features/noteSlice"

export default function InstantCheckoutConfirmationDialogue({
  isButtonLoading,
  setCurrentActionItem,
  showInstantCheckoutDialogue,
  setShowInstantCheckoutDialogue,
  membership,
  frequency,
  blog,
  user,
}: {
  isButtonLoading: boolean
  setCurrentActionItem: Dispatch<SetStateAction<string>>
  showInstantCheckoutDialogue: boolean
  setShowInstantCheckoutDialogue: Dispatch<SetStateAction<boolean>>
  membership: Membership
  frequency: Frequency
  blog: Blog
  user: User
}) {
  const dispatch = useAppDispatch()

  const noteId = useSelector(selectCurrentNote)?.id || null

  return (
    <div className="px-2 mt-3">
      <AreYouSureConfirmationDialogue
        isDestructiveAction={false}
        showDialogue={showInstantCheckoutDialogue}
        setShowDialogue={setShowInstantCheckoutDialogue}
        isDisabled={isButtonLoading}
        onConfirmCallback={async () => {
          setCurrentActionItem(membership.id)

          const selectedPlan = membership.plans.find(
            (p) => p.period == frequency.id
          )

          console.log("Selected plan", selectedPlan)

          if (selectedPlan === undefined) return

          await handleMembershipCheckoutForExistingPaymentMethod(
            selectedPlan,
            blog,
            noteId,
            user,
            membership,
            null,
            dispatch
          )

          setCurrentActionItem("")
        }}
        customPromptText="This will charge your existing payment method."
        customPromptTextStyling="text-sm text-gray-600"
        customConfirmButtonText="Purchase"
        customConfirmButtonStyling="w-22"
        customCancelButtonStyling="w-22"
        confirmTrackingData={{
          blogId: membership.blogId,
          membershipId: membership.id,
        }}
      />
    </div>
  )
}

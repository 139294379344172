import { ExclamationCircleIcon } from "@heroicons/react/24/solid"

import { selectUsersTokens } from "features/blogSlice"
import { Dispatch, SetStateAction } from "react"
import { useSelector } from "react-redux"
import clsx from "clsx"
import { isValidMinimumWei } from "./ValidationHelpers"

interface props {
  selectedExistingParagraphTokenId: string
  minQuantity: number
  setMinQuantity: Dispatch<SetStateAction<number>>
}

// Displayed to a user when they want to use a token they've already minted or impoted into Paragraph.
export default function UseExistingToken({
  selectedExistingParagraphTokenId,
  minQuantity,
  setMinQuantity,
}: props) {
  const tokens = useSelector(selectUsersTokens)
  const token = tokens?.find((t) => t.id == selectedExistingParagraphTokenId)

  return (
    <div>
      {token && (
        <div>
          <div className="py-4 grid grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Token name</dt>
            <dd className="mt-1 ml-3 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {token.onChainData.name}
            </dd>
          </div>

          {!("mintedOnParagraph" in token.onChainData) && (
            <div className="py-2 grid grid-cols-3 sm:gap-4 sm:px-6">
              <label
                htmlFor="min-quantity"
                className="mt-2 text-sm font-medium text-gray-500"
              >
                Minimum quantity
              </label>
              <div className="col-span-2 relative rounded-md">
                <input
                  type="number"
                  name="min-quantity"
                  id="min-quantity"
                  className={clsx(
                    "block w-full rounded-md sm:text-sm",
                    !isValidMinimumWei(minQuantity)
                      ? "border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500"
                      : "border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 max-w-sm"
                  )}
                  placeholder="1"
                  defaultValue={minQuantity}
                  aria-invalid="true"
                  aria-describedby="min-quantity-error"
                  onChange={async (e) =>
                    setMinQuantity(parseFloat(e.target.value))
                  }
                />
                {!isValidMinimumWei(minQuantity) && (
                  <div className="pointer-events-none absolute inset-y-0 bottom-7 right-0 flex items-center pr-3">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                )}

                {isValidMinimumWei(minQuantity) && (
                  <p
                    className="mt-2 text-sm text-gray-500"
                    id="min-quantity-description"
                  >
                    Enter the minimum number of tokens the user must hold for
                    access.
                  </p>
                )}

                {!isValidMinimumWei(minQuantity) && (
                  <p
                    className="mt-2 text-sm text-red-600"
                    id="min-quantity-error"
                  >
                    Please enter an at least 1 Wei.
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

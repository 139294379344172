"use client"
import Image from "@tiptap/extension-image"
import { Plugin } from "prosemirror-state"
import {
  ReactNodeViewRenderer,
  NodeViewWrapper,
  mergeAttributes,
} from "@tiptap/react"
import { Editor } from "@tiptap/core"
import {
  dataUrlToFile,
  isBase64Image,
  isExternalImage,
  isPlaceholderImage,
  isSvgImage,
  throttle,
  upload,
} from "./utils"
import React, { useEffect } from "react"
import { getThirdPartyImage } from "api_routes/notes"
import * as Sentry from "@sentry/nextjs"

import * as NextImage from "next/image"
import { useAppDispatch } from "store"
import { makeToast } from "features/pageSlice"
import { Fragment, Slice } from "@tiptap/pm/model"

// Domains configured for use with Next Image
export const validImageDomains = ["storage.googleapis.com"]

// https://regex101.com/r/g42eXB/1
export const base64ImageRegex = /data:image\/([a-zA-Z]*);base64,([^\"]*)/
export interface ImageOptions {
  addPasteHandler: boolean
  HTMLAttributes: Record<string, any>
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    customImage: {
      setImage: (options: {
        src?: string
        file?: File
        alt?: string | undefined
        title?: string
        blurdataurl?: string
        nextheight?: number
        nextwidth?: number
      }) => ReturnType
    }
  }
}

export default Image.extend({
  name: "image",
  draggable: false,
  atom: true,
  addOptions() {
    return {
      ...Image.options,
      addPasteHandler: true,
      HTMLAttributes: {},
    }
  },

  addAttributes() {
    return {
      // @ts-ignore
      ...Image.config.addAttributes(),
      src: {
        default: null,
        parseHTML: (element) => element.getAttribute("src"),
      },
      alt: {
        default: null,
        parseHTML: (element) => element.getAttribute("alt"),
      },
      title: {
        default: null,
        parseHTML: (element) => element.getAttribute("title"),
      },
      blurdataurl: {
        default: null,
      },
      nextheight: {
        default: null,
      },
      nextwidth: {
        default: null,
      },
    }
  },

  addCommands() {
    return {
      setImage:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
          })
        },
    }
  },

  renderHTML({ node, HTMLAttributes }) {
    const className = { class: `image-node embed` }

    // These attributes are only present on the public post view.
    // So, we statically render the image component on page load
    // and return the HTML.
    // The NodeView also runs this same logic, which is fine.
    /*
    if (
      node.attrs.blurdataurl &&
      node.attrs.nextheight &&
      node.attrs.nextwidth
    ) {
      const html = ReactDOMServer.renderToStaticMarkup(
        <ImageComponent node={node} />
      )

      return ["div", { html }]
    }
     */

    return [
      "img",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, className),
    ]
  },

  addNodeView() {
    return ReactNodeViewRenderer(ImageComponent)
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          handleDOMEvents: {
            drop(view, event) {
              const hasFiles =
                event.dataTransfer &&
                event.dataTransfer.files &&
                event.dataTransfer.files.length

              if (!hasFiles) return

              const images = Array.from(event.dataTransfer.files).filter(
                (file) => /image/i.test(file.type)
              )

              if (images.length === 0) return

              event.preventDefault()

              const { schema } = view.state
              const coordinates = view.posAtCoords({
                left: event.clientX,
                top: event.clientY,
              })

              images.forEach((image) => {
                const reader = new FileReader()

                reader.onload = (readerEvent) => {
                  if (!readerEvent.target || !coordinates) return

                  console.log(
                    "Handling image file drop...",
                    readerEvent.target.result
                  )

                  schema.nodes.image

                  const node = schema.nodes.figure.create(
                    {
                      src: readerEvent.target.result,
                    },
                    [
                      schema.nodes.image.create({
                        src: readerEvent.target.result,
                      }),
                      schema.nodes.figcaption.create(),
                    ]
                  )
                  const transaction = view.state.tr.insert(
                    coordinates.pos,
                    node
                  )
                  view.dispatch(transaction)
                }
                reader.readAsDataURL(image)
              })
            },
            paste(view, event) {
              const hasFiles =
                event.clipboardData &&
                event.clipboardData.files &&
                event.clipboardData.files.length

              if (!hasFiles) return

              const images = Array.from(event.clipboardData.files).filter(
                (file) => /image/i.test(file.type)
              )

              if (images.length === 0) return

              event.preventDefault()

              const { schema } = view.state

              images.forEach((image) => {
                const reader = new FileReader()

                reader.onload = (readerEvent) => {
                  if (!readerEvent.target) return

                  console.log(
                    "Handling image file paste...",
                    readerEvent.target.result
                  )

                  const node = schema.nodes.figure.create(
                    {
                      src: readerEvent.target.result,
                    },
                    [
                      schema.nodes.image.create({
                        src: readerEvent.target.result,
                      }),
                      schema.nodes.figcaption.create(),
                    ]
                  )
                  const transaction = view.state.tr.replaceSelectionWith(node)
                  view.dispatch(transaction)
                }
                reader.readAsDataURL(image)
              })
            },
          },
          // If image node in copy slice, copy the figure node instead
          transformCopied: (slice, view) => {
            const node = slice.content.firstChild
            if (node && node.type === view.state.schema.nodes.image) {
              const selectionStart = view.state.selection.$from
              let depth = selectionStart.depth
              let parent
              do {
                parent = selectionStart.node(depth)
                if (parent) {
                  if (parent.type === view.state.schema.nodes.figure) {
                    return new Slice(
                      Fragment.fromArray([parent]),
                      slice.openStart,
                      slice.openEnd
                    )
                  }
                  depth--
                }
              } while (depth > 0 && parent)
            }

            return slice
          },
        },
      }),
    ]
  },
})

type Props = {
  editor?: Editor
  node: any
  updateAttributes?: (a: any) => void
  deleteNode: () => void
}

export const processImage = async (
  src: string
): Promise<
  | {
      src: string
      blurdataurl: string
      nextwidth: number
      nextheight: number
    }
  | undefined
> => {
  if (!isBase64Image(src) && !isSvgImage(src) && isExternalImage(src)) {
    console.log("Handling third party image...", src)
    const imageWithPlaceholder = await getThirdPartyImage(src)
    if (imageWithPlaceholder && imageWithPlaceholder.base64)
      return {
        src: imageWithPlaceholder.img.src,
        blurdataurl: imageWithPlaceholder.base64,
        nextwidth: imageWithPlaceholder.img.width,
        nextheight: imageWithPlaceholder.img.height,
      }
  } else {
    console.log("Handling pasted dataUrl image...", src)
    const file = await dataUrlToFile(src)
    const attrs = await upload(file)
    return {
      src: attrs.src,
      blurdataurl: attrs.blurdataurl,
      nextwidth: attrs.nextwidth,
      nextheight: attrs.nextheight,
    }
  }
}

const ImageComponent = ({
  editor,
  node,
  updateAttributes,
  deleteNode,
}: Props): JSX.Element => {
  const { src, alt } = node.attrs
  const dispatch = useAppDispatch()

  useEffect(() => {
    const processAndUpload = async (
      updateAttributes: (a: any) => void,
      src: string
    ) => {
      try {
        const attrs = await processImage(src)
        console.log("[IMAGE] image processed", attrs)
        if (attrs) updateAttributes(attrs)
      } catch (e: any) {
        console.error("Failed to process & upload image ", e)
        dispatch(makeToast("error", e.msg || "Failed to upload image"))
        Sentry.captureException(e)
        deleteNode()
      }
    }

    if (!editor || !editor.isEditable) return

    if (src && isPlaceholderImage(src) && updateAttributes) {
      processAndUpload(updateAttributes, src)
    }
  }, [alt, src, updateAttributes, deleteNode, dispatch, editor])

  if (!src) return <></>
  if (isPlaceholderImage(src) && editor && editor.isEditable) {
    return <ImagePlaceholder src={src} />
  }

  const hasPlaceholder =
    node.attrs.blurdataurl && node.attrs.nextheight && node.attrs.nextwidth

  // Non-editable image
  if (!editor || !editor.isEditable) {
    if (hasPlaceholder) {
      return (
        <NodeViewWrapper className="react-component" data-drag-handle>
          <NextImage.default
            alt={node.attrs.alt}
            src={node.attrs.src}
            // Only render sizes that are less than the post body width (eg 832px).
            sizes="(min-width: 1024px) 832px, 100vw"
            width={node.attrs.nextwidth}
            height={node.attrs.nextheight}
            blurDataURL={node.attrs.blurdataurl}
            placeholder="blur"
            className={`image-node embed`}
          />
        </NodeViewWrapper>
      )
    }

    return (
      <NodeViewWrapper className="react-component" data-drag-handle>
        <img src={node.attrs.src} alt="" className={`image-node embed`} />
      </NodeViewWrapper>
    )
  }

  return (
    <NodeViewWrapper className="react-component">
      <div className="relative max-w-fit mx-auto clear-both">
        {/* <div data-drag-handle className="drag-handle" /> */}
        <ImageResizeHandle editor={editor} side="left" />
        <img
          data-nextwidth={node.attrs.nextwidth}
          data-nextheight={node.attrs.nextheight}
          data-blurdataurl={node.attrs.blurdataurl}
          src={node.attrs.src}
          className={`image-node embed omfg`}
        />
        <ImageResizeHandle editor={editor} side="right" />
      </div>
    </NodeViewWrapper>
  )
}

const ImagePlaceholder = ({ src }: { src: string }) => (
  <NodeViewWrapper className="react-component" data-drag-handle>
    <img src={src} className={`image-node embed animate-pulse`} />
  </NodeViewWrapper>
)

type ImageResizeHandleProps = {
  editor: Editor
  side: "left" | "right"
}

const ImageResizeHandle = ({ editor, side }: ImageResizeHandleProps) => {
  const handler = (mouseDownEvent: React.MouseEvent<HTMLDivElement>) => {
    const parent = (mouseDownEvent.target as HTMLElement).closest(
      ".react-component"
    )
    const image = parent?.querySelector("img") ?? null
    if (!image) return

    const startSize = { x: image.clientWidth, y: image.clientHeight }
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY }

    function onMouseMove(mouseMoveEvent: MouseEvent) {
      const width =
        side === "left"
          ? startSize.x + startPosition.x - mouseMoveEvent.pageX
          : startSize.x - startPosition.x + mouseMoveEvent.pageX

      // Enforce minimum width
      if (width < 100) return

      throttle(
        () =>
          editor
            .chain()
            .focus()
            .selectParentNode()
            .setWidth(`${width}px`)
            .run(),
        40
      )
    }

    function onMouseUp() {
      document.body.removeEventListener("mousemove", onMouseMove)
    }

    document.body.addEventListener("mousemove", onMouseMove)
    document.body.addEventListener("mouseup", onMouseUp, { once: true })
  }

  return (
    <div
      className={`image-node-resize-handle ${
        side === "left" ? "left-[-1rem]" : "right-[-1rem]"
      }`}
      onMouseDown={handler}
    />
  )
}

import { useRouter } from "next/router"
import { useEffect } from "react"
import { setUtmCookie } from "util/cookies"
import { useSelector } from "react-redux"
import { selectBlog } from "features/blogSlice"
import { useAnalytics } from "./useAnalytics"

/**
 * Gets the UTM params from the query string and sets it in a cookie.
 * This is used to track marketing campaigns and for general metrics.
 */
export default function useUtmParams() {
  const router = useRouter()

  // The blog should always be populated on the server-side because we use getStaticProps.
  // So, no need to rely on blog in the dependency array below.
  const blog = useSelector(selectBlog)

  const { track } = useAnalytics()

  useEffect(() => {
    const utm_source = router.query.utm_source as string | undefined
    const utm_medium = router.query.utm_medium as string | undefined
    const utm_campaign = router.query.utm_campaign as string | undefined
    const utm_term = router.query.utm_term as string | undefined
    const utm_content = router.query.utm_content as string | undefined

    // If the blog ID isn't present, don't save the cookie.
    if (!blog.id) return

    // If none of the UTM params are present, don't save the cookie.
    if (
      !utm_source &&
      !utm_medium &&
      !utm_campaign &&
      !utm_term &&
      !utm_content
    )
      return

    console.log("Saving UTM cookie.", {
      blogId: blog.id,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content,
    })

    const serializedUtmParams = JSON.stringify({
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content,
    })

    track("utm params set", {
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content,
      blogId: blog.id,
    })

    setUtmCookie(blog.id, serializedUtmParams)
  }, [
    blog.id,
    router.query.utm_source,
    router.query.utm_medium,
    router.query.utm_campaign,
    router.query.utm_term,
    router.query.utm_content,
  ])
}

import { axios, baseUrl } from "./resolve"
import { AxiosResponse } from "axios"

import { DeepPartial } from "@/types/utilities"

import {
  GatingRule,
  PostGatingRuleRes,
  GatingRuleGroup,
} from "@/types/gatingRules"

import type { ErrorResponse } from "@types"

const API = baseUrl + "/gating-rule-groups"

// Create a gating rule group.
export async function addGatingRuleGroupToPost(
  gatingRuleGroup: DeepPartial<GatingRuleGroup>
): Promise<GatingRuleGroup | ErrorResponse> {
  const res: AxiosResponse<GatingRuleGroup | ErrorResponse> = await axios.post(
    `${API}`,
    gatingRuleGroup
  )

  return res.data
}

// Add a gating rule to an existing gating rule group.
export async function addGatingRuleToGroup(
  gatingRuleGroupId: string,
  gatingRule: Partial<GatingRule>
): Promise<PostGatingRuleRes | ErrorResponse> {
  const res: AxiosResponse<PostGatingRuleRes | ErrorResponse> =
    await axios.post(`${API}/${gatingRuleGroupId}/gating-rule`, gatingRule)

  return res.data
}

// Retrieve gating rule groups for a given post ID.
export async function getGatingRuleGroupsForPost(
  postId: string
): Promise<GatingRuleGroup[] | ErrorResponse> {
  const res: AxiosResponse<GatingRuleGroup[] | ErrorResponse> = await axios.get(
    `${API}/post/${postId}`
  )

  return res.data
}

// Retrieve gating rule groups for all posts in a given blog ID.
export async function getGatingRuleGroupsForBlog(
  blogId: string
): Promise<GatingRuleGroup[] | ErrorResponse> {
  const res: AxiosResponse<GatingRuleGroup[] | ErrorResponse> = await axios.get(
    `${API}/blog/${blogId}`
  )

  return res.data
}

// Delete gating rule group using its ID.
export async function deleteGatingRuleGroup(
  gatingRuleGroupId: string
): Promise<null | ErrorResponse> {
  const res: AxiosResponse<null | ErrorResponse> = await axios.delete(
    `${API}/${gatingRuleGroupId}`
  )

  return res.data
}

// Delete gating rule groups that match a postId and embedId filter.
export async function deleteGatingRuleGroupByPostIdAndEmbedId(
  postId: string,
  embedId: string
): Promise<null | ErrorResponse> {
  const res: AxiosResponse<null | ErrorResponse> = await axios.delete(
    `${API}/embeds/?postId=${postId}&embedId=${embedId}`
  )

  return res.data
}

// Delete gating rule from an existing gating rule group using its ID.
export async function deleteGatingRule(
  gatingRuleGroupId: string,
  gatingRuleId: string
): Promise<null | ErrorResponse> {
  const res: AxiosResponse<null | ErrorResponse> = await axios.delete(
    `${API}/${gatingRuleGroupId}/${gatingRuleId}`
  )

  return res.data
}

import { selectPostGatingRuleGroupsForPostId } from "features/gatingRuleGroupsSlice"
import { useSelector } from "react-redux"
import {
  EnrichedGatingRule,
  EnrichedGatingRuleGroup,
  GatingContentType,
  GatingRule,
  GatingRuleGroup,
} from "@/types/gatingRules"
import { useEffect, useState } from "react"

// Custom hook, use internally only for loading posts for an author editing them in their dashboard.
// Checks whether the post has any gating rules, and populates them with appropriate display strings.
// Returns an array of EnrichedGatingRules which contain information about whether the user has access,
// and if not, why.
// Returns an empty array if there are no gating rules.
// Returns undefined if it has not yet been able to calculate gating rules (if they are still loading).
export default function useGatingRulesForEditingAuthor(
  postId: string, // ID of the post for which we're fetching gating rules.
  gateContentType: GatingContentType, // Type of gate rules we are interested in.
  embedId = "", // Specific gate we are interested in. (optional)
  requestCameFrom: string // Purely for logging purposes.
): Array<EnrichedGatingRuleGroup> | undefined {
  const gatingRuleGroups = useSelector(
    selectPostGatingRuleGroupsForPostId(postId, gateContentType, embedId)
  )
  const [gatingRulesAccessDecisions, setGatingRulesAccessDecisions] = useState<
    Array<EnrichedGatingRuleGroup> | undefined
  >()

  useEffect(() => {
    const determineAccess = async () => {
      console.log(
        `GatingRules - useGatingRulesForEditingAuthor: Gating rules detected in useGatingRulesForEditingAuthor hook for post with ID ${postId} were. Request came from ${requestCameFrom}`,
        gatingRuleGroups
      )

      const decisionArr = new Array<EnrichedGatingRuleGroup>()

      if (!gatingRuleGroups || gatingRuleGroups.length == 0) {
        console.log(
          "GatingRules - useGatingRulesForEditingAuthor: No gating rules detected!"
        )
        setGatingRulesAccessDecisions(decisionArr)

        return
      }

      for (const gr of gatingRuleGroups) {
        // If it's the author editing their own gating rules, then of course they have access.
        console.log(
          `GatingRules - useGatingRulesForEditingAuthor: User was editing their own gates. Access granted! Request came from ${requestCameFrom}`,
          gr
        )

        decisionArr.push(makeHasAccessGatingRuleGroup(gr))
      }

      setGatingRulesAccessDecisions(decisionArr)
    }

    determineAccess()
  }, [postId, JSON.stringify(gatingRuleGroups)])

  return gatingRulesAccessDecisions
}

// Used when the user has access to the gating rule group and you just need
// a quick way to make an enriched gating rule allowing access.
function makeHasAccessGatingRuleGroup(
  group: GatingRuleGroup
): EnrichedGatingRuleGroup {
  const gatingRules: EnrichedGatingRule[] = []

  for (const gr of group.gatingRules) {
    gatingRules.push(makeHasAccessGatingRule(gr))
  }

  return {
    ...group,
    hasAccess: true,
    enrichedGatingRules: gatingRules,
  }
}

// Used when the user has access to the gating rule and you just need
// a quick way to make an enriched gating rule allowing access.
function makeHasAccessGatingRule(gr: GatingRule): EnrichedGatingRule {
  return {
    ...gr,
    hasAccess: true,
    gatingRuleRequirementTypeDisplayTitle:
      populateGatingRuleRequirementTypeDisplayTitle(gr),
    gatingRuleRequirementTypeDisplayMessageForAuthor:
      populateGatingRuleRequirementTypeDisplayMessageForAuthor(gr),
  }
}

function populateGatingRuleRequirementTypeDisplayTitle(
  gatingRule: GatingRule
): string {
  switch (gatingRule.gatingRequirement.gateType) {
    case "MEMBERSHIP":
      return "Membership"
    case "TOKEN":
      return "Token"
    default:
      return ""
  }
}

function populateGatingRuleRequirementTypeDisplayMessageForAuthor(
  gatingRule: GatingRule
): string {
  switch (gatingRule.gatingRequirement.gateType) {
    case "MEMBERSHIP":
      return "membership"
    case "TOKEN":
      return "token"
    default:
      return ""
  }
}

import { User } from "@/types/users"
import { notEmpty } from "@/util/query"
import { GetNoteResp } from "@types"
import clsx from "clsx"
import AuthorHoverCard from "components/AuthorHoverCard"
import AvatarPlaceholder from "components/AvatarPlaceholder"
import UserAvatar from "components/UserAvatar"
import { LegacyRef, forwardRef } from "react"

type Props = {
  note: GetNoteResp
}

export default function AuthorAvatars(props: Props) {
  if (!props.note.authorDetails) return null

  const authors = props.note.authors
    // This is to ensure we're ordering authors correctly. The `authors` string array is the source of truth
    // for ordering.
    ?.map((a) => props.note.authorDetails.find((ad) => ad.id == a))
    .filter(notEmpty)
    .map((author) => author)

  return (
    <div>
      <div className="flex justify-end items-center gap-x-2">
        <AvatarOverlap users={authors as User[]} />
      </div>
    </div>
  )
}

export function CollectibleAvatars(props: {
  users: User[]
  size?: number
  color?: "light" | "dark"
}) {
  if (!props.users) return null

  return (
    <div>
      <div className="flex items-center gap-x-2 justify-center">
        <AvatarOverlap
          users={props.users}
          size={props.size}
          color={props.color}
          hideAuthorCard
        />
      </div>
    </div>
  )
}

function AvatarOverlap(props: {
  users: User[]
  size?: number
  color?: "light" | "dark"
  hideAuthorCard?: boolean
  hideSubscriberState?: boolean
}) {
  if (!props.users) return null

  return (
    <ul className="isolate flex -space-x-2 overflow-hidden p-2">
      {props.users.map((u, idx) => (
        <AuthorHoverCard
          key={idx}
          author={u}
          hideAuthorCard={props.hideAuthorCard}
        >
          <Avatar
            author={u}
            key={idx}
            idx={idx}
            length={props.users.length}
            size={props.size}
            color={props.color}
          />
        </AuthorHoverCard>
      ))}
    </ul>
  )
}

const Avatar = forwardRef(function Avatar(
  props: {
    author?: User
    idx: number
    length: number
    size?: number
    color?: "light" | "dark"
  },
  ref: LegacyRef<HTMLLIElement> | undefined
) {
  if (!props.author) return null

  return (
    <li
      ref={ref}
      id={props.author.id}
      key={props.author.id}
      className={clsx(
        props.size ? `h-${props.size} w-${props.size}` : "h-10 w-10",
        "relative inline-block rounded-full cursor-pointer transform duration-500 overflow-hidden",
        `z-${props.length - props.idx + 1}0`
      )}
      title={props.author.authorName}
    >
      {props.author.avatar_url && (
        <UserAvatar
          height={40}
          width={40}
          className={"rounded-full h-full w-full"}
          url={props.author.avatar_url}
        />
      )}
      {!props.author.avatar_url && (
        <AvatarPlaceholder
          author={props.author}
          color={props.color}
          border={true}
        />
      )}
    </li>
  )
})

import { Listbox, Transition } from "@headlessui/react"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid"
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight"
import { Fragment } from "@tiptap/pm/model"
import {
  NodeViewWrapper,
  NodeViewContent,
  ReactNodeViewRenderer,
  Editor,
} from "@tiptap/react"
import clsx from "clsx"
import { toHtml } from "hast-util-to-html"
import { lowlight } from "components/Editor/extensions"

export default CodeBlockLowlight.extend({
  addAttributes() {
    return {
      language: {
        default: undefined,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: `pre[data-type=${this.name}]`,
        getContent: (node, schema) => {
          const html = (node as HTMLElement).getAttribute("html") || ""
          const tempDivElement = document.createElement("div")
          tempDivElement.innerHTML = html
          const text = tempDivElement.textContent || tempDivElement.innerText

          return Fragment.fromJSON(schema, [{ type: "text", text }])
        },
      },
    ]
  },

  renderHTML({ node, HTMLAttributes }) {
    const html = `<code>${toHtml(
      node.attrs.language && lowlight.registered(node.attrs.language)
        ? lowlight.highlight(node.attrs.language, node.textContent)
        : lowlight.highlightAuto(node.textContent)
    )}</code>`
    return [
      "pre",
      { "data-type": this.name, ...node.attrs, ...HTMLAttributes, html },
    ]
  },

  addKeyboardShortcuts() {
    return {
      Tab: () => {
        if (this.editor.isActive("codeBlock"))
          return this.editor.commands.insertContent("\t")
        return false
      },
      "Shift-Tab": () => {
        if (this.editor.isActive("codeBlock")) return true
        return false
      },
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(CodeBlockNode)
  },
})

type Props = {
  editor?: Editor
  node: { attrs: { language: string } }
  updateAttributes(attrs: { [key: string]: any }): void
}

function CodeBlockNode({ editor, node, updateAttributes }: Props) {
  return (
    <NodeViewWrapper className="code-block">
      {editor && editor.isEditable && (
        <Listbox
          value={node.attrs.language}
          onChange={(language) => updateAttributes({ language })}
        >
          {({ open }) => (
            <>
              <div className="relative">
                <Listbox.Button className="relative cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6">
                  <span className="block truncate">
                    {node.attrs.language || "auto"}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="syntax-language-list not-prose z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    <Listbox.Option
                      key="auto"
                      className={({ active }) =>
                        clsx(
                          active ? "bg-blue-600 text-white" : "text-gray-900",
                          "relative cursor-default select-none py-2 pl-3 pr-9"
                        )
                      }
                      spellCheck={false}
                      value={null}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={clsx(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate"
                            )}
                          >
                            auto
                          </span>

                          {selected ? (
                            <span
                              className={clsx(
                                active ? "text-white" : "text-blue-600",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                    {lowlight.listLanguages().map((language, index) => (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          clsx(
                            active ? "bg-blue-600 text-white" : "text-gray-900",
                            "relative cursor-default select-none py-2 pl-3 pr-9"
                          )
                        }
                        spellCheck={false}
                        value={language}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={clsx(
                                selected ? "font-semibold" : "font-normal",
                                "block truncate"
                              )}
                            >
                              {language}
                            </span>

                            {selected ? (
                              <span
                                className={clsx(
                                  active ? "text-white" : "text-blue-600",
                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      )}
      <pre>
        <NodeViewContent
          as="code"
          style={{ padding: 0, fontFamily: "Menlo", fontSize: "14px" }}
        />
      </pre>
    </NodeViewWrapper>
  )
}

import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { selectUser } from "../features/userSlice"
import { selectBlog } from "../features/blogSlice"

export default function useIsUsersOwnBlog() {
  const user = useSelector(selectUser)
  const blog = useSelector(selectBlog)
  const [isUsersOwnBlog, setIsUsersOwnBlog] = useState(false)

  useEffect(() => {
    const currentRoute = window.location.pathname
    // If the user's viewing eg the Theme page, then
    // this is always their own blog.
    const isSettingsRoute = currentRoute.includes("/settings")
    if (user.id === blog.user?.id || isSettingsRoute) {
      setIsUsersOwnBlog(true)
    } else {
      setIsUsersOwnBlog(false)
    }
  }, [user, blog])

  return isUsersOwnBlog
}

import { Editor } from "@tiptap/core"
import { handlePrompt } from "../EmptyState/utils"
import { analytics } from "hooks/useAnalytics"

export const handlePromptSubmit = (editor: Editor) => {
  if (editor.state.selection.$anchor.parent.type.name !== "askAi") return false

  // Grab the current title & subtitle
  const title =
    (window.document.getElementById("title") as HTMLInputElement)?.value || ""
  const subtitle =
    (window.document?.getElementById("subtitle") as HTMLInputElement)?.value ||
    ""

  const prompt = editor.state.selection.$anchor.parent.textContent
  const content = editor.state.doc.textContent.replace(prompt, "<CURSOR>")

  const context = {
    title,
    subtitle,
    content,
  }

  console.log("Asking AI...\n", prompt, context)

  if (prompt)
    handlePrompt(editor, null, analytics.track, "ask", prompt, context)

  editor.commands.deleteNode("askAi")

  return true
}

import { CompletedCollectible } from "@/types/highlights"
import { erc721ABI } from "@/util/typechain-v0"
import { parseEther } from "viem"

import { useAccount } from "wagmi"
import useContractInteraction from "../useContractInteraction"

export default function useMintExistingCollectibleV1(
  collectible: CompletedCollectible
) {
  const enabled = collectible.version === 0
  const accountData = useAccount()
  const collectibleCostWei = parseEther(collectible.costEth.toString())

  return useContractInteraction({
    functionName: "mint",
    address: collectible.contractAddress as `0x${string}`,
    abi: erc721ABI,
    chain: collectible.chain,
    args: [accountData.address as `0x${string}`],
    enabled,
    collectibleCostWei,
    collectible,
  })
}

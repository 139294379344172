import { KeyboardShortcutCommand } from "@tiptap/core"

export const preventAfterGateDeletion: KeyboardShortcutCommand = ({
  editor,
}) => {
  const { selection } = editor.state

  /* @ts-ignore */
  return selection?.node?.type.name === "afterGate"
}

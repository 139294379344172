import { useEffect } from "react"
import { useAnalytics } from "hooks/useAnalytics"
import { MembershipWithNFT } from "@types"
import Button from "components/Button"
import { openseaUrlFromMembership } from "util/format"
import { useSelector } from "react-redux"
import { selectActiveSubscription } from "features/userSlice"

export default function ClaimNFTSuccess(props: {
  membership: MembershipWithNFT
}) {
  const { track } = useAnalytics()
  const subscription = useSelector(selectActiveSubscription)

  useEffect(() => {
    track("claim NFT success view")
  }, [])

  if (!props.membership.hasNFT) return null

  return (
    <div className="p-10 ">
      <div>
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-3xl font-bold text-gray-900 ">Success!</h3>
          <div className="mt-2">
            <p className="text-gray-500 text-md mb-3">
              You've successfully claimed your membership NFT. It's been
              airdropped to your wallet.
            </p>
            <a
              href={openseaUrlFromMembership(props.membership, subscription)}
              target="_blank"
              rel="noreferrer"
            >
              <Button type="button" text="View On OpenSea" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

import { Fragment, useEffect, useRef, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { CheckIcon } from "@heroicons/react/24/outline"

import { updateToken } from "api_routes/blogs"
import { Token } from "@/types/gatingRules"
import clsx from "clsx"

interface modelProps {
  token?: Token
  setToken: (val: Token) => void
}

function StageOne({ token, setToken }: modelProps) {
  return (
    <div>
      <div className="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
        <CheckIcon className="w-6 h-6 text-green-600" aria-hidden="true" />
      </div>
      <div className="mt-3 text-center sm:mt-5">
        <Dialog.Title
          as="h3"
          className="text-lg font-medium text-gray-900 leading-6"
        >
          Edit Token
        </Dialog.Title>
      </div>

      <div className="mt-3">
        <label
          htmlFor="purchase_url"
          className="block text-md font-medium text-gray-700"
        >
          Purchase URL
        </label>

        <div className="block text-xs text-gray-500">
          The URL at which users can purchase and learn more about this token.
        </div>
        <div className="mt-1">
          <input
            type="text"
            name="purchase_url"
            id="purchase_url"
            className="block w-full border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            placeholder="https://opensea.io/collection/paragraph"
            value={token?.onChainData.learnMoreUrl}
            onChange={(ev) => {
              if (!token) return

              setToken({
                ...token,
                onChainData: {
                  ...token.onChainData,
                  learnMoreUrl: ev.target.value,
                },
              })
            }}
          />
        </div>
      </div>
    </div>
  )
}

interface modalOpenProps {
  open: boolean
  setOpen: (val: boolean) => void
  editToken?: Token // If this is present, we're editing a token.
}

export default function Modal({ open, setOpen, editToken }: modalOpenProps) {
  const cancelButtonRef = useRef(null)

  const [token, setToken] = useState<Token | undefined>(editToken)

  console.log("Token editing modal opened, with this value:", editToken)

  useEffect(() => {
    setToken(editToken || undefined)
  }, [editToken])

  useEffect(() => {
    if (open === false) {
      setToken(undefined)
    }
  }, [open])

  const [error, setError] = useState("")
  const [isSaving, setIsSaving] = useState(false)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-50 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full px-4 pt-5 pb-4 overflow-hidden text-left align-bottom bg-white rounded-lg shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:p-6">
              <StageOne token={token} setToken={setToken} />

              {error && <div className="text-red-500 text-sm">{error}</div>}
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  onClick={async (e) => {
                    if (token) {
                      console.log("Saving token...", token)

                      setIsSaving(true)
                      const resp = await updateToken(token)

                      console.log("Updated token", resp, token)
                    }

                    setOpen(false)

                    setTimeout(() => setIsSaving(false), 500)
                  }}
                  type="button"
                  disabled={isSaving}
                  className={clsx(
                    "disabled:bg-blue-300 disabled:cursor-not-allowed inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm"
                  )}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => {
                    setOpen(false)
                  }}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

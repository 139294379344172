import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as api from "api_routes/api"
import * as emailsAPI from "api_routes/emails"
import {
  Blog,
  BlogIntroduction,
  CommunityEntry,
  CommunityEntryRes,
  Discount,
  EmptyBlog,
  ErrorResponse,
  ImportType,
  isErrorResponse,
  PostBlogEmailRes,
  SubscriberCountResp,
  SubscriptionOptOutOrIn,
  SuccessResponse,
  UserAndSubscription,
  UserAndSubscriptionPage,
} from "@types"

import { Token } from "@/types/gatingRules"
import { RootState, Thunk } from "../store"
import { HYDRATE } from "next-redux-wrapper"
import { setError } from "./errorSlice"
import { refreshUser, setSubscription } from "features/userSlice"
import { setUsersBlogProps, setUsersSubscribersProps } from "util/amplitude"
import { analytics } from "hooks/useAnalytics"
import { getBlogMemberships, getUpsellMemberships } from "./membershipSlice"
import { BlogEmail } from "@/types/emails"
import { setLoading } from "./pageSlice"
import { GetTeamMemberResp } from "@/types/teams"
import { validateEmail } from "@/util/format"

interface BlogState {
  initialLoad: boolean

  blog: Blog // The currently-viewed PUBLIC blog. It may or may not be the user's own
  usersBlog: Blog // The user's OWN blog.
  subscribers?: UserAndSubscriptionPage

  subscriberCount?: SubscriberCountResp

  communities?: Array<CommunityEntry> // The currently-viewed PUBLIC blog's communities. May or may not be user's own.
  usersCommunities?: Array<CommunityEntryRes> // The communities that are attached to the currently logged-in user's blog.

  tokens?: Array<Token> // The currently-viwed PUBLIC blog's tokens that said blog has added to Paragraph for gating purposes. May or may not be user's own.
  usersTokens?: Array<Token> // The tokens that are attached to the currently logged-in user's blog.

  usersTeamMembers?: Array<GetTeamMemberResp> // The team members that are associated to the currently logged-in user's blog.

  discounts?: Array<Discount> // The discounts that are associated to the currently logged-in user's blog.

  blogEmails?: Array<BlogEmail> // Emails the blog sends out automatically, such as the welcome email.

  blogIntroduction?: BlogIntroduction | null // The introduction section for the blog. This is optional, not all blogs have this. This is set to null if we've tried to fetch one and it doesn't exist.

  usersBlogIntroduction?: BlogIntroduction | null // The introduction section for the user's blog. This is optional, not all blogs have this. This is set to null if we've tried to fetch one and it doesn't exist.

  /**
   * Map of subscriber IDs to the communities they have opted out of.
   * Used to manage local state across various modals and badges shown
   * in the subscribers table (in which a blog manages its subscribers).
   */
  subscribersToOptOutsMap?: Record<string, SubscriptionOptOutOrIn[]>
}

const initialState: BlogState = {
  initialLoad: true,
  blog: EmptyBlog,
  usersBlog: EmptyBlog,
}

export const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    setBlog: (state, action: PayloadAction<Blog>) => {
      state.blog = action.payload
    },

    setUsersBlog: (state, action: PayloadAction<Blog>) => {
      state.usersBlog = action.payload
      setUsersBlogProps(action.payload)
    },
    setCommunities: (state, action: PayloadAction<Array<CommunityEntry>>) => {
      state.communities = action.payload
    },

    setDiscounts: (state, action: PayloadAction<Array<Discount>>) => {
      state.discounts = action.payload
    },

    setUsersTeamMembers: (
      state,
      action: PayloadAction<Array<GetTeamMemberResp>>
    ) => {
      state.usersTeamMembers = action.payload
      analytics.identify(analytics.user("userId"), {
        numTeamMembers: action.payload.length,
      })
    },
    setTokens: (state, action: PayloadAction<Array<Token>>) => {
      // console.log("setTokens TOKENS ARE", action.payload) // Noisy log.
      state.tokens = action.payload
      analytics.identify(analytics.user("userId"), {
        numTokens: action.payload.length,
      })
    },
    setUsersTokens: (state, action: PayloadAction<Array<Token>>) => {
      // console.log("setUsersTokens TOKENS ARE", action.payload) // Noisy log.
      state.usersTokens = action.payload
    },
    addUsersToken: (state, action: PayloadAction<Token>) => {
      console.log("addUsersToken TOKENS ARE", action.payload)
      const existingTokens = state.usersTokens
      existingTokens?.push(action.payload)

      state.usersTokens = existingTokens
    },

    setBlogEmails: (state, action: PayloadAction<Array<BlogEmail>>) => {
      state.blogEmails = action.payload
    },

    setBlogIntroduction: (
      state,
      action: PayloadAction<BlogIntroduction | null>
    ) => {
      state.blogIntroduction = action.payload
    },

    setUsersBlogIntroduction: (
      state,
      action: PayloadAction<BlogIntroduction | null>
    ) => {
      state.usersBlogIntroduction = action.payload
    },

    setUsersCommunities: (
      state,
      action: PayloadAction<Array<CommunityEntryRes>>
    ) => {
      state.usersCommunities = action.payload
    },
    setTotalSubscriberCount: (
      state,
      action: PayloadAction<SubscriberCountResp>
    ) => {
      state.subscriberCount = action.payload
      setUsersSubscribersProps(action.payload)
    },
    addSubscriber: (state, action: PayloadAction<UserAndSubscription>) => {
      // Either add to the records on that page.
      if (state.subscribers?.records) {
        // Add to the beginning of the array because it'll be the latest subscriber.
        state.subscribers.records.unshift(action.payload)

        // Then amend the total record counts.
        if (
          state.subscribers.query &&
          state.subscribers.query.totalRecords !== undefined &&
          state.subscribers.query.pageSize !== undefined
        ) {
          state.subscribers.query.totalRecords++

          // Then increment the page record count too.
          state.subscribers.query.pageSize++
        }
      } else {
        // Or if none exist, create a new page as if this were the first.
        state.subscribers = {
          records: [action.payload],
          query: { pageSize: 1, totalRecords: 1, pageOffset: 0 },
        }
      }
    },
    removeSubscriber: (state, action: PayloadAction<string>) => {
      const id = state.subscribers?.records.findIndex(
        (s) => s.subscription.id === action.payload
      )

      console.log("Removing subscription", action.payload, id)

      if (
        state.subscribers?.query &&
        state.subscribers.query.totalRecords !== undefined
      ) {
        // Decrement the total record count whether the record is on this page or not.
        state.subscribers.query.totalRecords--
      }

      if (id === undefined || id === -1) return
      state.subscribers?.records.splice(id, 1)

      if (
        state.subscribers?.query &&
        state.subscribers.query.pageSize !== undefined
      ) {
        // If the record is on this page, decrement the count of records on this particular page (instead of re-fetching more records on each delete which is DB heavy).
        state.subscribers.query.pageSize--
      }
    },
    updateSubscriber: (state, action: PayloadAction<UserAndSubscription>) => {
      if (!state.subscribers) return

      const subscriptionId = state.subscribers?.records.findIndex(
        (s) => s.subscription.id == action.payload.subscription.id
      )

      state.subscribers.records[subscriptionId] = action.payload
    },
    setPartialBlog: (state, action: PayloadAction<Partial<Blog>>) => {
      state.blog = { ...state.blog, ...action.payload }
    },
    setPartialUsersBlog: (state, action: PayloadAction<Partial<Blog>>) => {
      state.usersBlog = { ...state.usersBlog, ...action.payload }
    },
    setCreatedBlogEmail: (state, action: PayloadAction<BlogEmail>) => {
      if (!state.blogEmails) return

      state.blogEmails.push(action.payload)
    },
    setUpdatedBlogEmail: (state, action: PayloadAction<BlogEmail>) => {
      if (!state.blogEmails) return

      const id = state.blogEmails.findIndex((s) => s.id === action.payload.id)

      if (id === undefined || id === -1) return

      state.blogEmails[id] = action.payload
    },
    deleteBlogEmailLocal: (state, action: PayloadAction<BlogEmail>) => {
      if (!state.blogEmails) return

      const id = state.blogEmails.findIndex((s) => s.id === action.payload.id)

      if (id === undefined || id === -1) return

      state.blogEmails.splice(id, 1)
    },
    setSubscribersToOptOutsMap: (
      state,
      action: PayloadAction<Record<string, SubscriptionOptOutOrIn[]>>
    ) => {
      state.subscribersToOptOutsMap = action.payload
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      //if (!state.initialLoad) return state

      /*
      console.log("BLOG HYDRATE")
      console.log({ ...state })
      console.log(action.payload)
      */

      const nextState = {
        ...state,
        ...action.payload.blog,
      }
      nextState.initialLoad = false
      return nextState
    },
  },
})

const {
  setUsersCommunities,
  setUsersTokens,
  addUsersToken,
  setBlogEmails,
  setCommunities,
  setTokens,
  setPartialUsersBlog,
  addSubscriber,
  setCreatedBlogEmail,
  setUpdatedBlogEmail,
  deleteBlogEmailLocal,
  setTotalSubscriberCount,
  updateSubscriber: updateSubscription,
} = blogSlice.actions
// Publicly accessible, within components.
export const {
  removeSubscriber,
  setUsersBlog,
  setBlog,
  setUsersTeamMembers,
  setDiscounts,
  setSubscribersToOptOutsMap,
  setBlogIntroduction,
  setUsersBlogIntroduction,
} = blogSlice.actions

export const getLoggedInUsersBlog = (): Thunk => async (dispatch, getState) => {
  const blog = await api.getLoggedInUsersBlog()
  dispatch(setUsersBlog(blog))

  console.log(
    "About to get logged in users communities, memberships, teammembers, and emails...",
    blog
  )

  api.getCommunities(blog.id).then((c) => dispatch(setUsersCommunities(c)))
  api.getTokens(blog.id).then((t) => dispatch(setUsersTokens(t)))
  api.getTeamMembers(blog.id).then((t) => dispatch(setUsersTeamMembers(t)))
  api.getDiscounts(blog.id).then((t) => dispatch(setDiscounts(t)))
  emailsAPI.getBlogEmails(blog.id).then((t) => dispatch(setBlogEmails(t)))
  api
    .getBlogsSubscribersCount(blog.id)
    .then((t) => dispatch(setTotalSubscriberCount(t || 0)))
  // Fetch the blog's memberships (i.e. memberships the publication has created).
  dispatch(getBlogMemberships(blog.id))
}

/**
 * Retrieve some other blog the user is reading.
 */
export const getBlogByUrl =
  (url: string): Thunk<Promise<Blog>> =>
  async (dispatch) => {
    const blog = await api.getBlogByUrl(url)
    const communitiesPromise = api.getCommunities(blog.id)
    const tokensPromise = api.getTokens(blog.id)
    const upsellMembershipPromise = dispatch(
      getUpsellMemberships(blog.id, null)
    )
    const blogIntroductionPromise = dispatch(getBlogIntroduction(blog.id))

    console.log("About to get blog's communities, memberships, and emails...")

    const [communities, tokens, _] = await Promise.all([
      communitiesPromise,
      tokensPromise,
      upsellMembershipPromise,
      blogIntroductionPromise,
    ])

    await Promise.all([
      dispatch(setBlog(blog)),
      dispatch(setCommunities(communities)),
      dispatch(setTokens(tokens)),
    ])

    console.log("Set tokens and required data!", tokens)

    return blog
  }

// Updates the USER'S blog.
export const updateBlog =
  (blog: Blog): Thunk<Promise<boolean>> =>
  async (dispatch) => {
    const blogResp = await api.updateBlog(blog)
    if (isErrorResponse(blogResp)) {
      dispatch(setError(blogResp))
      return false
    }
    dispatch(setPartialUsersBlog(blog))
    dispatch(setError({ msg: "" }))

    return true
  }

/**
 * Create a new blog email.
 * @param blogEmail
 * @returns The string ID of the newly created blog email record in Firestore, or false if it's am error.
 */
export const createBlogEmail =
  (blogEmail: BlogEmail): Thunk<Promise<ErrorResponse | PostBlogEmailRes>> =>
  async (dispatch) => {
    console.log("Creating new blog email ", blogEmail)

    // Call POST API to update server store.
    const blogEmailResp = await emailsAPI.postBlogEmail(blogEmail)

    if (isErrorResponse(blogEmailResp)) {
      dispatch(setError(blogEmailResp))
      return blogEmailResp
    }

    // Update the record with the ID returned from the server.
    blogEmail.id = blogEmailResp.id

    // Update local store.
    dispatch(setCreatedBlogEmail(blogEmail))

    return blogEmailResp
  }

// Update a blog email.
export const updateBlogEmail =
  (blogEmail: BlogEmail): Thunk<Promise<ErrorResponse | SuccessResponse>> =>
  async (dispatch) => {
    console.log("Updating blog email ", blogEmail)

    // Call PUT API to update server store.
    const blogEmailResp = await emailsAPI.putBlogEmail(blogEmail)

    if (isErrorResponse(blogEmailResp)) {
      dispatch(setError(blogEmailResp))
      return blogEmailResp
    }

    // Update local store.
    dispatch(setUpdatedBlogEmail(blogEmail))

    return blogEmailResp
  }

// Delete a blog email.
export const deleteBlogEmail =
  (blogEmail: BlogEmail): Thunk<Promise<boolean>> =>
  async (dispatch) => {
    console.log("Deleting blog email ", blogEmail)

    // Delete from local store.
    dispatch(deleteBlogEmailLocal(blogEmail))

    // Call DELETE API to delete from server store.
    const blogEmailResp = await emailsAPI.deleteBlogEmail(blogEmail)

    if (isErrorResponse(blogEmailResp)) {
      dispatch(setError(blogEmailResp))
      return false
    }

    return true
  }

// When the user is viewing a DIFFERENT blog
// and subscriber to that blog
export const subscribeUserToBlog =
  (email?: string, blogUrl?: string): Thunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    dispatch(setError({ msg: "" }))
    dispatch(setLoading(true))

    if (email && !validateEmail(email)) {
      console.log("Invalid email!")
      dispatch(setError({ msg: "Please enter a valid email address" }))
      return false
    }

    const blog = selectBlog(getState())
    const sub = await api.subscribeUserToBlog({
      lowercaseBlogUrl: blogUrl || blog.lowercase_url,
      blogId: blog.id,
      email,
    })

    if (isErrorResponse(sub)) {
      console.log("Error subscribing to blog!", sub)
      dispatch(setError(sub))
      return false
    }

    dispatch(setSubscription(sub))

    // Since a user might have been registered as part of this flow (if they were a logged out user)
    // we need to refresh the user.
    dispatch(refreshUser())

    dispatch(setLoading(false))

    return true
  }

// When the user is in their own blogs
// settings and clicks "add Subscriber".
// Only an email or a wallet may be used - NOT both.
export const addSubscriberToOwnBlog =
  ({
    email,
    wallet,
  }: {
    email?: string
    wallet?: string
  }): Thunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    if (email && wallet) {
      console.error("Cannot add subscriber with both email and wallet")
      return false
    }

    const sub = await api.addSubscriberToBlog(email, wallet)
    if (isErrorResponse(sub)) {
      dispatch(setError(sub))
      return false
    }

    const count = getState().blog.subscriberCount

    const currentCount = {
      active: count?.active || 0,
      total: count?.total || 0,
      unsubscribed: count?.unsubscribed || 0,
      activeAndOptedIntoGeneral: count?.activeAndOptedIntoGeneral || 0,
    }

    currentCount.active = currentCount.active + 1
    currentCount.total = currentCount.total + 1
    dispatch(setTotalSubscriberCount(currentCount))

    dispatch(addSubscriber(sub))
    return true
  }

// Import file from Substack or other competitor.
export const importFile =
  (
    file: File,
    type: ImportType,
    importIntoCommunities: boolean,
    communityIds: string[]
  ): Thunk<Promise<string | null>> =>
  async (dispatch) => {
    const data = await api.importFile(
      file,
      type,
      importIntoCommunities,
      communityIds
    )

    if (isErrorResponse(data)) {
      dispatch(setError(data))
      return null
    }

    return data.jobId
  }

// Adds token to the server and then to local storage.
export const addToken =
  (token: Partial<Token>): Thunk<Promise<string>> =>
  async (dispatch) => {
    try {
      const serverToken = await api.addToken(token)

      dispatch(addUsersToken(serverToken))

      return serverToken.id
    } catch (err) {
      console.error("An error occurred adding token to the server:", {
        token,
        err,
      })
      return ""
    }
  }

export const addSubscriptionOptOut =
  (subscriptionId: string, communityId: string): Thunk<Promise<void>> =>
  async (dispatch) => {
    try {
      // Update server state.
      const updatedSubscription = await api.addSubscriptionOptOut(
        subscriptionId,
        communityId
      )

      if (isErrorResponse(updatedSubscription)) {
        console.log("Failed to add community to subscription.", {
          subscriptionId,
          communityId,
        })
        return
      }

      // Update local state.
      dispatch(updateSubscription(updatedSubscription))
    } catch (err) {
      console.error(
        "An error occurred adding community opt-out to subscription.",
        {
          subscriptionId,
          communityId,
        }
      )
    }
  }

export const addSubscriptionOptOutsInBulk =
  (
    subscriptionIds: string[],
    communityId: string,
    blogId: string
  ): Thunk<Promise<void>> =>
  async (dispatch) => {
    try {
      // Update server state.
      const updatedSubscriptions = await api.addSubscriptionOptOutsInBulk(
        subscriptionIds,
        communityId,
        blogId
      )

      if (isErrorResponse(updatedSubscriptions)) {
        console.log(
          "Failed to add community opt-out to subscriptions in bulk.",
          {
            subscriptionIds,
            communityId,
          }
        )
        return
      }

      // Update local state.
      for (const subscription of updatedSubscriptions) {
        dispatch(updateSubscription(subscription))
      }
    } catch (err) {
      console.error(
        "An error occurred adding community opt-out to subscriptions in bulk.",
        {
          subscriptionIds,
          communityId,
        }
      )
    }
  }

export const removeSubscriptionOptOut =
  (subscriptionId: string, communityId: string): Thunk<Promise<void>> =>
  async (dispatch) => {
    try {
      // Update server state.
      const updatedSubscription = await api.removeSubscriptionOptOut(
        subscriptionId,
        communityId
      )

      if (isErrorResponse(updatedSubscription)) {
        console.log("Failed to remove community from subscription.", {
          subscriptionId,
          communityId,
        })
        return
      }

      // Update local state.
      dispatch(updateSubscription(updatedSubscription))
    } catch (err) {
      console.error("An error occurred removing community from subscription.", {
        subscriptionId,
        communityId,
      })
    }
  }

export const removeSubscriptionOptOutsInBulk =
  (
    subscriptionIds: string[],
    communityId: string,
    blogId: string
  ): Thunk<Promise<void>> =>
  async (dispatch) => {
    try {
      // Update server state.
      const updatedSubscriptions = await api.removeSubscriptionOptOutsInBulk(
        subscriptionIds,
        communityId,
        blogId
      )

      if (isErrorResponse(updatedSubscriptions)) {
        console.log(
          "Failed to remove community opt-out from subscriptions in bulk.",
          {
            subscriptionIds,
            communityId,
          }
        )
        return
      }

      // Update local state.
      for (const subscription of updatedSubscriptions) {
        dispatch(updateSubscription(subscription))
      }
    } catch (err) {
      console.error(
        "An error occurred removing community opt-out to subscriptions in bulk.",
        {
          subscriptionIds,
          communityId,
        }
      )
    }
  }

export const getBlogIntroduction =
  (blogId: string): Thunk<Promise<BlogIntroduction | null>> =>
  async (dispatch) => {
    console.log("Getting blogIntroduction for blog", blogId)

    const blogIntroduction = await api.getBlogIntroduction(blogId)
    if (isErrorResponse(blogIntroduction)) {
      dispatch(setError(blogIntroduction))
      return null
    }

    console.log("Got blogIntroduction for blog with ID", {
      blogId,
      blogIntroduction,
    })

    dispatch(setBlogIntroduction(blogIntroduction))
    return blogIntroduction
  }

export const getUsersBlogIntroduction =
  (): Thunk<Promise<BlogIntroduction | null>> => async (dispatch, getState) => {
    const blogId = selectUsersBlog(getState()).id

    console.log("Getting blogIntroduction for user's blog", blogId)

    if (!blogId) {
      console.error(
        "No blog ID found in state, can't fetch user's blog introduction."
      )
      return null
    }

    const blogIntroduction = await api.getBlogIntroduction(blogId)
    if (isErrorResponse(blogIntroduction)) {
      dispatch(setError(blogIntroduction))
      return null
    }

    console.log("Got blogIntroduction for user's own blog", {
      blogId,
      blogIntroduction,
    })

    dispatch(setUsersBlogIntroduction(blogIntroduction))
    return blogIntroduction
  }

export const createUsersBlogIntroduction =
  (): Thunk<Promise<boolean>> => async (dispatch, getState) => {
    const blogId = selectUsersBlog(getState()).id

    const createdBlogIntroduction = await api.createBlogIntroduction(blogId)
    if (isErrorResponse(createdBlogIntroduction)) {
      dispatch(setError(createdBlogIntroduction))
      return false
    }

    dispatch(setUsersBlogIntroduction(createdBlogIntroduction))
    return true
  }

export const updateUsersBlogIntroduction =
  (blogIntroduction: BlogIntroduction): Thunk<Promise<boolean>> =>
  async (dispatch) => {
    const updatedBlogIntroduction = await api.updateBlogIntroduction(
      blogIntroduction
    )
    if (isErrorResponse(updatedBlogIntroduction)) {
      dispatch(setError(updatedBlogIntroduction))
      return false
    }

    dispatch(setUsersBlogIntroduction(blogIntroduction))
    return true
  }

export const deleteUsersBlogIntroduction =
  (): Thunk<Promise<boolean>> => async (dispatch, getState) => {
    const blogId = selectUsersBlog(getState()).id

    const deletedBlogIntroduction = await api.deleteBlogIntroduction(blogId)
    if (isErrorResponse(deletedBlogIntroduction)) {
      dispatch(setError(deletedBlogIntroduction))
      return false
    }

    dispatch(setUsersBlogIntroduction(null))
    return true
  }

export const selectBlog = (state: RootState) => state.blog.blog
export const selectBlogEmails = (state: RootState) => state.blog.blogEmails
export const selectUsersBlog = (state: RootState) => state.blog.usersBlog
export const selectUsersCommunities = (state: RootState) =>
  state.blog.usersCommunities

export const selectUsersTeamMembers = (state: RootState) => {
  return state.blog.usersTeamMembers?.filter((t) => !t.isDeleted)
}

export const selectBlogIntroduction = (state: RootState) =>
  state.blog.blogIntroduction

export const selectUsersBlogIntroduction = (state: RootState) =>
  state.blog.usersBlogIntroduction

export const selectDiscounts = (state: RootState) => state.blog.discounts

// Selects publicly accessible tokens that correspond to the current blog, whether the user's own or one they are viewing.
export const selectTokens = (state: RootState) => state.blog.tokens
// Selects the tokens that the user minted on (or added to) Paragraph.
export const selectUsersTokens = (state: RootState) => state.blog.usersTokens

export const selectCommunities = (state: RootState) => state.blog.communities
export const selectSubscriberCount = (state: RootState) =>
  state.blog.subscriberCount

export const selectSubscribers = (state: RootState) => state.blog.subscribers

export const selectSubscribersToOptOutsMap = (state: RootState) =>
  state.blog.subscribersToOptOutsMap

export default blogSlice.reducer

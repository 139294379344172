import { PhotoIcon } from "@heroicons/react/24/solid"
import { Membership } from "@types"
import clsx from "clsx"
import Image from "next/image"

export default function ImageSection({
  membership,
  memberships,
  isAuthorView,
}: {
  membership: Membership
  memberships: Membership[]
  isAuthorView?: boolean
}) {
  // Checking for both membership.image and memberships.every((t) => !t.image) because the former takes care of the case
  // when the membership just got an image uploaded as part of a form (but not yet saved to the database), since at that
  // point the `memberships` array is not yet updated.
  const isNoImages = memberships.every((t) => !t.image) && !membership.image

  if (isNoImages && !membership.image && !isAuthorView) return null

  return (
    <div
      className={clsx(
        "flex-1 flex justify-center items-center my-4 sm:my-8",
        isNoImages ? "sm:h-6" : "sm:h-18"
      )}
    >
      {!membership.image && isAuthorView && (
        <PhotoIcon
          className="mx-auto w-12 h-12 text-gray-300"
          aria-hidden="true"
        />
      )}
      {membership.image && (
        <Image
          src={membership.image.img}
          className="rounded-l w-32 shadow-md"
          alt={`${membership.name} image.`}
          height={200}
          width={200}
        />
      )}
    </div>
  )
}

import { CheckIcon } from "@heroicons/react/24/solid"
import { Membership } from "@types"

export default function BenefitsSection({
  membership,
}: {
  membership: Membership
}) {
  return (
    <ul
      role="list"
      className="mx-2 mt-6 space-y-3 text-sm leading-6 text-custom-600 dark:text-gray-400"
    >
      {membership.benefits?.map((benefit, index) => (
        <li key={index} className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-primary-600"
            aria-hidden="true"
          />
          {benefit}
        </li>
      ))}
    </ul>
  )
}

import { useEffect } from "react"
import { useAnalytics } from "hooks/useAnalytics"
import clsx from "clsx"

interface modelProps {
  title?: string
  subtitle?: string | React.ReactNode
  body?: React.ReactNode
  className?: string
  fadeInClickRef?: React.Ref<any>
}

export default function InnerModalSkeleton(props: modelProps) {
  const { track } = useAnalytics()

  useEffect(() => {
    track("modal shown", {
      title: props.title,
      subtitle: props.subtitle,
    })
  }, [])

  return (
    <div className="min-h-screen flex justify-center flex-col py-4 sm:py-0 px-4">
      <div
        ref={props.fadeInClickRef}
        className={clsx(
          "flex flex-col border-gray-150 rounded-xl border bg-white m-auto p-8"
        )}
      >
        <div
          className={clsx(
            props.className,
            "flex justify-center flex-col py-4 sm:py-0"
          )}
        >
          <div>
            <div className="mt-3 text-center sm:mt-5">
              <h3 className="text-3xl font-bold text-gray-900 leading-6">
                {props.title}
              </h3>
              {props.subtitle !== undefined && (
                <div className="mt-2">
                  <p className="text-gray-500 text-md">{props.subtitle}</p>
                </div>
              )}
            </div>
          </div>

          {props.body}
        </div>
      </div>
    </div>
  )
}

import { EnrichedCollectRecord, ExtendedCollectible } from "@/types/highlights"
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { getHighlights } from "api_routes/highlights"
import { Thunk, RootState } from "../store"
import { HYDRATE } from "next-redux-wrapper"
import { v4 as uuidv4 } from "uuid"

import {
  setColorMode as setCookieColorMode,
  getColorMode as getCookieColorMode,
} from "util/cookies"

type EditorType = "WYSIWYG" | "Markdown"

type ToastDetails = {
  /**
   * Unique ID for this toast created on the FE to keep track of it so we can delete it from local storage after it's been closed.
   */
  id: string
  type: "info" | "success" | "warning" | "error"
  /**
   * Main text to display in the toast.
   */
  text: string
  /**
   * Optional. Gets shown in bold at the top of the toast.
   */
  title?: string
  isShown: boolean
}

type PostCollectors = {
  total: number
  records: Array<EnrichedCollectRecord>
}

interface PageState {
  highlights: Array<ExtendedCollectible>
  postCollectors: PostCollectors
  sidebarOpen: boolean
  searchText: string
  loading: boolean
  currentNoteId: string
  editor: EditorType
  fullscreenEditor: boolean
  colorMode: ColorMode
  bannerToasts: ToastDetails[]
}

const defaultColorMode = (): ColorMode => {
  const cookieColorMode = getCookieColorMode()
  if (cookieColorMode) {
    return cookieColorMode as ColorMode
  }
  return "light"
}

export type ColorMode = "light" | "dark"

const initialState: PageState = {
  highlights: [],
  postCollectors: {
    total: 0,
    records: [],
  },
  sidebarOpen: false,
  colorMode: defaultColorMode(),
  searchText: "",
  currentNoteId: "",
  editor: "WYSIWYG",
  fullscreenEditor: false,
  loading: false,
  bannerToasts: [],
}

export const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    setSidebarOpen: (state, action) => {
      state.sidebarOpen = action.payload
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload
    },

    setLoading: (state, action) => {
      state.loading = action.payload
    },

    setHighlights: (
      state,
      action: PayloadAction<Array<ExtendedCollectible>>
    ) => {
      console.log("Setting HIGHLIGHTS", action.payload)

      state.highlights = action.payload
    },

    setColorMode: (state, action: PayloadAction<ColorMode>) => {
      state.colorMode = action.payload
      setCookieColorMode(action.payload)
    },

    setEditor: (state, action: PayloadAction<EditorType>) => {
      state.editor = action.payload
    },

    setPostCollectors: (state, action: PayloadAction<PostCollectors>) => {
      state.postCollectors = action.payload
    },

    toggleFullscreenEditor: (state) => {
      state.fullscreenEditor = !state.fullscreenEditor
    },
    addBannerToast: (state, action: PayloadAction<ToastDetails>) => {
      state.bannerToasts = [...state.bannerToasts, action.payload]
    },
    markBannerToastAsShown: (state, action: PayloadAction<string>) => {
      state.bannerToasts = state.bannerToasts.map((toast) => {
        if (toast.id === action.payload) {
          toast.isShown = true
        }
        return toast
      })
    },
    clearBannerToast: (state, action: PayloadAction<string>) => {
      state.bannerToasts = [
        ...state.bannerToasts.filter((toast) => toast.id !== action.payload),
      ]
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      const nextState = {
        ...state,
        ...action.payload.page,
      }
      nextState.initialLoad = false
      return nextState
    },
  },
})

// Publicly accessible, within components.
export const {
  setSidebarOpen,
  setSearchText,
  setEditor,
  toggleFullscreenEditor,
  setHighlights,
  setColorMode,
  setLoading,
  addBannerToast,
  markBannerToastAsShown,
  clearBannerToast,
  setPostCollectors,
} = pageSlice.actions

// Privately accessible, only within Thunks in this file.
export const selectSidebarOpen = (state: RootState) => state.page.sidebarOpen
export const selectSearchText = (state: RootState) => state.page.searchText
export const selectEditor = (state: RootState) => state.page.editor
export const selectPostCollectors = (state: RootState) =>
  state.page.postCollectors

export const selectAllCollectibles = (state: RootState) => {
  //console.log("should i show, fucking holy toleto state", state)
  return state.page.highlights
}

const _selectPageHighlights = (state: RootState) => state.page.highlights

export const selectHighlights = createSelector(
  [_selectPageHighlights],
  (highlights) => {
    return highlights.filter((h) => h.collectible.type === "HIGHLIGHT") || []
  }
)

export const selectPostCollectible = (
  state: RootState
): ExtendedCollectible | undefined => {
  const postCollectibles = state.page.highlights.filter(
    (h) => h.collectible.type === "POST"
  )

  if (postCollectibles.length > 1) {
    console.error(
      "There should only ever be a single post collectible per post, so something went wrong here."
    )
  }

  return postCollectibles?.[0] || undefined
}

export const selectLoading = (state: RootState) => state.page.loading

export const selectFullscreenEditor = (state: RootState) =>
  state.page.fullscreenEditor

export const retrieveHighlights =
  (noteId: string): Thunk<void> =>
  async (dispatch, getState) => {
    console.log("Retrieving highlights...", noteId)
    const hls = await getHighlights(noteId)
    dispatch(setHighlights(hls))
  }

export const makeToast =
  (type: ToastDetails["type"], text: string, title?: string): Thunk<void> =>
  async (dispatch, getState) => {
    const toastDetails = {
      id: uuidv4(),
      type,
      text,
      title,
      isShown: false, // Default to not yet shown.
    }

    dispatch(addBannerToast(toastDetails))
  }

export const selectBannerToasts = (state: RootState) => state.page.bannerToasts

export default pageSlice.reducer

import { BaseUserPublic, User } from "@/types/users"
import { notEmpty } from "@/util/query"
import { Blog, GetNoteResp } from "@types"
import { HTMLProps, MutableRefObject } from "react"
import {
  FaTwitter,
  FaFacebook,
  FaInstagram,
  FaGithub,
  FaEthereum,
} from "react-icons/fa"

import { SiFarcaster } from "react-icons/si"

import SocialIcon from "components/public/BlogTheme/PostPage/Header/SocialIcon"
import useAuthorName from "hooks/useAuthorName"
import { FarcasterProfile } from "@/types/responses/public"
import AuthorHoverCard from "components/AuthorHoverCard"

type Props = {
  blog: Blog
  note: GetNoteResp
}

export default function AuthorNames(props: Props) {
  if (!props.note.authorDetails) return null

  return (
    <ul className="flex">
      <h3 className="text-sm font-semibold leading-7 transition-opacity ease-in duration-1000 opacity-100 xs:w-full break-all">
        {props.note.authors
          // This is to ensure we're ordering authors correctly. The `authors` string array is the source of truth
          // for ordering.
          ?.map((a) => props.note.authorDetails.find((ad) => ad.id == a))
          .filter(notEmpty)
          .map((author, idx) => (
            <span key={author.id} className="inline-flex">
              <span>
                <AuthorName author={author} props={props} />
              </span>
              {idx < props.note.authorDetails.length - 2 && (
                <AuthorNamesComma />
              )}
              {idx == props.note.authorDetails.length - 2 && <AuthorNamesAnd />}
            </span>
          ))}
      </h3>
    </ul>
  )
}

function AuthorName({
  author,
  props,
}: {
  author: BaseUserPublic
  props: Props
}) {
  const authorName = useAuthorName({
    user: author,
    blog: props.note.authors.length <= 1 ? props.blog : undefined,
  })

  const onlyOneAuthor = props.note.authors.length <= 1

  return (
    <AuthorHoverCard key={author.id} author={author as User}>
      <span className="text-custom-800">
        {!onlyOneAuthor ? authorName.name : authorName.fullName}
      </span>
    </AuthorHoverCard>
  )
}

function AuthorNamesAnd(): JSX.Element {
  return <span className="text-custom-500 whitespace-pre"> and </span>
}

function AuthorNamesComma(): JSX.Element {
  return <span className="text-custom-500 whitespace-pre">, </span>
}

export function SocialMediaIcons({
  author,
  useOutsideClickRef,
  farcaster,
  ...rest
}: {
  author?: BaseUserPublic
  farcaster?: FarcasterProfile
  useOutsideClickRef?: MutableRefObject<null>
} & HTMLProps<HTMLInputElement>): JSX.Element {
  const wallet = author?.wallet_address || author?.lowercase_wallet_address

  return (
    // If the user DOES have social media links, make this component the ref outside of which clicking will close this author's popup.
    // That way they can still click on the social media links and it'll work instead of just collapsing the popup.
    <div className="flex items-center space-x-2" ref={useOutsideClickRef}>
      {wallet && (
        <SocialIcon
          Icon={FaEthereum}
          href={`https://rainbow.me/${wallet}`}
          {...rest}
        />
      )}
      {(author?.social?.farcaster || farcaster) && (
        <SocialIcon
          Icon={SiFarcaster}
          href={`https://warpcast.com/${
            author?.social?.farcaster || farcaster?.username
          }`}
          {...rest}
        />
      )}
      {author?.social?.twitter && (
        <SocialIcon
          Icon={FaTwitter}
          href={`https://twitter.com/${author.social.twitter}`}
          {...rest}
        />
      )}
      {author?.social?.facebook && (
        <SocialIcon
          Icon={FaFacebook}
          href={`${author.social.facebook}`}
          {...rest}
        />
      )}
      {author?.social?.instagram && (
        <SocialIcon
          Icon={FaInstagram}
          {...rest}
          href={`https://instagram.com/${author.social.instagram}`}
        />
      )}
      {author?.social?.github && (
        <SocialIcon
          Icon={FaGithub}
          href={`https://github.com/${author.social.github}`}
          {...rest}
        />
      )}
    </div>
  )
}

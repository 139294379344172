import psl from "psl"

export const getDomainName = (hostname: string): string => {
  // Split any https:// prefixes from the rest of the hostname.
  const splitOrigin = hostname?.split("://")

  // Remove any https:// prefixes.
  const hostnameWithoutProtocolPrefix =
    splitOrigin && splitOrigin.length > 1
      ? splitOrigin[1]
      : splitOrigin && splitOrigin.length > 0
      ? splitOrigin[0]
      : "paragraph.xyz"

  // Use psl library to remove any subdomains.
  const pslParse = psl.parse(hostnameWithoutProtocolPrefix)
  const originDomain = !pslParse.error ? pslParse.domain : null

  return originDomain || "paragraph.xyz"
}

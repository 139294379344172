export default function FarcasterIcon(props: any) {
  return (
    <svg
      width="1024"
      height="1024"
      viewBox="0 0 1024 1024"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_1_2)">
        <rect
          x="100"
          y="100"
          width="824"
          height="824"
          rx="184"
          fill="#8A63D2"
        />
        <rect
          x="100"
          y="100"
          width="824"
          height="824"
          rx="184"
          fill="url(#paint0_linear_1_2)"
          fillOpacity="0.5"
        />
      </g>
      <g filter="url(#filter1_ddd_1_2)">
        <path
          d="M797.357 756.286C808.604 756.286 817.714 765.396 817.714 776.643V797H614.143V776.643C614.143 765.396 623.253 756.286 634.5 756.286H797.357Z"
          fill="#FEFEFE"
        />
        <path
          d="M797.357 756.286V735.928C797.357 724.681 788.247 715.571 777 715.571H654.857C643.61 715.571 634.5 724.681 634.5 735.928L634.5 756.286H797.357Z"
          fill="#FEFEFE"
        />
        <path d="M715.928 227H308.786V308.429H715.928V227Z" fill="#FEFEFE" />
        <path
          d="M715.928 288.071H308.786V308.429H715.928V288.071Z"
          fill="#FEFEFE"
        />
        <path
          d="M797.357 389.857H227.357L207 308.429H817.714L797.357 389.857Z"
          fill="#FEFEFE"
        />
        <path
          d="M777 389.857H654.857L654.857 715.571H777V389.857Z"
          fill="#FEFEFE"
        />
        <path
          d="M390.214 756.286C401.462 756.286 410.571 765.396 410.571 776.643V797H207V776.643C207 765.396 216.11 756.286 227.357 756.286H390.214Z"
          fill="#FEFEFE"
        />
        <path
          d="M390.214 756.286V735.928C390.214 724.681 381.104 715.571 369.857 715.571H247.714C236.467 715.571 227.357 724.681 227.357 735.928L227.357 756.286H390.214Z"
          fill="#FEFEFE"
        />
        <path
          d="M369.857 389.857H247.714L247.714 715.571H369.857L369.857 389.857Z"
          fill="#FEFEFE"
        />
        <path
          d="M369.857 551.086C369.857 472.385 433.656 408.586 512.357 408.586C591.058 408.586 654.857 472.385 654.857 551.086V347.514H369.857V551.086Z"
          fill="#FEFEFE"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1_2"
          x="96"
          y="100"
          width="832"
          height="832"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1_2"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1_2"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_ddd_1_2"
          x="203"
          y="223"
          width="642.714"
          height="602"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="12" dy="12" />
          <feGaussianBlur stdDeviation="8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1_2"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="6" dy="6" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1_2"
            result="effect2_dropShadow_1_2"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_1_2"
            result="effect3_dropShadow_1_2"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_1_2"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1_2"
          x1="512"
          y1="100"
          x2="512"
          y2="924"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="1" stopOpacity="0.2" />
        </linearGradient>
      </defs>
    </svg>
  )
}

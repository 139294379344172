import { EnrichedGatingRuleGroup, Token } from "@/types/gatingRules"

import ConnectWalletBtn from "@/components/ConnectWalletButton"
import Banner from "../Banner"
import { Dispatch, SetStateAction } from "react"
import Placeholder from "components/Placeholder"
import { isTokenGate } from "@/util/gatingRules"
import clsx from "clsx"
import { Membership } from "@types"
import { subscribeBlockProps } from "./SharedTypes"
import GatingRuleGroups from "./GatingRuleGroups"

export default function ReaderGateTokenModal({
  props,
  gatingRuleGroups,
  user,
  color,
  tokens,
  memberships,
  error,
  setError,
  isError,
  setIsError,
}: {
  props: subscribeBlockProps
  gatingRuleGroups: EnrichedGatingRuleGroup[] | undefined
  user: any
  color: any
  tokens: Token[] | undefined
  memberships: Membership[] | undefined
  error: Error | null
  setError: Dispatch<SetStateAction<Error | null>>
  isError: boolean
  setIsError: Dispatch<SetStateAction<boolean>>
}) {
  let title = "This is an exclusive post."

  if (props.gateContentType === "AFTER")
    title = "The rest of this post is exclusive."

  return (
    <div
      className={clsx(
        "relative rounded-2xl divide-y divide-gray-200 -mt-28 sm:-mt-32 z-10",
        color.isDarkTheme
          ? "shadow-dark-gray"
          : "shadow-2xl border border-gray-200"
      )}
      style={{
        backgroundColor: color.secondary || "white",
      }}
    >
      <div className="px-4 py-5 sm:px-6">
        <div className="flex flex-col place-items-center">
          <h3
            className="text-lg leading-6 font-medium"
            style={{ color: color.fontColor || "text-gray-900" }}
          >
            {title}
          </h3>
          <p
            className="mt-1 text-sm"
            style={{
              color: color.fontColor || "text-gray-500",
            }}
          >
            {gatingRuleGroups &&
              gatingRuleGroups.flatMap((g) => g.gatingRules).length == 1 &&
              "You need to meet this requirement to see it."}
            {gatingRuleGroups &&
              gatingRuleGroups.flatMap((g) => g.gatingRules).length > 1 &&
              "You need to meet the following requirements to see it."}
          </p>
        </div>
        {/* Loading placeholder. */}
        {!gatingRuleGroups && (
          <div className="p-4 mt-2">
            <Placeholder />
          </div>
        )}
        {gatingRuleGroups && gatingRuleGroups.length !== 0 && (
          <div className="mt-4">
            <div>
              <div
                role="list"
                className="flex flex-col divide-y-8 divide-transparent overflow-auto max-h-64 md:max-h-148"
              >
                {gatingRuleGroups &&
                  tokens &&
                  gatingRuleGroups.map((group, index) => (
                    <GatingRuleGroups
                      key={group.id}
                      gatingRuleGroups={gatingRuleGroups}
                      group={group}
                      index={index}
                      memberships={memberships}
                      tokens={tokens}
                      setIsError={setIsError}
                      setError={setError}
                      color={color}
                    />
                  ))}
              </div>
            </div>

            {gatingRuleGroups
              ?.flatMap((group) => group.enrichedGatingRules)
              .some((g) => isTokenGate(g)) &&
              !user.cryptoData?.isConnected && (
                <div className="flex justify-center">
                  <p className="mt-8 text-sm text-gray-500 max-w-md">
                    Already meet{" "}
                    {gatingRuleGroups?.flatMap((group) => group.gatingRules)
                      .length > 1
                      ? "these requirements"
                      : "this requirement"}
                    ? Connect your wallet to verify or to purchase the token.
                  </p>
                </div>
              )}

            {isError && error?.message && (
              <div className="mt-2 overflow-x-auto">
                <Banner type="error" text={error.message} />
              </div>
            )}
            {!user.cryptoData?.isConnected &&
              gatingRuleGroups
                ?.flatMap((group) => group.enrichedGatingRules)
                .some((g) => isTokenGate(g)) && (
                <div className="flex justify-center items-center sm:ml-4 mt-4 sm:flex-shrink-0 sm:w-auto">
                  <ConnectWalletBtn />
                </div>
              )}
          </div>
        )}
      </div>
    </div>
  )
}

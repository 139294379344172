import { Dialog } from "@headlessui/react"
import { selectUsersBlog } from "features/blogSlice"
import { useSelector } from "react-redux"
import { SharedProps } from "./SharedProps"
import { BlogSubscriptionAndMembership, Membership } from "@types"
import { getSubscriptions } from "api_routes/memberships"
import {
  getBlogMemberships,
  selectBlogMemberships,
} from "features/membershipSlice"
import { selectUser } from "features/userSlice"
import { useState, useEffect } from "react"
import { useAppDispatch } from "store"
import clsx from "clsx"
import {
  ChevronRightIcon,
  CubeTransparentIcon,
} from "@heroicons/react/24/outline"
import { isFreeMembership } from "components/Memberships/SharedFunctions"
import Image from "next/legacy/image"
import {
  EnrichedGatingRuleGroup,
  GatingRequirementMembership,
} from "@/types/gatingRules"
import Button from "components/Button"
import {
  DisableMembershipGateSelection,
  CreateOrGroupsOfMemberships,
} from "./GateModalHelperFunctions"

export default function GateModalStepChooseMembership(props: SharedProps) {
  const dispatch = useAppDispatch()
  const blog = useSelector(selectUsersBlog)
  const user = useSelector(selectUser)
  const initialMemberships = useSelector(selectBlogMemberships)
  const [isLoading, setIsLoading] = useState(true)
  const [memberships, setMemberships] = useState<Membership[]>(
    initialMemberships || []
  )

  const [subscriptions, setSubscriptions] = useState<
    BlogSubscriptionAndMembership[]
  >([])

  useEffect(() => {
    const loadMemberships = async () => {
      setIsLoading(true)
      dispatch(getBlogMemberships(blog.id))
    }

    loadMemberships()
  }, [dispatch])

  useEffect(() => {
    const retrieveSubscriptions = async () => {
      setSubscriptions(await getSubscriptions())
    }

    retrieveSubscriptions()
  }, [blog, user, isLoading])

  useEffect(() => {
    if (initialMemberships) {
      setMemberships(initialMemberships)
    }
  }, [initialMemberships])

  useEffect(() => {
    if (memberships && memberships.length > 0) {
      setIsLoading(false)
    }
  }, [memberships])

  const previouslySelectedMemberships = props.existingGateGroups
    .filter((g) =>
      g.gatingRules.filter(
        (gr) => gr.gatingRequirement.gateType == "MEMBERSHIP"
      )
    )
    .flatMap((g) =>
      g.gatingRules.flatMap(
        (gr) =>
          (gr.gatingRequirement as GatingRequirementMembership).membershipId
      )
    )

  return (
    <div className="w-80 sm:w-full">
      <Dialog.Title
        as="h3"
        className="text-2xl font-bold text-center text-gray-900 leading-6"
      >
        Select membership
      </Dialog.Title>
      {/* 
          Select all memberships or all paid memberships buttons.
          Only show these when there are no gates created for this post yet.
      */}
      {props.existingGateGroups.length == 0 && (
        <div className="mt-6">
          <div className="flex justify-center space-x-4">
            <Button
              type="button"
              className="w-40"
              onClick={() =>
                CreateOrGroupsOfMemberships(
                  memberships.map((m) => m.id),
                  props
                )
              }
            >
              All memberships
            </Button>
            <Button
              type="button"
              className="w-40"
              onClick={() =>
                CreateOrGroupsOfMemberships(
                  memberships
                    .filter((m) => isFreeMembership(m) === false)
                    .map((m) => m.id),
                  props
                )
              }
            >
              Paid memberships
            </Button>
          </div>
        </div>
      )}
      <div className="mt-6">
        <div>
          <div className="overflow-hidden p-1 mt-2">
            <SelectMembershipsList
              memberships={memberships}
              selectedMembershipId={props.selectedMembershipId}
              setSelectedMembershipId={props.setSelectedMembershipId}
              selectedMembershipIds={previouslySelectedMemberships}
              existingGateGroups={props.existingGateGroups}
              selectedGateGroupId={props.selectedGateGroupId}
            />
          </div>
          <div>
            <div className="text-m font-medium text-gray-900 mt-4">
              {/* TODO: Make this text variable based on whether they have paid memberships or not. */}
              {/* "Create a paid one in
            your settings." */}
              Manage membership settings
            </div>
            <div className="mt-4">
              <p className="text-gray-600 text-base w-56 sm:w-full">
                Add paid memberships, rename them, change the list of benefits,
                and more{" "}
                <a
                  href="/settings/publication/memberships-token-gating"
                  target="_blank"
                  className=" text-blue-500 font-bold hover:underline cursor-pointer "
                >
                  here.
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function SelectMembershipsList({
  memberships,
  selectedMembershipId,
  setSelectedMembershipId,
  selectedMembershipIds: selectedMemberships,
  existingGateGroups,
  selectedGateGroupId,
}: {
  memberships: Membership[]
  selectedMembershipId: string
  setSelectedMembershipId: (id: string) => void
  selectedMembershipIds: string[]
  existingGateGroups: EnrichedGatingRuleGroup[]
  selectedGateGroupId: string | undefined
}) {
  return (
    <div className="max-h-70 overflow-y-auto">
      {memberships && memberships.length > 0 && (
        <nav className="h-full overflow-y-auto" aria-label="Directory">
          <ul role="list" className="relative z-0">
            {memberships?.map((membership) => (
              <SelectMembershipListItem
                key={membership.id}
                membership={membership}
                memberships={memberships}
                selectedMembershipId={selectedMembershipId}
                setSelectedMembershipId={setSelectedMembershipId}
                selectedMembershipIds={selectedMemberships}
                existingGateGroups={existingGateGroups}
                selectedGateGroupId={selectedGateGroupId}
              />
            ))}
          </ul>
        </nav>
      )}
    </div>
  )
}

function SelectMembershipListItem({
  membership,
  memberships,
  selectedMembershipId,
  setSelectedMembershipId,
  selectedMembershipIds,
  existingGateGroups,
  selectedGateGroupId,
}: {
  membership: Membership
  memberships: Membership[]
  selectedMembershipId: string
  setSelectedMembershipId: (id: string) => void
  selectedMembershipIds: string[]
  existingGateGroups: EnrichedGatingRuleGroup[]
  selectedGateGroupId: string | undefined
}) {
  const isDisabled = DisableMembershipGateSelection(
    membership,
    memberships,
    selectedMembershipIds,
    existingGateGroups,
    selectedGateGroupId
  )

  return (
    <div
      className={clsx(
        "relative group",
        isDisabled ? "cursor-not-allowed" : "cursor-pointer"
      )}
      onClick={() => {
        // If the membership is disabled, don't do anything.
        if (isDisabled) return

        // Otherwise, call the props parent onSelected function.
        if (setSelectedMembershipId) {
          console.log("Selected ", membership.id)
          setSelectedMembershipId(membership.id)
        }
      }}
    >
      <li key={membership.id} className="p-1">
        <div
          className={clsx(
            "relative flex items-center space-x-3 px-4 py-5 outline-none rounded-md group",
            selectedMembershipId == membership.id
              ? "ring-blue-500 ring-2 shadow-sm shadow-gray-400" // Selected.
              : "dark:ring-gray-600 ring-gray-200 ring-2 shadow-sm shadow-gray-400", // Not selected.
            // If we've already selected this membership, make it unclickable.
            isDisabled ? "opacity-50" : "opacity-100"
          )}
        >
          <div className="flex-shrink-0">
            {/* <img className="h-10 w-10 rounded-full" src={""} alt="" /> */}
            {!membership.image && (
              <CubeTransparentIcon className="w-8 h-8 mx-2 dark:text-white" />
            )}
            {membership.image && (
              <div className="h-12 w-12 relative">
                <Image
                  src={membership.image.img}
                  alt="Membership image."
                  objectFit="scale-down"
                  objectPosition="center"
                  height={50}
                  width={50}
                />
              </div>
            )}
          </div>
          <div className="min-w-0 flex-1">
            <div className="focus:outline-none">
              <p className={clsx("text-sm font-medium")}>{membership.name}</p>
              <p className="truncate text-sm text-gray-500 dark:text-gray-400">
                {isFreeMembership(membership)
                  ? "Free"
                  : [...membership.plans]
                      ?.sort((a, b) => a.price - b.price)
                      .map((plan) => `$${plan.price}/${plan.period}`)
                      .join(", ")}
              </p>
            </div>
          </div>
          <div>
            <ChevronRightIcon
              className={`h-5 w-5 group-hover:text-blue-600 ${
                selectedMembershipId == membership.id
                  ? "text-blue-600"
                  : "text-gray-400"
              }`}
              aria-hidden="true"
            />
          </div>
        </div>
      </li>
    </div>
  )
}

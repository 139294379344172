import { axios, params } from "./resolve"
import { AxiosResponse } from "axios"
import { getStaticHtml } from "util/tiptap"

import type { SuccessResponse, ErrorResponse, PostBlogEmailRes } from "@types"
import { BlogEmail } from "@/types/emails"

const API = "/blogs"

export async function getBlogEmails(blogId: string) {
  const res: AxiosResponse<Array<BlogEmail>> = await axios.get(
    `${API}/${blogId}/emails`,
    params
  )

  return res.data
}

export async function sendTestBlogEmail(
  blogEmailId: string
): Promise<ErrorResponse | SuccessResponse> {
  try {
    const res: AxiosResponse<SuccessResponse> = await axios.post(
      `${API}/emails/test/${blogEmailId}`,
      {},
      params
    )

    return res.data
  } catch (err: any) {
    if (err && err.response && err.response.data.msg) {
      return {
        msg: err.response.data.msg,
      }
    }

    console.log("Error encountered", err)

    return { msg: "An unknown error occurred sending test blog email." }
  }
}

/**
 * Create a new custom blog email.
 * @param blogEmail The blog email to create.
 * @returns The ID of the newly created blog email, or an error.
 */
export async function postBlogEmail(
  blogEmail: BlogEmail
): Promise<ErrorResponse | PostBlogEmailRes> {
  try {
    // TODO: Remove after implementing generation on API server.
    const staticHtml = getStaticHtml(blogEmail.json || "")
    if (!staticHtml) {
      console.log("Error encountered converting JSON to Static HTML.")
      return { msg: "An unknown error occurred updating your email settings." }
    }

    const blogEmailToUpdate = { ...blogEmail, staticHtml: staticHtml }

    console.log("[EDITOR]: BlogEmail staticHtml", blogEmailToUpdate.staticHtml)

    const res: AxiosResponse<PostBlogEmailRes> = await axios.post(
      `${API}/${blogEmail.blogId}/emails`,
      blogEmailToUpdate,
      params
    )

    return res.data
  } catch (err: any) {
    if (err && err.response && err.response.status === 400) {
      return {
        msg: err.response.data.msg,
      }
    }

    console.log("Error encountered", err)

    return { msg: "An unknown error occurred updating your email settings." }
  }
}

/**
 * Update an existing custom blog email.
 * @param blogEmail The blog email to update.
 * @returns Success or error response. Doesn't return any custom blog email-related data.
 */
export async function putBlogEmail(
  blogEmail: BlogEmail
): Promise<ErrorResponse | SuccessResponse> {
  try {
    // TODO: Remove after implementing generation on API server.
    const staticHtml = getStaticHtml(blogEmail.json || "")
    if (!staticHtml) {
      console.log("Error encountered converting JSON to Static HTML.")
      return { msg: "An unknown error occurred updating your email settings." }
    }

    const blogEmailToUpdate = { ...blogEmail, staticHtml: staticHtml }

    console.log("[EDITOR]: BlogEmail staticHtml", blogEmailToUpdate.staticHtml)

    const res: AxiosResponse<SuccessResponse> = await axios.put(
      `${API}/${blogEmail.blogId}/emails/${blogEmail.id}`,
      blogEmailToUpdate,
      params
    )

    return res.data
  } catch (err: any) {
    if (err && err.response && err.response.status === 400) {
      return {
        msg: err.response.data.msg,
      }
    }

    console.log("Error encountered", err)

    return { msg: "An unknown error occurred updating your email settings." }
  }
}

export async function deleteBlogEmail(
  blogEmail: BlogEmail
): Promise<ErrorResponse | SuccessResponse> {
  try {
    const res: AxiosResponse<SuccessResponse> = await axios.delete(
      `${API}/${blogEmail.blogId}/emails/${blogEmail.id}`,
      params
    )

    return res.data
  } catch (err: any) {
    if (err && err.response && err.response.status === 400) {
      return {
        msg: err.response.data.msg,
      }
    }

    console.log("Error encountered", err)

    return { msg: "An unknown error occurred deleting your email." }
  }
}

import {
  EnrichedGatingRule,
  GatingRequirementToken,
  GatingRule,
  Token,
} from "@/types/gatingRules"

export function AppendTokenOrTokensStringToGatingRuleWhenAppropriate(
  str: string,
  gatingRule: EnrichedGatingRule
) {
  // Some tokens, when imported, already have the word token at the end of their name.
  // In this case it looks weird to just blindly append " token", so let's not.
  if (
    str?.toLowerCase().endsWith("token") ||
    str?.toLowerCase().endsWith("tokens")
  ) {
    return ""
  }

  const tokenMinCount =
    "tokenMinCount" in gatingRule.gatingRequirement
      ? gatingRule.gatingRequirement.tokenMinCount
      : 0

  const needPlural = tokenMinCount > 1

  return needPlural ? " tokens" : " token"
}

export function GetTokenNameAndCountDisplayString(
  gatingRule: EnrichedGatingRule,
  token: Token | undefined
): string {
  const tokenMinCount =
    "tokenMinCount" in gatingRule.gatingRequirement
      ? gatingRule.gatingRequirement.tokenMinCount
      : 0
  const tokenMinCountDisplay = tokenMinCount > 1 ? `${tokenMinCount}x ` : ""

  const tokenName = token?.onChainData.name
  const tokenNameDisplay =
    tokenName +
    AppendTokenOrTokensStringToGatingRuleWhenAppropriate(
      tokenName || "",
      gatingRule
    )

  return tokenMinCountDisplay + tokenNameDisplay
}

export function GetNetworkNameAndRootURL(token?: Token): {
  name: string
  rootURL: string
} {
  if (!token) return { name: "", rootURL: "" }

  switch (token.onChainData.network) {
    case "matic":
      return { name: "Polygonscan", rootURL: "https://polygonscan.com" }
    case "bsc":
      return { name: "BscScan", rootURL: "https://bscscan.com" }
    case "arbitrum":
      return { name: "Arbiscan", rootURL: "https://arbiscan.io" }
    case "avalanche":
      return { name: "Snowtrace", rootURL: "https://snowtrace.io" }
    case "gnosis":
      return { name: "GnosisScan", rootURL: "https://gnosisscan.io" }
    case "optimism":
      return {
        name: "Etherscan",
        rootURL: "https://optimistic.etherscan.io",
      }
    default:
      return { name: "Etherscan", rootURL: "https://etherscan.io" }
  }
}

export function GetTokenById(
  gr: GatingRule,
  tokens?: Token[]
): Token | undefined {
  console.log(
    "GatingRules: getTokenById ",
    { gatingRule: gr },
    { all_tokens: tokens }
  )
  if (gr.gatingRequirement.gateType != "TOKEN") return

  const tokenId = (gr.gatingRequirement as GatingRequirementToken).tokenId

  return tokens?.find((t) => t.id == tokenId)
}

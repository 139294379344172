import { useEffect, useState } from "react"
import Button from "components/Button"
import { subscribeUserToBlog } from "api_routes/blogs"
import { Blog } from "@types"
import { getUsersSubscriptionToBlog } from "api_routes/user"
import { useAnalytics } from "hooks/useAnalytics"
import { CryptoUser } from "@/types/users"

interface Props {
  blog: Pick<Blog, "id" | "lowercase_url">
  referrer?: Blog
}

// Variation of the regular SubscribeButtonComponent
// that only supports inline subscribing to a specific blog.
export default function SubscribeButtonInline({ blog, referrer }: Props) {
  const [isUserSubscribed, setIsUserSubscribed] = useState(false)

  const [loading, setLoading] = useState(false)
  const { track } = useAnalytics()

  useEffect(() => {
    async function run() {
      if (!blog.id) return

      const subscription = await getUsersSubscriptionToBlog(blog.id)
      if (
        !subscription ||
        "msg" in subscription ||
        (subscription && subscription.status === "UNSUBSCRIBED")
      )
        return

      setIsUserSubscribed(true)
    }

    run()
  }, [blog.id])

  return (
    <>
      <Button
        className="w-28"
        type="button"
        onClick={async () => {
          setLoading(true)
          const subscribe = await subscribeUserToBlog({
            lowercaseBlogUrl: blog.lowercase_url,
            blogId: blog.id,
            skipWelcomeEmail: true,
            referrer: {
              id: blog.id,
              wallet:
                referrer?.collectibleWalletAddress ||
                (referrer?.user as CryptoUser)?.lowercase_wallet_address,
            },
          })
          setLoading(false)
          setIsUserSubscribed(true)
          if (referrer) {
            track("user subscribed as a result of recommend subscribe button", {
              subscribedBlog: blog,
              referrer,
            })
          } else
            track("user subscribed as a result of inline subscribe button", {
              subscribedBlog: blog,
            })
        }}
        text={isUserSubscribed ? "Subscribed" : "Subscribe"}
        disabled={isUserSubscribed}
        loading={loading}
      />
    </>
  )
}

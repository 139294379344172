import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import {
  apiGetCommentsForNote,
  apiNewComment,
  apiUpdateComment,
  apiDeleteComment,
  apiGetFcCommentsForNote,
} from "api_routes/comments"

import { Thunk as AppThunk, RootState } from "store"

import { CommentAndRepliesWithUser, CommentWithUser } from "@types"

import type { CastThread } from "@/types/farcaster"

import { HYDRATE } from "next-redux-wrapper"

interface CommentState {
  comments: Array<CommentAndRepliesWithUser> | null // Undefined if not loaded
  fcComments: Array<CastThread> | null
  initialLoad: boolean
}
import { selectCurrentNote } from "./noteSlice"

const initialState: CommentState = {
  comments: null,
  fcComments: null,
  initialLoad: true,
}

export const commentSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {
    // action.payload = noteId
    addComment: (state, action: PayloadAction<CommentWithUser>) => {
      if (!state.comments) state.comments = []

      state.comments.unshift({ comment: action.payload })
      console.log("Added new comment!")
      console.log(state.comments)
    },
    setAllComments: (
      state,
      action: PayloadAction<Array<CommentAndRepliesWithUser>>
    ) => {
      state.comments = action.payload
    },
    setFarcasterComments: (state, action: PayloadAction<Array<CastThread>>) => {
      state.fcComments = action.payload
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      /*
      console.log("COMMENT HYDRATE")
      console.log({ ...state })
      console.log(action.payload)
      */
      // if (!state.initialLoad) return state

      const nextState = {
        ...state,
        ...action.payload.comments,
      }
      nextState.initialLoad = false
      return nextState
    },
  },
})

// Publicly accessible, within components.
export const { addComment } = commentSlice.actions

// Privately accessible, only within Thunks in this file.
const { setAllComments, setFarcasterComments } = commentSlice.actions

export const createNewComment =
  (text: string, replyToCommentId?: string): AppThunk<Promise<undefined>> =>
  async (dispatch, getState) => {
    const currentNote = selectCurrentNote(getState())
    if (!currentNote) return
    const commentWithUser = await apiNewComment(
      currentNote.id,
      text,
      replyToCommentId
    )
    //dispatch(addComment(commentWithUser))
    dispatch(getCommentsForNote(currentNote.id))
    return undefined
  }

export const getCommentsForNote =
  (currentNoteId: string): AppThunk =>
  async (dispatch, getState) => {
    console.log("Getting comments for note: " + currentNoteId)
    const comments = await apiGetCommentsForNote(currentNoteId)

    console.log("Setting comments for note: " + currentNoteId, comments)
    dispatch(setAllComments(comments))
  }

// Method to get Fc comments for a note
export const getFcCommentsForNote =
  (currentNoteId: string): AppThunk =>
  async (dispatch, getState) => {
    console.log("Getting Fc comments for note: " + currentNoteId)
    const fcComments = await apiGetFcCommentsForNote(currentNoteId)

    console.log("Setting Fc comments for note: " + currentNoteId, fcComments)
    dispatch(setFarcasterComments(fcComments))
  }

export const selectComments = (state: RootState) => {
  console.log("Selecting comments!", state.comments.comments)
  if (!state.comments.comments) return
  const comments = state.comments.comments.slice()

  comments.sort((a, b) => {
    if (a.comment.createdAt > b.comment.createdAt) return -1
    if (a.comment.createdAt < b.comment.createdAt) return 1
    return 0
  })

  return comments
}

export const selectFcComments = (state: RootState) => {
  console.log("Selecting FC comments!", state.comments.fcComments)
  if (!state.comments.fcComments) return
  return state.comments.fcComments
}

export const updateComment =
  (commentId: string, text: string): AppThunk<Promise<undefined>> =>
  async (dispatch, getState) => {
    const currentNote = selectCurrentNote(getState())
    if (!currentNote) return
    const commentWithUser = await apiUpdateComment(commentId, text)

    dispatch(getCommentsForNote(currentNote.id))
    return undefined
  }

export const deleteComment =
  (commentId: string): AppThunk<Promise<undefined>> =>
  async (dispatch, getState) => {
    const currentNote = selectCurrentNote(getState())
    if (!currentNote) return
    const commentWithUser = await apiDeleteComment(commentId)

    dispatch(getCommentsForNote(currentNote.id))
    return undefined
  }

export default commentSlice.reducer

import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { getNotifications, markAsRead } from "api_routes/notification"
import { NotificationResp } from "@types"
import { RootState, Thunk } from "../store"

interface BlogState {
  notifications?: Array<NotificationResp>
}

const initialState: BlogState = {
  notifications: [],
}

export const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotifications: (
      state,
      action: PayloadAction<Array<NotificationResp>>
    ) => {
      state.notifications = action.payload
    },
  },
})

//const { setPartialUsersBlog, setPartialBlog, setSubscribers, addSubscriber } =
//  blogSlice.actions
// Publicly accessible, within components.
export const { setNotifications } = notificationSlice.actions

// When the user is in their own blogs
// settings and clicks "add Subscriber"
export const getUsersNotifications =
  (): Thunk<Promise<boolean>> => async (dispatch) => {
    const notifications = await getNotifications()
    dispatch(setNotifications(notifications))
    return true
  }

export const markNotificationsAsRead =
  (): Thunk<Promise<boolean>> => async (dispatch, getState) => {
    const notifications = selectNotifications(getState())
    const newNotifs = notifications.map((n) => {
      return { ...n, read: true }
    })
    // TODO: Only make this API call if there are unread notifications
    await markAsRead()
    dispatch(setNotifications(newNotifs))
    return true
  }

export const selectNotifications = (state: RootState) => {
  const notifications = state.notifications.notifications?.slice()

  if (!notifications) return []

  if (!Array.isArray(notifications)) {
    console.error(
      `Notifications was not an array! It was ${typeof notifications}`,
      notifications
    )

    return []
  }

  // TODO: We only display the first 10 notifications. We should paginate,
  // or have some type of "display all comments" button.
  return notifications
    .sort((a, b) => {
      return b.createdAt - a.createdAt
    })
    .slice(0, 10)
}

export default notificationSlice.reducer

import Banner from "components/Banner"
import {
  DoesGateExistInGroup,
  FindExistingGatesInGroupByGateType,
} from "./GateModalHelperFunctions"
import { GateTypeDescriptions } from "./GateTypeDescriptions"
import { SharedProps } from "./SharedProps"

export default function GateModalStepEmailSettings(props: SharedProps) {
  return (
    <div>
      {GateTypeDescriptions.filter(
        (gateTypeDesc) => gateTypeDesc.gateType == "MEMBERSHIP"
      ).map((gateTypeDesc) => (
        <div key={gateTypeDesc.gateType}>
          <div className="text-base font-medium text-gray-900">
            {!props.uiSensitiveExistingGateGroups ||
            !DoesGateExistInGroup(
              props.uiSensitiveExistingGateGroups,
              "MEMBERSHIP",
              props.selectedGateGroupId
            )
              ? // Show the standard title if this gate doesn't already exist.
                gateTypeDesc.selectedOptionTitle
              : // If the gate does exist, show a different title indicating we're editing the gate.
                `Edit the ${FindExistingGatesInGroupByGateType(
                  props.uiSensitiveExistingGateGroups,
                  gateTypeDesc.gateType,
                  props.selectedGateGroupId
                )?.[0]?.gatingRuleRequirementTypeDisplayTitle} gate`}
          </div>
          <p className="text-base text-gray-600 mt-2">
            {!props.uiSensitiveExistingGateGroups ||
            !DoesGateExistInGroup(
              props.uiSensitiveExistingGateGroups,
              gateTypeDesc.gateType,
              props.selectedGateGroupId
            )
              ? gateTypeDesc.selectedOptionDescription // Show the standard description if this gate doesn't already exist.
              : // If the gate does exist, show a different description.
                gateTypeDesc.selectedOptionEditExistingDescription}
          </p>
        </div>
      ))}
      {props.createGateError && (
        <div className="mt-5">
          <Banner type="error" text={props.createGateError} />
        </div>
      )}
    </div>
  )
}

import _axios, { AxiosRequestConfig } from "axios"
import { getActiveBlogId } from "util/cookies"
import http from "http"
import https from "https"
import { blobToText } from "util/string"
import { captureException } from "@sentry/nextjs"
import { absoluteUrl } from "@/lib/utils/general"

// Some API routes require this attached, if a user is impersonating another blog.
export const params = { params: { blogId: getActiveBlogId() } }

// This is the URL of the API server corresponding to this deploy.
// This is also used in next.config.js
export const absoluteAPI = absoluteUrl("/api")

const relativeAPI = "/api"

console.log("Next public vercel env", process.env.NEXT_PUBLIC_VERCEL_ENV)
console.log(process.env.VERCEL_ENV)
console.log("Vercel URL", process.env.VERCEL_URL)
console.log(`Relative API URL is ${relativeAPI} and absolute is ${absoluteAPI}`)
//

// If the code is running on a vercel build step, we need to use
// an absolute URL since relative won't work. If it's running in the user's browser, use a relative one (which
// gets rewritten to the above API; this is needed for cookie reasons).
export const baseUrl = absoluteAPI

const axiosBaseOptions: AxiosRequestConfig = {
  withCredentials: true,
  validateStatus: function (status) {
    return status >= 200 && status < 500 // Don't throw exception on 4xx errors.
  },
  // If we're making a server-side request, use the absolute URL. For client-side requests, use relative URL to make this work for custom domains and keep CORS happy.
  baseURL: typeof window === "undefined" ? absoluteAPI : relativeAPI,
}

const axios = _axios.create({
  ...axiosBaseOptions,
})

// Instantiate an Axios instance that stays alive past browser tab closing.
// Important for certain API calls that we want to outlive browser tab closings, such as metrics (e.g. read time).
const httpAgent = new http.Agent({ keepAlive: true })
const httpsAgent = new https.Agent({ keepAlive: true })
const axiosKeepAlive = _axios.create({
  ...axiosBaseOptions,
  httpAgent,
  httpsAgent,
})

type ErrorCallbackType = ((error: any) => void) | undefined

let onErrorCallback: ErrorCallbackType

export const setAxiosOnErrorCallback = (callback: ErrorCallbackType) => {
  onErrorCallback = callback
}

// Add a response interceptor.
axios.interceptors.response.use(
  async function (response) {
    if (
      response.status === 403 &&
      response.data.redirect &&
      typeof window !== "undefined"
    ) {
      console.log("Axios: redirecting to", response.data.redirect)
      window.location.href = response.data.redirect
      return
    }

    // Sometimes we get a blob response (as for instance when exporting a CSV).
    // But if it's an error, we want to handle it as JSON.
    if (response.status === 403 && response.data instanceof Blob) {
      // Convert the blob to text and then handle it
      const textData = await blobToText(response.data)

      // Try to parse the text as JSON
      try {
        const jsonData = JSON.parse(textData)

        if ("actionId" in jsonData) {
          console.error(
            "User is missing permissions to perform this action.",
            jsonData
          )

          if (onErrorCallback) {
            onErrorCallback(jsonData)
          }
        }
      } catch (e) {
        // Handle the case where the response is not JSON (optional)
        console.error(
          "Received a 403 error, but response is not JSON.",
          textData
        )
      }
    } else if (
      response.status === 403 &&
      response.data &&
      "actionId" in response.data
    ) {
      // Handle non-Blob data
      console.error(
        "User is missing permissions to perform this action.",
        response.data
      )

      if (onErrorCallback) {
        onErrorCallback(response.data)
      }
    }

    // Do something with response data
    return response
  },
  function (error) {
    console.error("Error in axios response interceptor", error)

    if (error.config) {
      console.error(`Request to ${error.config.url} failed`)
    }

    if (error.response) {
      console.error(`Status code: ${error.response.status}`)
      console.error(`Data: ${JSON.stringify(error.response.data)}`)
      console.error(`Headers: ${JSON.stringify(error.response.headers)}`)
    } else if (error.request) {
      // The request was made but no response was received
      console.error(`Request made but no response received.`)
      console.error(error.request)
    } else {
      // Something else happened while setting up the request
      console.error(`Axios error occurred: ${error.message}`)
    }

    captureException(error)

    // Do something with response error
    return Promise.reject(error)
  }
)

export { axios, axiosKeepAlive }

import { useRouter } from "next/router"
import { useEffect } from "react"

import { isAddress } from "viem"
import {
  getCreatorReferrer,
  getReaderReferrer,
  setCreatorReferrer,
  setReaderReferrer,
} from "util/cookies"
import { useSelector } from "react-redux"
import { selectBlog } from "features/blogSlice"
import { useEnsResolver } from "wagmi"
import { useAnalytics } from "./useAnalytics"
import { selectActiveSubscription } from "features/userSlice"

function saveReaderReferrer(walletAddress: string, blogId: string) {
  if (!isAddress(walletAddress) || !blogId) {
    console.error(
      `Invalid reader referrer or blogId: ${walletAddress}, ${blogId}`
    )
    return
  }

  const existingReferrer = getReaderReferrer(blogId)
  if (existingReferrer) {
    console.warn(
      `Referrer for blog ${blogId} with address ${existingReferrer} already exists, so not setting referrer to ${walletAddress}`
    )
    return
  }
  setReaderReferrer(walletAddress, blogId)
}

function saveCreatorReferrer(walletAddress: string) {
  if (!isAddress(walletAddress)) {
    console.error(`Invalid creator referrer : ${walletAddress}`)
    return
  }

  const existingReferrer = getCreatorReferrer()
  if (existingReferrer) {
    console.warn(
      `Referrer with address ${existingReferrer} already exists, so not setting referrer to ${walletAddress}`
    )
    return
  }
  setCreatorReferrer(walletAddress)
}

/**
 * Gets the referrer from the query string and sets it in a cookie.
 * This is used to track the referrer for the on-chain referral flow.
 *
 * Applies to both the creator and reader referrals.
 */
export default function useReferrer() {
  const router = useRouter()
  const { track } = useAnalytics()

  // The blog should always be populated on the server-side because we use getStaticProps.
  // So, no need to rely on blog in the dependency array below.
  const blog = useSelector(selectBlog)

  const possibleEnsAddressData = useEnsResolver({
    // Default to ethereum chain ID always.
    chainId: 1,
    name: router.query.referrer as string,
    query: {
      enabled: !!router.query.referrer,
    },
  })?.data

  useEffect(() => {
    const walletOrEns = router.query.referrer as string | undefined

    if (!walletOrEns) return

    const isWallet = isAddress(walletOrEns)
    const isEns = !!possibleEnsAddressData
    const isValid = isWallet || isEns

    if (!walletOrEns || !isValid) {
      console.log(
        "Empty or invalid referrer found in query string",
        walletOrEns
      )
      return
    }

    async function setReferrerInfo() {
      const wallet = (
        isWallet ? walletOrEns : await possibleEnsAddressData
      ) as string

      if (blog.id) {
        console.log("Saving reader referrer..", wallet)
        track("reader referrer set", { wallet, referrerBlogId: blog.id })
        saveReaderReferrer(wallet, blog.id)
      }
      console.log("Saving creator referrer..", wallet)
      saveCreatorReferrer(wallet)
      track("creator referrer set", { wallet })
    }

    setReferrerInfo()
  }, [router.query.referrer, possibleEnsAddressData])
}

/**
 * Gets the reader referrer from the active subscription or cookie.
 */
export const useReaderReferrer = (
  blogId: string
): `0x${string}` | undefined => {
  // Check if user has an active subscription with a referrer set
  const subscription = useSelector(selectActiveSubscription)
  if (subscription && subscription.referrer)
    return subscription.referrer as `0x${string}`

  // If no recommendation referrer, use cookie referrer if exists
  return getReaderReferrer(blogId)
}

import { useSelector } from "react-redux"
import { selectCurrentNote } from "features/noteSlice"

import { selectBlog } from "features/blogSlice"

import { useAccount } from "wagmi"

/**
 * This hook ensures that the user is ready and able to collect
 * a collectible. It checks that the user has their wallet connected,
 * the blog currently supports collectible content, etc.
 */
export default function useCollectPrecondition() {
  const note = useSelector(selectCurrentNote)
  const blog = useSelector(selectBlog)
  const accountData = useAccount()

  const postHasWallet =
    note?.collectibleWalletAddress ||
    blog.collectibleWalletAddress ||
    (blog.user && "wallet_address" in blog?.user)

  if (
    !note?.id ||
    !blog?.id ||
    !accountData.isConnected ||
    !blog.user ||
    !postHasWallet ||
    //!("wallet_address" in user) ||
    !accountData.address
  ) {
    console.warn(
      "useCollectPrecondition: not ready to collect since one of the following is missing: note, blog, accountData, blog.user.wallet_address, accountData.address"
    )
    return false
  }

  if (blog.disable_highlights) {
    console.warn("useCollectPrecondition: blog.disable_highlights is true")
    return false
  }

  if (note.collectiblesDisabled) {
    console.warn("useCollectPrecondition: note.disable_collectibles is true")
    return false
  }

  return true
}

import { axios } from "./resolve"
import { AxiosResponse } from "axios"
import {
  BalanceResp,
  ContractMetadataResp,
  ErrorResponse,
  isErrorResponse,
  SuccessResponse,
} from "@types"

const API = "/crypto"

/**
 * Mints a membership NFT.
 */
export async function mintMembershipNFT(
  membershipId: string
): Promise<SuccessResponse | ErrorResponse> {
  const res: AxiosResponse<SuccessResponse | ErrorResponse> = await axios.post(
    `${API}/mint/membership/${membershipId}`
  )

  console.log("mintMembershipNFT", res.data)

  return res.data
}

export async function getContractMetadata(address: string, provider?: string) {
  const params = provider ? { providerUrl: provider } : {}

  const res: AxiosResponse<ContractMetadataResp> = await axios.get(
    `${API}/contract/metadata/${address}`,
    { params }
  )
  console.log("GOT DATA", res.data)

  return res.data
}

// Retrieves balances for the logged in user from the API.
// tokenIds: this is a string array of unique token IDs in the Firestore DB.
export async function getBalances(tokenIds: string[]): Promise<BalanceResp[]> {
  const res: AxiosResponse<BalanceResp[] | ErrorResponse> = await axios.get(
    `${API}/balances?${tokenIds.map((n) => `tokenIds=${n}`).join("&")}`
  )

  if (isErrorResponse(res?.data)) {
    console.error("Error retrieving balances from API.", res.data)
    return []
  }

  return res?.data || []
}

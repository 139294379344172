// prettier-ignore
// This stops vscode from erroneously formatting this file in a way that actually breaks compilation.

/**
 * Allows you to generically group objects together by certain fields.
 */
export const groupBy = <T>(
  array: T[],
  predicate: (value: T, index: number, array: T[]) => string
) =>
  array.reduce(
    (acc, value, index, array) => {
      (acc[predicate(value, index, array)] ||= []).push(value)
      return acc
    },
    {} as { [key: string]: T[] }
  )

/**
 * Filters out null and undefined values from an array.
 * @example someArray.filter(notEmpty)
 */
export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  if (value === null || value === undefined) return false
  return true
}

export function removeDuplicatesByField<TValue>(
  array: TValue[],
  field?: keyof TValue
): TValue[] {
  const seen = new Map()

  return array.filter((item) => {
    let key
    if (field !== undefined) {
      key = item[field]
    } else {
      key = item as unknown as string // Treat item as the key if field is undefined
    }

    const duplicate = seen.has(key)

    if (!duplicate) {
      seen.set(key, true)
    }

    return !duplicate
  })
}

import { useSelector } from "react-redux"
import {
  selectActiveSubscription,
  selectSubscription,
} from "features/userSlice"

import {
  subscribeModalAlreadyShown,
  setSubscribeModalShown,
} from "util/cookies"
import { selectBlog } from "features/blogSlice"
import { useEffect, useState } from "react"
import useUser from "./useUser"
import useIsUsersOwnBlog from "./useIsUsersOwnBlog"
import { useRouter } from "next/router"

const EXCLUDED_ROUTES = ["/memberships", "/preview", "/subscribe", "/embed"]

function getDomain(url: string): string {
  const a = document.createElement("a")
  a.href = url
  return a.hostname
}

type SUBSCRIBE_POPUP_TYPE = "on-scroll" | "on-visit"

/**
 * Returns true if the user's subscription is loaded and they don't have an active subscription
 * (or, if they're not logged in).
 */
function useSubscriptionPrecondition() {
  const subscription = useSelector(selectSubscription)
  const activeSubscription = useSelector(selectActiveSubscription)
  const user = useUser()

  console.log("useSubscriptionPrecondition", {
    subscription,
    user,
    activeSubscription,
  })

  if (!user.loggedIn) return true

  // If subscription is false, it's loaded but not present.
  // If it's undefined, it's not yet loaded. So just check for undefined
  // here.
  return !activeSubscription && subscription !== undefined
}

/**
 * Has the user come from an external link, or is this their first visit?
 * If they come from internal links (eg clicking through the Discover feed),
 * we never want to show this.
 */
function useLinkPrecondition() {
  const [shouldShow, setShouldShow] = useState<undefined | boolean>()

  const router = useRouter()

  useEffect(() => {
    const referrer = document.referrer
    const currentDomain = getDomain(window.location.href)

    // Are we coming from an external link?
    const isExternalLink = referrer && getDomain(referrer) !== currentDomain

    // Is this the first visit?
    const isFirstVisit = !referrer

    // If we're on an excluded route, never show the subscribe modal.
    const isOnExcludedRoute = EXCLUDED_ROUTES.some((route) => {
      return router.asPath.includes(route)
    })

    console.log("useLinkPrecondition effect", {
      referrer,
      currentDomain,
      isExternalLink,
      isFirstVisit,
      routerPath: router.asPath,
      isOnExcludedRoute,
    })

    if (isOnExcludedRoute) {
      setShouldShow(false)
      return
    }

    setShouldShow(!!isExternalLink || isFirstVisit)

    // TODO: We need to confirm this works in all cases.
  }, [router.asPath])

  console.log("useLinkPrecondition", shouldShow)

  return shouldShow
}

/**
 * Has the user already seen the subscribe modal for this publication?
 */
function useCookiePrecondition(cookiePrefix: SUBSCRIBE_POPUP_TYPE) {
  const blog = useSelector(selectBlog)
  const notShownBefore = !subscribeModalAlreadyShown(cookiePrefix + blog.id)

  console.log("useCookiePrecondition", cookiePrefix, notShownBefore)

  return notShownBefore
}

/**
 * Does the user's blog have the subscribe popup enabled?
 * Is this not the user's own blog?
 */
function useBlogPrecondition(subscribeType: SUBSCRIBE_POPUP_TYPE) {
  const blog = useSelector(selectBlog)
  const isUsersOwnBlog = useIsUsersOwnBlog()

  if (isUsersOwnBlog) return false

  if (subscribeType === "on-scroll") {
    return !!blog.enable_subscribe_scroll
  } else if (subscribeType === "on-visit") {
    return !!blog.enable_subscribe_popup
  }

  console.error("Invalid subscribe type", subscribeType)

  return false
}

/**
 * Is this user logged in? If so, we assume they're a power user and don't want to harm their reading experience
 * by showing them the fade-in subscribe modal. There's already the subscribe button in the top-right corner,
 * as well as optional subscribe buttons within the post that the writer can add, so let's not annoy them and hide
 * the fade-in modal.
 *
 * Note that even for logged in users we still want to show them the initial subscribe modal when they land on a
 * blog page.
 *
 * So for a logged out user, we want to show both the cover page blog modal and the fade-in post subscribe modal.
 * For a logged in user, we only want to show the cover page blog modal.
 *
 * @returns If this is true, we want to show the subscribe modal.
 */
function useLoggedOutOrCoverPagePrecondition(
  subscribeType: SUBSCRIBE_POPUP_TYPE
): boolean {
  const { loggedIn } = useUser()

  if (subscribeType === "on-scroll") {
    // For the fade-in modal, we only want to show it to logged out users.
    const shouldShow = !loggedIn
    console.log("useLoggedOutOrCoverPagePrecondition", {
      subscribeType,
      loggedIn,
      shouldShow,
    })

    return shouldShow
  } else if (subscribeType === "on-visit") {
    // For the interstitial (blog cover page) modal, we want to show it to everyone.
    console.log("useLoggedOutOrCoverPagePrecondition", {
      subscribeType,
      loggedIn,
      shouldShow: true,
    })

    return true
  }

  console.error("Invalid subscribe type", subscribeType)

  return false
}

/**
 * If this returns true, we're ready to perform the other checks.
 *
 * If this is false, then the required data (eg the user's subscription)
 * has not loaded yet, so we need to wait until that data loads before
 * determining if we should show the subscribe modal.
 */
function useLoading() {
  const blog = useSelector(selectBlog)
  const subscription = useSelector(selectSubscription)
  const router = useRouter()

  // If blog.id is emprty, then the blog hasn't loaded yet.
  if (!blog.id) {
    console.log("useReadyPrecondition: blog not loaded yet")
    return true
  }

  if (subscription === undefined) {
    console.log(
      "useReadyPrecondition: subscription not loaded yet",
      subscription
    )
    return true
  }

  if (!router.isReady) {
    console.log("useReadyPrecondition: router not ready yet")
    return true
  }

  console.log("useReadyPrecondition: ready")

  return false
}

/**
 * Whether we want to show the initial subscribe modal or not.
 *
 * Note that there are two flows for tihs:
 *
 * 1) The initial subscribe modal upon landing on the blog or a post.
 * 2) When a user scrolls down, we show the subscribe modal again.
 *
 * These two flows are similar but use different cookies. Users need to dismiss
 * both for the cookies to take effect.
 *
 * The cookie prefix sets this.
 */
export default function useShowInitialSubscribeModal(
  cookiePrefix: SUBSCRIBE_POPUP_TYPE
) {
  const noSubscription = useSubscriptionPrecondition()
  const externalLinkOrFirstVisit = useLinkPrecondition()
  const notShownBefore = useCookiePrecondition(cookiePrefix)
  const blogEnabledPopup = useBlogPrecondition(cookiePrefix)
  const loggedOutOrCoverPage = useLoggedOutOrCoverPagePrecondition(cookiePrefix)
  const blog = useSelector(selectBlog)

  const loading = useLoading()

  const shouldShow =
    noSubscription &&
    externalLinkOrFirstVisit &&
    notShownBefore &&
    blogEnabledPopup &&
    loggedOutOrCoverPage // Show to all users if it's the cover page modal, and to logged out users if it's the fade-in modal.

  const dontShowAgain = () => {
    console.log(
      "useShowInitialSubscribeModal: shouldShow, and not showing again!"
    )
    // Set the cookie so we don't show it again.
    setSubscribeModalShown(cookiePrefix + blog.id)
  }

  console.log("Show initial subscribe modal for " + cookiePrefix, {
    shouldShow,
    noSubscription,
    externalLinkOrFirstVisit,
    notShownBefore,
    blogEnabledPopup,
    loggedOutOrCoverPage,
  })

  // If loading is true, then we need to wait until it's false
  // before we can determine if we should show the modal or not.
  return { loading, shouldShow, dontShowAgain }
}

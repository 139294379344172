import type { SuggestionOptions } from "@tiptap/suggestion"
import { AiParagraph } from "components/icons/AiParagraph"
import { AiSentence } from "components/icons/AiSentence"
import { BsTable, BsTwitter, BsYoutube } from "react-icons/bs"
import { IoText } from "react-icons/io5"
import {
  MdAttachFile,
  MdChecklist,
  MdCode,
  MdFormatListBulleted,
  MdFormatListNumbered,
  MdFormatQuote,
  MdHorizontalRule,
  MdImage,
  MdInsertLink,
  MdMic,
  MdMovie,
  MdPictureAsPdf,
  MdTagFaces,
  MdToc,
  MdInfo,
  MdSmartButton,
  MdInsertChartOutlined,
  MdLock,
  MdExpandMore,
} from "react-icons/md"
import { TbMath } from "react-icons/tb"
import { generateContinuedWriting } from "util/chatgpt"

export interface CommandSuggestionItem {
  title: string
  group?: string
  icon?: React.ReactElement
  description?: string
  /**
   * Aliases (eg shorthands; h1 should trigger header 1)
   */
  alias?: string
  command?: SuggestionOptions<CommandSuggestionItem>["command"]
}

export const getSuggestionItems = ({ query }: any) => {
  const items: CommandSuggestionItem[] = [
    {
      icon: <IoText />,
      title: "Text",
      alias: "txt normal",
      description: "Just start writing with plain text.",
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).setParagraph().run()
      },
    },

    {
      icon: (
        <div className={"flex items-center justify-center text-[1.6em]"}>
          H1
        </div>
      ),
      title: "Heading 1",
      alias: "h1 heading1",
      description: "Big section heading.",
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .setNode("heading", { level: 1 })
          .run()
      },
    },
    {
      icon: (
        <div className={"flex items-center justify-center text-[1.4em]"}>
          H2
        </div>
      ),
      title: "Heading 2",
      alias: "h2 heading2",
      description: "Medium section heading.",
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .setNode("heading", { level: 2 })
          .run()
      },
    },
    {
      icon: (
        <div className={"flex items-center justify-center text-[1.2em]"}>
          H3
        </div>
      ),
      title: "Heading 3",
      alias: "h3 heading3",
      description: "Small section heading.",
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .setNode("heading", { level: 3 })
          .run()
      },
    },
    {
      icon: <MdChecklist />,
      title: "To-do list",
      alias: "todo task checklist tasklist",
      description: "Track tasks with a to-do list.",
      command: ({ editor, range }) =>
        editor.chain().focus().deleteRange(range).toggleTaskList().run(),
    },
    {
      icon: <MdFormatListBulleted />,
      title: "Bulleted list",
      alias: "ul bullet",
      description: "Create a simple bulleted list.",
      command: ({ editor, range }) =>
        editor.chain().focus().deleteRange(range).toggleBulletList().run(),
    },
    {
      icon: <MdFormatListNumbered />,
      title: "Numbered list",
      alias: "ol",
      description: "Create a list with numbering.",
      command: ({ editor, range }) =>
        editor.chain().focus().deleteRange(range).toggleOrderedList().run(),
    },
    {
      title: "Toggle list",
      alias: "toggle accordion",
      description: "Toggles can hide and show content inside.",
    },

    {
      icon: <MdFormatQuote />,
      title: "Quote",
      alias: "blockquote",
      description: "Capture a quote.",
      command: ({ editor, range }) =>
        editor.chain().focus().deleteRange(range).setBlockquote().run(),
    },
    {
      icon: <MdHorizontalRule />,
      title: "Divider",
      alias: "hr line rule",
      description: "Visually divide blocks.",
      command: ({ editor, range }) =>
        editor.chain().focus().deleteRange(range).setHorizontalRule().run(),
    },
    {
      icon: <MdInsertLink />,
      title: "Link to page",
      alias: "url external website",
      description: "Link to and exiting page.",
    },
    {
      icon: <MdInfo />,
      title: "Callout",
      alias: "info warning danger note",
      description: "Make writing stand out.",
      command: ({ editor, range }) =>
        editor.chain().focus().deleteRange(range).wrapIn("callout").run(),
    },
    // Inline
    {
      title: "Mention a person",
      description: "Ping someone so they get a notification.",
    },
    {
      icon: <MdTagFaces />,
      title: "Emoji",
      description: "Search for an emoji to place in text.",
    },
    {
      icon: <TbMath />,
      title: "Inline equation",
      alias: "math mathinline latex katex",
      description: "Insert mathematical symbols in text.",
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).insertContent("$$$$").run()
        editor
          .chain()
          .focus(editor.state.selection.anchor - 2)
          .run()
      },
    },
    // Media
    {
      icon: <MdImage />,
      title: "Image",
      alias: "img picture photo",

      description: "Upload or embed with a link.",
    },
    {
      title: "Video",
      alias: "movie",
      icon: <MdMovie />,
      description: "Upload or embed with a link.",
    },
    {
      title: "Audio",
      alias: "sound",
      icon: <MdMic />,
      description: "Upload or embed with a link.",
    },
    {
      icon: <MdExpandMore />,
      title: "Collapsible",
      alias: "collapse expand",
      description: "Create a collapsible section.",
      command: ({ editor, range }) =>
        editor.chain().focus().deleteRange(range).setDetails().run(),
    },
    {
      icon: <MdCode />,
      title: "Code",
      description: "Capture a code snippet.",
      command: ({ editor, range }) =>
        editor.chain().focus().deleteRange(range).setCodeBlock().run(),
    },
    {
      icon: <BsTable />,
      title: "Table",
      alias: "grid",
      description: "Create a table.",
      command: ({ editor, range }) =>
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .insertTable({ withHeaderRow: false, rows: 2, cols: 2 })
          .run(),
    },
    {
      icon: <MdImage />,
      title: "Image",
      alias: "img picture photo",
      description: "Upload an image.",
      command: ({ editor, range }) => console.log(""), // This is intercepted and handled in CommandsListNew
    },
    {
      icon: <BsTwitter />,
      title: "Twitter",
      alias: "tweet x",
      description: "Embed a tweet.",
      command: ({ editor, range }) =>
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .setTweet({ tweetURL: prompt("Enter tweet URL") || "" })
          .run(),
    },
    {
      icon: <BsYoutube />,
      title: "Youtube",
      description: "Embed a youtube video.",
      command: ({ editor, range }) =>
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .setYoutubeVideo({ src: prompt("Enter Youtube video URL") || "" })
          .run(),
    },
    {
      icon: <MdInsertChartOutlined />,
      title: "Embed",
      alias: "embedly iframe",
      description: "Create an embed from a url",
      command: ({ editor, range }) =>
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .setEmbedly({ src: prompt("Enter URL") || "" })
          .run(),
    },
    {
      icon: <MdSmartButton />,
      title: "Subscribe Button",
      description: "Embed a subscribe button",
      command: ({ editor, range }) =>
        editor.chain().focus().deleteRange(range).setSubscribeButton().run(),
    },
    {
      icon: <MdSmartButton />,
      title: "Share Button",
      description: "Embed a share button",
      command: ({ editor, range }) =>
        editor.chain().focus().deleteRange(range).setShareButton().run(),
    },
    {
      icon: <MdSmartButton />,
      title: "Collect Post Button",
      alias: "nft",
      description: "Embed a button for collecting this post",
      command: ({ editor, range }) =>
        editor.chain().focus().deleteRange(range).setCollectButton().run(),
    },
    {
      icon: <MdSmartButton />,
      title: "Connect Wallet Button",
      description: "Embed a connect wallet button",
      command: ({ editor, range }) =>
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .setConnectWalletButton()
          .run(),
    },
    {
      icon: <MdSmartButton />,
      title: "Custom Button",
      description: "Embed a custom button",
      command: ({ editor, range }) =>
        editor.chain().focus().deleteRange(range).setCustomButton().run(),
    },
    {
      icon: <MdLock />,
      title: "Gate",
      description: "Gate the rest of the post.",
      command: ({ editor, range }) =>
        editor.chain().focus().deleteRange(range).setAfterGate().run(),
    },
    {
      icon: <AiSentence />,
      title: "Generate Sentence",
      description: "Have AI generate the next sentence.",
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).run()
        generateContinuedWriting(editor, range, "sentence")
      },
    },
    {
      icon: <AiParagraph />,
      title: "Generate Paragraph",
      description: "Have AI generate the next paragraph.",
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).run()
        generateContinuedWriting(editor, range, "paragraph")
      },
    },
    {
      icon: <MdAttachFile />,
      title: "File",
      description: "Upload or embed with a link.",
    },
    // Embeds
    {
      icon: <MdPictureAsPdf />,
      title: "PDF",
      description: "Embed a PDF.",
    },

    // Advanced Blocks
    {
      icon: <MdToc />,

      title: "Table of contents",
      description: "Show an outline of your page.",
    },
    {
      title: "Block equation",
      description: "Display a standalone math equation.",
    },
    {
      title: "Toggle heading 1",
      description: "Hide content inside a large heading",
    },
    {
      title: "Toggle heading 2",
      description: "Hide content inside a medium heading",
    },
    {
      title: "Toggle heading 2",
      description: "Hide content inside a small heading",
    },
  ]
  return items
    .filter((v) => v.command)
    .filter(
      (v) =>
        v.title.toLowerCase().includes(query?.toLowerCase()) ||
        v.alias?.toLowerCase().includes(query?.toLowerCase()) ||
        v.description?.toLowerCase().includes(query?.toLowerCase())
    )
}

import { Node, nodePasteRule } from "@tiptap/core"
import { Editor, NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react"

import { useAnalytics } from "hooks/useAnalytics"

import Script from "next/script"
import { useEffect } from "react"

import { getHighlightImg } from "util/highlight"

// https://regex101.com/r/oECSpo/1
const regex = /^(https?:\/\/)?(www\.)?(mint\.)?highlight\.xyz\/(mint\/)?(.+)?$/
const regex_global =
  /(https?:\/\/)?(www\.)?(mint\.)?highlight\.xyz\/(mint\/)?(.+)?/g

const extractCollectionId = (url: string) => {
  const match = url.match(regex)

  if (match && match[5]) {
    return match[5]
  } else {
    return
  }
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    highlightEmbed: {
      setHighlightEmbed: (options: { src: string }) => ReturnType
    }
  }
}

export interface HighlightEmbedOptions {
  HTMLAttributes: Record<string, any>
}

export default Node.create<HighlightEmbedOptions>({
  name: "highlightEmbed",
  group: "block",
  draggable: true,
  atom: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  addAttributes() {
    return {
      nftId: { default: null },
      imgUrl: { default: "" },
    }
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ]
  },

  addCommands() {
    return {
      setHighlightEmbed:
        (options) =>
        ({ commands }) => {
          const match = options.src.match(regex_global)
          if (!match) return false

          const nftId = extractCollectionId(options.src)
          if (!nftId) return false

          return commands.insertContent({
            type: this.name,
            attrs: { nftId },
          })
        },
    }
  },

  addPasteRules() {
    return [
      nodePasteRule({
        find: regex_global,
        type: this.type,
        getAttributes: (match) => {
          console.log("MATCH", match)

          if (!match.input) return false
          const nftId = extractCollectionId(match.input)
          if (!nftId) return false
          return { nftId }
        },
      }),
    ]
  },

  // This renders just the image and a collect button.
  renderHTML({ HTMLAttributes }) {
    const { nftId, imgUrl } = HTMLAttributes

    const html = `
    <img src="${imgUrl}" 
    style="
    width: 100;
    margin-left: auto;
    margin-right; auto;
    "
    />
    <div style="text-align: center; margin-top: 24px; margin-bottom: 24px;">
      <a  style="
    display: inline-block;
    border-radius: 4px;
    background-color: rgb(59, 130, 246);
    padding: 16px 24px;
    font-weight: 600;
    line-height: 100%;
    color: rgb(255, 255, 255);
    text-decoration: none;
    "
    href="https://mint.highlight.xyz/${nftId}">
    Collect NFT
        </a>
      </div>`

    return ["div", { "data-type": this.name, nftId, imgUrl, html }]
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component)
  },
})

type Props = {
  editor: Editor
  node: any
  updateAttributes: (a: any) => void
}

const Component = ({ updateAttributes, editor, node }: Props): JSX.Element => {
  const { nftId } = node.attrs
  const { track } = useAnalytics()

  const html = `
  <div data-widget="highlight-mint-card" data-mint-collection-id="${nftId}" class="embed"></div>
  `

  // Fetch the image for the given collection ID.
  // This is used when sending email newsletters.
  useEffect(() => {
    track("highlight_nft_embed viewed", {
      editerEditable: editor.isEditable,
      nftId,
    })

    getHighlightImg(nftId).then((imgUrl) => {
      console.log("Got highlight image wow!", imgUrl, node)

      // If the image is the same as the one currently on the node, don't update it.
      if (!imgUrl || imgUrl === node?.attrs?.imgUrl) return

      updateAttributes({ imgUrl })
    })
  }, [nftId])

  return (
    <NodeViewWrapper
      className="flex justify-center react-component not-prose"
      data-drag-handle
    >
      <Script
        strategy="lazyOnload"
        type="module"
        src="https://mint.highlight.xyz/assets/embed.js?v=1"
        key="highlight_embed"
      />

      <div dangerouslySetInnerHTML={{ __html: html }} />
    </NodeViewWrapper>
  )
}

import { ReactNode } from "react"
import { ParseWagmiError } from "util/cryptocurrency"

function Text({ children }: { children: ReactNode }) {
  return <div className="text-gray-500 text-sm mt-2">{children}</div>
}

export default function ErrorMessage({ error }: { error: any }) {
  const parsedError = ParseWagmiError(error)

  console.error(
    "Collectible error encountered",
    error,
    typeof error,
    typeof error?.message,
    parsedError
  )

  return <Text> {parsedError.msg}</Text>
}

import Heading from "@tiptap/extension-heading"
import slugify from "slugify"

export const HeadingWithId = Heading.extend({
  renderHTML({ node, HTMLAttributes }) {
    const hasLevel = this.options.levels.includes(node.attrs.level)
    const level = hasLevel ? node.attrs.level : this.options.levels[0]

    const id = `h-${slugify(node.textContent, { lower: true, strict: true })}`

    return [
      "div",
      {
        class: "relative header-and-anchor",
      },
      [
        `h${level}`,
        {
          ...this.options.HTMLAttributes,
          ...HTMLAttributes,
          id,
        },
        0,
      ],
    ]
  },
})

export default HeadingWithId

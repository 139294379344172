import { CheckCircleIcon } from "@heroicons/react/24/solid"

import useUser from "hooks/useUser"

import RainbowConnectWalletBtn from "@/components/ConnectWalletButton"
import { useEffect } from "react"
import { useAnalytics } from "hooks/useAnalytics"

export default function WalletPrompt(props: {
  title?: string
  description?: string
}) {
  const user = useUser()
  const { track } = useAnalytics()

  console.log("This is the user", user)

  useEffect(() => {
    track("subscribe wallet prompt view")
  }, [])

  return (
    <div className="p-10 ">
      <div>
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-3xl font-bold text-gray-900 ">
            {props.title || "What's your wallet?"}
          </h3>
          <div className="mt-2">
            <p className="text-gray-500 text-md">
              {props.description ||
                "Connect your wallet to receive newsletters, airdrops and other benefits directly to your wallet."}
            </p>
          </div>
        </div>
      </div>

      <div className="space-y-6 mt-9 mb-2 relative">
        {user.hasWallet && "wallet_address" in user ? (
          <>
            <div className="text-gray-500 text-center p-4 border border-gray-150 rounded-2xl flex flex-row align-middle justify-center items-center">
              <div>
                <CheckCircleIcon className="w-5 h-5 text-green-400 m-2 inline" />
                Your wallet is connected:
                <div className="text-xs font-semibold text-gray-700">
                  {user.wallet_address}.
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="max-w-sm justify-center text-center ">
            <div className="w-44 mx-auto ">
              <RainbowConnectWalletBtn hideError />
            </div>
            <div className="text-xs text-gray-500 pt-3">
              No wallet? Download{" "}
              <a
                className="text-primary-900 hover:text-primary-500"
                target="_blank"
                href="https://www.coinbase.com/wallet/"
                rel="noreferrer"
              >
                Coinbase Wallet
              </a>{" "}
              to get started.
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

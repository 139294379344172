import { CompletedCollectible, CollectibleMetricData } from "@/types/highlights"

import { useAccount, useReadContracts } from "wagmi"
import { getChainId } from "@/util/crypto"
import { useEffect } from "react"
import { useAnalytics } from "hooks/useAnalytics"

import { erc721ABI } from "@/util/typechain"
import { useSelector } from "react-redux"
import { selectPostCollectors } from "@/features/pageSlice"

/**
 * Retrieve the default metadata (supply, price, chain) for a new
 * collectible. This relies on the currently selected blog & note.
 */
export default function useExistingHighlightMetadata(props: {
  collectible: CompletedCollectible
}): CollectibleMetricData {
  const accountData = useAccount()
  const currentUserAddress = accountData.address
  const { track } = useAnalytics()
  //const { data: blockNumber } = useBlockNumber({ watch: true })

  const postCollectors = useSelector(selectPostCollectors)

  const contract = {
    address: props.collectible.contractAddress,
    abi: erc721ABI,
    chainId: getChainId(props.collectible.chain),
  }

  console.log("======== GETTING METADATA FOR COLLECTIBLE ========")

  const contractReadArgs: Parameters<typeof useReadContracts>[0] = {
    //staleTime: 60_000,
    //cacheTime: 20_000,
    allowFailure: false,
    contracts: [
      // @ts-ignore I don't know why wagmi complains about the ABI here, but it's autogenerated
      {
        ...contract,
        functionName: "totalSupply",
      },
      // @ts-ignore
      {
        ...contract,
        functionName: "maxSupply",
      },
      // @ts-ignore
      {
        ...contract,
        functionName: "priceWei",
      },
    ],
  }

  // Only read the balance if the user
  // has a wallet connected.
  if (currentUserAddress) {
    // @ts-ignore
    contractReadArgs.contracts?.push({
      ...contract,
      functionName: "balanceOf",
      args: [currentUserAddress || ""],
    })
  }

  console.log("HLC - contract read args", contractReadArgs)

  const {
    data,
    error,
    isError,
    isLoading,
    status,
    isFetching,
    isSuccess,
    isFetched,
    refetch,
    queryKey,
  } = useReadContracts(contractReadArgs)

  const stringifiedPostCollectors = JSON.stringify(postCollectors)

  // Refetch the data once the post collectors change.
  //
  // We refetch post collectors whenever the txn clears.
  useEffect(() => {
    console.log("Manually refetching metadata for collectible")

    refetch()
  }, [stringifiedPostCollectors])

  let supply: bigint | undefined,
    maxSupply: bigint | undefined,
    priceEth: bigint | undefined,
    balance: bigint | undefined,
    priceWei: bigint | undefined

  console.log(
    "HLC got the data!",
    data,
    error,
    isError,
    isLoading,
    status,
    isFetching,
    isSuccess,
    isFetched
  )

  useEffect(() => {
    if (!error) return
    track("collectible metadata error", {
      error,
      collectible: props.collectible,
    })
  }, [error])

  if (Array.isArray(data)) {
    supply = BigInt(data[0])
    maxSupply = BigInt(data[1])
    priceWei = BigInt(data[2])
    balance = BigInt(data[3] || 0)

    priceEth = priceWei * BigInt(10 ** 18)
  }

  console.log("HLC - Read contract", balance, data, error, isError, isLoading)

  // Set sensible defaults here, while the data is loading.
  // This will prevent the modal from displaying incorrectly
  // (eg Popper will calculate that the width of the modal is too small,
  // since we return null here, and displays it possibly overlapping
  // the bottom or top of the screen).
  if (
    supply === undefined ||
    maxSupply === undefined ||
    priceEth === undefined ||
    priceWei === undefined
  ) {
    supply = BigInt(0)
    maxSupply = BigInt(0)
    priceEth = BigInt(0)
    priceWei = BigInt(0)
  }

  const userAlreadyOwns = balance && balance > 0n ? true : false

  const metricData = {
    chain: props.collectible.chain,
    maxSupply,
    remainingSupply: maxSupply - supply,
    supply,
    userAlreadyOwns,
    priceWei,
  }

  console.log("Running hook with user's metric data", {
    metricData,
    contract,
    maxSupply: maxSupply.toString(),
    supply: supply.toString(),
    priceWei: priceWei.toString(),
    remainingSupply: (maxSupply - supply).toString(),
  })
  return metricData
}

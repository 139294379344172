import { Subscription } from "@types"

export default function TrialEndSection({
  subscription,
}: {
  subscription?: Subscription
}) {
  if (!subscription?.trialEndAt) return null

  const trialEndAtString = new Date(
    subscription.trialEndAt
  ).toLocaleDateString()

  return (
    <div className="mt-4 text-sm text-gray-400">
      Free trial ends on {trialEndAtString}
    </div>
  )
}

import { CollectibleMetricData } from "@/types/highlights"
import { COLLECTIBLE_DATA } from "@/util/crypto"
import { utils, constants } from "ethers"

/**
 * Safely get the BigInt from a BigIntish or number
 * @param value BigIntish or number
 * @param defaultValue default value to return if value is not a BigInt or number
 * @returns BigInt
 */
function getBigInt(
  value: bigint | number | string,
  defaultValue: bigint | number | string
): bigint {
  console.log("This is the value", value, defaultValue)

  try {
    if (
      typeof value === "bigint" ||
      typeof value === "number" ||
      typeof value === "string"
    ) {
      console.log("This is the value, it's a BigInt", value, defaultValue)
      return BigInt(value)
    }

    return BigInt(defaultValue)
  } catch (e) {
    return BigInt(defaultValue)
  }
}

function getFontSizeClass(priceEth: string) {
  if (priceEth.length > 5) {
    return "text-sm"
  } else {
    return "text-xl"
  }
}

export default function Metrics(props: CollectibleMetricData) {
  let maxSupply: bigint | string = getBigInt(props.maxSupply, 0)
  let remaining: bigint | string | undefined = getBigInt(
    props.remainingSupply,
    0
  )
  const priceWei: bigint | string = getBigInt(props.priceWei, 0)

  console.log("This is metrics popover data top", maxSupply, props, remaining)

  if (maxSupply == 0n || constants.MaxUint256.eq(maxSupply)) {
    maxSupply = "∞"
    remaining = undefined
  }
  const unit = COLLECTIBLE_DATA[props.chain].unit

  const fontSizeClass = getFontSizeClass(utils.formatEther(priceWei))

  console.log("This is metrics popover data", maxSupply, props, remaining)

  const priceEth =
    priceWei == 0n ? (
      <div className="text-xl">FREE</div>
    ) : (
      <>
        <div className={fontSizeClass}>{utils.formatEther(priceWei)}</div>
        <div className="text-sm text-gray-400 font-semibold">{unit}</div>
      </>
    )

  // Inside your React component's render method:

  return (
    <div>
      <dl className="mt-5 grid grid-cols-2 gap-2 sm:grid-cols-2">
        <div className="overflow-hidden rounded-lg bg-white px-2 py-3 shadow border border-gray-100 sm:p-3 text-center">
          <dt className="truncate text-sm font-medium text-gray-500">Supply</dt>
          <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900 text-center">
            {remaining && remaining > 0n ? (
              <div className="border-gray-200 border-b-2">
                {remaining.toString()}
              </div>
            ) : null}

            {maxSupply.toString()}
          </dd>
        </div>

        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow border border-gray-100 sm:p-3 text-center flex flex-col">
          <dt className="truncate text-sm font-medium text-gray-500">Cost</dt>
          <dd className="mt-1 font-semibold tracking-tight text-gray-900 text-center flex flex-col grow justify-center">
            {priceEth}
          </dd>
        </div>
      </dl>
    </div>
  )
}

export function blobToText(blob: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()

    reader.onloadend = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result)
      } else {
        reject(new Error("Unexpected result type"))
      }
    }

    reader.onerror = () => reject(reader.error)

    reader.readAsText(blob)
  })
}

import { axios, baseUrl } from "./resolve"
import { AxiosResponse } from "axios"
import type { PublicNotesResp, GetNoteResp } from "@types"
import { AllUserInfoResponse } from "@/types/responses/public"

const API = "/published_notes"

export async function getTweet(id: string): Promise<{ [key: string]: any }> {
  const response: AxiosResponse<{ [key: string]: any }> = await axios.get(
    `${API}/tweet/${id}`
  )
  return response.data.data
}

export async function getEmbed(url: string): Promise<{ [key: string]: any }> {
  const response: AxiosResponse<{ [key: string]: any }> = await axios.get(
    `${API}/embed?url=${url}`
  )
  return response.data.data
}

export async function generateAiResponse(
  type: string,
  prompt: string,
  extraData?: { [key: string]: any }
): Promise<ReadableStreamDefaultReader<string> | null> {
  const response = await fetch(`${baseUrl}${API}/ai/generate`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ type, prompt, extraData }),
  })

  if (!response || !response.body || response.status !== 200) return null
  return response.body.pipeThrough(new TextDecoderStream()).getReader()
}

export async function getSoundXyzEmbed(
  artist: string,
  release: string
): Promise<{ [key: string]: any }> {
  const response: AxiosResponse<{ [key: string]: any }> = await axios.get(
    `${API}/soundxyz/${artist}/${release}`
  )
  return response.data
}

export async function getPublishedNotes(
  blogName: string,
  pageNum?: number,
  category?: string,
  communityName?: string
): Promise<PublicNotesResp> {
  const response: AxiosResponse<PublicNotesResp> = await axios.get(
    `${API}/blog/${blogName}`,
    {
      params: {
        page: pageNum,
        category,
        communityName,
      },
    }
  )
  console.log("URL was", response.config.url)
  return response.data
}

export async function getPublishedNote(
  blogName: string,
  noteSlugOrId: string,
  preview?: boolean
): Promise<GetNoteResp | undefined> {
  console.log("API call: Getting pulished note", blogName, noteSlugOrId)

  let params

  if (preview) {
    params = {
      preview,
    }
  }

  const response: AxiosResponse<PublicNotesResp> = await axios.get(
    `${API}/blog/${blogName}/${encodeURIComponent(noteSlugOrId)}`,

    {
      params,
    }
  )
  console.log("Got published note!")
  return response.data?.notes?.[0]
}

export async function getPublicUserInfoByWallet(
  wallet: string,
  blogIdContext?: string
): Promise<AllUserInfoResponse> {
  const response: AxiosResponse<AllUserInfoResponse> = await axios.get(
    `${API}/all-user-info/wallet/${wallet}`,
    {
      params: {
        blogIdContext,
      },
    }
  )
  return response.data
}

export async function getPublicUserInfoByUserId(
  userId: string,
  blogIdContext?: string
): Promise<AllUserInfoResponse> {
  const response: AxiosResponse<AllUserInfoResponse> = await axios.get(
    `${API}/all-user-info/userid/${userId}`,
    {
      params: {
        blogIdContext,
      },
    }
  )
  return response.data
}

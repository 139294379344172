import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { selectUser, logoutUser } from "../features/userSlice"
import { useDisconnect, useAccountEffect, useAccount } from "wagmi"
import { useAppDispatch } from "store"

export default function useUser() {
  const user = useSelector(selectUser)
  const dispatch = useAppDispatch()
  const [loggedIn, setLoggedIn] = useState(false)
  const [signoutTriggered, setSignoutTriggered] = useState(false)
  const accountData = useAccount()
  useAccountEffect({
    onConnect() {
      console.log("CONNECTED!")
    },
    onDisconnect() {
      console.log("DISCONNECTED!")
      dispatch(logoutUser())
    },
  })
  const { disconnect } = useDisconnect()

  const signoutUser = async () => {
    console.log("SIGNING OUT USER!", accountData)

    // TODO: The disconnect function is NOT working. It doesn't disconnect me from Metamask.
    disconnect()
    await dispatch(logoutUser())
    setSignoutTriggered(true)
    //window.location.href = "/"
  }

  const stringifiedUser = JSON.stringify(user)

  useEffect(() => {
    const isLoggedIn = !!user?.id
    console.log("Logged in user: ", user?.id, isLoggedIn)
    setLoggedIn(isLoggedIn)
  }, [stringifiedUser])

  useEffect(() => {
    if (!signoutTriggered) return

    window.location.reload()
  }, [signoutTriggered])

  useEffect(() => {
    if (user?.disabled && !window.location.href.includes("/disabled_account")) {
      window.location.href = "/disabled_account"
    }
  }, [user?.disabled])

  const hasEmail = "email" in user && user.email !== ""
  const hasWallet = "wallet_address" in user && !!user.wallet_address
  const hasEmailAndWallet = hasEmail && hasWallet

  return {
    ...user,
    cryptoData: loggedIn ? accountData : undefined,
    loggedIn,

    // If the user is logged into Paragraph, and has a wallet connected to their account.
    walletReadyAndLoggedIn: loggedIn && accountData.isConnected,
    hasEmail,
    hasWallet,
    hasEmailAndWallet,
    signoutUser,
  }
}

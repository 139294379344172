import { Blog } from "@types"
import { useEffect, useState } from "react"

interface Props {
  blog?: Blog
}

export interface BlogUrlReturnVals {
  url: string
  host: string
  fullUrl: string
  customDomainOrRelativeUrl: string
}

// Hook that makes it easier to get the blog URL,
// for both CUSTOM domain names as well as non-custom domain names.
export default function useBlogUrl(props: Props): BlogUrlReturnVals {
  let blog = props.blog

  // If we're on a non-prod Vercel deploy,
  // treat custom domains like regular domains so relative URLs are set correctly.
  //
  // If we want to test custom domain login on Vercel preview branches, we need to
  // comment this out.
  if (
    process.env.NEXT_PUBLIC_VERCEL_ENV !== "production" &&
    blog &&
    blog.url !== "@colins-blog"
  ) {
    blog = {
      ...blog,
      custom_domain: undefined,
    }
  }

  const [url, setUrl] = useState(
    blog?.custom_domain ? "/" : `/${blog?.lowercase_url}/`
  ) // The URL of the blog, ie /@colinarms, OR, if it's a custom domain, just /
  const [host, setHost] = useState("") // Either https://paragraph.xyz, or the custom domain
  const [customDomainOrRelativeUrl, setCustomDomainOrRelativeUrl] = useState(
    blog?.custom_domain || `/${blog?.lowercase_url}/` || ""
  ) //Either the custom domain, or /@lowercase_url/

  const [fullUrl, setFullUrl] = useState(
    blog?.custom_domain
      ? `https://${blog?.custom_domain}`
      : `https://paragraph.xyz/${blog?.lowercase_url}`
  ) // Either https://paragraph.xyz/@lowercase_url, or the custom domain

  useEffect(() => {
    if (!blog) {
      return
    }

    // Window is only accessible client-side. Do this to prevent hydration mismatch.
    const _host = window.location.host
    const actualHost =
      window.location.protocol + "//" + (blog?.custom_domain || _host)

    // If this is a custom domain, do not include the URL in the path (Next middleare
    // rewrites automatically to add the URL to the path).
    if (blog.custom_domain) {
      setUrl("/")
      setCustomDomainOrRelativeUrl(actualHost + "/")
      setFullUrl(actualHost)
    } else {
      // Otherwise, include the URL in the path for a regular blog.
      setUrl("/" + blog.lowercase_url + "/")
      setCustomDomainOrRelativeUrl("/" + blog.lowercase_url + "/")
      setFullUrl(actualHost + "/" + blog.lowercase_url)
    }

    setHost(actualHost)
  }, [blog])

  if (!blog) {
    return { url: "", host: "", fullUrl: "", customDomainOrRelativeUrl: "" }
  }

  return { url, host, customDomainOrRelativeUrl, fullUrl }
}

import { mergeAttributes, Node } from "@tiptap/core"

export default Node.create({
  name: "figcaption",

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  content: "inline*",

  selectable: false,

  draggable: false,

  parseHTML() {
    return [
      {
        tag: "figcaption",
      },
    ]
  },

  renderHTML({ HTMLAttributes, node }) {
    let className = ""
    if (node.content.size === 0) className = "hide-figcaption"

    return ["figcaption", mergeAttributes({ HTMLAttributes, className }), 0]
  },

  addKeyboardShortcuts() {
    return {
      // When in figcaption and user hits enter -> create a paragraph node after figure & select it
      Enter: () => {
        if (
          this.editor.state.selection.$anchor.parent.type.name === this.name
        ) {
          this.editor.commands.selectParentNode()
          const nodePos = this.editor.state.selection.$anchor.pos
          // @ts-ignore
          const nodeSize = this.editor.state.selection.node.nodeSize

          this.editor
            .chain()
            // Note: Originally I was inserting a paragraph but couldn't get it focused.
            //       Focusing right after the figure automatically creates and selects a
            //       trailing paragraph
            .focus(nodePos + nodeSize + 1)
            .run()
        }

        return false
      },
    }
  },
})

import { useState } from "react"
import { useAppDispatch as useDispatch } from "store"

import { selectBlog, subscribeUserToBlog } from "features/blogSlice"
import { useSelector } from "react-redux"
import { setError } from "features/errorSlice"
import { useAnalytics } from "hooks/useAnalytics"
import useSharemint from "hooks/useSharemint"
import SubscribeEmailInput from "components/SubscribeEmailInput"
import { validateEmail } from "@/util/format"

interface modelProps {
  email?: string
  initialFocusRef?: React.Ref<any>
  minimal?: boolean

  // Vertically stacked rows, or horizontally stacked columns?
  // By default we use horizontal columns.
  vertical?: boolean

  origin?: string
}

export default function FreeSubscribeModalInner(props: modelProps) {
  const [email, setEmail] = useState(props.email || "")
  const blog = useSelector(selectBlog)
  const { track } = useAnalytics()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const pingSharemint = useSharemint()

  const submitEmail = async () => {
    track("subscribe email submit click", { email, origin: props.origin })

    dispatch(setError({ msg: "" }))

    if (!validateEmail(email)) {
      console.log("Invalid email!")
      dispatch(setError({ msg: "Please enter a valid email address" }))
      return
    }

    setLoading(true)
    const hasSubscribed = await dispatch(subscribeUserToBlog(email))

    if (hasSubscribed) {
      console.log("User has subscribed, so pinging sharemint...")
      pingSharemint({ blog, email })
    }

    console.log("Dispatched this...")
    setLoading(false)
  }

  return (
    <SubscribeEmailInput
      preFilledEmail={props.email}
      email={email}
      setEmail={setEmail}
      loading={loading}
      submitEmailCallback={submitEmail}
      initialFocusRef={props.initialFocusRef}
      minimal={props.minimal}
      vertical={props.vertical}
    />
  )
}

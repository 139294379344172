import React from "react"
import Image from "next/legacy/image"
import q from "../public/branding/logo_no_text.png"

interface Props {
  className?: string
  customUrl?: string
}

export default function Logo(props: Props) {
  const classes = `${props.className}`
  return (
    <div className={classes}>
      {props.customUrl ? (
        <Image
          alt="Paragraph logo"
          src={props.customUrl}
          priority
          objectFit="scale-down"
          objectPosition="center"
          height={48}
          width={48}
        />
      ) : (
        <Image
          alt="Paragraph logo"
          src={q}
          priority
          placeholder="blur"
          height={48}
          width={48}
        />
      )}
    </div>
  )
}

import { Node } from "@tiptap/core"
import { Editor, NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react"
import { getEmbed } from "api_routes/published"
import { useEffect } from "react"
import ReactDOMServer from "react-dom/server"
import { Plugin, PluginKey } from "@tiptap/pm/state"
import { find } from "linkifyjs"
import psl from "psl"

const IFRAME_WIDTH_HEIGHT_REGEX = /width="([^"]*)" height="([^"]*)"/

// Domains that are handled by other extensions and should be ignored
const EMBED_BLACKLIST = [
  "twitter",
  "x",
  "youtube",
  "youtu",
  "highlight",
  "giphy",
  "zora",
  "layer3",
  "sound",
]
declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    embedly: {
      setEmbedly: (options: { src: string }) => ReturnType
      setFormat: (format: "link" | "small" | "large" | "iframe") => ReturnType
    }
  }
}

export default Node.create({
  name: "embedly",
  group: "block",
  draggable: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  addAttributes() {
    return {
      src: {
        default: null,
      },
      data: {
        default: null,
      },
      format: {
        default: "small",
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ]
  },

  renderHTML({ node, HTMLAttributes }) {
    const html = ReactDOMServer.renderToStaticMarkup(
      <EmbedlyComponent editor={null} node={node} />
    )

    // Note: this check is necessary since embeds were created before stringifying data
    let data = HTMLAttributes.data
    if (typeof data !== "string") data = JSON.stringify(data)

    return ["div", { html, "data-type": this.name, ...HTMLAttributes, data }]
  },

  addNodeView() {
    return ReactNodeViewRenderer(EmbedlyComponent)
  },

  addCommands() {
    return {
      setEmbedly:
        (options) =>
        ({ chain }) => {
          if (!options.src) return false
          let src = options.src
          if (!options.src.includes("http")) src = "https://" + options.src

          // Handle embeds that have their own extensions
          const parsedLink = psl.parse(
            src.replace(/http(s)?(:)?(\/\/)?|(\/\/)?/, "").split("/")[0]
          )
          if (!parsedLink || parsedLink.error || !parsedLink.sld) {
            console.error(
              "EMBEDLY: parsedLink error",
              parsedLink,
              parsedLink.error
            )
            return false
          }

          switch (parsedLink.sld) {
            case "youtube":
            case "youtu":
              chain().setYoutubeVideo({ src }).run()
              break
            case "twitter":
              chain().setTweet({ tweetURL: src }).run()
              break
            case "highlight":
              chain().setHighlightEmbed({ src }).run()
              break
            case "zora":
              chain().setZoraEmbed({ src }).run()
              break
            case "sound":
              chain().setSoundXyzEmbed({ src }).run()
              break
            case "layer3":
              chain().setQuestEmbed({ src }).run()
              break
            default:
              return chain()
                .insertContent({
                  type: this.name,
                  attrs: { src },
                })
                .run()
          }

          return false
        },
      setFormat:
        (format) =>
        ({ commands }) => {
          return commands.updateAttributes("embedly", {
            format,
          })
        },
    }
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("embedlyPlugin"),
        props: {
          // Check if link pasted alone into empty node and, if so, turn into embed
          handlePaste: (view, event, slice) => {
            const { state } = view
            const { selection } = state

            // If current node isn't empty -> skip embed transformation
            if (selection.$anchor.node(1).textContent) return false

            let textContent = ""

            slice.content.forEach((node) => {
              textContent += node.textContent
            })

            const link = find(textContent).find(
              (item) => item.isLink && item.value === textContent
            )

            // If not link -> skip embed transformation
            if (!textContent || !link) return false

            // Ignore embeds that have their own extensions
            const parsedLink = psl.parse(
              link.href.replace(/http(s)?(:)?(\/\/)?|(\/\/)?/, "").split("/")[0]
            )
            if (!parsedLink || parsedLink.error || !parsedLink.sld) {
              console.error(
                "EMBEDLY: parsedLink error",
                parsedLink,
                parsedLink.error
              )
              return false
            }

            if (EMBED_BLACKLIST.includes(parsedLink.sld)) return false

            this.editor.commands.setEmbedly({ src: link.href })

            return true
          },
        },
        // Replace embed with plain link when data is "error" or when format is "link"
        appendTransaction: (transactions, oldState, newState) => {
          const tr = newState.tr
          let modified = false
          newState.doc.descendants((node, pos, _parent) => {
            if (node.type.name !== "embedly") return

            const data = JSON.parse(node.attrs.data)
            if (
              node.attrs.data === "error" ||
              node.attrs.format === "link" ||
              (data &&
                (!data.thumbnail_url ||
                  !data.provider_url ||
                  !data.title ||
                  !data.description))
            ) {
              tr.delete(pos, pos + node.nodeSize)
              tr.insert(
                pos,
                this.editor.schema.node(
                  "paragraph",
                  null,
                  this.editor.schema.text(node.attrs.src, [
                    this.editor.schema.mark("link", {
                      href: node.attrs.src,
                      target: "_blank",
                    }),
                  ])
                )
              )
              modified = true
            }
          })
          if (modified) return tr
        },
      }),
    ]
  },
})

type Props = {
  editor: Editor | null
  node: any
  updateAttributes?: (a: any) => void
}

const EmbedlyComponent = (props: Props) => {
  const { updateAttributes } = props
  const { src, data } = props.node.attrs
  useEffect(() => {
    const retrieveEmbedData = async (url: string) => {
      try {
        const data = await getEmbed(src)
        if (updateAttributes)
          updateAttributes({
            data: JSON.stringify(data),
            format: data.html ? "iframe" : data.large ? "large" : "small",
          })
      } catch (e) {
        console.error(`Failed to retrieve embed data for url: ${url}`, e)
      }
    }

    if (!data) retrieveEmbedData(src)
  }, [updateAttributes, src, data])

  // Embed failed to load -> Render as link (handled by ProseMirror plugin)
  if (data === "error" && props.editor) return null

  // Embed loading -> Render placeholder
  if (!data) return <EmbedPlaceholder />

  // Note: this check is necessary since embeds were created before stringifying data
  const embedData = typeof data === "string" ? JSON.parse(data) : data

  // Embed is iFrame -> Embed iFrame HTML
  if (props.node.attrs.format === "iframe" && props.editor) {
    const [_, width, height] =
      IFRAME_WIDTH_HEIGHT_REGEX.exec(embedData.html) || []

    const ratio =
      height && width
        ? ((Number(height) / Number(width)) * 100).toPrecision(4) + "%"
        : null
    const responsiveIframe = ratio
      ? `<div class="responsive-object" style="padding-bottom:${ratio};">${embedData.html}</div>`
      : embedData.html

    return (
      <NodeViewWrapper
        className="react-component my-5"
        style={
          props.editor && props.editor.isEditable
            ? { pointerEvents: "none" }
            : {}
        }
        data-drag-handle
      >
        <div dangerouslySetInnerHTML={{ __html: responsiveIframe }} />
      </NodeViewWrapper>
    )
  }

  // Embed does not have all required information -> Render as link (handled by ProseMirror plugin)
  if (
    !embedData.thumbnail_url ||
    !embedData.provider_url ||
    !embedData.title ||
    !embedData.description
  )
    return null

  // Embed is link preview (embeds without iFrame or embed for newsletter)
  return (
    <NodeViewWrapper className="react-component embed my-5" data-drag-handle>
      <a
        className="twitter-card-link"
        href={props.node.attrs.src}
        style={
          props.editor && props.editor.isEditable
            ? { pointerEvents: "none" }
            : {}
        }
        target="_blank"
        rel="noreferrer"
      >
        <div
          className={
            props.node.attrs.format === "small"
              ? "twitter-summary"
              : "twitter-summary-large-image"
          }
        >
          {embedData.thumbnail_url && (
            <img
              src={embedData.thumbnail_url}
              className={`${
                props.node.attrs.format === "large" && "large-summary-image"
              }`}
            />
          )}
          <div className="twitter-summary-card-text">
            <span>{embedData.provider_url}</span>
            <h2>{embedData.title}</h2>
            <p>{embedData.description}</p>
          </div>
        </div>
      </a>
    </NodeViewWrapper>
  )
}

const EmbedPlaceholder = () => {
  return (
    <NodeViewWrapper className="react-component not-prose" data-drag-handle>
      <div
        role="status"
        className="mx-auto w-full max-w-[548px] rounded border border-gray-200 p-4 dark:border-gray-700 md:p-6 embed bg-white"
      >
        <div className="mb-2 flex items-center space-x-3 animate-pulse">
          <svg
            className="h-14 w-14 text-gray-200 dark:text-gray-700"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
              clipRule="evenodd"
            ></path>
          </svg>
          <div>
            <div className="animate-pulse mb-2 h-2.5 w-32 rounded-full bg-gray-200 dark:bg-gray-700"></div>
            <div className="animate-pulse h-2 w-48 rounded-full bg-gray-200 dark:bg-gray-700"></div>
          </div>
        </div>
        <div className="animate-pulse mb-2.5 h-2 rounded-full bg-gray-200 dark:bg-gray-700"></div>
        <div className="animate-pulse mb-2.5 h-2 rounded-full bg-gray-200 dark:bg-gray-700"></div>
        <div className="animate-pulse mr-16 h-2 rounded-full bg-gray-200 dark:bg-gray-700"></div>

        <span className="sr-only">Loading...</span>
      </div>
    </NodeViewWrapper>
  )
}

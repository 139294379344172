import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Thunk, RootState } from "../store"

import { setLoading } from "./pageSlice"

import { ErrorResponse } from "@types"

interface ErrorState {
  error: ErrorResponse
  success: string
}

const initialState: ErrorState = {
  error: {
    msg: "",
  },
  success: "",
}

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<ErrorResponse>) => {
      state.error = action.payload
      state.success = ""
    },
    setSuccess: (state, action: PayloadAction<string>) => {
      state.success = action.payload
      state.error = { msg: "" }
    },
  },
})

// Publicly accessible, within components.
export const { setSuccess } = errorSlice.actions

export const setError =
  (error: { msg: string }): Thunk<void> =>
  async (dispatch, getState) => {
    dispatch(setLoading(false))
    dispatch(errorSlice.actions.setError(error))
  }

export const selectError = (state: RootState) => state.error.error
export const selectSuccess = (state: RootState) => state.error.success

export default errorSlice.reducer

"use client"

import { Node } from "@tiptap/core"
import { generateJSON } from "@tiptap/html"
import {
  ReactNodeViewRenderer,
  NodeViewWrapper,
  NodeViewContent,
  Editor,
} from "@tiptap/react"
import { MdOutlineLightbulb } from "react-icons/md"
import { FiAlertCircle, FiAlertTriangle, FiAlertOctagon } from "react-icons/fi"
import ReactDOMServer from "react-dom/server"
import { getHtml } from "util/tiptap"
import { Fragment } from "@tiptap/pm/model"
import { extensions } from "components/Editor/extensions"

type CalloutType = "info" | "tip" | "warning" | "important"

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    callout: {
      setCallout: ({ type }: { type: CalloutType }) => ReturnType
    }
  }
}

export default Node.create({
  name: "callout",
  group: "block",
  content: "block+",

  addAttributes() {
    return {
      type: {
        default: "info",
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-type="callout"]',
        getContent: (node, schema) => {
          const json = generateJSON(
            (node as HTMLElement).getAttribute("html") || "",
            extensions
          )

          // The first array element is the callout icon for the newsletter so we can skip it
          const fragment = Fragment.fromJSON(schema, json.content.slice(1))

          return fragment
        },
      },
    ]
  },

  addCommands() {
    return {
      setCallout:
        (options) =>
        ({ commands }) => {
          return commands.setCallout({ type: options.type })
        },
    }
  },

  renderHTML({ node, HTMLAttributes }) {
    const childrenHTML = getHtml(node.toJSON())
    const html = ReactDOMServer.renderToStaticMarkup(
      // @ts-ignore
      <Callout
        editor={null}
        // @ts-ignore
        node={{ attrs: { ...HTMLAttributes } }}
        childrenHTML={childrenHTML}
      />
    )

    return ["div", { "data-type": this.name, ...HTMLAttributes, html }]
  },

  addNodeView() {
    return ReactNodeViewRenderer(Callout)
  },
})

type Props = {
  editor: Editor | null
  node: { attrs: { type: CalloutType } }
  updateAttributes({}): void
  childrenHTML?: string
}

export function Callout(props: Props): JSX.Element {
  const TYPES = {
    info: {
      class: "callout-base callout-info",
      icon: <FiAlertCircle className="callout-info-icon" />,
      img: "{{DOMAIN}}/editor/callout/information-icon.png",
    },
    tip: {
      class: "callout-base callout-tip",
      icon: <MdOutlineLightbulb className="callout-tip-icon" />,
      img: "{{DOMAIN}}/editor/callout/tip-icon.png",
    },
    warning: {
      class: "callout-base callout-warning",
      icon: <FiAlertTriangle className="callout-warning-icon" />,
      img: "{{DOMAIN}}/editor/callout/warning-icon.png",
    },
    important: {
      class: "callout-base callout-important",
      icon: <FiAlertOctagon className="callout-important-icon" />,
      img: "{{DOMAIN}}/editor/callout/important-icon.png",
    },
  }

  const type = TYPES[props.node.attrs.type]

  const setCalloutType = () => {
    const types = Object.keys(TYPES)
    const nextType = types[types.indexOf(props.node.attrs.type) + 1]
    props.updateAttributes({
      type: nextType,
    })
  }

  if (!type) return <></>

  return (
    <NodeViewWrapper className={type.class}>
      {!props.childrenHTML ? (
        <button
          className="callout-button"
          contentEditable={false}
          disabled={!props.editor || !props.editor.isEditable}
          onClick={setCalloutType}
        >
          {!props.editor || !props.editor.isEditable ? (
            type.icon
          ) : (
            <div className="animate-bounceIntroduce">{type.icon}</div>
          )}
        </button>
      ) : (
        <img src={type.img} className="callout-button" />
      )}
      <div className="callout-content">
        {!props.childrenHTML ? (
          <NodeViewContent className="pr-2 w-full " />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: props.childrenHTML }} />
        )}
      </div>
    </NodeViewWrapper>
  )
}

import { Node } from "@tiptap/core"
import { Plugin, PluginKey } from "@tiptap/pm/state"
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react"
import { Editor } from "@tiptap/core"
import { MdFormatListNumbered, MdLightbulbOutline } from "react-icons/md"
import { IoDocumentTextOutline } from "react-icons/io5"
import { AiPromptType, handlePrompt } from "./utils"
import { useAnalytics } from "hooks/useAnalytics"
import { useAppDispatch } from "store"
import { AiOutlineClose } from "react-icons/ai"

const examplePrompts = [
  "What are the benefits of using AI to write?",
  "Why is Paragraph a great platform for writers?",
  "What are the benefits of web3?",
  "How can I use NFTs to build a community?",
  "5 steps to growing my newsletter",
  "What are the most important things about content creation?",
  "How can I create a compelling blog that brings in an audience?",
]

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    emptyState: {
      setEmptyState: (options: {
        type: AiPromptType
        input?: string
      }) => ReturnType
    }
  }
}

export default Node.create({
  name: "emptyState",
  group: "block",
  draggable: false,
  atom: true,
  allowGapCursor: false,
  selectable: false,

  addAttributes() {
    return {
      type: {
        default: null,
      },
      loading: {
        default: false,
      },
      placeholder: {
        default: null,
      },
      prompt: {
        default: null,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ]
  },

  renderHTML() {
    return ["div", { "data-type": this.name }]
  },

  addCommands() {
    return {
      setEmptyState:
        (attrs) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs,
          })
        },
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(EmptyStateComponent)
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey(this.name),
        // Don't allow the user to select emptyState
        filterTransaction: (tr, state) => {
          if (tr.selection.$head.nodeBefore?.type.name === this.name)
            return false
          return true
        },
        // If editor is empty -> Add EmptyState
        appendTransaction: (_, __, state) => {
          const { doc, tr, schema } = state

          // If not editing return
          if (!this.editor.isEditable) return

          // Check editor state against default editor state to determine if empty
          const emptyDocInstance = doc.type.createAndFill()
          const isEditorEmpty =
            emptyDocInstance?.sameMarkup(doc) &&
            emptyDocInstance.content.findDiffStart(doc.content) === null

          // Check if emptyState node and first child (the paragraph) isn't empty
          const shouldRemoveEmptyState =
            doc.lastChild?.type.name === this.name &&
            (doc.content.firstChild?.content.size !== 0 ||
              doc.content.firstChild?.type.name !== "paragraph" ||
              doc.content.childCount !== 2)

          const endPosition = doc.content.size
          const type = schema.nodes[this.name]

          if (isEditorEmpty) return tr.insert(endPosition, type.create())

          if (shouldRemoveEmptyState) {
            return tr.delete(
              doc.content.size - doc.lastChild.nodeSize,
              doc.content.size
            )
          }
        },
      }),
    ]
  },
})

type Props = {
  editor: Editor | null
  updateAttributes(attrs: any): void
  node: any
  deleteNode(): void
}

export function EmptyStateComponent(props: Props): JSX.Element {
  const dispatch = useAppDispatch()

  if (!props.editor || !props.editor.isEditable) return <></>

  if (props.node.attrs.type) {
    if (!props.node.attrs.placeholder)
      props.updateAttributes({
        placeholder:
          examplePrompts[Math.floor(Math.random() * examplePrompts.length)],
      })
    let prompt
    switch (props.node.attrs.type) {
      case "title":
        prompt =
          "What do you want to write about? AI will generate a catchy title for you."
        break
      case "outline":
        prompt =
          "What do you want to write about? AI will generate a rough outline for you."
        break
      case "draft":
        prompt =
          "What do you want to write about? AI will generate a first draft for you."
        break
    }

    return (
      <EmptyStateInput
        editor={props.editor}
        dispatch={dispatch}
        updateAttributes={props.updateAttributes}
        deleteNode={props.deleteNode}
        type={props.node.attrs.type}
        loading={props.node.attrs.loading}
        prompt={prompt}
        input={props.node.attrs.prompt}
        placeholder={props.node.attrs.placeholder}
      />
    )
  }

  return (
    <NodeViewWrapper>
      <p className="text-gray-400 !mt-0">
        Start writing or let AI generate a starting point:
      </p>
      <div className="mx-auto max-w-md flex justify-around items-center text-gray-400 gap-4">
        <EmptyStateButton
          editor={props.editor}
          updateAttributes={props.updateAttributes}
          loading={props.node.attrs.loading}
          type="title"
          title="Title"
          icon={<MdLightbulbOutline className="mr-2" />}
        />
        <EmptyStateButton
          editor={props.editor}
          updateAttributes={props.updateAttributes}
          loading={props.node.attrs.loading}
          type="outline"
          title="Outline"
          icon={<MdFormatListNumbered className="mr-2" />}
        />
        <EmptyStateButton
          editor={props.editor}
          updateAttributes={props.updateAttributes}
          loading={props.node.attrs.loading}
          type="draft"
          title="Draft"
          icon={<IoDocumentTextOutline className="mr-2" />}
        />
      </div>
    </NodeViewWrapper>
  )
}

type ButtonProps = {
  editor: Editor
  updateAttributes(attrs: any): void
  loading: AiPromptType | null
  type: AiPromptType
  title: string
  icon: any
}

const EmptyStateButton = (props: ButtonProps) => {
  const { track } = useAnalytics()

  return (
    <button
      className="border rounded-md p-4 h-16 flex flex-1 justify-center items-center dark:hover:bg-gray-700 hover:bg-gray-100 border-gray-300 text-gray-400"
      contentEditable={false}
      onClick={() => {
        track("AI starter button clicked", { type: props.type })
        props.updateAttributes({ type: props.type })
      }}
    >
      <>
        {props.icon}
        <span>{props.title}</span>
      </>
    </button>
  )
}

type InputProps = {
  editor: Editor
  dispatch: any
  updateAttributes(attrs: any): void
  deleteNode(): void
  type: AiPromptType
  loading: boolean
  prompt?: string
  input?: string
  placeholder: string
  defaultValue?: string
}

const EmptyStateInput = (props: InputProps) => {
  const { track } = useAnalytics()

  return (
    <NodeViewWrapper>
      <form
        className="flex flex-col justify-center items-center border border-gray-200 p-2 rounded-md"
        onSubmit={async (event) => {
          event.preventDefault()
          if (!props.input) return
          props.updateAttributes({ loading: true })
          await handlePrompt(
            props.editor,
            props.dispatch,
            track,
            props.type,
            props.input
          )
          // Only update loading state for title prompts since EmptyState node will be gone already for others
          if (props.type === "title") props.updateAttributes({ loading: false })
        }}
      >
        <AiOutlineClose
          className="self-start m-2 text-lg cursor-pointer"
          onClick={() => {
            if (!props.loading) props.deleteNode()
          }}
        />
        {props.prompt && (
          <p className="text-gray-500 dark:text-gray-400 text-center">
            {props.prompt}
          </p>
        )}
        <div className="w-full p-4">
          <input
            autoFocus
            className="dark:text-white dark:bg-gray-700 w-full border dark:ring-0 dark:border-0 rounded-md text-gray-500 p-2 placeholder:text-gray-400"
            placeholder={props.placeholder}
            value={props.input}
            onChange={(e) => props.updateAttributes({ prompt: e.target.value })}
          />
        </div>
        <div className="flex gap-2 self-end m-4">
          <button
            type="button"
            className={`border rounded-md p-4 h-16 max-w-60 justify-center items-center ${
              !props.loading
                ? "hover:bg-gray-100 border-gray-300 text-gray-400 dark:hover:bg-gray-700"
                : "border-gray-200 text-gray-200 cursor-default"
            }`}
            onClick={() =>
              props.updateAttributes({
                prompt:
                  examplePrompts[
                    Math.floor(Math.random() * examplePrompts.length)
                  ],
              })
            }
            disabled={props.loading}
          >
            Inspire Me
          </button>
          <button
            type="submit"
            className={`border rounded-md p-4 h-16 w-20 flex flex-1 justify-center items-center ${
              !props.loading && props.input
                ? "hover:bg-gray-100 border-gray-300 text-gray-400 dark:hover:bg-gray-700"
                : "border-gray-200 text-gray-200 cursor-default dark:text-gray-600 dark:border-gray-600"
            }`}
            disabled={props.loading || !props.input}
          >
            {props.loading === false ? (
              "Submit"
            ) : (
              <svg
                aria-hidden="true"
                role="status"
                className="inline w-4 h-4 my-auto mx-0.5 text-gray-400 animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
            )}
          </button>
        </div>
      </form>
    </NodeViewWrapper>
  )
}

import useColor from "hooks/useColor"

export default function useColorStyles(defaultColor?: boolean) {
  const color: any = useColor(defaultColor)

  return {
    // @ts-ignore
    "--color-secondary": color.secondary,
    "--color-secondary-50": color.secondary50,
    "--color-secondary-100": color.secondary100,
    "--color-secondary-200": color.secondary200,
    "--color-secondary-300": color.secondary300,
    "--color-secondary-400": color.secondary400,
    "--color-secondary-500": color.secondary500,
    "--color-secondary-600": color.secondary600,
    "--color-secondary-700": color.secondary700,
    "--color-secondary-800": color.secondary800,
    "--color-secondary-900": color.secondary900,

    "--color-primary": color.primary,
    "--color-primary-text": color.primaryText,
    // This will only be set if the user has a custom font.
    "--text-custom-50": color.fontColor50,
    "--text-custom-100": color.fontColor100,
    "--text-custom-200": color.fontColor200,
    "--text-custom-300": color.fontColor300,
    "--text-custom-400": color.fontColor400,
    "--text-custom-500": color.fontColor500,
    "--text-custom-600": color.fontColor600,
    "--text-custom-700": color.fontColor700,
    "--text-custom-800": color.fontColor800,
    "--text-custom-900": color.fontColor900,

    "--bg-custom-50": color.bgColor50,
    "--bg-custom-100": color.bgColor100,
    "--bg-custom-200": color.bgColor200,
    "--bg-custom-300": color.bgColor300,
    "--bg-custom-400": color.bgColor400,
    "--bg-custom-500": color.bgColor500,
    "--bg-custom-600": color.bgColor600,
    "--bg-custom-700": color.bgColor700,
    "--bg-custom-800": color.bgColor800,
    "--bg-custom-900": color.bgColor900,

    "--text-primary-50": color.primaryColor50,
    "--text-primary-100": color.primaryColor100,
    "--text-primary-200": color.primaryColor200,
    "--text-primary-300": color.primaryColor300,
    "--text-primary-400": color.primaryColor400,
    "--text-primary-500": color.primaryColor500,
    "--text-primary-600": color.primaryColor600,
    "--text-primary-700": color.primaryColor700,
    "--text-primary-800": color.primaryColor800,
    "--text-primary-900": color.primaryColor900,
  }
}

import { Membership, PlanPeriod } from "@types"

export type Frequency = {
  id: PlanPeriod
  label: string
  resubscribeToLabel: string
  priceSuffix: string
}

export const monthlyFrequency: Frequency = {
  id: "month",
  label: "Monthly",
  resubscribeToLabel: "monthly",
  priceSuffix: "/month",
}

export const yearlyFrequency: Frequency = {
  id: "year",
  label: "Annually",
  resubscribeToLabel: "annual",
  priceSuffix: "/year",
}

export const frequencies: Frequency[] = [monthlyFrequency, yearlyFrequency]

const currencies = [
  { id: "fiat", label: "USD" },
  { id: "crypto", label: "Crypto" },
]

export const newEmptyTier: Membership = {
  id: "placeholder_id_",
  name: "",
  description: "",
  benefits: ["Access to exclusive posts.", "Gated Discord channel."],
  plans: [],
  isRecommended: false,
  hasNFT: false,
}

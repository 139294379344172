import {
  CreateNewCollectibleData,
  CollectibleType,
  UnsavedCollectible,
} from "@/types/highlights"
import { utils } from "ethers"

import { erc721FactoryABI } from "@/util/typechain"
import { COLLECTIBLE_DATA } from "@/util/crypto"

import { selectBlog } from "features/blogSlice"
import { selectCurrentNote } from "features/noteSlice"
import { useSelector } from "react-redux"
import CollectButton from "../../CollectButton"
import { useReaderReferrer } from "@/hooks/useReferrer"
import useContractInteraction from "@/hooks/collectibles/useContractInteraction"
import { zeroAddress } from "viem"
import { useEffect, useState } from "react"

const REVERTED_ERRORS = ["nft already exists"]

export default function CreateNewCollectibleBtn(
  props: CreateNewCollectibleData & {
    type: CollectibleType
    text: string
    btnText?: string
  }
) {
  const blog = useSelector(selectBlog)
  const note = useSelector(selectCurrentNote)

  // Need to fetch this from the blog and NOT the cookie.
  const creatorReferrer = blog?.user?.referrerWalletAddress || zeroAddress
  const minterReferrer = useReaderReferrer(blog.id) || zeroAddress

  const mintData: any = [
    props.name,
    props.symbol,
    props.ownerAddress,
    props.minterAddress,
    creatorReferrer,
    props.supply,
    props.costWei,
  ]

  const [position, setPosition] = useState({
    from: props.position.from,
    to: props.position.to,
  })

  const noteId = note?.id || ""

  const collectible: Omit<UnsavedCollectible, "txHash"> = {
    blogId: blog.id,
    noteId: note?.id || "",
    createdAt: Date.now(),
    type: props.type,
    status: "PENDING",
    // We set this here but it's overridden on the backend.
    version: 1,
    text: props.text,
    position: position,
    collectorWallet: props.minterAddress?.toLowerCase(),
    costEth: utils.formatEther(props.costWei.toString()),
    supply: props.supply.toString(),
    chain: props.chain,
  }

  const { write, error, pending, txnHash, isTxnConfirmed } =
    useContractInteraction({
      functionName: "createAndMint",
      collectible,
      address: COLLECTIBLE_DATA[props.chain].contractAddress,
      abi: erc721FactoryABI,
      chain: props.chain,
      args: [mintData, minterReferrer, noteId, position.from, position.to],
      collectibleCostWei: props.costWei,
      enabled: true,
    })

  /**
   * This is a hacky solution. Very occasionally the contract gets deployed onchain
   * but not in our DB, so if future people try to mint, they would be unsuccessful.
   *
   * This updates the position (which updates on the onchain hash), making a new NFT
   * mintable.
   */
  useEffect(() => {
    if (!error || !("message" in error)) return

    console.log("Stringified error!", {
      error,
      msg: error.message,
      cause: error.cause,
      name: error.name,
      pending,
    })

    for (const err of REVERTED_ERRORS) {
      if (error.message.toLowerCase().includes(err)) {
        const newPosition = {
          from: position.from + 1,
          to: position.to + 1,
        }
        console.error(
          "Previous NFT mint failed, updating position to " +
            JSON.stringify(newPosition) +
            " to try again"
        )
        setPosition(newPosition)
        return
      }
    }
  }, [error])

  return (
    <CollectButton
      onClick={write}
      btnText={props.btnText || "Collect"}
      error={error}
      desiredChain={props.chain}
      collectibleText={props.text}
      isLoading={pending}
      isSuccess={isTxnConfirmed}
    />
  )
}

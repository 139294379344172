/* This example requires Tailwind CSS v2.0+ */

import { EnrichedGatingRule } from "@/types/gatingRules"

import { LockClosedIcon, LockOpenIcon } from "@heroicons/react/24/outline"
import { Dispatch, SetStateAction } from "react"
import SubscribeButton from "../SubscribeButton"
import { isMembershipGate } from "@/util/gatingRules"
import useColor from "hooks/useColor"
import { Membership } from "@types"

type Props = {
  gatingRule: EnrichedGatingRule
  membership: Membership | undefined
  setIsError: Dispatch<SetStateAction<boolean>>
  setError: Dispatch<SetStateAction<Error | null>>
}

export default function GatingRulePleaseSubscribe({
  gatingRule,
  membership,
  setIsError,
  setError,
}: Props) {
  const color = useColor()

  return (
    <div className="rounded-lg focus:outline-none">
      <div className="flex flex-col">
        <div className="flex justify-between py-4">
          <div className="my-auto">
            {gatingRule.hasAccess && (
              <span className="rounded-lg inline-flex p-1 sm:p-3">
                <LockOpenIcon
                  className="w-5 h-5 sm:w-7 sm:h-7 text-green-500"
                  aria-hidden="true"
                />
              </span>
            )}
            {!gatingRule.hasAccess && (
              <span className="rounded-lg inline-flex p-1 sm:p-3">
                <LockClosedIcon
                  className="w-5 h-5 sm:w-7 sm:h-7 text-red-500"
                  aria-hidden="true"
                />
              </span>
            )}
          </div>
          <div className="ml-3 flex-1 flex flex-col justify-center h-18">
            <p
              className="text-base font-medium"
              style={{ color: color.fontColor || "text-gray-900" }}
            >
              {membership?.name ||
                gatingRule.gatingRuleRequirementTypeDisplayTitle}
            </p>
            <p className="text-sm text-custom-500">
              {gatingRule.hasAccess && (
                <span>
                  {gatingRule.gatingRuleRequirementTypeDisplayMessage}
                </span>
              )}
              {!gatingRule.hasAccess && (
                <span>
                  You need{" "}
                  <span>
                    {gatingRule.gatingRuleRequirementTypeDisplayMessage}
                  </span>
                  .
                </span>
              )}
            </p>
          </div>
          <div className="my-auto">
            {isMembershipGate(gatingRule) && !gatingRule.hasAccess && (
              <SubscribeButton className="inline-flex justify-center px-4 py-2 w-24 md:w-32" />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

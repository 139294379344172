import { Blog, Note } from "@types"
import { AFTER_GATE_REGEX } from "./gatingRules"
import { baseDomain } from "./frames/helpers"

/**
 * A mapping of blog IDs to custom "from" emails.
 * These blogs will send emails from a custom email address
 * instead of the default newsletter.paragraph.xyz address. Note that
 * these NEED to be configured in SendGrid via domain authentication
 * before these can actually work.
 */
const BLOG_EMAIL_MAPPING: { [key: string]: string } = {
  // Flooz
  czawqZzaSNfEe7rQ8pOw: "no-reply@flooz.xyz",
}

export function removeAtSign(relativeUrl: string) {
  if (relativeUrl.indexOf("@") == 0) {
    return relativeUrl.substring(1)
  }

  return relativeUrl
}

export function addAtSignIfNotPresent(relativeUrl: string) {
  if (relativeUrl.indexOf("@") !== 0) {
    return `@${relativeUrl}`
  }

  return relativeUrl
}

export function isValidBlog(blogName: string): boolean {
  if (!blogName) return false
  if (blogName.substring(0, 1) !== "@") return false
  return true
}

/*
 * Note text sometimes has erroneous markdown formatting, with
 * problematic newlines. This strips them.
 */
export function formatNoteText(noteText?: string): string {
  const text = noteText?.replace(/\\\n/g, "\n") || noteText || ""
  return text
}

// TODO: Also support other types of content (like headers & images).
export function generateParagraphsFromHtml(html?: string): Array<string> {
  if (typeof html !== "string") {
    return []
  }
  // Remove divs with the class 'twitter-embed' (and their contents)
  let previousHtml
  do {
    previousHtml = html
    html = html.replace(
      /<div class="[^"]*twitter-embed[^"]*">[\s\S]*?<div class="twitter-footer">[\s\S]*?<\/div>[\s\S]*?<\/div>/gi,
      ""
    )
  } while (html !== previousHtml)

  // Find all content within <p> tags
  const paragraphRegex = /<p[^>]*>(.*?)<\/p>/g
  const paragraphs = []
  let match
  while ((match = paragraphRegex.exec(html)) !== null) {
    // Remove any HTML tags within the paragraph content
    const textContent = match[1].replace(/<\/?[^>]+(>|$)/g, "")

    // Decode HTML entities
    const decodedText = textContent
      .replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&quot;/g, '"')
      .replace(/&apos;/g, "'")
      .replace(/&#39;/g, "'")
      .replace(/&nbsp;/g, " ")

    // May be empty text; if so don't push
    if (decodedText) {
      paragraphs.push(decodedText)
    }
  }

  return paragraphs
}

export function stripHtml(html?: string): string {
  if (typeof html !== "string") {
    // If html is not a string, return an empty string
    return ""
  }

  let strippedHtml = html.replace(/<\/?[^>]+(>|$)/g, "")

  // Decode HTML entities
  strippedHtml = strippedHtml
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&quot;/g, '"')
    .replace(/&#39;/g, "'")
  return strippedHtml
}

// This is either a Note,
// or a string of HTML. The HTML needs to be generated on the client-side.
export function generatePostPreview(
  noteOrHtml?: Note | string,
  // The ... will bring us to the 140 character limit
  chars = 137
): string {
  if (!noteOrHtml) return ""

  const truncate = (n: number, str?: string) =>
    str && str.length > n ? str.substr(0, n) + "..." : str

  // If this is HTML and/or markdown...
  if (typeof noteOrHtml === "string") {
    // Truncate to first after gate (if exists)
    const afterGateIndex = noteOrHtml.search(AFTER_GATE_REGEX)
    const html =
      afterGateIndex !== -1 ? noteOrHtml.slice(0, afterGateIndex) : noteOrHtml
    const strippedHtml = stripHtml(html)
    const text = truncate(chars, strippedHtml) || ""

    return text
  }

  // We use the ?? operator here since someone could manually set an empty string in the post_preview purposely,
  // and we don't want to override that.
  return (
    // Use a high enough number to ensure that the preview is always truncated
    // to the desired length.
    truncate(600, noteOrHtml.post_preview) ??
    generatePostPreview(noteOrHtml.staticHtml, chars) ??
    ""
  )
}

export function encodeEmailAddressFromUrl(url: string): string {
  const removedAtSignUrl = removeAtSign(url)

  // Need to encode this. If the URL has spaces in it, for example,
  // then Sendgrid will reject the emails
  return encodeURIComponent(removedAtSignUrl)
}

export function emailAddressToBlogUrl(emailAddress: string): string {
  const decoded = decodeURIComponent(emailAddress)
  return `@${decoded}`
}

/*
 * Creates a properly-formatted FROM email address from a blog's
 * URL.
 */
export function createEmailAddressFromBlog(blog: Blog): string {
  if (blog.id && BLOG_EMAIL_MAPPING[blog.id]) {
    return BLOG_EMAIL_MAPPING[blog.id]
  }

  const encoded = encodeEmailAddressFromUrl(blog.url)

  return `${encoded || "newsletter"}@newsletter.paragraph.xyz`
}

// Validates an email using a regex function. Returns true for valid emails and false for invalid ones.
export function validateEmail(email?: string): boolean {
  const validEmail = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )

  return validEmail.test(email?.toLowerCase() || "")
}

// Classes applied to editor contents (used in TipTap configuration)
export function timestampToHumanReadable(unixTimestamp: number): string {
  // Convert the UNIX timestamp to a JavaScript Date object
  const date = new Date(unixTimestamp * 1000)

  // Define the month names
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  // Get the day, month and year from the date
  const day = date.getDate()
  const month = months[date.getMonth()]
  const year = date.getFullYear()

  // Return the formatted date
  return `${month} ${day}, ${year}`
}

export function capitalizeFirstLetter(input: string): string {
  return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase()
}

/**
 * Gets the share image for a publication (NOT a post).
 *
 * This is used for the OpenGraph image on Twitter, Facebook,
 * as well as a frame on Farcaster.
 */
export function getShareImage({
  image,
  title,
  description,
  color,
  showSubscribeBtn = true,
}: {
  image?: string
  title?: string
  description?: string
  color?: string
  showSubscribeBtn?: boolean
}): string {
  // Truncate if needed
  title = title && title?.length > 34 ? title?.substring(0, 34) + "..." : title

  const encodedTitle = encodeURIComponent(title || "")
  const encodedDescription = encodeURIComponent(description || "")
  const encodedColor = encodeURIComponent(color || "")
  const encodedImage = encodeURIComponent(image || "")

  const domain = baseDomain()

  const share_img = `${domain}/api/share_img?img=${encodedImage}&title=${encodedTitle}&description=${encodedDescription}&primaryColor=${encodedColor}&showSubscribeBtn=${showSubscribeBtn}`
  return share_img
}

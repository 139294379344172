/* This example requires Tailwind CSS v2.0+ */

import { EnrichedGatingRule } from "@/types/gatingRules"

import { LockClosedIcon, LockOpenIcon } from "@heroicons/react/24/outline"
import { Dispatch, SetStateAction } from "react"
import SubscribeButton from "../SubscribeButton"
import { isMembershipGate } from "@/util/gatingRules"
import useColor from "hooks/useColor"
import { Membership } from "@types"
import { isFreeMembership } from "components/Memberships/SharedFunctions"
import Button from "components/Button"
import Link from "next/link"
import { selectBlog } from "features/blogSlice"
import { useSelector } from "react-redux"

type Props = {
  gatingRule: EnrichedGatingRule
  membership: Membership | undefined
  setIsError: Dispatch<SetStateAction<boolean>>
  setError: Dispatch<SetStateAction<Error | null>>
}

export default function GatingRuleShortSummaryRowSubscription({
  gatingRule,
  membership,
  setIsError,
  setError,
}: Props) {
  const blog = useSelector(selectBlog)
  const color = useColor(false)
  const isFreeMembershipConst =
    (membership && isFreeMembership(membership)) || false

  return (
    <div className="rounded-lg focus:outline-none w-84 md:w-96 md:py-2">
      <div className="flex flex-col">
        <div className="flex">
          <div className="my-auto">
            {gatingRule.hasAccess && (
              <span className="rounded-lg inline-flex px-2">
                <LockOpenIcon
                  className="w-4 h-4 md:w-5 md:h-5 text-green-500"
                  aria-hidden="true"
                />
              </span>
            )}
            {!gatingRule.hasAccess && (
              <span className="rounded-lg inline-flex px-2">
                <LockClosedIcon
                  className="w-4 h-4 md:w-5 md:h-5 text-red-500"
                  aria-hidden="true"
                />
              </span>
            )}
          </div>
          <div className="ml-3 flex flex-col justify-center h-10">
            <p
              className="text-sm font-medium"
              style={{ color: color.fontColor || "text-gray-900" }}
            >
              {membership?.name ||
                gatingRule.gatingRuleRequirementTypeDisplayTitle}
            </p>
          </div>
          <div className="my-auto ml-auto">
            {isMembershipGate(gatingRule) &&
              !gatingRule.hasAccess &&
              isFreeMembershipConst && (
                <SubscribeButton replaceClassName="inline-flex justify-center px-4 py-2 text-base font-medium text-white bg-blue-500 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 text-sm w-24 md:w-32" />
              )}
            {isMembershipGate(gatingRule) &&
              !gatingRule.hasAccess &&
              !isFreeMembershipConst && (
                <Link href={`/${blog.url}/memberships`}>
                  <Button
                    text="Purchase"
                    type="button"
                    className="flex justify-center items-center"
                  />
                </Link>
              )}
          </div>
        </div>
      </div>
    </div>
  )
}

import { Node } from "@tiptap/core"
import { Editor, NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react"
import GateModal from "components/GateWizardModal/GateModal"
import { MdDelete, MdLock, MdLockOpen } from "react-icons/md"
import { useSelector } from "react-redux"
import { deleteGatingRuleGroupByPostIdAndEmbedId } from "features/gatingRuleGroupsSlice"
import { preventAfterGateDeletion } from "./utilities"
import { selectCurrentNote, selectLatestCurrentNote } from "features/noteSlice"
import GatingRulesShortSummary from "components/ReaderGates/GatingRulesShortSummary"
import { Note } from "@types"
import useGatingRulesForReader from "hooks/useGatingRulesForReader"
import { Plugin } from "@tiptap/pm/state"
import SubscribeButton from "../../../components/SubscribeButton"
import useSubscribeModalState from "hooks/useSubscribeModalState"
import { useAppDispatch } from "store"
import { ReaderGateEmbed } from "components/ReaderGates/ReaderGate"

export interface AfterGateOptions {
  HTMLAttributes: Record<string, any>
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    afterGate: {
      setAfterGate: (options?: { embedid?: string }) => ReturnType
    }
  }
}

export const AfterGate = Node.create<AfterGateOptions>({
  name: "afterGate",
  group: "block",
  draggable: true,
  atom: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  addAttributes() {
    return {
      embedid: {
        default: Math.random().toString(16).slice(2),
        parseHTML: (element) =>
          element.getAttribute("embedId") || element.getAttribute("embedid"),
      },
      isActive: {
        default: false,
      },
      open: {
        default: null,
      },
      unlocked: {
        default: false,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ]
  },

  addCommands() {
    return {
      setAfterGate:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: { embedid: options?.embedid },
          })
        },
    }
  },

  addKeyboardShortcuts() {
    return {
      Backspace: preventAfterGateDeletion,
      "Mod-Backspace": preventAfterGateDeletion,
      Delete: preventAfterGateDeletion,
      "Mod-Delete": preventAfterGateDeletion,
    }
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "div",
      {
        "data-type": this.name,
        embedid: HTMLAttributes.embedid || HTMLAttributes.embedId,
      },
    ]
  },

  addNodeView() {
    return ReactNodeViewRenderer(GateModalEditorUI)
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        // Don't allow a nested after gate
        filterTransaction(tr) {
          let allowTransaction = true
          tr.doc.descendants((node: any, _pos: any, parent: any) => {
            if (node.type.name === "afterGate" && parent?.type.name !== "doc")
              allowTransaction = false
          })
          return allowTransaction
        },
      }),
    ]
  },
})

type Props = {
  isActive?: boolean
  embedid?: string
  open: boolean
  node: any
  updateAttributes: (a: any) => void
  editor: Editor
  deleteNode: () => void
}

/**
 * This is the UI for the after gate.
 */
const GateModalEditorUI = ({
  editor,
  node,
  updateAttributes,
  deleteNode,
}: Props) => {
  const { embedid, isActive, open, unlocked } = node.attrs
  const dispatch = useAppDispatch()
  const note = useSelector(selectLatestCurrentNote)
  const currNote = useSelector(selectCurrentNote)
  const gatingRuleGroups = useGatingRulesForReader(
    currNote?.id || "",
    "AFTER",
    embedid,
    "[noteId]"
  )

  const { userHasActiveSubscription } = useSubscribeModalState()

  console.log("MEMBERSHIP CHECK: GatingRules ", gatingRuleGroups)

  const deleteDisabled = note?.published

  const deleteGate = async () => {
    if (isActive && note?.id)
      await dispatch(deleteGatingRuleGroupByPostIdAndEmbedId(note.id, embedid))
    deleteNode()
  }

  const isMembershipGate = gatingRuleGroups?.some((group) =>
    group.gatingRules.some(
      (rule) => rule.gatingRequirement.gateType === "MEMBERSHIP"
    )
  )

  // Display the membership gate embed if the user does not have access and this is a membership gated post.
  if ((!editor || !editor.isEditable) && !unlocked && isMembershipGate) {
    /**
     * NOTE: If the styling or text of this changes at all, we need
     * to update the newsletter after gate in util/gating-rules.ts
     */
    return (
      <NodeViewWrapper className="react-component not-prose">
        <div className="bg-primary p-10 w-full rounded-md shadow-lg">
          <p className="text-xl text-center mb-8 font-medium tracking-tight">
            You need to be a member to read the rest of this post.
          </p>
          <div className="flex justify-center">
            <SubscribeButton
              className="h-14 text-xl bg-secondary shadow-md hover:bg-secondary-500 text-custom-700 hover:text-custom-500 transition-all duration-150 tracking-tight"
              // Skip directly to the memberships section.
              //defaultState="MEMBERSHIPS"
              gatingRuleGroups={gatingRuleGroups}
              useSecondaryTheme
              overrideText={
                userHasActiveSubscription
                  ? "Upgrade your Membership"
                  : "Become a Member"
              }
            />
          </div>
        </div>
      </NodeViewWrapper>
    )
  }

  // Display the token gate embed if the user does not have access and this is a token gated post.
  if ((!editor || !editor.isEditable) && !unlocked && !isMembershipGate) {
    return (
      <NodeViewWrapper className="react-component not-prose pt-28">
        <ReaderGateEmbed
          noteId={currNote?.id || ""}
          gateContentType={"AFTER"}
          embedId={embedid}
        />
      </NodeViewWrapper>
    )
  }

  // If the user does have access, display a short summary of why.
  if ((!editor || !editor.isEditable) && unlocked) {
    return (
      <NodeViewWrapper className="react-component not-prose">
        <div className="my-auto group">
          <div className="">
            <div className="flex items-center">
              <div className="flex-1">
                <hr />
              </div>
              <div className="p-4 text-2xl">
                <MdLockOpen />
              </div>
              <div className="flex-1">
                <hr />
              </div>
            </div>
          </div>
          {/* Show a short summary of why the user has access if they do have access and there are gates setup. */}
          <div
            className="invisible group-hover:visible absolute left-0 right-0 max-w-md mx-auto z-10 
                                transition-all duration-500 opacity-0 group-hover:opacity-100"
          >
            <GatingRulesShortSummary
              note={currNote as Note}
              gatingRuleGroups={gatingRuleGroups || []}
              isEmbed
            />
          </div>
        </div>
      </NodeViewWrapper>
    )
  }

  // If the author is editing the post, show the lock icon that gives access to the editable gate modal.
  return (
    <NodeViewWrapper className="react-component not-prose" data-drag-handle>
      <div className="flex items-center">
        <div className="flex-1 pl-4">
          <hr />
        </div>
        <div
          className="p-4 text-2xl cursor-pointer hover:text-gray-500"
          onClick={() => updateAttributes({ open: true })}
        >
          {isActive ? <MdLock className="" /> : <MdLockOpen />}
        </div>
        <div className="flex-1">
          <hr />
        </div>
        <button
          className={`p-4 text-2xl ${
            deleteDisabled
              ? "text-gray-200"
              : "cursor-pointer hover:text-gray-500"
          }`}
          onClick={() => deleteGate()}
          // Don't allow delete action if published note
          disabled={deleteDisabled}
        >
          <MdDelete />
        </button>
      </div>

      <GateModal
        open={open === null || open}
        setOpen={(open) => updateAttributes({ open })}
        gateContentType={"AFTER"}
        embedId={embedid}
        callbackSetIsActive={(isActive: boolean) => {
          updateAttributes({ isActive })
        }}
      />
    </NodeViewWrapper>
  )
}

export default AfterGate
function detectMembershipsToDisableDueToInsufficientPermissions(
  arg0: any,
  arg1: import("@/types/gatingRules").EnrichedGatingRuleGroup[]
) {
  throw new Error("Function not implemented.")
}

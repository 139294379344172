import { CommandSuggestionItem } from "components/Editor/Suggestion/items"
import React, {
  BaseSyntheticEvent,
  cloneElement,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import { MdImage } from "react-icons/md"
import clsx from "clsx"
import { Editor } from "@tiptap/react"
import { convertFileToBase64 } from "../Image/utils"

export type CommandListRef = {
  onKeyDown: (o: { event: KeyboardEvent }) => boolean
}
export type CommandListProps = {
  items: CommandSuggestionItem[]

  command: any
  editor: Editor
  range: any
}
const CommandList = forwardRef<CommandListRef, CommandListProps>(
  (props, ref) => {
    const imageInput = useRef<HTMLInputElement | null>(null)

    const handleFileChange = async (event: BaseSyntheticEvent) => {
      try {
        const src = (await convertFileToBase64(event.target.files[0])) as string
        props.editor
          .chain()
          .focus()
          .deleteRange(props.range)
          .setFigure({ src })
          .run()
      } catch (e) {
        console.error("Failed to convert image to base64: ", e)
      }
    }

    const [selectedIndex, setSelectedIndex] = useState(0)
    const selectItem = (index: number) => {
      const item = props.items[index]
      console.log("COMMAND INDEX: ", index, item)
      if (!item) {
        console.error("Invalid command list index: ", index)
        return
      }

      if ("title" in item && item.title === "Image") {
        imageInput.current?.click()
      } else if (item) {
        props.command(item)
      }
    }

    const containerRef = useRef<HTMLDivElement>(null)
    useEffect(() => setSelectedIndex(0), [props.items])
    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }) => {
        if (event.key === "ArrowUp") {
          setSelectedIndex(
            (selectedIndex + props.items.length - 1) % props.items.length
          )
          return true
        }

        if (event.key === "ArrowDown") {
          setSelectedIndex((selectedIndex + 1) % props.items.length)
          return true
        }

        if (event.key === "Enter") {
          selectItem(selectedIndex)
          return true
        }

        return false
      },
    }))
    useEffect(() => {
      const $div = containerRef.current
      if (!$div) {
        return
      }
      const $ele = $div.querySelector(
        `[data-index="${selectedIndex}"]`
      ) as HTMLButtonElement
      if (!$ele) {
        return
      }
      const top = $div.scrollTop

      const min = $ele.offsetTop
      if (min < top) {
        $div.scrollTop = min
        return
      }
      const max = min + $ele.clientHeight
      const h = $div.clientHeight
      if (max > top + h) {
        $div.scrollTop = max - h
        return
      }
    }, [selectedIndex])
    return (
      <>
        <div
          ref={containerRef}
          className="z-50 items relative flex flex-col shadow-lg rounded border w-80 h-80 overflow-y-auto p-1  bg-white"
        >
          {props.items.length ? (
            props.items.map((item, index) => (
              <button
                type={"button"}
                className={clsx(
                  `item ${
                    index === selectedIndex
                      ? "is-selected bg-slate-100"
                      : "hover:bg-slate-50"
                  }`,
                  "flex rounded p-1 gap-2 "
                )}
                key={index}
                data-index={index}
                onClick={() => selectItem(index)}
              >
                <div
                  className={
                    "w-12 h-12 p-2 border border-slate-300 rounded text-slate-400 shrink-0"
                  }
                >
                  {!item.icon && <MdImage className={"h-full w-full"} />}
                  {item.icon &&
                    cloneElement(item.icon, {
                      className: clsx(
                        "h-full w-full",
                        item.icon.props.className
                      ),
                    })}
                </div>
                <div className={"flex flex-col "}>
                  <span className="self-start font-medium text-sm text-slate-800">
                    {item.title}
                  </span>
                  <span className={"text-slate-500 truncate text-xs"}>
                    {item.description}
                  </span>
                </div>
              </button>
            ))
          ) : (
            <>
              <div className="item mx-auto my-auto text-slate-500">
                No actions
              </div>
            </>
          )}
        </div>

        <input
          className="hidden"
          type="file"
          onChange={(e) => handleFileChange(e)}
          ref={imageInput}
        />
      </>
    )
  }
)

CommandList.displayName = "CommandList"

export default CommandList

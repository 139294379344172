// Used to display AND and OR groups of gating rules in the modal.

import { isMembershipGate, isTokenGate } from "@/util/gatingRules"
import { Dialog } from "@headlessui/react"
import { PlusCircleIcon, TrashIcon } from "@heroicons/react/24/outline"
import {
  EnrichedGatingRule,
  Token,
  GatingRequirementToken,
  EnrichedGatingRuleGroup,
  GatingRequirementMembership,
} from "@/types/gatingRules"
import { selectUsersTokens } from "features/blogSlice"
import {
  deleteGatingRuleById,
  deleteGatingRuleGroupById,
} from "features/gatingRuleGroupsSlice"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "store"
import {
  GateModalStep,
  StepDefinitionGateType,
  StepDefinitionGroup,
} from "./GateModalSteps"
import clsx from "clsx"
import { SharedProps } from "./SharedProps"
import { Membership } from "@types"
import { selectBlogMemberships } from "features/membershipSlice"
import { ShouldShowAddGateButton } from "./GateModalHelperFunctions"
import Button from "components/Button"
import Spinner from "components/Spinner"

const bgColor = "bg-white border border-1 border-gray-300"

type GateGroupElements = {
  groupId: string
  elements: JSX.Element[]
}

export default function GateModalStepGroup(props: SharedProps) {
  const dispatch = useAppDispatch()
  const memberships = useSelector(selectBlogMemberships)
  const tokens = useSelector(selectUsersTokens)
  const [groupElements, setGroupElements] = useState<GateGroupElements[]>([])
  const [isDeleting, setIsDeleting] = useState(false)

  const deleteGate = async (groupId: string, deleteExistingGateId: string) => {
    setIsDeleting(true)

    console.log(
      `Deleting gating rule with ID ${deleteExistingGateId} from group with ID ${groupId}`
    )

    // First, let's check if this is the last gate in this group. If it is, let's delete the entire group and not just this gate.
    const group = props.existingGateGroups.find((g) => g.id == groupId)
    if (group?.gatingRules && group.gatingRules.length <= 1) {
      await dispatch(deleteGatingRuleGroupById(groupId))
      if (props.callbackSetNewGroupId) props.callbackSetNewGroupId(null)
    } else {
      await dispatch(deleteGatingRuleById(groupId, deleteExistingGateId))
    }

    props.setSelectedGateGroupId("")

    setIsDeleting(false)
  }

  useEffect(() => {
    const newGroupElements: GateGroupElements[] = []

    props.existingGateGroups.map((g) => {
      const elements: JSX.Element[] = []

      g.enrichedGatingRules.map((gr) => {
        elements.push(
          ExistingGateComponent(
            g,
            gr,
            memberships || [],
            tokens || [],
            deleteGate,
            isDeleting
          )
        )
      })

      // At the end of every group, add an "ADD" button so user can add to the gates for that group.
      // (But only if there's not already a paid membership within that group, since a paid membership gate
      // cannot be combined with any token gates, and you can only have one membership per AND group.)
      const [showAddMoreBtnForTokens, gateDoesntExistYetForTokens] =
        ShouldShowAddGateButton(
          props.existingGateGroups,
          "TOKEN",
          memberships,
          g.id
        )
      const [showAddMoreBtnForMemberships, gateDoesntExistYetForMemberships] =
        ShouldShowAddGateButton(
          props.existingGateGroups,
          "MEMBERSHIP",
          memberships,
          g.id
        )

      // If we wouldn't be showing the add button for either tokens or memberships within the gate type selection page,
      // then don't show it at all at this stage either so the user can't get to an empty screen.
      if (showAddMoreBtnForTokens || showAddMoreBtnForMemberships) {
        elements.push(
          andRowComponent(
            g.id,
            props.setAddingGate,
            props.changeStep,
            props.setSelectedGateGroupId
          )
        )
      }

      newGroupElements.push({
        groupId: g.id,
        elements: elements,
      })
    })

    setGroupElements(newGroupElements)
  }, [tokens, props.existingGateGroups, isDeleting])

  return (
    <div>
      <div>
        <div className="mt-3 sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-2xl font-bold text-center text-gray-900 leading-6"
          >
            Gate your post
          </Dialog.Title>
          <div className="mt-6">
            <p className="text-gray-600 text-base">
              Setup a gate so that readers must meet some requirement to view
              this post.{" "}
              <a
                href="/settings/publication/memberships-token-gating"
                target="_blank"
                className=" text-blue-500 font-bold hover:underline cursor-pointer "
              >
                Read more or edit memberships and tokens here.
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className="text-gray-600 mt-4">
        You're requiring the following gates to view this content.
        <div
          className={`mt-2 overflow-auto ${
            props.existingGateGroups?.flatMap((g) => g.gatingRules).length < 3
              ? "h-72"
              : "h-104"
          }`}
        >
          {/* List of gating rule groups. */}
          {props.existingGateGroups?.map((group, groupIndex) => (
            <div key={group.id}>
              <div className="border border-1 border-gray-300 shadow-md rounded-lg">
                <div
                  className={clsx(
                    group.gatingRules && group.gatingRules.length > 0
                      ? "px-2 rounded-lg"
                      : ""
                  )}
                >
                  {/* Dynamic list of gates within each group. */}
                  <div className="flex justify-center items-center pt-2 pb-1">
                    <div className="w-full">
                      {
                        groupElements.find((g) => g.groupId == group.id)
                          ?.elements
                      }
                    </div>
                  </div>
                </div>
              </div>
              {groupIndex < props.existingGateGroups.length && (
                <div className="relative flex justify-center my-2">
                  <div className="absolute top-3 z-0 border-b border-dotted border-b-1 border-b-gray-600 w-72 sm:w-96"></div>
                  <div className="z-10 bg-white w-12 flex justify-center">
                    OR
                  </div>
                </div>
              )}
            </div>
          ))}
          <div className="mt-2">
            {andRowComponent(
              "",
              props.setAddingGate,
              props.changeStep,
              props.setSelectedGateGroupId
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

function ExistingGateComponent(
  group: EnrichedGatingRuleGroup,
  gatingRule: EnrichedGatingRule,
  memberships: Membership[],
  tokens: Token[],
  deleteGate: (groupId: string, deleteExistingGateId: string) => void,
  isDeleting: boolean
): JSX.Element {
  const membership = getMembershipById(gatingRule, memberships)
  const token = getTokenById(gatingRule, tokens)

  return (
    <div key={gatingRule.id} className="rounded-lg shadow-sm h-14">
      <div className={clsx("rounded-lg border-gray-200 shadow-sm", bgColor)}>
        <div className="justify-between h-full flex items-center">
          <div className="h-full font-medium text-base text-gray-700 px-3 py-3 truncate">
            {isMembershipGate(gatingRule) && (
              <span>
                a {membership && membership.name}{" "}
                {gatingRule.gatingRuleRequirementTypeDisplayMessageForAuthor}
              </span>
            )}
            {isTokenGate(gatingRule) && (
              <span>
                <span className="text-gray-400">
                  {`${
                    (gatingRule.gatingRequirement as GatingRequirementToken)
                      .tokenMinCount
                  }x `}
                </span>
                {token && token.onChainData.name}
              </span>
            )}
          </div>
          <div className="border-l border-l-1 border-l-gray-300 px-0.5">
            <div className="py-1 px-2 bg-white rounded-b-lg">
              <div className="flex justify-center p-1 items-center text-red-600">
                <Button
                  replaceClassName={clsx(
                    "border border-red-600 rounded-lg px-2 py-1 items-center justify-center flex hover:bg-red-600 hover:text-white shadow-sm cursor-pointer",
                    "disabled:opacity-50 disabled:cursor-not-allowed"
                  )}
                  onClick={() => {
                    if (isDeleting) return

                    deleteGate(group.id, gatingRule.id)
                  }}
                  disabled={isDeleting}
                >
                  {isDeleting ? (
                    <div className="w-5 h-5 flex justify-center items-center pl-2">
                      <Spinner width={4} height={4} />
                    </div>
                  ) : (
                    <TrashIcon className="w-5 h-5" />
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function andRowComponent(
  groupId: string,
  setAddingGate: Dispatch<SetStateAction<boolean>>,
  changeStep: (id: string, proposedSteps?: GateModalStep[]) => void,
  setSelectedGateGroupId: Dispatch<SetStateAction<string | undefined>>
): JSX.Element {
  return (
    <div key="ADD" className="rounded-lg h-14">
      <div
        className={clsx(
          "rounded-lg border-dashed border-2 border-gray-200 shadow-sm flex justify-center",
          bgColor
        )}
      >
        <div className="justify-between h-full flex items-center py-1">
          <div className="p-1 text-green-600">
            <div
              className="border border-green-600 rounded-lg px-2 py-1 items-center flex hover:bg-green-600 hover:text-white shadow-sm cursor-pointer"
              onClick={async () => {
                setAddingGate(true)
                setSelectedGateGroupId(groupId)
                changeStep("GATE_TYPE", [
                  StepDefinitionGroup,
                  StepDefinitionGateType,
                ])
              }}
            >
              <PlusCircleIcon className="w-5 h-5 mr-1" />
              <span className="text-sm">Add</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function getTokenById(
  gr: EnrichedGatingRule,
  tokens?: Token[]
): Token | undefined {
  if (gr.gatingRequirement.gateType != "TOKEN") return

  const tokenId = (gr.gatingRequirement as GatingRequirementToken).tokenId

  return tokens?.find((t) => t.id == tokenId)
}

function getMembershipById(
  gr: EnrichedGatingRule,
  memberships?: Membership[]
): Membership | undefined {
  if (gr.gatingRequirement.gateType != "MEMBERSHIP") return

  const membershipId = (gr.gatingRequirement as GatingRequirementMembership)
    .membershipId

  return memberships?.find((m) => m.id == membershipId)
}

type ChildDetailsProps = {
  text: string
  title?: string
}

export default function ChildDetails(props: ChildDetailsProps) {
  const { title, text } = props

  return (
    <div className="pointer-events-auto flex flex-col w-full max-w-md">
      <div className="flex items-start">
        <div className="ml-3 w-0 flex-1">
          {title && <p className="font-medium text-gray-900">{title}</p>}
          <p className="mt-1 text-md text-gray-500">{text}</p>
        </div>
      </div>
    </div>
  )
}

import { axios, axiosKeepAlive, params } from "./resolve"
import { AxiosResponse } from "axios"
import { ErrorResponse, isErrorResponse } from "@types"
import { PostMetricsResp, RevenueMetricDisplaySummary } from "@/types/metrics"

const API = "/metrics"

/**
 * Retrieves metrics for all of the latest posts for the logged in user's blog from the API.
 * @returns Array of PostMetricsResp objects.
 */
export async function getPostMetricsForBlog(): Promise<PostMetricsResp[]> {
  const res: AxiosResponse<PostMetricsResp[] | ErrorResponse> = await axios.get(
    `${API}/posts/blog`,
    params
  )

  if (isErrorResponse(res?.data)) {
    console.error("Error retrieving blog's post metrics from API.", res.data)
    return []
  }

  return res?.data || []
}

// Increments the view count, or increments post metric readTime after a user has exited a page.
// In the future this can update more metrics.
export async function upsertPostMetric(
  blogId: string,
  postId: string,
  incrementPostView: boolean,
  incrementReadTime?: number
): Promise<void> {
  const res: AxiosResponse<ErrorResponse> = await axiosKeepAlive.post(
    `${API}/post-track`,
    { blogId, postId, incrementPostView, incrementReadTime }
  )

  if (isErrorResponse(res?.data)) {
    console.error("Error storing read time post metric.", res.data)
  }
}

/**
 * Retrieves revenue metrics for the logged in user's blog from the API.
 * @returns A display summary of the revenue metrics.
 */
export async function getRevenueMetricsForBlog(): Promise<RevenueMetricDisplaySummary | null> {
  const res: AxiosResponse<RevenueMetricDisplaySummary | ErrorResponse> =
    await axios.get(`${API}/revenue/blog`, params)

  if (isErrorResponse(res?.data)) {
    console.error("Error retrieving blog revenue metrics from API.", res.data)
    return null
  }

  return res?.data || null
}

/**
 * Retrieves metrics for an array of posts for the logged in user from the API.
 * @param postIds this is a string array of unique post IDs in the Firestore DB.
 * @returns
 */
export async function getPostMetrics(
  postIds: string[]
): Promise<PostMetricsResp[]> {
  const res: AxiosResponse<PostMetricsResp[] | ErrorResponse> = await axios.get(
    `${API}/posts?${postIds.map((n) => `postIds=${n}`).join("&")}`,
    params
  )

  if (isErrorResponse(res?.data)) {
    console.error("Error retrieving post metrics from API.", res.data)
    return []
  }

  return res?.data || []
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  refreshUser as apiRefreshUser,
  logoutUser as apiLogoutUser,
  loginUser as apiLoginUser,
  loginUnstoppableDomainsUser as apiLoginUnstoppableDomainsUser,
  newUser as apiNewUser,
  getTeamBlogs as apiGetTeamBlogs,
  getUsersSubscriptionToBlog as apiGetUsersSubscriptionToBlog,
  getAllUsersSubscriptions as apiGetAllUsersSubscriptions,
  disconnectWallet,
} from "api_routes/user"
import Cookies from "js-cookie"

import { analytics } from "hooks/useAnalytics"
import { Thunk, RootState, AppDispatch } from "../store"
import * as api from "api_routes/api"

import { removeSubscriber } from "features/blogSlice"

import { getCreatorReferrer, removeActiveBlogId } from "util/cookies"

import jwt_decode from "jwt-decode"

import {
  isErrorResponse,
  Subscription,
  Blog,
  BlogSubscriptionAndMembership,
  ErrorResponse,
} from "@types"

import {
  User,
  emptyUser,
  UserParams,
  UserParamsEmail,
  CryptoUser,
} from "@/types/users"

import { setError } from "./errorSlice"
import { getDomainName } from "@/util/domain"
import { setColorMode } from "./pageSlice"

// Retrieve the user info in the encoded JWT.
// Set is as the state, by default!
export const defaultUser = (): User => {
  const token = Cookies.get("jwt")

  console.log("GETTING DEFAULT USER", token)
  if (!token) {
    return emptyUser
  }
  const decoded = jwt_decode<User>(token)
  return decoded
}

interface UserState {
  user: User
  subscription?: Subscription | false // The user's subscription to the blog. Undefined = not finished loading. False = no subscription.
  allSubscriptions?: Array<BlogSubscriptionAndMembership> // All the user's subscriptions to all other blogs. Used in the user's Settings page.

  teamBlogs?: Array<Blog> // The blogs that the user is also members of (other than their own).
  // Whenever the user is performing some on-chain transaction, this gets updated.
  // Currently just used to monitor changes to the user's wallet.
  currentTransactionHash: string | null
}

const initialState: UserState = {
  user: defaultUser(),
  currentTransactionHash: null,
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      console.log("Setting user!", action.payload)
      state.user = action.payload
    },
    setSubscription: (state, action: PayloadAction<Subscription | false>) => {
      state.subscription = action.payload
    },

    setTeamBlogs: (state, action: PayloadAction<Array<Blog>>) => {
      state.teamBlogs = action.payload
    },

    removeSubscription: (state, action: PayloadAction<string>) => {
      const id = state.allSubscriptions?.findIndex(
        (s) => s.subscription.id === action.payload
      )

      console.log("Removing subscription", action.payload, id)

      if (id === undefined || id === -1) return
      state.allSubscriptions?.splice(id, 1)
    },

    setAllSubscriptions: (
      state,
      action: PayloadAction<Array<BlogSubscriptionAndMembership>>
    ) => {
      state.allSubscriptions = action.payload
    },

    setCurrentTransactionHash: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.currentTransactionHash = action.payload
    },
  },
})

// Publicly accessible, within components.
export const { setUser, setSubscription } = userSlice.actions

// Privately accessible, only within Thunks in this file.
const {
  setTeamBlogs,
  removeSubscription,
  setAllSubscriptions,
  setCurrentTransactionHash,
} = userSlice.actions

export const selectUser = (state: RootState) => state.user.user
export const selectSubscription = (state: RootState) => state.user.subscription

export const selectActiveSubscription = (state: RootState) =>
  state.user.subscription && state.user.subscription.status === "ACTIVE"
    ? state.user.subscription
    : undefined

export const refreshUser = () => async (dispatch: AppDispatch) => {
  const user = await apiRefreshUser()

  dispatch(setUser(user))
}

export const getUsersSubscriptionToBlog =
  (blogId: string): Thunk<void> =>
  async (dispatch, getState) => {
    const userLoggedIn = !!getState().user.user.id

    // If user is not logged in, we want to set the subscription to false.
    // By default it's undefined which means it's loading.
    if (!userLoggedIn) {
      dispatch(setSubscription(false))
      return
    }

    const sub = await apiGetUsersSubscriptionToBlog(blogId)

    if (isErrorResponse(sub)) {
      dispatch(setSubscription(false))
    } else {
      dispatch(setSubscription(sub || false))
    }
  }

export const getAllUsersSubscriptions = () => async (dispatch: AppDispatch) => {
  const subscriptionsResp = await apiGetAllUsersSubscriptions()
  dispatch(setAllSubscriptions(subscriptionsResp))

  analytics.identify(analytics.user("userId"), {
    totalSubscriptions: subscriptionsResp?.length || 0,
  })
}

// Retrieve the blogs that the user has access to (other than their own).
export const getTeamBlogs = () => async (dispatch: AppDispatch) => {
  const teamBlogs = await apiGetTeamBlogs()
  if (teamBlogs && Array.isArray(teamBlogs)) {
    dispatch(setTeamBlogs(teamBlogs))
  }
}

export const logoutUser =
  (): Thunk<Promise<void>> => async (dispatch: AppDispatch) => {
    console.log("Logging out user...")
    await apiLogoutUser()

    const c = Cookies.get("jwt")
    console.log(c)
    Cookies.remove("jwt")
    removeActiveBlogId()

    // Remove JWT for main paragraph domain.
    Cookies.remove("jwt", {
      path: "/",
      domain: ".paragraph.xyz",
      secure: true,
      samesite: "none",
    })

    const domain = getDomainName(window.location.hostname)
    console.log("Removing cookie for custom domain ", domain)

    // Remove JWT for custom domain. If no cookie set on this custom domain
    // or if the domain URL is wrong, this just fails silently so no worries.
    Cookies.remove("jwt", {
      path: "/",
      domain: `.${domain}`,
      secure: true,
      samesite: "none",
    })

    dispatch(setUser(emptyUser))

    // This ensures we clear any dark mode settings that might be set for the user
    // that could negatively impact the user experience outside of the dashboard
    // where not everything is correctly themed for dark mode.
    dispatch(setColorMode("light"))
  }

export const createNewUser =
  (userParams: Omit<UserParamsEmail, "referrerWalletAddress">) =>
  async (dispatch: AppDispatch) => {
    const referrer = getCreatorReferrer()

    const paramsWithReferrer: UserParamsEmail = {
      ...userParams,
      referrerWalletAddress: referrer,
    }

    dispatch(setError({ msg: "" }))
    const userOrErr = await apiNewUser(paramsWithReferrer)

    if (isErrorResponse(userOrErr)) {
      console.log("Error upon creating new user")
      console.log(setError(userOrErr))
      dispatch(setError(userOrErr))
      return true
    }

    dispatch(setUser(userOrErr))
  }

export const loginUser =
  (userParams: UserParams) =>
  async (dispatch: AppDispatch): Promise<boolean> => {
    dispatch(setError({ msg: "" }))
    console.log("Logging in user!")
    const userOrErr = await apiLoginUser(userParams)

    console.log("Login user response:")
    console.log(userOrErr)

    if (isErrorResponse(userOrErr)) {
      console.log("Error response when user logged in")
      console.log(setError(userOrErr))
      dispatch(setError(userOrErr))

      return false
    }

    dispatch(setUser(userOrErr))
    return true
  }

export const loginUsersWallet =
  (userOrErr: User | ErrorResponse) => async (dispatch: AppDispatch) => {
    dispatch(setError({ msg: "" }))
    if (isErrorResponse(userOrErr)) {
      console.log("Error response when user logged in")
      console.log(setError(userOrErr))
      dispatch(setError(userOrErr))
      return false
    }

    dispatch(setUser(userOrErr))
    return true
  }

// This function can only be called for a user that has some other way to login too,
// and not just their wallet! Moreover, they must be authenticated in that other way
// to prove that they own the account.
export const disconnectUsersWallet =
  (user: User, wallet_address: string): Thunk<Promise<boolean>> =>
  async (dispatch: AppDispatch) => {
    const userHasWallet = "wallet_address" in user && !!user.wallet_address
    if (!userHasWallet) return false

    dispatch(setError({ msg: "" }))
    console.log(`Disconnecting wallet address ${wallet_address}`)

    // 1. Call API to remove wallet_address on user record in DB.
    const errOrNull = await disconnectWallet(wallet_address, user.id)

    if (isErrorResponse(errOrNull)) {
      console.log("Error response when user logged in")
      console.log(setError(errOrNull))
      dispatch(setError(errOrNull))
      return false
    }

    console.log(`Disconnect wallet address response: `, errOrNull)

    // 2. If that is successful, remove wallet_address from the user record in local storage.
    const updatedUser: User & Partial<CryptoUser> = { ...user }
    delete updatedUser.wallet_address
    dispatch(setUser(updatedUser))

    return true
  }

export const loginUnstoppableDomainsUser =
  (userParams: { usernameUnstoppable: string; email?: string }) =>
  async (dispatch: AppDispatch) => {
    dispatch(setError({ msg: "" }))
    console.log("Logging in UD user!")
    const userOrErr = await apiLoginUnstoppableDomainsUser(userParams)

    console.log("Login user response (UD):")
    console.log(userOrErr)

    if (isErrorResponse(userOrErr)) {
      console.log("Error response when UD user logged in")
      console.log(setError(userOrErr))
      dispatch(setError(userOrErr))
      return
    }

    dispatch(setUser(userOrErr))
  }

export const deleteSubscription =
  (subId: string): Thunk<Promise<boolean>> =>
  async (dispatch) => {
    await api.unsubscribeSubscriber(subId)
    dispatch(removeSubscription(subId))
    dispatch(removeSubscriber(subId))

    return true
  }

export const selectTeamBlogs = (state: RootState) => state.user.teamBlogs

export const selectAllUsersSubscriptions = (state: RootState) => {
  const subs = state.user.allSubscriptions?.slice()

  if (!subs) {
    return []
  }

  const filtered = subs?.sort((a, b) => {
    return b.subscription.createdAt - a.subscription.createdAt
  })

  return filtered
}

export const updateCurrentTransactionHash =
  (hash: string | undefined): Thunk<Promise<void>> =>
  async (dispatch: AppDispatch) => {
    dispatch(setCurrentTransactionHash(hash || null))
  }

export const selectCurrentTransactionHash = (state: RootState) => {
  return state.user.currentTransactionHash
}

// Updates the user's author details (such as their bio).
export const updateUserAuthorDetails =
  (user: User): Thunk<Promise<boolean>> =>
  async (dispatch) => {
    const userResp = await api.updateUserAuthorDetails(
      user.id,
      user.authorName,
      user.authorBio,
      user.social
    )
    if (isErrorResponse(userResp)) {
      dispatch(setError(userResp))
      return false
    }
    dispatch(setUser(user))

    return true
  }
export default userSlice.reducer

import { useEffect } from "react"

import { useAnalytics } from "hooks/useAnalytics"
import { useAccount } from "wagmi"
import Button from "./Button"
import { useSelector } from "react-redux"
import { selectError, setError } from "features/errorSlice"
import Banner from "./Banner"
import { useAppDispatch } from "store"

import { ConnectKitButton } from "connectkit"

export default function ConnectWalletButton({
  text,
  hide,
  showConnectedButton = false,
  hideError = false,
}: {
  text?: string
  hide?: boolean
  showConnectedButton?: boolean
  hideError?: boolean
}) {
  const { track } = useAnalytics()
  const accountData = useAccount()
  const errorMsg = useSelector(selectError)
  const dispatch = useAppDispatch()

  useEffect(() => {
    console.log("[useEffect in ConnectWalletBtn]", {
      hide,
      accountData,
    })
    if (hide || !accountData.isConnected) return

    track("wallet_connected", {
      address: accountData.address,
    })
  }, [accountData.isConnected])

  if (hide) {
    console.log("Hiding button")
    return null
  }

  if (errorMsg.msg && !hideError) {
    console.log("Error message", errorMsg.msg)
    return (
      <div className="flex justify-center flex-col space-y-2">
        <Banner type="error" text={errorMsg.msg} />
        <Button
          className="text-sm text-gray-400"
          onClick={() => dispatch(setError({ msg: "" }))}
        >
          OK
        </Button>
      </div>
    )
  }

  return (
    <div
      onClick={() => {
        track("wallet_connect_button_clicked")
      }}
    >
      {showConnectedButton ? (
        <Button>Your wallet is already connected</Button>
      ) : (
        <ConnectButton />
      )}
    </div>
  )
}

const ConnectButton = () => {
  return (
    <ConnectKitButton.Custom>
      {({ isConnected, isConnecting, show, hide, address, ensName, chain }) => {
        return (
          <Button
            type="button"
            onClick={show}
            className="hover:bg-blue-700 w-full mx-auto"
          >
            {isConnected ? address : "Connect Wallet"}
          </Button>
        )
      }}
    </ConnectKitButton.Custom>
  )
}

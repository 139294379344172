import { useState } from "react"
import ShareModal from "./ShareModal"
import Button from "components/Button"
import { useAnalytics } from "hooks/useAnalytics"

interface Props {
  className?: string
  replaceClassName?: string
  isEditable?: boolean
}

export default function ShareButton({
  replaceClassName,
  className,
  isEditable,
}: Props) {
  const [shareModalOpen, setShareModalOpen] = useState(false)
  const { track } = useAnalytics()

  return (
    <>
      <Button
        type="button"
        replaceClassName={replaceClassName}
        className={className}
        onClick={() => {
          // For editing view, disabled modal
          if (isEditable) return
          setShareModalOpen(true)
          track("share modal opened")
        }}
        text="Share"
      />

      <ShareModal open={shareModalOpen} setOpen={setShareModalOpen} />
    </>
  )
}

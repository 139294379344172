import { useEffect, useState } from "react"

import clsx from "clsx"
import {
  Membership,
  MembershipSubscriberCountResp,
  MembershipWithNFT,
} from "@types"
import {
  getUsersSubscriptionToBlog,
  refreshUser,
  selectSubscription,
} from "features/userSlice"
import { useSelector } from "react-redux"
import { FrequencySelector } from "../SharedComponents"

import { getNumberOfSubscribersForMembership } from "api_routes/memberships"
import { Transition } from "@headlessui/react"
import useWindowDimensions from "hooks/useWindowDimensions"
import useColorStyles from "hooks/useColorStyles"
import CTAButton from "./CTAButton"
import { Props } from "./SharedTypes"
import CancelMembershipButton from "./CancelButton"
import ResubscribeButton from "./ResubscribeButton"
import { frequencies } from "../SharedTypes"
import BenefitsSection from "./BenefitsSection"
import ImageSection from "./ImageSection"
import MembershipExpirationSection from "./MembershipExpirationSection"
import PriceSection from "./PriceSection"
import NameSection from "./NameSection"
import DescriptionSection from "./DescriptionSection"
import MostPopularSection from "./MostPopularSection"
import ClaimNFTButton from "./ClaimNFTButton"
import WalletPrompt from "components/SubscribeModal/EmailAndWallet/WalletPrompt"
import { ArrowLeftIcon } from "@heroicons/react/24/solid"
import ClaimNFTSuccess from "./ClaimNFTSuccess"
import useUser from "hooks/useUser"
import { selectMembershipsDiscounts } from "features/membershipSlice"
import AuthorOnlySubscriberCount from "./AuthorOnlySubscriberCount"
import AuthorOnlyDeleteMembershipBtn from "./AuthorOnlyDeleteMembershipBtn"
import TrialEndSection from "./TrialEndSection"
import { MdOutlineExpandMore } from "react-icons/md"
import ManageSection from "./ManageSection"

import initializeLoopCheckoutWidget from "@loop-crypto/loop-checkout-widget"
import {
  isSubscribedToThisMembershipAndFrequency,
  pollForSubscriptionCreationStatus,
} from "../SharedFunctions"
import { useAppDispatch } from "store"
import { createLoopCheckoutSession } from "api_routes/loop"
import { isMaster } from "@/util/constants/server"
import { selectCurrentNote } from "features/noteSlice"

export default function MembershipCard(props: Props) {
  const {
    blog,
    user,
    membership,
    memberships,
    isAuthorView,
    isFormPreview,
    disabledDueToInsufficientPermissions = [],
    selectedPlan,
    frequencyChangedCallback,
    isOnUpsellPage,
    showLoopCheckout,
    coupon,
    currentActionItem,
    setCurrentActionItem,
  } = props

  const discounts = useSelector(selectMembershipsDiscounts)

  console.log(
    "These are the memberships and discounts in MembershipCard",
    memberships,
    discounts
  )

  const [frequency, setFrequency] = useState(
    frequencies.find((f) => f.id == selectedPlan?.period || f.id === "year") ||
      frequencies[0]
  )
  const [subscriberCount, setSubscriberCount] = useState<
    MembershipSubscriberCountResp | undefined
  >(undefined)
  let subscription = useSelector(selectSubscription) || undefined
  if (subscription?.status !== "ACTIVE" && subscription?.status !== "PAST_DUE")
    subscription = undefined

  // If there's only one membership, expand it.
  const [mobileIsExpanded, setMobileIsExpanded] = useState(
    memberships.length < 2
  )

  const noteId = useSelector(selectCurrentNote)?.id || null

  const dims = useWindowDimensions()

  const dispatch = useAppDispatch()

  /** Loop checkout widget control */
  const { openWidget } = initializeLoopCheckoutWidget({
    entityId: blog.loop_entity_id || "",
    email: "email" in user ? user.email : undefined,
    // Hide the amount & coupon field when coupon is used since amount won't be correct with discount
    cartEnabled:
      discounts && discounts[0] && discounts[0].percentOff ? false : true,
    coupon,
    discount: discounts && discounts[0] ? discounts[0].percentOff : undefined,
    trial: discounts && discounts[0] ? discounts[0].trialDays : undefined,
    refId: user.id,
    env: isMaster ? "" : "demo",
    onSuccess: async () => {
      setCurrentActionItem(membership.id)

      // Then retrieve the updated subscription status.
      await pollForSubscriptionCreationStatus(blog.id, membership.id, dispatch)

      await Promise.all([
        dispatch(getUsersSubscriptionToBlog(blog.id)),
        dispatch(refreshUser()),
      ])

      setCurrentActionItem("")
    },
  })

  console.log("Setting openWidget", {
    entityId: blog.loop_entity_id || "",
    email: "email" in user ? user.email : undefined,
    cartEnabled: true,
    coupon,
    discount: discounts && discounts[0] ? discounts[0].percentOff : undefined,
    trial: discounts && discounts[0] ? discounts[0].trialDays : undefined,
  })

  const isSubscribedToThisFrequency = isSubscribedToThisMembershipAndFrequency(
    frequency,
    subscription,
    membership
  )

  console.log("CURRENT SUBSCRIPTION: ", subscription)

  const isDisabledDueToInsufficientPermissions =
    disabledDueToInsufficientPermissions.find((m) => m === membership.id) !==
    undefined

  /**
   * The state of the NFT Claim button.
   *
   * If we're displaying CONNECT_WALLET, hide everything else in the card.
   */
  const [claimState, setClaimState] = useState<
    "CLAIM" | "CONNECT_WALLET" | "SUCCESS"
  >("CLAIM")

  const _user = useUser()

  /**
   * If the user already has a wallet connected, just punt them
   * directly to the CLAIM state.
   */
  useEffect(() => {
    if (_user.hasWallet && claimState === "CONNECT_WALLET") {
      setClaimState("CLAIM")
    }
  }, [_user.hasWallet, claimState])

  useEffect(() => {
    const paidPlans = membership.plans?.filter((t) => t.price > 0) || []
    if (paidPlans.length == 1) {
      const newFrequency = frequencies.find((f) => f.id == paidPlans[0].period)
      if (newFrequency) {
        setFrequency(newFrequency)
      }
    }
  }, [JSON.stringify(membership)])

  useEffect(() => {
    frequencyChangedCallback?.(
      membership.id,
      membership.plans?.find((t) => t.period == frequency.id)
    )
  }, [JSON.stringify(frequency)])

  // If the viewer is the author and this isn't on the create/edit form,
  // then show the number of subscribers to this membership.
  useEffect(() => {
    const getSubscriberCount = async () => {
      const count = await getNumberOfSubscribersForMembership(
        blog.id,
        membership.id
      )

      console.log("Retrieved subscriber count", membership.id, count)

      setSubscriberCount(count)
    }

    if (isAuthorView && !isFormPreview) {
      getSubscriberCount()
    }
  }, [isAuthorView, isFormPreview, blog.id, membership.id])

  useEffect(() => {
    const openCheckoutWidget = async () => {
      const loopItemId = membership.plans.find(
        (p) => p.period === frequency.id
      )?.loopItemId

      if (!loopItemId) return

      const refId = await createLoopCheckoutSession(
        blog.id,
        noteId,
        membership.id,
        loopItemId,
        blog.loop_entity_id || "",
        blog.loop_wallet_address || "",
        discounts?.find((discount) => discount)?.discountId || null
      )
      openWidget(loopItemId, { refId })
    }

    console.log({ showLoopCheckout })

    if (showLoopCheckout) openCheckoutWidget()
  }, [])

  if (!frequency) return null

  const isNoMembershipsHaveMoreThanOnePlanFrequency = memberships.every(
    (m) => m.plans?.filter((t) => t.price > 0).length < 2
  )
  const isAtLeastOneFreePlan = membership.plans?.some((t) => t.price == 0)
  const isOnlyOnePaidPlan =
    membership.plans?.filter((t) => t.price > 0).length < 2

  const BackArrow = () => (
    <ArrowLeftIcon
      className="w-6 h-6 cursor-pointer"
      onClick={() => setClaimState("CLAIM")}
    />
  )

  if (claimState === "CONNECT_WALLET") {
    return (
      <Wrapper
        membership={membership}
        isDisabledDueToInsufficientPermissions={
          isDisabledDueToInsufficientPermissions
        }
        isFormPreview={isFormPreview}
        isFormInsideModal={isOnUpsellPage}
      >
        <BackArrow />
        <WalletPrompt description="Connect your wallet to claim your membership NFT." />
      </Wrapper>
    )
  }

  if (claimState === "SUCCESS" && membership.hasNFT) {
    return (
      <Wrapper
        membership={membership}
        isDisabledDueToInsufficientPermissions={
          isDisabledDueToInsufficientPermissions
        }
        isFormPreview={isFormPreview}
        isFormInsideModal={isOnUpsellPage}
      >
        <BackArrow />
        <ClaimNFTSuccess membership={membership as MembershipWithNFT} />
      </Wrapper>
    )
  }

  return (
    <Wrapper
      membership={membership}
      isDisabledDueToInsufficientPermissions={
        isDisabledDueToInsufficientPermissions
      }
      isFormPreview={isFormPreview}
      isFormInsideModal={isOnUpsellPage}
    >
      <div
        className={clsx("flex sm:hidden justify-start")}
        onClick={() => setMobileIsExpanded(!mobileIsExpanded)}
      >
        <div className="flex flex-col justify-center">
          {mobileIsExpanded ? (
            <MdOutlineExpandMore className="w-6 h-6 text-gray-400 mr-4 transform rotate-180" />
          ) : (
            <MdOutlineExpandMore className="w-6 h-6 text-gray-400 mr-4 " />
          )}
        </div>
        <NameSection
          {...props}
          discounts={discounts || []}
          frequency={frequency}
        />
      </div>
      <AuthorOnlySubscriberCount
        isAuthorView={isAuthorView}
        isFormPreview={isFormPreview}
        subscriberCount={subscriberCount}
      />
      <div className="absolute -top-4 left-[5.75rem] ">
        <MostPopularSection
          isRecommended={membership.isRecommended}
          isFormPreview={isFormPreview}
        />
      </div>
      <Transition
        appear={true}
        show={
          mobileIsExpanded || (dims?.width !== undefined && dims.width > 640)
        }
        unmount={false}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={clsx("flex-col sm:flex")}
      >
        <div className="sm:my-4 border-t border-gray-150 sm:border-t-0 mt-4 pt-4 sm:pt-0">
          {/* If none of the memberships have any plans with more than one price, remove all the Frequency selectors (and height fillers) to save on whitespace. */}
          {isNoMembershipsHaveMoreThanOnePlanFrequency ? null : isAtLeastOneFreePlan || // if there is a free plan
            isOnlyOnePaidPlan ? ( // or if there is only one paid plan
            <div className="h-0 sm:h-9" /> // add some space only on mobile
          ) : (
            <FrequencySelector // Otherwise, show the frequency selector when there are two or more paid plans.
              frequency={frequency}
              setFrequency={setFrequency}
              currentMembership={membership}
              subscription={subscription}
            />
          )}
        </div>
        <div className="flex flex-col items-center">
          <div className="flex-none sm:flex justify-center hidden">
            <NameSection
              {...props}
              discounts={discounts || []}
              frequency={frequency}
            />
          </div>
          <div className="flex-none h-auto sm:h-28">
            <DescriptionSection membership={membership} />
          </div>
          <div className="flex-auto">
            <ImageSection membership={membership} memberships={memberships} />
          </div>
          <div className="flex-none">
            <PriceSection
              membership={membership}
              frequency={frequency}
              discounts={discounts || []}
            />
          </div>
          <div className="flex flex-col">
            <CTAButton
              currentActionItem={currentActionItem}
              setCurrentActionItem={setCurrentActionItem}
              props={props}
              frequency={frequency}
              isDisabledDueToInsufficientPermissions={
                isDisabledDueToInsufficientPermissions
              }
              isAtLeastOneFreePlan={isAtLeastOneFreePlan}
              blog={blog}
              user={user}
              memberships={memberships}
              isOnUpsellPage={props.isOnUpsellPage}
              isFormPreview={isFormPreview}
              coupon={
                discounts?.find((discount) => discount)?.discountId || null
              }
              loopOpenWidget={openWidget}
            />
          </div>
          <ClaimNFTButton
            membership={membership}
            setClaimState={setClaimState}
            claimState={claimState}
          />
        </div>
        <div className="flex-1 h-auto sm:h-48">
          <BenefitsSection membership={membership} />
        </div>
        {!isAuthorView && !isFormPreview && (
          <>
            {!props.hideCancelButton && (
              <div className="flex-none flex justify-center">
                <CancelMembershipButton
                  memberships={memberships}
                  membership={membership}
                  subscription={subscription}
                  isLoading={!!currentActionItem}
                  setCurrentActionItem={setCurrentActionItem}
                />
              </div>
            )}
            <div className="flex-none flex justify-center flex-col space-y-3">
              <ResubscribeButton
                frequency={frequency}
                membership={membership}
                subscription={subscription}
                isLoading={!!currentActionItem}
                setCurrentActionItem={setCurrentActionItem}
              />
            </div>
            <div className="flex-none flex justify-center text-center">
              <MembershipExpirationSection
                membership={membership}
                subscription={subscription}
              />
            </div>
            <div className="flex-none flex justify-center text-center">
              <TrialEndSection subscription={subscription} />
            </div>
            {membership.id === subscription?.membershipId && (
              <div className="flex-none flex justify-center text-center">
                <ManageSection subscription={subscription} />
              </div>
            )}
          </>
        )}
      </Transition>

      {/* Spacer so button appears on the bottom of the card. */}
      <div className="flex justify-center mt-auto">
        <AuthorOnlyDeleteMembershipBtn
          isAuthorView={isAuthorView}
          isFormPreview={isFormPreview}
          membership={membership}
        />
      </div>
    </Wrapper>
  )
}

/**
 * Helper function to style the card.
 */
function Wrapper({
  children,
  membership,
  isDisabledDueToInsufficientPermissions,
  isFormPreview,
  isFormInsideModal,
}: {
  children: React.ReactNode
  membership: Membership
  isDisabledDueToInsufficientPermissions: boolean
  isFormPreview: boolean
  isFormInsideModal: boolean
}) {
  const customStyles = useColorStyles()

  return (
    <div
      // Need to set the custom color styles here, since
      // we rely on it in the ManageMemberships for custom colors.
      style={
        !isFormPreview && !isFormInsideModal ? ({ ...customStyles } as any) : {}
      }
      className={clsx(
        "col-span-1 flex flex-col relative rounded-lg p-4 snap-center w-70 mt-2 mx-1 shadow",
        membership.isRecommended
          ? "ring-2 ring-primary"
          : "ring-1 ring-custom-300",
        isDisabledDueToInsufficientPermissions ? "opacity-50 bg-custom-100" : ""
      )}
    >
      {children}
    </div>
  )
}

import { ImportedTokenOnChainData } from "@/types/gatingRules"
import { OnChainCryptoData } from "@types"
import Button from "components/Button"
import { useEffect, useState } from "react"
import { SharedProps } from "../SharedProps"
import { GateModalStep } from "../GateModalSteps"

export default function CreateGateBtn(props: SharedProps) {
  const [isCreateGateDisabled, setIsCreateGateDisabled] = useState(true)

  useEffect(() => {
    setIsCreateGateDisabled(
      DisableCreateGateButton(
        props.minQuantity,
        props.mintOrImportToken,
        props.importTokenData,
        props.onChainCryptoData,
        props.selectedMembershipId,
        props.currentStep
      )
    )
  }, [
    props.minQuantity,
    props.mintOrImportToken,
    props.importTokenData,
    props.onChainCryptoData,
    props.selectedMembershipId,
    props.currentStep,
  ])

  return (
    <Button
      loading={props.loading}
      replaceClassName={`
        disabled:opacity-50 disabled:cursor-not-allowed
        col-start-2 mt-3 sm:mt-0 inline-flex w-full px-4 py-2 text-base font-medium text-white 
        bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 
        focus:outline-none justify-center focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm
      `}
      text={"Enable gate"}
      disabled={isCreateGateDisabled}
      onClick={() =>
        props.currentStep.id == "CHOOSE_MEMBERSHIP"
          ? props.handleCreateGate("MEMBERSHIP", props)
          : props.handleCreateGate("TOKEN", props)
      }
      trackingData={{
        ...props,
      }}
    />
  )
}

// Returns true if we want to disable the create gate button. False if it should be enabled.
function DisableCreateGateButton(
  minQuantity: number,
  mintOrImportToken: string,
  importTokenData: ImportedTokenOnChainData,
  onChainCryptoData: OnChainCryptoData,
  selectedMembershipId: string,
  currentStep: GateModalStep
): boolean {
  // If we're on the membership selection stage, disable the create gate button if no membership has been selected yet.
  if (currentStep.id == "CHOOSE_MEMBERSHIP") {
    return selectedMembershipId == ""
  }

  // Otherwise, when creating token gates...

  // Disable the create gate button if either the minimum quantity is missing.
  if (!minQuantity || minQuantity < 0.000000000000000001) return true

  // Or if we're importing a token, if the contract address is missing.
  if (mintOrImportToken == "IMPORT" && importTokenData.contractAddress == "")
    return true

  if (mintOrImportToken !== "MINT") return false

  // Or if we're minting a new Paragraph token, ensure token name, price, and total quantity (optional) are all there and valid.
  if (
    !onChainCryptoData.maticPrice ||
    !onChainCryptoData.name ||
    onChainCryptoData.name == ""
  )
    return true

  if (
    onChainCryptoData.totalQuantity !== undefined &&
    (onChainCryptoData.totalQuantity < 1 ||
      !Number.isInteger(onChainCryptoData.totalQuantity))
  )
    return true

  return false
}

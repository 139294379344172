import { axios } from "./resolve"
import { AxiosResponse } from "axios"

import { Job } from "@/types/jobs"

import type { ErrorResponse } from "@types"

const API = "/jobs"

/**
 * Retrieve details about an asynchronous job running in the background.
 * @param jobId ID of the job to retrieve.
 * @returns The job details, or an error response.
 */
export async function getJob(jobId: string): Promise<Job | ErrorResponse> {
  const res: AxiosResponse<Job | ErrorResponse> = await axios.get(
    `${API}/${jobId}`
  )

  return res.data
}

import { useEffect, useRef, useState } from "react"
import { Transition } from "@headlessui/react"
import { usePopper } from "react-popper"
import { XMarkIcon } from "@heroicons/react/24/solid"
import useOutsideClick from "hooks/useOutsideClick"

import NewCollectPost from "./New/NewCollectPost"

import { useSelector } from "react-redux"
import { selectPostCollectible } from "features/pageSlice"
import PendingCollect from "./Pending/PendingCollect"
import ExistingCollect from "./Existing/ExistingCollect"

type Props = {
  isModalOpen: boolean
  referenceElement: HTMLElement | null | undefined
  setIsModalOpen: (isModalOpen: boolean) => void
}

function Popover() {
  const postCollectible = useSelector(selectPostCollectible)

  console.log("Post collectible", postCollectible)

  // If the post doesn't have an existing collectible, show the "New Collect Post" popover
  if (!postCollectible) return <NewCollectPost />

  // If the post is in the process of being collected, show the pending one.
  if (postCollectible.collectible.status === "PENDING")
    return <PendingCollect pendingCollectible={postCollectible.collectible} />

  return <ExistingCollect highlight={postCollectible.collectible} />
}

export default function CollectPostPopover(props: Props): JSX.Element {
  const [popperElement, setPopperElement] = useState<
    HTMLDivElement | undefined | null
  >()

  console.log("Using popper with", props.referenceElement, popperElement)

  const { styles, forceUpdate, attributes } = usePopper(
    props.referenceElement,
    popperElement,

    {
      strategy: "absolute",
      //placement: "bottom-end",
      //
      modifiers: [
        {
          name: "offset",
          enabled: true,
          options: {
            offset: [0, 10],
          },
        },
        {
          name: "flip",
          enabled: true,
          options: {
            fallbackPlacements: ["top-end", "left-end", "right-end"],
          },
        },
        {
          name: "preventOverflow",
          options: {
            altAxis: true,
            padding: 40,
          },
        },
      ],
    }
  )

  const ref = useRef(null)

  useOutsideClick(ref, () => {
    console.log("Outside click, so closing modal")
    props.setIsModalOpen(false)
  })

  useEffect(() => {
    console.log("Modal open changed:", props.isModalOpen)
    if (!props.isModalOpen) return

    forceUpdate?.()
  }, [props.isModalOpen])

  return (
    <div className="relative">
      <div
        className="z-50 "
        style={styles.popper}
        {...attributes.popper}
        ref={setPopperElement}
      >
        <Transition
          className="relative z-50"
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          //show={true}
          show={props.isModalOpen}
          // We need to refresh Popper here. When the transition begins,
          // the DOM changes, so Popper needs to re-calculate proper positioning.
          // If we don't do this then the popover can be pushed off screen (esp on mobile).
          afterEnter={() => {
            forceUpdate?.()
          }}
          beforeEnter={() => {
            forceUpdate?.()
          }}
        >
          <div className="shadow-lg ring-1 ring-black ring-opacity-5 bg-white px-5 py-6 rounded-lg">
            <div className="flex flex-row">
              <XMarkIcon
                className="h-5 w-5 text-gray-400 cursor-pointer"
                onClick={() => {
                  props.setIsModalOpen(false)
                }}
              />
            </div>
            <div className="relative grid gap-6 sm:gap-8 sm:p-2 " ref={ref}>
              <Popover />
            </div>
          </div>
        </Transition>
      </div>
    </div>
  )
}

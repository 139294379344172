import Details from "@tiptap-pro/extension-details"
import DetailsSummary from "@tiptap-pro/extension-details-summary"
import DetailsContent from "@tiptap-pro/extension-details-content"
import { getHtml } from "util/tiptap"

export const Collapsible = Details.extend({
  renderHTML() {
    if (this.editor)
      return ["details", { "data-type": "details", class: "details" }, 0]

    // Render as paragraph for staticHtml
    return ["div", { "data-type": "details", class: "details" }, 0]
  },
})

export const CollapsibleSummary = DetailsSummary.extend({
  renderHTML({ node }) {
    if (this.editor)
      return [
        "summary",
        { "data-type": "detailsSummary", class: "detailsSummary" },
        0,
      ]

    // Render as paragraph with expanded arrow for staticHtml
    const html = `<span style="margin-right: 8px">&#x25BC;</span>${getHtml(
      node.toJSON()
    )}`
    return [
      "p",
      { "data-type": "detailsSummary", class: "detailsSummary", html },
    ]
  },
})

export const CollapsibleContent = DetailsContent.extend({
  renderHTML() {
    return [
      "div",
      { "data-type": "detailsContent", class: "detailsContent" },
      0,
    ]
  },
})

export default Collapsible

import { Blog, GetNoteResp, Note } from "@types"
import { keccak } from "ethereumjs-util"

import { BigNumber, constants } from "ethers"

import { isMaster } from "./constants/server"
import { User } from "@/types/users"
import { AllCollectibleData, CollectibleType } from "@/types/highlights"
import { HIGHLIGHTS_DEFAULT_SUPPLY } from "./constants"

import { defineChain } from "viem"

import { CHAINS } from "@/types/highlights"

import {
  mainnet,
  polygon,
  goerli,
  polygonMumbai,
  optimism,
  optimismGoerli,
  base,
  zora,
  zoraTestnet,
} from "viem/chains"
import { FramePostWithJson, FrameUser } from "./frames/types"

// After generating the nonce, we need to generate a friendly,
// human readable message so that users aren't sketched out when
// connecting their wallet.
export function nonceToHumanReadableMsg(nonce: string): string {
  return `Welcome to Paragraph! 

Click to sign in.

This request will not trigger a blockchain transaction or cost any gas fees.

Here is your nonce: ${nonce}
`
}

/**
 * This is the contract address of the ERC1155, NOT the collectible posts.
 */
export const MATIC_PARAGRAPH_CONTRACT_ADDRESS = isMaster
  ? "0x62468a7C7a8d9af361a817c5203EC6d9D1774607"
  : "0x54CdC3a1B5579d9f1d6c1a77089FD8befe785E6A"

const ProdCollectibleData: AllCollectibleData = {
  /**
   * Note that this is for legacy purposes. No one can select
   * ethereum when creating new collectibles.
   */
  ethereum: {
    providerUrl:
      "https://mainnet.infura.io/v3/976fdbdbd348401899327207e5d311f9",
    // No V1 contract address so just use 0x00.
    contractAddress: "0x0",
    contractAddressV0: "0x141720507A0DCC971596240a711ee94bbD49d902",
    feeManagerAddress: "0x0",
    unit: "ETH",
    defaultCost: 0,
    chainId: 1,
  },
  polygon: {
    providerUrl:
      "https://polygon-mainnet.infura.io/v3/976fdbdbd348401899327207e5d311f9",
    contractAddress: "0x3285cE203B073bd009200Dfd416a8fD6DF155A57",
    contractAddressV0: "0xE870163Ad66ae94AEDa4c9B7d068432663ADB020",
    feeManagerAddress: "0xDc184312be2533083987166941D72FF4B6759EB8",
    unit: "MATIC",
    defaultCost: 0,
    chainId: 137,
  },
  optimism: {
    providerUrl:
      "https://optimism-mainnet.infura.io/v3/976fdbdbd348401899327207e5d311f9",
    contractAddress: "0x584DfE9780C962e0A48fe09D353CbAa62e67C309",
    contractAddressV0: "0x62468a7C7a8d9af361a817c5203EC6d9D1774607",
    feeManagerAddress: "0x214227b9514388D42f5f412f42b05319E400CF0c",
    unit: "ETH",
    defaultCost: 0,
    chainId: 10,
  },
  base: {
    providerUrl:
      "https://base-mainnet.g.alchemy.com/v2/5eNIM5cl9lz33g7A3u5Eb1vVSzpHm7fI",
    contractAddress: "0x9Bf9D0D88C1A835F1052Ef0FBa325b35bBea127a",
    contractAddressV0: "0x0254f66E7F1a3d497Eb734651e82ACFef51602dE",
    feeManagerAddress: "0x54A57E8Cee1c443d3090F901e85741e4E3Cadba1",
    unit: "ETH",
    defaultCost: 0,
    chainId: 8453,
  },

  zora: {
    providerUrl:
      "https://zora-mainnet.g.alchemy.com/v2/5eNIM5cl9lz33g7A3u5Eb1vVSzpHm7fI",
    contractAddress: "0x88e6b1341EFA068348b8177F2E59E900CC6D864b",
    feeManagerAddress: "0x65781BcE4fb051082f966c8114a426e647322D99",
    unit: "ETH",
    defaultCost: 0,
    chainId: 7777777,
  },
}

const TestCollectibleData: AllCollectibleData = {
  /**
   * Note that this is for legacy purposes. No one can select
   * ethereum when creating new collectibles.
   */
  ethereum: {
    providerUrl: "https://goerli.infura.io/v3/976fdbdbd348401899327207e5d311f9",
    // We never deployed the V1 contract on Goerli, so
    // we only have the v0 populated.
    contractAddress: "0x0",
    contractAddressV0: "0x4Cd4960759886cfF06f3164266b214F1B1fC3bdC",
    // We don't have the fee manager adderss here, since we never deployed the v0 FeeManager on Goerli.
    feeManagerAddress: "0x000",
    unit: "ETH",
    defaultCost: 0,
    chainId: 5,
  },

  polygon: {
    providerUrl:
      "https://polygon-mumbai.infura.io/v3/976fdbdbd348401899327207e5d311f9",
    contractAddress: "0x7dFD74A2FdCaDC86Fa4C0187A85CB1820Ec9D1Cb",
    contractAddressV0: "0x784F71dD3A17845CC9125de00Bd37AFd6DC4Ab50",
    feeManagerAddress: "0xc9eb938654ebaCA30cf2806c109E925c015BCf93",
    unit: "MATIC",
    defaultCost: 0,
    chainId: 80001,
  },
  optimism: {
    providerUrl:
      "https://optimism-goerli.infura.io/v3/976fdbdbd348401899327207e5d311f9",
    contractAddress: "0x10e89739e639d65d111c1285160F943b636223c1",
    contractAddressV0: "0xF689821aFBb11BD650e704f174779eC7A3B7200E",
    feeManagerAddress: "0xFf59bEEbc12073dDb5332c727B629ce99d67dB0a",
    unit: "ETH",
    defaultCost: 0,
    // OP Goerli
    chainId: 420,
  },

  base: {
    providerUrl:
      "https://eth-goerli.g.alchemy.com/v2/gwzEpDvJYpKpaMqklrZG77L5nSY1x76Y",
    contractAddress: "0x5d96d87E00c6FB1395b02428b192d2205095bF10",
    contractAddressV0: "0xF689821aFBb11BD650e704f174779eC7A3B7200E",
    feeManagerAddress: "0x3FCF1B0ba4850D13664f32ad241e9dCa55610196",
    unit: "ETH",
    defaultCost: 0,
    chainId: 84531,
  },

  zora: {
    providerUrl: "https://testnet.rpc.zora.co/",
    contractAddress: "0x87f148110af5F1A08CaE79e39982b51abC17F91c",
    feeManagerAddress: "0x3819802BF36546Aa9ee0f37054f1132030d703F1",
    unit: "ETH",
    defaultCost: 0,
    chainId: 999,
  },
}

export const COLLECTIBLE_DATA =
  isMaster ||
  process.env.FORCE_PROD_CHAINS ||
  process.env.NEXT_PUBLIC_FORCE_PROD_CHAINS
    ? ProdCollectibleData
    : TestCollectibleData

// Parses the nonce into something usable for verification.
export function parseNonce(nonce: string) {
  let humanReadable = nonceToHumanReadableMsg(nonce)
  humanReadable = "\x19Ethereum Signed Message:\n" + nonce.length + nonce
  return keccak(Buffer.from(humanReadable, "utf-8"))
}

export const GNOSIS_VALID_SIGNATURE_MAGIC_VALUE = "0x1626ba7e"

/**
 * Gets the URL of the collectible image.
 *
 * This returns either:
 *
 * 1) The collectibleImgUrl on the post, if it exists
 * 2) The RELATIVE highlight image URL, if this is called on the FE
 * 2) The FULLY QUALIFIED highlight image URL, if this is called on the BE
 */
export function getCollectibleImageUrl({
  post,
  text,
  blog,
  blogOwnerUser,
  collectibleType,
  alwaysGenerate,
  size,
}: {
  text: string
  post?: Note | GetNoteResp | FramePostWithJson | null
  blog?: Blog
  blogOwnerUser?: User | FrameUser
  collectibleType: CollectibleType
  alwaysGenerate?: boolean
  /**
   * The size of the generated image. Defaults to 512.
   */
  size?: number
}) {
  if (
    post?.collectibleImgUrl &&
    collectibleType === "POST" &&
    !alwaysGenerate
  ) {
    return post.collectibleImgUrl
  }

  const collectiblePath = generateHighlightImgPath({
    text,
    blog,
    blogOwnerUser,
    featuredImageUrl: post?.cover_img?.img?.src,
    size,
  })

  // This method can be called on the frontend, in which case FRONTEND_URL will be
  // undefined, so just return the relative path.
  // On the backend, we need to return the fully qualified path.
  return process.env.FRONTEND_URL
    ? `${process.env.FRONTEND_URL}/${collectiblePath}`
    : `/${collectiblePath}`
}

function generateHighlightImgPath(props: {
  text: string
  blog?: Blog
  blogOwnerUser?: User | FrameUser
  featuredImageUrl?: string
  size?: number
}) {
  const { text, blog, blogOwnerUser, featuredImageUrl } = props

  const userIdentifier =
    blogOwnerUser && "wallet_address" in blogOwnerUser
      ? blogOwnerUser.wallet_address
      : ""

  const author = blog?.name
  const url = blog?.lowercase_url || userIdentifier || ""
  const avatarUrl =
    blog?.logo_url ||
    (blog && "user" in blog && blog?.user?.avatar_url) ||
    blogOwnerUser?.avatar_url ||
    ""

  const path = _generateHighlightImgPath({
    text,
    author,
    url,
    avatarUrl,
    featuredImageUrl,
    size: props.size,
  })

  return path
}

function _generateHighlightImgPath(props: {
  text: string
  author?: string
  url?: string
  avatarUrl?: string
  featuredImageUrl?: string
  size?: number
}) {
  if (!props.text) {
    console.error("No text provided to generateHighlightImg")
    return
  }

  const encodedText = encodeURIComponent(props.text)
  const encodedAuthor = encodeURIComponent(props.author || "")
  const encodedUrl = encodeURIComponent(props.url || "")
  const encodedAvatarUrl = encodeURIComponent(props.avatarUrl || "")
  const encodedImageUrl = encodeURIComponent(props.featuredImageUrl || "")
  const size = encodeURIComponent(props.size || 512)

  const url = `api/highlight?text=${encodedText}&author=${encodedAuthor}&url=${encodedUrl}&avatarUrl=${encodedAvatarUrl}&featuredImageUrl=${encodedImageUrl}&size=${size}`
  return url
}

export const baseSepolia = defineChain({
  id: 84532,
  name: "Base Sepolia",
  network: "base-sepolia",
  nativeCurrency: {
    decimals: 18,
    name: "Sepolia Ether",
    symbol: "ETH",
  },
  rpcUrls: {
    default: {
      http: ["https://sepolia.base.org"],
    },
    public: {
      http: ["https://sepolia.base.org"],
    },
  },
  blockExplorers: {
    default: {
      name: "Explorer",
      url: "https://sepolia.basescan.org",
    },
  },
  contracts: {
    multicall3: {
      address: "0xca11bde05977b3631167028862be2a173976ca11",
      blockCreated: 1059647,
    },
  },
  testnet: true,
})

/** Use mainnet if we're deployed on Vercel (eg staging or prod).
 * For local development, use the test network.
 */
export const ethChain =
  isMaster || process.env.NEXT_PUBLIC_FORCE_PROD_CHAINS ? mainnet : goerli
/** Use mainnet if we're deployed on Vercel (eg staging or prod).
 * For local development, use the test network.
 */
export const polygonChain =
  isMaster || process.env.NEXT_PUBLIC_FORCE_PROD_CHAINS
    ? polygon
    : polygonMumbai

/** Use mainnet if we're deployed on Vercel (eg staging or prod).
 * For local development, use the test network.
 */
export const optimismChain =
  isMaster || process.env.NEXT_PUBLIC_FORCE_PROD_CHAINS
    ? optimism
    : optimismGoerli

/** Use mainnet if we're deployed on Vercel (eg staging or prod).
 * For local development, use the test network.
 */
export const baseChain =
  isMaster || process.env.NEXT_PUBLIC_FORCE_PROD_CHAINS ? base : baseSepolia

/** Use mainnet if we're deployed on Vercel (eg staging or prod).
 * For local development, use the test network.
 */
export const zoraChain =
  isMaster || process.env.NEXT_PUBLIC_FORCE_PROD_CHAINS ? zora : zoraTestnet

export const getChainId = (chain: CHAINS): number => {
  switch (chain) {
    case "ethereum":
      return ethChain.id
    case "polygon":
      return polygonChain.id
    case "optimism":
      return optimismChain.id
    case "base":
      return baseChain.id
    case "zora":
      return zoraChain.id
    default:
      console.warn("Incorrect chain passed to getChainId.")
      return ethChain.id
  }
}

/**
 * We return the cost of the collectible, first by checking
 * the current note, then the blog, and lastly if none are set,
 * the default.
 */
export const getCollectibleCost = (
  note?: Pick<Note, "highlightsChain" | "highlightsCost"> | null,
  blog?: Blog | null
): number => {
  const chain = getCollectibleChain(note, blog)
  const defaultCost = COLLECTIBLE_DATA[chain].defaultCost
  return note?.highlightsCost ?? blog?.highlightsCost ?? defaultCost
}

/**
 * We return the supply of the collectible, first by checking
 * the current note, then the blog, and lastly if none are set,
 * the default.
 */
export const getCollectibleSupply = (
  note?: Pick<Note, "highlightsSupply"> | null,
  blog?: Blog | null
): string => {
  const supply =
    note?.highlightsSupply ??
    blog?.highlightsSupply ??
    HIGHLIGHTS_DEFAULT_SUPPLY

  // If the supply is equal to zero, or not set (empty string),
  // we set it to the max uint256 value.
  if (supply == "0" || supply == "") {
    return BigNumber.from(constants.MaxUint256).toString()
  }

  return supply
}

/**
 * We return the chain of the collectible, first by checking
 * the current note, then the blog, and lastly if none are set,
 * the default.
 */
export const getCollectibleChain = (
  note?: Pick<Note, "highlightsChain"> | null,
  blog?: Pick<Blog, "highlightsChain"> | null
) => note?.highlightsChain ?? blog?.highlightsChain ?? "polygon"

import { Membership, Subscription } from "@types"

/**
 * The expiration status of a subscription, if applicable. If not applicable, use "NOT_APPLICABLE".
 */
export type SubscriptionExpirationStatus =
  | "EXPIRED"
  | "ACTIVE"
  | "NOT_APPLICABLE"

/**
 * Figures out whether a subscription to a membership is expired or not.
 * @param subscription The subscription to check.
 * @param membership The membership to check.
 * @returns Returns "EXPIRED" for an expired membership, "ACTIVE" for an unexpired membership, and "NOT_APPLICABLE"
 * for memberships that have no expiration date set or if the subscription provided does not match the membership.
 */
export function isSubscriptionToMembershipExpired(
  membership: Membership | undefined,
  subscription: Subscription | undefined
): SubscriptionExpirationStatus {
  if (
    subscription?.status !== "ACTIVE" ||
    subscription.membershipId !== membership?.id ||
    !("toBeUnsubscribedAt" in subscription) ||
    subscription.toBeUnsubscribedAt === undefined
  )
    return "NOT_APPLICABLE"

  return new Date(subscription.toBeUnsubscribedAt * 1000).getTime() < Date.now()
    ? "EXPIRED"
    : "ACTIVE"
}

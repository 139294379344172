import { Node } from "@tiptap/core"
import { mergeAttributes } from "@tiptap/react"

export interface ImageLinkOptions {
  HTMLAttributes: Record<string, any>
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    imageLink: {
      setImageLink: (options: { href: string }) => ReturnType
      toggleImageLink: (options: { href: string }) => ReturnType
      unsetImageLink: () => ReturnType
    }
  }
}

const childIsImage = (node: HTMLElement) => {
  if (node.firstElementChild)
    return node.firstElementChild && node.firstElementChild.nodeName === "IMG"
}

export default Node.create<ImageLinkOptions>({
  name: "imageLink",
  group: "block",
  draggable: true,
  atom: true,
  priority: 1000,
  content: "image",

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  addAttributes() {
    return {
      href: { default: null },
    }
  },

  parseHTML() {
    return [
      {
        tag: "a",
        getAttrs: (node) => {
          const aTag = node as HTMLElement
          if (childIsImage(aTag as HTMLElement))
            return { href: aTag.attributes.getNamedItem("href") }

          return false
        },
      },
    ]
  },

  addCommands() {
    return {
      setImageLink:
        (options) =>
        ({ commands }) => {
          return commands.wrapIn("imageLink", { ...options })
        },
      toggleImageLink:
        (options) =>
        ({ commands }) => {
          return commands.toggleWrap("imageLink", { ...options })
        },
      unsetImageLink:
        () =>
        ({ commands }) => {
          return commands.lift("imageLink")
        },
    }
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "a",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        target: "_blank",
        rel: "noopener noreferrer nofollow ugc",
        style: "cursor: pointer;",
      }),
      0,
    ]
  },
})

import type { IconType } from "react-icons"

export default function SocialIcon({
  Icon,
  href,
  ...rest
}: {
  Icon: IconType
  href: string
}) {
  return (
    <a
      className="text-gray-300 text-sm hover:text-gray-500 duration-200 transition-all tracking-wider !border-b-0"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon className="" {...rest} />
    </a>
  )
}

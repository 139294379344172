import "../styles/globals.css"
import "../styles/content.css"
import "../styles/twitter.css"
import "katex/dist/katex.min.css"
import "../styles/credit-cards.css"
import "../styles/toast.css"
import "lite-youtube-embed/src/lite-yt-embed.css"
import { RootState, useAppDispatch, wrapper } from "../store"
import type { ReactElement, ReactNode } from "react"
import type { NextPage } from "next"
import type { AppProps } from "next/app"
import { Provider, useSelector } from "react-redux"
import ErrorBoundary from "components/ErrorBoundary"
import { install as installResizeObserverPolyfill } from "resize-observer"

import { analytics, useTrackPageAnalytics } from "hooks/useAnalytics"

import { AnalyticsProvider } from "use-analytics"

import React, { FC, useEffect, useState } from "react"

import useReferrer from "hooks/useReferrer"
import { ToastContainer } from "react-toastify"
import { setAxiosOnErrorCallback } from "api_routes/resolve"
import { makeToast } from "features/pageSlice"
import { UnauthorizationErrorResponse } from "@/types/teams"
import useShowToast from "components/ToastManager/useShowToast"
import useUtmParams from "hooks/useUtmParams"
import { SpeedInsights } from "@vercel/speed-insights/next"

import { Inter } from "next/font/google"
import WagmiProvider from "@/components/providers/WagmiProvider"
import ConnectKitProvider from "@/components/providers/ConnectKitProvider"

// If loading a variable font, you don't need to specify the font weight
const inter = Inter({ subsets: ["latin"] })

// Polyfill Resize Observer for legacy browsers https://caniuse.com/resizeobserver
if (typeof window !== "undefined" && !window.ResizeObserver)
  installResizeObserverPolyfill()

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

/**
 * We want this to run on every single page, so
 * anyone can refer anyone else to any page.
 */
function ReferrerWrapper({ children }: { children: ReactNode }) {
  useReferrer()

  return <>{children}</>
}

/**
 * We want this to run on every single page, so that UTM params
 * are saved in a cookie for any link someone is directed to.
 */
function UtmWrapper({ children }: { children: ReactNode }) {
  useUtmParams()

  return <>{children}</>
}

function DarkModeWrapper({ children }: { children: ReactNode }) {
  const colorMode = useSelector((state: RootState) => state.page.colorMode)
  const [clientColorMode, setClientColorMode] = useState("light")

  // Need to set this client-side so the color mode is set correctly when the user changes the theme.
  useEffect(() => {
    setClientColorMode(colorMode)

    // Remove the existing theme class and add the new one,
    // without touching any of the other, unrelated classes we might have here.
    document.body.classList.remove("dark", "light")
    document.body.classList.add(colorMode)

    document.documentElement.classList.remove("dark", "light")
    document.documentElement.classList.add(colorMode)
  }, [colorMode])

  console.log("Got the color mode", colorMode, colorMode === "dark")

  return (
    <div className={clientColorMode === "dark" ? "dark" : "light"}>
      {children}
    </div>
  )
}

/**
 * Placing the Toast Component at the top level of the app here ensures that it will be available both in all the public
 * and private layouts (e.g. to readers viewing public pages, and writers in their dashboards).
 */
function ToastComponent() {
  const dispatch = useAppDispatch()

  // Need to set the axios error callback here so that we can dispatch the error
  // to the Redux store (as we don't have access to the dispatch hook in resolve.ts
  // where axios is first created and the interceptors defined).
  useEffect(() => {
    // Set the callback when the component mounts
    setAxiosOnErrorCallback((error) => {
      if (!("actionId" in error)) {
        return
      }

      const parsedErr = error as UnauthorizationErrorResponse

      dispatch(makeToast("error", parsedErr.msg, parsedErr.title))
    })

    // Reset the callback when the component unmounts
    return () => {
      setAxiosOnErrorCallback(undefined)
    }
  }, [dispatch])

  useShowToast()

  return (
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
  )
}

const MyApp: FC<AppPropsWithLayout> = ({ Component, ...rest }) => {
  useTrackPageAnalytics()

  const { store, props } = wrapper.useWrappedStore(rest)
  const getLayout = Component.getLayout || ((page) => page)

  return (
    <ErrorBoundary>
      <AnalyticsProvider instance={analytics}>
        <Provider store={store}>
          <WagmiProvider>
            <ConnectKitProvider>
              <ReferrerWrapper>
                <UtmWrapper>
                  <DarkModeWrapper>
                    <div className={inter.className}>
                      {getLayout(<Component {...props.pageProps} />)}
                    </div>

                    <SpeedInsights />

                    <ToastComponent />
                  </DarkModeWrapper>
                </UtmWrapper>
              </ReferrerWrapper>
            </ConnectKitProvider>
          </WagmiProvider>
        </Provider>
      </AnalyticsProvider>
    </ErrorBoundary>
  )
}

export default MyApp

import { Blog, SubscriberCountResp } from "@types"
import { analytics } from "hooks/useAnalytics"

// Re-export for convenience
// export { analytics } from "hooks/useAnalytics"

/**
 * Base properties shared by all events.
 */
export const getDefaultEventProperties = () => {
  if (typeof window === "undefined") return {}

  return {
    app: {
      env: process.env.NEXT_PUBLIC_VERCEL_ENV,
      commitSha: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
      region: process.env.NEXT_PUBLIC_VERCEL_REGION,
    },
    page: {
      url: location.href,
      path: location.pathname,
      origin: location.origin,
      width: window.innerWidth,
      height: window.innerHeight,
      name: null, // XXX Will be set by the page (usually through its layout)
    },
    customer: {
      //ref: customerRef,
    },
  }
}

// This is for the logged-in user only; their OWN blog.
export const setUsersBlogProps = (blog: Blog) => {
  const props = {
    blogId: blog.id,
    blogName: blog.name,
    blogUrl: blog.lowercase_url,
    createdAt: blog.createdAt,
    customDomain: blog.custom_domain,
  }

  analytics.identify(analytics.user("userId"), props)
}

// This is for the logged-in user only; their OWN blog.
export const setUsersSubscribersProps = (sub: SubscriberCountResp) => {
  const props = {
    activeSubscribers: sub.active,
    totalSubscribers: sub.total,
    totalUnsubscribedSubscribers: sub.unsubscribed,
  }

  analytics.identify(analytics.user("userId"), props)
}

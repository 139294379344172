import {
  EnrichedGatingRule,
  EnrichedGatingRuleGroup,
  GatingRequirementMembership,
} from "@/types/gatingRules"

import useUser from "hooks/useUser"
import useGatingRulesForReader from "hooks/useGatingRulesForReader"
import { useSelector } from "react-redux"
import { selectBlog, selectTokens } from "features/blogSlice"
import useColor from "hooks/useColor"
import {
  getUpsellMemberships,
  selectUpsellMemberships,
} from "features/membershipSlice"
import ViewMemberships from "components/Memberships/ViewMemberships"
import { hasTokenGates } from "components/Memberships/SharedFunctions"
import { Blog, Membership } from "@types"
import useBlogUrl, { BlogUrlReturnVals } from "hooks/useBlogUrl"
import Link from "next/link"
import Logo from "components/Logo"
import { subscribeBlockProps } from "./SharedTypes"
import { useState } from "react"
import ReaderGateTokenModal from "./ReaderGateTokenModal"

// Modal shown to reader when they see a gated post they don't have access to.
export default function ReaderGate(props: subscribeBlockProps) {
  return (
    <div className="absolute -top-12 inset-x-0 inset-y-0 z-50 mx-2 md:mx-0">
      <ReaderGateInner {...props} />
    </div>
  )
}

export function ReaderGateEmbed(props: subscribeBlockProps) {
  const color = useColor(false)

  return (
    <div
      className="border-2 border-primary-50 rounded-2xl"
      style={{
        backgroundColor: color.secondary || "white",
      }}
    >
      <ReaderGateInner {...props} />
    </div>
  )
}

function ReaderGateInner(props: subscribeBlockProps) {
  const user = useUser()
  const blog = useSelector(selectBlog)
  const blogUrl = useBlogUrl({ blog: blog })
  const [isError, setIsError] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const memberships = useSelector(selectUpsellMemberships)
  const tokens = useSelector(selectTokens)
  const color = useColor(false)
  const gatingRuleGroups = useGatingRulesForReader(
    props.noteId,
    props.gateContentType,
    props.embedId,
    "ReaderGateFromAfterGate"
  )

  const membershipsToDisable =
    detectMembershipsToDisableDueToInsufficientPermissions(
      memberships || [],
      gatingRuleGroups || []
    )

  console.log(
    "ReaderGate",
    props.noteId,
    props.gateContentType,
    props.embedId,
    gatingRuleGroups,
    memberships,
    membershipsToDisable
  )

  // If any of the gating rules involve tokens, then show the old UI.
  if (hasTokenGates(gatingRuleGroups)) {
    return (
      <ReaderGateTokenModal
        props={props}
        gatingRuleGroups={gatingRuleGroups}
        user={user}
        color={color}
        tokens={tokens}
        memberships={memberships}
        error={error}
        setError={setError}
        isError={isError}
        setIsError={setIsError}
      />
    )
  } else {
    // Otherwise, show the new UI with membership cards.
    return (
      <ReaderGateMembershipModal
        blog={blog}
        blogUrl={blogUrl}
        membershipsToDisable={membershipsToDisable}
      />
    )
  }
}

function ReaderGateMembershipModal({
  blog,
  blogUrl,
  membershipsToDisable,
}: {
  blog: Blog
  blogUrl: BlogUrlReturnVals
  membershipsToDisable: string[]
}) {
  const color = useColor()

  return (
    <div
      className="py-6 px-2 rounded-lg border border-1 border-gray-300 shadow-xl overflow-auto "
      style={{
        backgroundColor: color.secondary || "white",
      }}
    >
      <div className="flex justify-center items-center mb-2">
        {blogUrl.url && blog.logo_url && (
          <Link href={blogUrl.url} className="cursor-pointer">
            <Logo
              customUrl={blog.logo_url}
              className="flex content-center h-10 w-10 sm:h-auto sm:w-auto"
            />
          </Link>
        )}
      </div>
      <div className="flex items-center justify-center mt-2 mb-4 pb-4 text-xl font-semibold text-custom-900 border-b-gray-200 border-b">
        Access this post by joining {blog.name}
      </div>
      <ViewMemberships
        selectAppropriateBlog={selectBlog}
        getMemberships={getUpsellMemberships}
        selectMemberships={selectUpsellMemberships}
        isAuthorView={false}
        isOnDedicatedMembershipsPage={false}
        disabledDueToInsufficientPermissions={membershipsToDisable}
        isOnUpsellPage={false}
      />
    </div>
  )
}

// Iterates over all gating rule groups to figure out which memberships grant access to the post.
// Returns a list of membership IDs that don't grant access to the post.
export function detectMembershipsToDisableDueToInsufficientPermissions(
  memberships: Membership[],
  gatingRuleGroups: EnrichedGatingRuleGroup[]
) {
  const membershipsThatGrantAccess: string[] = []

  if (gatingRuleGroups.length === 0) return []

  memberships.forEach((membership) => {
    gatingRuleGroups.forEach((group) => {
      group.enrichedGatingRules.forEach((rule: EnrichedGatingRule) => {
        if (rule.gatingRequirement.gateType === "MEMBERSHIP") {
          if (
            membership.id ===
            (rule.gatingRequirement as GatingRequirementMembership).membershipId
          ) {
            membershipsThatGrantAccess.push(membership.id)
          }
        }
      })
    })
  })

  const membershipsToDisable = memberships
    .filter((membership) => !membershipsThatGrantAccess.includes(membership.id))
    .flatMap((membership) => membership.id)

  return membershipsToDisable
}

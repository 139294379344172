import { ArrowRightIcon } from "@heroicons/react/24/solid"
import Link from "next/link"
import useUser from "hooks/useUser"
import Banner from "components/Banner"

import { useSelector } from "react-redux"
import { selectError } from "features/errorSlice"
import { useRouter } from "next/router"
import Button from "components/Button"
import { selectActiveSubscription } from "features/userSlice"

import EmailPrompt from "./EmailPrompt"
import WalletPrompt from "./WalletPrompt"
import useSubscribeModalState from "hooks/useSubscribeModalState"
import { AiFillCaretRight } from "react-icons/ai"
import clsx from "clsx"
import { XMarkIcon } from "@heroicons/react/24/outline"

interface modelProps {
  initialFocusRef?: React.Ref<any>
  continueCallback: () => void
  initialPopup?: boolean
  setOpen: (open: boolean) => void
  appearOnScroll?: boolean
  /**
   * Pass this in as true if you want to not immediately subscribe the user
   * when the modal is opened, for instance when the modal contents is actually
   * being used as an embedded component.
   */
  doNotSubscribeImmediately?: boolean
  /**
   * If this is set, we'll prefill the email field with this value.
   */
  emailPrefilled?: string
}

/**
 * Wraps content in the subscribe modal and provides it with the white background.
 */
function Wrapper(props: {
  children: React.ReactNode
  props: modelProps
  appearOnScroll?: boolean
}) {
  const error = useSelector(selectError)

  const isExistingUserButLoggedOutErrorOrIncorrectPassword =
    error.code === "SUBSCRIBING_USER_LOGGED_OUT_BUT_EMAIL_ALREADY_EXISTS" ||
    error.code === "INCORRECT_PASSWORD"

  const isAlreadySubscribedError = error.code === "ALREADY_SUBSCRIBED"

  return (
    <div className="min-h-screen flex justify-center flex-col py-4 sm:py-0 px-4">
      <div
        className={clsx(
          "flex flex-col border-gray-150 rounded-xl border",
          props.appearOnScroll ? "bg-white" : ""
        )}
      >
        <div className="ml-auto p-2">
          <XMarkIcon
            className="w-6 h-6 text-gray-400 hover:text-gray-500 cursor-pointer"
            onClick={() => props.props.setOpen(false)}
          />
        </div>

        <div className="divide-y-2 divide-gray-100 rounded-none">
          {!isAlreadySubscribedError && props.children}
          {isAlreadySubscribedError && (
            <div className="pb-8 pt-12 text-center">
              <span className="text-s">
                You're already subscribed to this publication.
              </span>
              <div
                className={`mt-4 flex justify-center flex-col space-y-8 max-w-md mx-auto`}
              >
                <Button
                  onClick={() => props.props.setOpen(false)}
                  replaceClassName="mt-12 text-xs w-32 mx-auto text-gray-700 flex flex-row justify-center"
                >
                  Continue <ArrowRightIcon className="h-4 w-4 ml-1" />
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      {error.msg &&
        !isExistingUserButLoggedOutErrorOrIncorrectPassword &&
        !isAlreadySubscribedError && (
          <Banner className="mt-4" type={error.type} text={error.msg} />
        )}
      <Footer {...props} />
    </div>
  )
}

/**
 * This is used for publications that ONLY have FREE memberships , in the SubscribeModal.
 * It causes the user to enter their email and prompt to connect their wallet,
 * since we don't need them to choose a membership.
 *
 * For paid memberships in the SubscribeModal, we only prompt to choose an email first,
 * then memberships, then lastly connect their wallet.
 */
export function EmailAndWallet(props: modelProps) {
  const user = useUser()

  const subscription = useSelector(selectActiveSubscription)
  const { blogHasPaidMemberships } = useSubscribeModalState()

  console.log(
    "Rendering collect email and wallet stuff...",
    subscription,
    user.hasEmailAndWallet
  )

  /*
  useEffect(() => {
    // If the user is not subscribed or does not have both email and wallet connected, no need to do anything
    // here.
    if (!subscription || !user.hasEmailAndWallet) return

    // Otherwise, proceed automatically to the next stage.
    props.continueCallback()
  }, [])
   */

  const userHasEmailAndIsSubscribed = user.hasEmail && !!subscription

  /**
   * If the user has an email and only if the blog has no paid memberships do we want to show the wallet prompt.
   * (If the blog has paid memberships we show the wallet prompt at the very end of the modal).
   */
  const showWalletPrompt =
    userHasEmailAndIsSubscribed && !user.hasWallet && !blogHasPaidMemberships

  return (
    <>
      <Wrapper props={props} appearOnScroll={props.appearOnScroll}>
        <>
          <EmailPrompt
            initialFocusRef={props.initialFocusRef}
            initialPopup={props.initialPopup}
            appearOnScroll={props.appearOnScroll}
            doNotSubscribeImmediately={props.doNotSubscribeImmediately}
            emailPrefilled={props.emailPrefilled}
          />
          {props.appearOnScroll && !showWalletPrompt && (
            <div
              className="flex justify-center text-gray-500 py-3 text-sm hover:text-gray-900 hover:bg-gray-50 hover:cursor-pointer rounded-b-lg"
              onClick={() => {
                props.setOpen(false)
              }}
            >
              No thanks
            </div>
          )}
          {showWalletPrompt && <WalletPrompt />}
        </>
      </Wrapper>
    </>
  )
}

function Footer({ props }: { props: modelProps }) {
  const user = useUser()
  const subscription = useSelector(selectActiveSubscription)
  const userHasEmailAndIsSubscribed = user.hasEmail && !!subscription

  return (
    <div>
      {props.initialPopup && (
        <div
          className="items-center justify-center text-sm text-center text-gray-400 mt-3
            hover:cursor-pointer hover:text-gray-500
  
            "
          onClick={() => {
            props.setOpen(false)
          }}
        >
          <span className="font-bold ">
            No thanks
            <AiFillCaretRight className="inline-block ml-1" />
          </span>
        </div>
      )}

      <AlreadySubscriberLink props={props} />

      {userHasEmailAndIsSubscribed && (
        <div className="flex justify-center mt-6">
          <Button
            text="Continue"
            type="button"
            replaceClassName="px-3 py-4  mt-4 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm leading-4 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 w-full flex justify-center "
            onClick={() => {
              props.continueCallback?.()
            }}
          >
            <ArrowRightIcon
              className="w-5 h-5 ml-2 text-gray-400"
              aria-hidden="true"
            />
          </Button>
        </div>
      )}
    </div>
  )
}

function AlreadySubscriberLink({ props }: { props: modelProps }) {
  const router = useRouter()
  const user = useUser()
  const error = useSelector(selectError)
  const isExistingUserButLoggedOutErrorOrIncorrectPassword =
    error.code === "SUBSCRIBING_USER_LOGGED_OUT_BUT_EMAIL_ALREADY_EXISTS" ||
    error.code === "INCORRECT_PASSWORD"

  if (
    props.initialPopup ||
    user.loggedIn ||
    props.appearOnScroll ||
    // Only show the "already a subscriber" link if the user is not trying to log in
    // using an existing account, otherwise hide it since we're already going to be
    // showing a login form.
    isExistingUserButLoggedOutErrorOrIncorrectPassword
  )
    return null

  return (
    <div className="items-center justify-center text-sm text-center text-gray-400 mt-3">
      Already a subscriber?{" "}
      <span className="font-bold underline">
        <Link href={`/login?redirect=${router.asPath}`}>Log in</Link>.
      </span>
    </div>
  )
}

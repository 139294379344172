import { Editor, NodeViewWrapper } from "@tiptap/react"
import { useEffect } from "react"
import { getTweet } from "api_routes/published"
import { constructHtml } from "./utils"
import { makeToast } from "features/pageSlice"
import { useAppDispatch } from "store"

type Props = {
  node: any
  getPos: () => void
  updateAttributes: (a: any) => void
  editor: Editor
  deleteNode(): void
}

// https://github.com/transitive-bullshit/react-static-tweets#usage
export default function StaticTwitter(props: Props) {
  const dispatch = useAppDispatch()
  const { updateAttributes, deleteNode } = props
  const { tweetData, tweetId } = props.node.attrs

  useEffect(() => {
    if (tweetData || !tweetId) return
    getTweet(tweetId).then((val) => {
      if (updateAttributes && val) {
        try {
          updateAttributes({ tweetData: val })
        } catch (e) {
          console.error("Failed to update Twitter props: ", e)
        }
      } else {
        console.warn("Failed to fetch Tweet: ", tweetId)
        updateAttributes({ tweetData: "error" })
        dispatch(makeToast("error", "Failed to fetch Tweet."))
      }
    })
  }, [tweetData, tweetId, updateAttributes, deleteNode, dispatch])

  if (tweetId && !tweetData) return <TweetSkeleton />

  try {
    const { html } = constructHtml(tweetData)

    return (
      <NodeViewWrapper className="react-component not-prose" data-drag-handle>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </NodeViewWrapper>
    )
  } catch (e) {
    console.error("Failed to construct Twitter html: ", e)
    updateAttributes({ tweetData: "error" })
  }
}

const TweetSkeleton = () => (
  <NodeViewWrapper className="react-component not-prose my-5" data-drag-handle>
    <div
      role="status"
      className="mx-auto w-full max-w-[548px] rounded border border-gray-200 p-4 dark:border-gray-700 md:p-6 embed bg-white"
    >
      <div className="mb-2 flex items-center space-x-3 animate-pulse">
        <svg
          className="h-14 w-14 text-gray-200 dark:text-gray-700"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
            clipRule="evenodd"
          ></path>
        </svg>
        <div>
          <div className="animate-pulse mb-2 h-2.5 w-32 rounded-full bg-gray-200 dark:bg-gray-700"></div>
          <div className="animate-pulse h-2 w-48 rounded-full bg-gray-200 dark:bg-gray-700"></div>
        </div>
      </div>
      <div className="animate-pulse mb-2.5 h-2 rounded-full bg-gray-200 dark:bg-gray-700"></div>
      <div className="animate-pulse mb-2.5 h-2 rounded-full bg-gray-200 dark:bg-gray-700"></div>
      <div className="animate-pulse mr-16 h-2 rounded-full bg-gray-200 dark:bg-gray-700"></div>

      <span className="sr-only">Loading...</span>
    </div>
  </NodeViewWrapper>
)

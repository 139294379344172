import { Node, nodePasteRule } from "@tiptap/core"
import { Editor, NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react"
import { getEmbed } from "api_routes/published"
import { selectUsersBlog } from "features/blogSlice"
import { selectUser } from "features/userSlice"
import { useEffect } from "react"
import { useSelector } from "react-redux"

// https://regex101.com/r/RbrUuL/1
export const QUEST_REGEX = /^https:\/\/app\.layer3\.xyz\/quests\/([^?\s]*)(.*)$/
export const QUEST_REGEX_GLOBAL =
  /^https:\/\/app\.layer3\.xyz\/quests\/([^?\s]*)(.*)$/g

const extractEmbedID = (url: string) => {
  const match = url.match(QUEST_REGEX)
  if (match && match[1]) return match[1]
  return
}

export interface Options {
  HTMLAttributes: Record<string, any>
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    quest: {
      setQuestEmbed: (options: { src: string }) => ReturnType
    }
  }
}

export default Node.create<Options>({
  name: "quest",
  group: "block",
  draggable: true,
  atom: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  addAttributes() {
    return {
      src: { default: null },
      embedId: { default: null },
      referral: { default: null },
      data: { default: null },
    }
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ]
  },

  addCommands() {
    return {
      setQuestEmbed:
        (options) =>
        ({ commands }) => {
          const embedId = extractEmbedID(options.src)
          if (!embedId) return false
          return commands.insertContent({
            type: this.name,
            attrs: { ...options, src: options.src, embedId },
          })
        },
    }
  },

  addPasteRules() {
    return [
      nodePasteRule({
        find: QUEST_REGEX_GLOBAL,
        type: this.type,
        getAttributes: (match) => {
          if (!match.input) return false
          const embedId = extractEmbedID(match.input)
          if (!embedId) return false
          return { src: match.input, embedId }
        },
      }),
    ]
  },

  renderHTML({ HTMLAttributes }) {
    const { src, data: jsonData, embedId, referral } = HTMLAttributes

    const data = JSON.parse(jsonData)
    const html = `
    ${
      data
        ? `<div style="text-align: center; margin-top: 24px; margin-bottom: 24px;">
    ${
      data.thumbnail_url
        ? `<img
      src=${data.thumbnail_url}
      className="large-summary-image"
      style="border-radius: 4px;"
    />`
        : ""
    }
    ${
      data.title && data.description
        ? `<div className="twitter-summary-card-text">
      <h2 style="margin-top: 0.5em; margin-bottom: 0.1em !important;">${data.title}</h2>
      <p>${data.description}</p>`
        : ""
    }
    </div>`
        : ""
    }
      <a  style="
    display: inline-block;
    border-radius: 4px;
    background-color: rgb(59, 130, 246);
    padding: 16px 24px;
    font-weight: 600;
    line-height: 100%;
    color: rgb(255, 255, 255);
    text-decoration: none;
    "
    href="https://layer3.xyz/quests/${embedId}${
      referral ? `?referrer=${referral}` : ""
    }">
    Start Quest
        </a>
      </div>`

    return [
      "div",
      { "data-type": this.name, src, data: jsonData, embedId, referral, html },
    ]
  },

  addNodeView() {
    return ReactNodeViewRenderer(QuestEmbedComponent)
  },
})

type Props = {
  editor: Editor
  node: any
  updateAttributes: (attrs: any) => void
}

const QuestEmbedComponent = ({
  editor,
  node,
  updateAttributes,
}: Props): JSX.Element => {
  const { embedId, referral } = node.attrs
  const blog = useSelector(selectUsersBlog)
  const user = useSelector(selectUser)
  const userWallet = "wallet_address" in user ? user["wallet_address"] : null

  useEffect(() => {
    let wallet = blog.collectibleWalletAddress
    if (userWallet) wallet = userWallet

    const retrieveEmbedData = async (url: string) => {
      try {
        const data = await getEmbed(node.attrs.src)
        if (
          updateAttributes &&
          editor?.isEditable &&
          wallet &&
          referral !== wallet
        ) {
          updateAttributes({ data: JSON.stringify(data), referral: wallet })
          return
        }
        if (updateAttributes) updateAttributes({ data: JSON.stringify(data) })
      } catch (e) {
        console.error(
          `Failed to retrieve embed data for Quest embeb for url: ${url}`,
          e
        )
      }
    }

    if (!node.attrs.data) retrieveEmbedData(node.attrs.src)
  }, [
    node.attrs.data,
    node.attrs.src,
    blog.collectibleWalletAddress,
    userWallet,
    updateAttributes,
    editor.isEditable,
    referral,
  ])

  const html = `
  <div class="embed"><iframe
    src="https://layer3.xyz/quests/embedded/${embedId}"
    width="100%"
    height="750px"
    allow="ethereum; solana"
  ></iframe></div>
  `

  return (
    <NodeViewWrapper className="react-component my-5" data-drag-handle>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </NodeViewWrapper>
  )
}

import { EnrichedGatingRule, Token } from "@/types/gatingRules"

import { LockClosedIcon, LockOpenIcon } from "@heroicons/react/24/outline"

import {
  InformationCircleIcon,
  ShoppingCartIcon,
} from "@heroicons/react/24/outline"

import { useAccount } from "wagmi"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { isTokenGate } from "@/util/gatingRules"
import clsx from "clsx"
import {
  AppendTokenOrTokensStringToGatingRuleWhenAppropriate,
  GetNetworkNameAndRootURL,
  GetTokenNameAndCountDisplayString,
} from "./ReaderGatesHelperFunctions"
import useColor from "hooks/useColor"
import Button from "components/Button"
import { useModal } from "connectkit"

const purchaseButtonStyles =
  "ml-2 text-base sm:col-start-2 text-sm w-24 md:w-32"

const shoppingCartStyles =
  "md:hidden ml-2 py-1.5 px-2 text-base sm:col-start-2 text-sm"

type Props = {
  gatingRule: EnrichedGatingRule
  token: Token | undefined
  setIsError: Dispatch<SetStateAction<boolean>>
  setError: Dispatch<SetStateAction<Error | null>>
}

export default function GatingRulePleaseBuyToken({
  gatingRule,
  token,
  setIsError,
  setError,
}: Props) {
  const account = useAccount()
  const networkScanner = GetNetworkNameAndRootURL(token)
  const color = useColor()

  const { setOpen } = useModal()

  console.log("GatingRules: token is", token)

  const [isPurchaseClicked, setIsPurchaseClicked] = useState(false)

  // If the user clicked Purchase, either log them in OR trigger the buy flow.
  useEffect(() => {
    if (!isPurchaseClicked) return

    // If the wallet's not connected,
    // open the RainbowKit connect modal (when it's ready).
    if (!account.isConnected && setOpen) {
      setOpen(true)
      return
    }

    // connectWalletOrBuyFlow()
    setIsPurchaseClicked(false)
  }, [account.isConnected, isPurchaseClicked, setOpen])

  return (
    <div className="rounded-lg focus:outline-none w-68 sm:w-full">
      <div className="flex flex-col">
        <div className="flex py-4 justify-between">
          <div className="my-auto">
            {gatingRule.hasAccess && (
              <span className="rounded-lg inline-flex p-1 sm:p-3">
                <LockOpenIcon
                  className="w-5 h-5 sm:w-7 sm:h-7 text-green-500"
                  aria-hidden="true"
                />
              </span>
            )}
            {!gatingRule.hasAccess && (
              <span className="rounded-lg inline-flex p-1 sm:p-3">
                <LockClosedIcon
                  className="w-5 h-5 sm:w-7 sm:h-7 text-red-500"
                  aria-hidden="true"
                />
              </span>
            )}
          </div>
          <div className="ml-3 flex-1 flex flex-col justify-center my-auto h-24 sm:h-18">
            <p
              className="text-base font-medium"
              style={{ color: color.fontColor || "text-gray-900" }}
            >
              {GetTokenNameAndCountDisplayString(gatingRule, token)}
            </p>
            <p className="text-sm text-custom-500">
              {gatingRule.hasAccess && (
                <span>
                  {gatingRule.gatingRuleRequirementTypeDisplayMessage}!
                </span>
              )}
              {!gatingRule.hasAccess && (
                <span className="">
                  You need{" "}
                  <span>
                    {gatingRule.gatingRuleRequirementTypeDisplayMessage}
                  </span>
                  {AppendTokenOrTokensStringToGatingRuleWhenAppropriate(
                    gatingRule.gatingRuleRequirementTypeDisplayMessage || "",
                    gatingRule
                  )}
                  .
                </span>
              )}
            </p>
          </div>
          <div className="ml-4 flex flex-col items-end justify-center">
            {/* Info buttons with link to chain scanner and contract for token. */}
            <div className="relative z-0 flex flex-col shadow-sm rounded-md pt-2">
              <div className="flex justify-end">
                {!gatingRule.hasAccess && isTokenGate(gatingRule) && token && (
                  <div>
                    <a
                      href={`${networkScanner.rootURL}/address/${token?.onChainData.contractAddress}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button
                        type="button"
                        className="hidden md:block justify-center px-4 py-2 font-medium border border-transparent rounded-md border-primary-600 focus:outline-none sm:col-start-2 text-sm w-24 md:w-32"
                        style={{
                          backgroundColor: color.primary || "bg-blue-500",
                          color: color.fontColor || "text-gray-900",
                        }}
                        text={networkScanner.name}
                        trackingData={{
                          event: "viewed information about token",
                          network: networkScanner.name,
                          token: token.onChainData.name,
                        }}
                      />
                      <Button
                        type="button"
                        className="md:hidden inline-flex justify-center py-1.5 px-2 font-medium border border-transparent rounded-md border-gray-300 focus:outline-none sm:col-start-2 text-sm"
                        style={{
                          backgroundColor: color.primary || "bg-blue-500",
                          color: color.fontColor || "text-gray-900",
                        }}
                        trackingData={{
                          event: "viewed information about token",
                          network: networkScanner.name,
                          token: token.onChainData.name,
                        }}
                      >
                        <InformationCircleIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </Button>
                    </a>
                  </div>
                )}
                {/* Purchase buttons for tokens imported into Paragraph. */}
                {!gatingRule.hasAccess &&
                  isTokenGate(gatingRule) &&
                  token &&
                  token.onChainData.learnMoreUrl && (
                    <a
                      href={`${token.onChainData.learnMoreUrl}`}
                      target="_blank"
                      rel="noreferrer"
                      className="!border-b-0" // Override the underline <a/> tags add.
                    >
                      <Button
                        type="button"
                        className={clsx(
                          purchaseButtonStyles,
                          "hidden md:block"
                        )}
                        text={"Purchase"}
                        trackingData={{
                          event: "purchased off-paragraph token",
                          network: networkScanner.name,
                          token: token.onChainData.name,
                        }}
                      />
                      <Button
                        type="button"
                        className={shoppingCartStyles}
                        trackingData={{
                          event: "purchased off-paragraph token",
                          network: networkScanner.name,
                          token: token.onChainData.name,
                        }}
                      >
                        <ShoppingCartIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </Button>
                    </a>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

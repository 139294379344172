import Image from "./CollectibleImage"

import Metrics from "./Metrics"

import { ReactNode } from "react"
import { CollectibleMetricData, CollectibleType } from "@/types/highlights"

export default function BasePopover(props: {
  imageText: string
  body?: ReactNode
  footer?: ReactNode
  header?: ReactNode
  metricData: CollectibleMetricData
  collectibleType: CollectibleType
}) {
  return (
    <div className="w-60 z-50">
      <div className="flex flex-col">
        <>
          {props.header && props.header}
          <div className="w-full">
            <Image
              text={props.imageText}
              collectibleType={props.collectibleType}
            />
          </div>

          <div className="flex flex-col p-3 sm:p-6 text-center">
            {props.body && props.body}
            <Metrics {...props.metricData} />

            <div className="block mt-4">{props.footer && props.footer}</div>
          </div>
        </>
      </div>
    </div>
  )
}

import { UserCircleIcon } from "@heroicons/react/24/solid"
import clsx from "clsx"
import Image from "next/image"
import { useEffect, useState } from "react"

interface Props {
  url?: string
  width?: number
  height?: number
  useRegularImgComponent?: boolean
  avatarType?: "user" | "blog" | null
  className?: string
  /**
   * Whether we do NOT want to SSR this image.
   *
   * If a user has a cookie that sets their avatar, we don't want to
   * SSR this image, as it will cause a hydration error.
   */
  noSSR?: boolean
}

export default function UserAvatar(props: Props) {
  const [clientSide, setClientSide] = useState(false)

  useEffect(() => {
    setClientSide(true)
  }, [])

  if (!props.url || (props.noSSR && !clientSide)) {
    return (
      <UserCircleIcon className={props.className || "w-8 h-8 rounded-full"} />
    )
  }

  if (props.useRegularImgComponent) {
    return (
      <img
        width={props.width || 36}
        height={props.height || 36}
        className={clsx(
          props.className || "w-5 h-5 rounded-full object-scale-down"
        )}
        src={props.url}
        alt="User avatar"
      />
    )
  }

  return (
    <Image
      width={props.width || 36}
      height={props.height || 36}
      priority
      className={clsx(
        props.className ||
          `${
            props.avatarType === "blog"
              ? "rounded"
              : "w-[36px] h-[36px] rounded-full basis-full"
          }`,
        "duration-700 ease-in-out"
      )}
      src={props.url}
      alt="User avatar"
    />
  )
}

import Button from "components/Button"
import useUser from "hooks/useUser"
import { useState } from "react"
import { mintMembershipNFT } from "api_routes/crypto"
import { isErrorResponse, Membership, MembershipWithNFT } from "@types"
import useSubscribeModalState from "hooks/useSubscribeModalState"
import Banner from "components/Banner"
import { useSelector } from "react-redux"
import {
  getUsersSubscriptionToBlog,
  selectActiveSubscription,
} from "features/userSlice"
import { openseaUrlFromMembership } from "util/format"
import { ArrowRightIcon } from "@heroicons/react/24/solid"
import { useAppDispatch } from "store"
import { selectBlog } from "features/blogSlice"
import { getUpsellMemberships } from "features/membershipSlice"

export default function ClaimNFTButton(props: {
  membership: Membership
  claimState: "CLAIM" | "CONNECT_WALLET" | "SUCCESS"
  setClaimState: (state: "CLAIM" | "CONNECT_WALLET" | "SUCCESS") => void
}) {
  const { userHasPaidSub } = useSubscribeModalState()
  const user = useUser()
  const [loading, setLoading] = useState(false)
  const [msg, setMsg] = useState("")
  const dispatch = useAppDispatch()
  const blog = useSelector(selectBlog)

  const sub = useSelector(selectActiveSubscription)

  const hasNFT = !!sub?.tokenId

  const { claimState, setClaimState } = props

  async function mintNFT(membershipId: string) {
    setLoading(true)
    const res = await mintMembershipNFT(membershipId)

    setLoading(false)

    console.log(res)

    if (isErrorResponse(res)) {
      console.error("Error minting NFT")
      setMsg(res.msg)
    } else {
      // Refresh the user's subscription,
      // since it will now have a tokenId.
      dispatch(getUsersSubscriptionToBlog(blog.id))
      /**
       * Refresh the membership only if the contractAddress
       * does not exist yet (eg it's the very first NFT lazy-minted)
       */
      dispatch(getUpsellMemberships(blog.id, null))
      setClaimState("SUCCESS")
    }
  }

  if (
    !userHasPaidSub ||
    !props.membership.hasNFT ||
    props.membership.id !== sub?.membershipId
  )
    return null

  return (
    <>
      <Button
        className="mt-4"
        type="button"
        text={hasNFT ? "NFT already claimed" : "Claim Membership NFT"}
        loading={loading}
        disabled={loading || hasNFT}
        onClick={async () => {
          if (hasNFT) return

          console.log("Claim membership NFT clicked")
          if (!user.hasWallet) {
            setClaimState("CONNECT_WALLET")
            return
          }

          await mintNFT(props.membership.id)
        }}
      />
      {msg && <Banner type="info" text={msg} />}
      {hasNFT && (
        <a
          href={openseaUrlFromMembership(
            props.membership as MembershipWithNFT,
            sub
          )}
          target="_blank"
          rel="noreferrer"
          className="text-xs text-gray-400 text-center mt-2"
        >
          View on OpenSea{" "}
          <ArrowRightIcon className="inline-block w-4 h-4 ml-1" />
        </a>
      )}
    </>
  )
}

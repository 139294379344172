import {
  getPublicUserInfoByUserId,
  getPublicUserInfoByWallet,
} from "api_routes/published"
import { AllUserInfoResponse } from "@/types/responses/public"

class PublicUser {
  private constructor(
    public readonly data: AllUserInfoResponse,
    public readonly wallet: string
  ) {}

  /**
   * blogIdContext is used to get the user's subscription to a given blog.
   */
  static async createServiceWithWallet({
    wallet,
    blogIdContext,
  }: {
    wallet: string
    blogIdContext?: string
  }) {
    const data = await getPublicUserInfoByWallet(wallet, blogIdContext)

    if (!data) throw "User not found"

    return new PublicUser(data, wallet)
  }

  /**
   * blogIdContext is used to get the user's subscription to a given blog.
   */
  static async createServiceWithUserId({
    userId,
    blogIdContext,
  }: {
    userId: string
    blogIdContext?: string
  }) {
    const data = await getPublicUserInfoByUserId(userId, blogIdContext)

    if (!data) throw "User not found"

    return new PublicUser(data, userId)
  }

  get name() {
    const { user, blog, farcaster } = this.data

    if (user && "authorName" in user && user.authorName) {
      return user.authorName
    }

    if (farcaster && "authorName" in farcaster && farcaster.authorName) {
      return farcaster.authorName
    }

    return this.wallet
  }

  get pfp() {
    const { user, blog, farcaster } = this.data
    if (user && "avatar_url" in user && user.avatar_url) {
      return user.avatar_url
    }

    if (farcaster && "avatar_url" in farcaster && farcaster.avatar_url) {
      return farcaster.avatar_url
    }

    return undefined
  }

  get bio() {
    const { user, blog, farcaster } = this.data

    if (user && "authorBio" in user) {
      return user.authorBio
    }

    if (farcaster && "bio" in farcaster) {
      return farcaster.bio
    }

    if (blog && "summary" in blog) {
      return blog.summary
    }

    return undefined
  }
}

export default PublicUser

import { Membership, MembershipDiscount } from "@types"
import { Frequency, monthlyFrequency } from "../SharedTypes"
import { isFreeMembership } from "../SharedFunctions"

/**
 * Shows the price of the membership. To make annual memberships look more attractive, displays
 * pricing per month if the membership is annual (with the annual, more shocking price in smaller font).
 * @returns JSX element.
 */
export default function PriceSection({
  membership,
  discounts,
  frequency,
}: {
  membership: Membership
  discounts: MembershipDiscount[]
  frequency: Frequency
}) {
  const isFreeMembershipConst = isFreeMembership(membership)

  const plan = membership.plans?.find((p) => p.period == frequency.id)
  const price = plan?.price || 0
  const pricePerMonth =
    Math.round((frequency.id === "year" ? price / 12 : price) * 100) / 100

  const discountedPrice = discounts.find(
    (discount) =>
      discount.planId === plan?.stripePriceId ||
      discount.planId === plan?.loopItemId
  )?.discountedPrice
  const discountedPricePerMonth =
    discountedPrice !== undefined
      ? Math.round(
          (frequency.id === "year" ? discountedPrice / 12 : discountedPrice) *
            100
        ) / 100
      : null

  const trialDays = discounts.find(
    (discount) =>
      discount.planId === plan?.stripePriceId ||
      discount.planId === plan?.loopItemId
  )?.trialDays

  const pricePerFrequencySuffix =
    frequency.id === "year"
      ? monthlyFrequency.priceSuffix
      : frequency.priceSuffix

  return (
    <div className="flex flex-col justify-center">
      <div className="mx-2 sm:mt-6 flex justify-center items-baseline gap-x-1 text-center">
        {/* Free */}
        {isFreeMembershipConst && (
          <div>
            <span className="text-4xl font-bold tracking-tight text-custom-900 uppercase dark:text-white">
              Free
            </span>
          </div>
        )}
        {/* Paid with Trial */}
        {!isFreeMembershipConst && trialDays && (
          <div>
            <div>
              <div className="text-4xl font-bold tracking-tight text-custom-900 dark:text-white mb-2">
                FREE
              </div>
              <div className="text-sm font-semibold leading-6 text-custom-600 dark:text-gray-300">
                For {trialDays} days, then
              </div>
              <div className="text-sm font-semibold leading-6 text-custom-600 dark:text-gray-300">
                {`$${pricePerMonth}`}
                <span className="text-sm font-semibold leading-6 text-custom-600 dark:text-gray-300">
                  {pricePerFrequencySuffix}
                </span>
              </div>
              {frequency.id === "year" && (
                <div className="text-sm text-custom-400 w-48 text-center">
                  ${price} (billed annually)
                </div>
              )}
            </div>
          </div>
        )}
        {/* Paid (possibly with discount) */}
        {!isFreeMembershipConst && !trialDays && (
          <div>
            {discountedPricePerMonth !== null && (
              <div className="mb-2">
                <div>
                  <span className="text-4xl font-bold tracking-tight text-custom-900 dark:text-white">
                    {discountedPricePerMonth === 0 ? (
                      "FREE"
                    ) : (
                      <span>
                        {`$${discountedPricePerMonth}`}
                        <span className="text-sm font-semibold leading-6 text-custom-600 dark:text-gray-300">
                          {pricePerFrequencySuffix}
                        </span>
                      </span>
                    )}
                  </span>
                </div>
                {frequency.id === "year" && (
                  <div className="text-sm text-custom-400 w-48 text-center">
                    {discountedPricePerMonth === 0
                      ? ""
                      : `$${discountedPrice} (billed annually)`}
                  </div>
                )}
              </div>
            )}
            <div>
              <div>
                <span
                  className={`text-4xl font-bold tracking-tight text-custom-900 dark:text-white ${
                    discountedPrice !== undefined &&
                    "line-through opacity-50 !text-2xl"
                  }`}
                >
                  ${pricePerMonth || 0}
                </span>
                <span
                  className={`text-sm font-semibold leading-6 text-custom-600 dark:text-gray-300 ${
                    discountedPrice !== undefined &&
                    "line-through opacity-50 !text-xs"
                  }`}
                >
                  {pricePerFrequencySuffix}
                </span>
              </div>
              {frequency.id === "year" && (
                <div
                  className={`mx-auto text-sm text-custom-400 w-48 text-center ${
                    discountedPrice !== undefined &&
                    "line-through text-custom-600 opacity-50"
                  }`}
                >
                  ${price} (billed annually)
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

import { AxiosResponse } from "axios"
import {
  ApiPortalSessionResponse,
  StripePaymentMethodObj,
} from "@/types/stripe"
import { Stripe } from "stripe"

import { axios, params } from "./resolve"
import {
  StripeImportMapping,
  StripeImportMappingOption,
  StripeImportResult,
} from "@types"

const API = "/stripe"

interface stripeSession {
  sessionId: string
}

interface StripeConnectResponse {
  created: number
  expires_at: number
  object: string
  url: string
}

interface StripeDashboardLoginLinkResponse {
  object: "login_link"
  created: number
  url: string
  id: string
}

export async function createCheckoutSession(
  priceId: string
): Promise<stripeSession> {
  const res = await axios.post(`${API}/create-checkout-session`, { priceId })
  return res.data || []
}

export async function createPortalSession(): Promise<ApiPortalSessionResponse> {
  const res: AxiosResponse<ApiPortalSessionResponse> = await axios.post(
    `${API}/create-portal-session`,
    {},
    params
  )
  return res.data
}

export async function createOAuthLink(): Promise<StripeConnectResponse> {
  const res: AxiosResponse<StripeConnectResponse> = await axios.post(
    `${API}/create-seller-oauth-link`,
    {},
    params
  )
  return res.data
}

export async function connectSellerAccount(
  code?: string,
  state?: string,
  error?: string
): Promise<StripeConnectResponse> {
  const res: AxiosResponse<StripeConnectResponse> = await axios.post(
    `${API}/connect-seller-account`,
    { code, state, error },
    params
  )
  return res.data
}

export async function createSellerDashboardLogin(): Promise<StripeDashboardLoginLinkResponse> {
  const res: AxiosResponse<StripeDashboardLoginLinkResponse> = await axios.post(
    `${API}/create-seller-dashboard-login`,
    {},
    params
  )
  return res.data
}

export async function retrieveSellerAccount(): Promise<Stripe.Account> {
  const res: AxiosResponse<Stripe.Account> = await axios.get(
    `${API}/seller-account`,
    params
  )
  return res.data
}

export async function disconnectSellerAccount(): Promise<null> {
  const res: AxiosResponse<null> = await axios.post(
    `${API}/disconnect-seller-account`,
    {},
    params
  )
  return res.data
}

export async function createCheckoutSessionForCustomDomain({
  redirectUrl,
}: {
  redirectUrl: string
}): Promise<stripeSession> {
  const res = await axios.post(
    `${API}/create-checkout-session-custom-domain`,
    {
      redirectUrl,
    },
    params
  )
  return res.data || []
}

export async function createCustomerPortalSession(
  subscriptionId?: string
): Promise<string> {
  const res = await axios.get(
    `${API}/create-customer-portal-session/${subscriptionId}`,
    params
  )
  return res.data.url
}

export async function createSubscription(
  membership: string,
  plan: string
): Promise<string> {
  const res = await axios.post(
    `${API}/create-subscription`,
    {
      membership,
      plan,
    },
    params
  )
  return res.data.client_secret
}

export async function getStripePaymentMethods(
  blogId?: string,
  stripeAccount?: string
): Promise<StripePaymentMethodObj[]> {
  const res: AxiosResponse<{ paymentMethods: StripePaymentMethodObj[] }> =
    await axios.get(
      `${API}/payment-methods?blogId=${blogId}&stripeAccount=${stripeAccount}`,
      params
    )
  return res.data.paymentMethods
}

export async function getStripeImportOptions(): Promise<
  StripeImportMappingOption[]
> {
  const res: AxiosResponse<{ options: StripeImportMappingOption[] }> =
    await axios.get(`${API}/import-options`, params)
  return res.data.options
}

export async function importStripePaidSubs(
  mappings: StripeImportMapping[],
  pauseOldPaymentCollection: boolean
): Promise<StripeImportResult> {
  const res: AxiosResponse<StripeImportResult> = await axios.post(
    `${API}/import-paid-subs`,
    { mappings, pauseOldPaymentCollection },
    params
  )
  return res.data
}

import { GatingRule } from "@/types/gatingRules"

// Returns true if this gating rule is a token gate.
export function isTokenGate(gr: GatingRule): boolean {
  return gr.gatingRequirement.gateType == "TOKEN"
}

// Returns true if this gating rule is a subscription gate.
export function isMembershipGate(gr: GatingRule): boolean {
  return gr.gatingRequirement.gateType == "MEMBERSHIP"
}

// https://regex101.com/r/4Tq0k7/1
export const AFTER_GATE_REGEX_GLOBAL =
  /\<div data-type=\"afterGate\" embed(?:I|i)d=\"([a-f0-9\-]*)\"\>\<\/div\>/gm
export const AFTER_GATE_REGEX =
  /\<div data-type=\"afterGate\" embed(?:I|i)d=\"([a-f0-9\-]*)\"\>\<\/div\>/m

import { axios, params } from "./resolve"
import { AxiosResponse } from "axios"

import { Discount, ErrorResponse } from "@types"

const API = "/blogs"

export async function getDiscounts(blogId: string) {
  const res: AxiosResponse<Array<Discount> | ErrorResponse> = await axios.get(
    `${API}/${blogId}/discounts`,
    params
  )

  if (res && res.data instanceof Array) return res.data as Discount[]
  return []
}

export async function addDiscount(blogId: string, discount: Partial<Discount>) {
  const res: AxiosResponse<Discount | ErrorResponse> = await axios.post(
    `${API}/${blogId}/discount`,
    { discount },
    params
  )

  return res.data
}

export async function disableDiscount(blogId: string, discountId: string) {
  const res: AxiosResponse<null | ErrorResponse> = await axios.put(
    `${API}/${blogId}/discounts/${discountId}/disable`,
    params
  )

  return res.data
}

export async function enableDiscount(blogId: string, discountId: string) {
  const res: AxiosResponse<null | ErrorResponse> = await axios.put(
    `${API}/${blogId}/discounts/${discountId}/enable`,
    params
  )

  return res.data
}

import { EnvelopeIcon } from "@heroicons/react/20/solid"
import { useEffect, useState } from "react"

import PublicUser from "services/public-user"
import { SocialMediaIcons } from "./public/BlogTheme/AuthorNames"
import { useSelector } from "react-redux"
import { selectBlog } from "features/blogSlice"
import ContentLoader from "react-content-loader"
import useInlineSubscribe from "hooks/useInlineSubscribe"
import useBlogUrl from "hooks/useBlogUrl"
import clsx from "clsx"
import { CheckIcon } from "@heroicons/react/24/solid"

function NameOrPlaceholder({ publicUser }: { publicUser: PublicUser | null }) {
  const blogUrl = useBlogUrl({ blog: publicUser?.data.blog })

  if (!publicUser)
    return (
      <ContentLoader
        speed={2}
        width={250}
        height={100}
        viewBox="0 0 300 100"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="12" y="10" rx="3" ry="3" width="300" height="20" />
        <rect x="119" y="76" rx="0" ry="0" width="6" height="7" />
        <rect x="15" y="70" rx="0" ry="0" width="3" height="6" />
        <rect x="13" y="53" rx="3" ry="3" width="300" height="20" />
      </ContentLoader>
    )

  const InnerComponent = () => {
    // Check if 'publicUser' and 'publicUser.data.blog' are defined
    if (publicUser?.data?.blog) {
      return (
        <a
          href={blogUrl.fullUrl}
          className="text-sm font-medium text-gray-900 cursor-pointer"
        >
          {publicUser.name}
        </a>
      )
    } else {
      return <div className="">{publicUser.name}</div>
    }
  }

  return (
    <h3 className="truncate max-w-64 text-sm font-medium text-gray-900">
      <InnerComponent />
    </h3>
  )
}

function SubscriberBadge({ publicUser }: { publicUser: PublicUser | null }) {
  if (!publicUser?.data.subscription) return null

  return (
    <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
      Subscriber
    </span>
  )
}

function SubscribeButton({ userInfo }: { userInfo: PublicUser | null }) {
  const {
    isUserSubscribed,
    loading: subscribeClickLoading,
    subscribeUser,
    ableToSubscribe,
  } = useInlineSubscribe({ blog: userInfo?.data.blog })

  const Container = (props: any) => (
    <div>
      <div className="-mt-px flex divide-x divide-gray-200 bg-gray-50">
        <div className="flex w-0 flex-1">
          <div className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900 ">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  )

  // Eg user not loged in or user has no Paragraph newsletter.
  if (!ableToSubscribe) return null

  // Loading
  if (!userInfo || isUserSubscribed === null || subscribeClickLoading) {
    return (
      <Container>
        <svg
          className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-500 justify-center "
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </Container>
    )
  }

  if (!userInfo?.data.blog) return null

  return (
    <Container>
      <a
        onClick={() => subscribeUser()}
        className={clsx(
          "relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 ",

          isUserSubscribed ? "cursor-auto" : "cursor-pointer"
        )}
      >
        {isUserSubscribed ? (
          <CheckIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
        ) : (
          <EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        )}

        {isUserSubscribed
          ? "Subscribed"
          : subscribeClickLoading
          ? "Subscribing..."
          : "Subscribe"}
      </a>
    </Container>
  )
}

export default function AuthorCard(props: {
  authorId?: string
  wallet?: string
  hideSubscriberState?: boolean
}) {
  const { authorId, wallet } = props
  const [userInfo, setUserInfo] = useState<PublicUser | null>(null)
  const blog = useSelector(selectBlog)

  useEffect(() => {
    if (!wallet && !authorId) return

    if (authorId) {
      PublicUser.createServiceWithUserId({
        userId: authorId,
        blogIdContext: blog.id,
      }).then((data) => {
        // We don't care about the subscription status of the author
        delete data.data.subscription
        setUserInfo(data)
      })
      return
    }

    if (wallet) {
      PublicUser.createServiceWithWallet({
        wallet: wallet,
        blogIdContext: blog.id,
      }).then((data) => {
        setUserInfo(data)
      })
    }
  }, [authorId, wallet, blog.id])

  return (
    <div className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow-lg absolute w-80 md:w-96 border-gray-100 border">
      <div className="pb-6 px-6">
        <div className="flex w-full items-center justify-between space-x-6 pt-6">
          <div className="flex-1 ">
            <div className="flex items-center space-x-3">
              <NameOrPlaceholder publicUser={userInfo} />

              <SubscriberBadge publicUser={userInfo} />
            </div>
            {userInfo !== null && userInfo?.bio && (
              <p className="mt-1 text-sm text-gray-500 break-normal font-normal">
                {userInfo.bio}
              </p>
            )}
          </div>
          {userInfo?.pfp && (
            <img
              className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"
              src={userInfo.pfp}
              alt=""
            />
          )}
        </div>

        {(userInfo?.data.user || userInfo?.data?.farcaster) && (
          <SocialMediaIcons
            author={userInfo.data.user}
            farcaster={userInfo.data.farcaster}
            className="text-lg mt-4"
          />
        )}
      </div>

      <SubscribeButton userInfo={userInfo} />
    </div>
  )
}

import { Membership } from "@types"
import clsx from "clsx"
import useMembershipSubscriptionInfo from "./useMembershipSubscriptionInfo"
import useIsUsersOwnBlog from "./useIsUsersOwnBlog"

/**
 * Used by the MembershipCard CTAButton component for styling.
 * 
 * There are several visual states in which the CTA button can be, depending on whether it's disabled,
 * whether the button is loading, and whether the membership is recommended.
 * 
  // There are several visual states in which the CTA button can be:
  // 1. Grants access and is recommended.
  // 2. Grants access but is not recommended.
  // 3. Does not grant access.
  // 4. Disabled because the user already has this membership.
 */
export default function useMembershipCardButtonStyles(
  membership: Membership,
  memberships: Membership[],
  isDisabledDueToInsufficientPermissions: boolean,
  currentActionItem: string,
  isSubscribedToThisFrequency: boolean,
  isPastDue: boolean,
  isAuthorView: boolean,
  isFormPreview: boolean,
  isOnUpsellPage?: boolean
) {
  const {
    subscription,
    isFreeAndActivePlan,
    isSubscribedToThisMembership,
    isExpired,
  } = useMembershipSubscriptionInfo(membership, memberships)

  const isUsersOwnBlog = useIsUsersOwnBlog()

  // Variables that determine class names.
  const isBlocked = // Don't allow a user to click the button.
    currentActionItem || // If action in progress.
    isExpired === "EXPIRED" || // If the user's subscription is expired.
    (isPastDue && // If the user's subscription is past due
      subscription?.membershipId === membership.id) ||
    (!isUsersOwnBlog && isSubscribedToThisFrequency && !isOnUpsellPage) || // If the user is subscribed to this frequency.
    // If the user is subscribed to this membership and are on the same frequency, and this membership is not free.
    (!isUsersOwnBlog &&
      isSubscribedToThisMembership &&
      isSubscribedToThisFrequency &&
      !isFreeAndActivePlan &&
      !isOnUpsellPage)

  const grantsAccess = !isDisabledDueToInsufficientPermissions

  const logVars = {
    isRecommended: membership.isRecommended,
    isSubscribedToThisMembership,
    isSubscribedToThisFrequency,
    isDisabledDueToInsufficientPermissions,
    grantsAccess,
    currentActionItem,
    isFreeAndActivePlan,
    isOnUpsellPage,
    isExpired,
    isBlocked,
    membership,
    subscription,
  }

  // Class names.
  const mainClasses =
    "mx-2 mt-4 block rounded-md py-2 px-4 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
  const recommendedClasses = "cursor-pointer bg-primary shadow-sm"
  const notRecommendedClasses =
    "cursor-pointer text-primary-700 ring-1 ring-inset ring-primary-200 hover:ring-primary-300 hover:bg-primary duration-100"
  const doesNotGrantAccessClasses =
    "text-gray-600 hover:text-gray-900 ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
  const disabledRecommendedClasses =
    "cursor-not-allowed bg-opacity-50 text-custom-300 ring-1 ring-inset ring-custom-300"
  const disabledNotRecommendedClasses =
    "cursor-not-allowed text-custom-300 ring-1 ring-inset ring-custom-300"

  if (isAuthorView || isFormPreview)
    return clsx(mainClasses, recommendedClasses)

  if (
    grantsAccess &&
    membership.isRecommended &&
    !isBlocked

    // !isSubscribedToThisMembership
  ) {
    console.log(
      `useMembershipCardButtonStyles for ${membership.name} card 1`,
      logVars
    )
    return clsx(mainClasses, recommendedClasses)
  }

  if (grantsAccess && !membership.isRecommended && !isBlocked) {
    console.log(
      `useMembershipCardButtonStyles for ${membership.name} card 2`,
      logVars
    )
    return clsx(mainClasses, notRecommendedClasses)
  }

  if (!grantsAccess) {
    console.log(
      `useMembershipCardButtonStyles for ${membership.name} card 3`,
      logVars
    )
    return clsx(mainClasses, doesNotGrantAccessClasses)
  }

  if (isBlocked && !membership.isRecommended) {
    console.log(
      `useMembershipCardButtonStyles for ${membership.name} card 4`,
      logVars
    )
    return clsx(mainClasses, disabledNotRecommendedClasses)
  }

  if (isBlocked && membership.isRecommended) {
    console.log(
      `useMembershipCardButtonStyles for ${membership.name} card 5`,
      logVars
    )
    return clsx(mainClasses, disabledRecommendedClasses)
  }

  console.log(
    `useMembershipCardButtonStyles for ${membership.name} card 6`,
    logVars
  )

  return mainClasses
}

import { Editor } from "@tiptap/core"
import { generateAiResponse } from "api_routes/published"
import { updateAndSaveNote } from "features/noteSlice"

export type AiPromptType =
  | "title"
  | "outline"
  | "draft"
  | "sentence"
  | "paragraph"
  | "ask"

export const handlePrompt = async (
  editor: Editor,
  dispatch: any | null,
  track: (msg: string, data?: { [key: string]: any }) => void,
  type: AiPromptType,
  prompt: string,
  extraData?: { [key: string]: any }
) => {
  track("AI prompt entered", { type, prompt })

  if (prompt) {
    const reader = await generateAiResponse(type, prompt, extraData)

    if (!reader) {
      console.error(
        `failed to generate AI response for ${type} and prompt ${prompt}`
      )
      track("failed to generate AI response", { type, prompt })
      return
    }

    let response = "",
      title,
      subtitle,
      content
    while (true) {
      const { value, done } = await reader.read()
      if (done) {
        // Actually save changes
        if (dispatch) {
          dispatch(
            updateAndSaveNote({
              note: { title, subtitle },
              save: true,
              calledBy: "Editor Utils - handlePrompt save title and subtitle",
            })
          )
        }
        if (content) editor.commands.setContent(content, true)
        track("generated AI response", { type, prompt })
        console.log("Generated AI response", { type, prompt })
        break
      }
      if (!value) continue

      // value is the response delta so we will append it to the current response
      // in order to reconstruct the entire response
      response += value

      // Check for prompt not allowed response
      if (response.startsWith("$")) {
        dispatch(
          updateAndSaveNote({
            note: {
              title: "Sorry, this AI prompt is not allowed",
              subtitle: "",
            },
            save: false,
            calledBy: "Editor Utils - handlePrompt error message",
          })
        )
        console.error("AI prompt is not allowed", prompt)
        track("forbidden AI prompt entered", { type, prompt })
        return
      }

      switch (type) {
        case "title":
          // Split title: subtitle
          // eslint-disable-next-line @typescript-eslint/no-extra-semi
          ;[title, subtitle] = response.split(":")
          if (title) title = title.trim()
          if (subtitle) subtitle = subtitle.trim()

          console.log("Updating title and subtitle", { title, subtitle })

          dispatch(
            updateAndSaveNote({
              note: { title, subtitle },
              save: false,
              calledBy: "Editor Utils - handlePrompt switch case title",
            })
          )
          break
        case "outline":
        case "draft":
          // Extract html
          // https://regex101.com/r/VPzbTl/1
          const htmlStartMatch = response.match(/<html.*>/)
          if (!htmlStartMatch) continue
          const htmlStart = response.indexOf(htmlStartMatch[0])
          const htmlEnd = response.indexOf("</html>")
          const html = response.slice(
            htmlStart,
            htmlEnd !== -1 ? htmlEnd + 7 : response.length
          )

          // Extract title
          const titleStart = html.indexOf("<title>")
          if (titleStart === -1) continue
          const titleEnd = html.indexOf("</title>", titleStart)
          title = html.slice(
            titleStart + 7,
            titleEnd !== -1 ? titleEnd : html.length
          )
          dispatch(
            updateAndSaveNote({
              note: { title },
              save: false,
              calledBy: "Editor Utils - handlePrompt switch case draft title",
            })
          )

          // Extract subtitle (meta description)
          const metaStart = html.indexOf('<meta name="description" content="')
          if (metaStart === -1) continue
          const metaEnd = html.indexOf('">', metaStart)
          subtitle = html.slice(
            metaStart + 34,
            metaEnd !== -1 ? metaEnd : html.length
          )
          dispatch(
            updateAndSaveNote({
              note: { subtitle },
              save: false,
              calledBy:
                "Editor Utils - handlePrompt switch case draft subtitle",
            })
          )

          // Extract body
          const bodyStart = html.indexOf("<body>")
          if (bodyStart === -1) continue
          const bodyEnd = html.indexOf("</body>", bodyStart)
          content = html.slice(
            bodyStart,
            bodyEnd !== -1 ? bodyEnd + 7 : html.length
          )

          editor.commands.setContent(content)
          editor.commands.scrollIntoView()
          break
        case "sentence":
        case "paragraph":
          editor.commands.insertContent(value)
          editor.commands.scrollIntoView()
          break
        case "ask":
          editor.commands.insertContent(value)
          editor.commands.scrollIntoView()
          break
        default:
          console.error(`AI prompt type not supported: ${type}`)
      }
    }
  }
}

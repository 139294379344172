import Banner from "components/Banner"
import ConnectWalletBtn from "@/components/ConnectWalletButton"
import { SharedProps } from "./SharedProps"

export default function GateModalStepConnectWallet(props: SharedProps) {
  return (
    <div className="mt-1 sm:mt-0 sm:col-span-1">
      {!("wallet_address" in props.user) && (
        <div className="my-4 text-gray-600 text-base w-full">
          Connect your wallet gate using a token.
        </div>
      )}
      {"wallet_address" in props.user && props.user.wallet_address && (
        <div className="max-w-lg pt-2 text-sm pb-4 text-gray-600">
          You're using wallet address{" "}
          <span className="text-gray-800 font-semibold">
            {props.user.wallet_address}
          </span>
        </div>
      )}
      <ConnectWalletBtn hide={props.userHasWallet} text="Connect wallet" />

      {props.walletConnectError.msg && (
        <div className="mt-5">
          <Banner type="error" text={props.walletConnectError.msg} />
        </div>
      )}
    </div>
  )
}

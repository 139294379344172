var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2bfd498e94df363f74516c73cb7fd3de5b48c459"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { initSentry } from "./sentry.config"
import {
  browserTracingIntegration,
  replayIntegration,
  browserProfilingIntegration,
} from "@sentry/nextjs"

initSentry({
  integrations: [
    browserTracingIntegration(),
    replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
    browserProfilingIntegration(),
  ],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0.2,
  tracePropagationTargets: ["paragraph.xyz/api"],
})

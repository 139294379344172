import { Membership } from "@types"
import FreeSubscribeModalInner from "components/SubscribeModal/FreeSubscribeModalInner"
import { useRef } from "react"

export default function MembershipRegister({
  membership,
}: {
  membership: Membership
}) {
  const initialFocusRef = useRef(null)

  return (
    <div className="mt-2 mb-8 border border-gray-300 rounded-lg w-100 py-10 px-4">
      <p className="text-gray-900 text-md text-center">
        You're subscribing to the {membership.name} plan. Enter your email to
        get started.
      </p>
      <FreeSubscribeModalInner
        initialFocusRef={initialFocusRef}
        origin="membershipRegister"
      />
    </div>
  )
}

import { Node } from "@tiptap/core"
import {
  Editor,
  NodeViewContent,
  NodeViewWrapper,
  ReactNodeViewRenderer,
} from "@tiptap/react"
import Button from "components/Button"
import { MdDelete } from "react-icons/md"
import { RiLinkM } from "react-icons/ri"
import { Fragment } from "@tiptap/pm/model"

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    customButton: {
      setCustomButton: () => ReturnType
    }
  }
}

export default Node.create({
  name: "customButton",
  group: "block",
  content: "text*",

  addAttributes() {
    return {
      href: {
        default: null,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
        getContent: (node, schema) => {
          const html = new DOMParser().parseFromString(
            (node as HTMLElement).getAttribute("html") || "",
            "text/html"
          ).body.children

          const fragment = Fragment.fromJSON(schema, [
            {
              type: "text",
              text: html[0].textContent,
            },
          ])

          return fragment
        },
      },
    ]
  },

  addCommands() {
    return {
      setCustomButton:
        () =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            content: [{ type: "text", text: "Button" }],
          })
        },
    }
  },

  renderHTML({ node, HTMLAttributes }) {
    const title = node.textContent
    const html = `<a class="email-subscribe-button" href="${HTMLAttributes.href}">${title}</a>`

    return [
      "div",
      {
        "data-type": this.name,
        ...HTMLAttributes,
        class: "center-contents",
        html,
      },
    ]
  },

  addNodeView() {
    return ReactNodeViewRenderer(CustomButtonComponent)
  },
})

type Props = {
  editor: Editor | null
  node: any
  updateAttributes: any
  deleteNode(): void
}

function CustomButtonComponent({
  editor,
  node,
  updateAttributes,
  deleteNode,
}: Props): JSX.Element {
  return (
    <NodeViewWrapper>
      <div className="flex justify-center my-5" contentEditable={false}>
        <a
          href={node.attrs.href}
          target="_blank"
          rel="noreferrer"
          className={`${
            editor?.isEditable && "pointer-events-none ml-40"
          } hover:!border-none`}
        >
          <Button onClick={(e) => editor?.isEditable && e.preventDefault()}>
            {editor?.isEditable ? (
              <NodeViewContent
                contentEditable={true}
                className="custom-button-input"
              />
            ) : (
              <span>{node.textContent}</span>
            )}
          </Button>
        </a>
        {editor?.isEditable && (
          <div className="w-40 flex justify">
            <button
              className={
                "relative p-1 pl-2 text-2x cursor-pointer hover:text-gray-500"
              }
              onClick={() => {
                let href = prompt("Enter button link", node.attrs.href || "")
                if (href) {
                  const re = new RegExp("^http(s)?://.*")
                  href = re.test(href) ? href : `https://${href}`
                  updateAttributes({ href })
                }
              }}
            >
              <RiLinkM size={"1.2rem"} />
            </button>
            <button
              className={"p-1 text-2x cursor-pointer hover:text-gray-500"}
              onClick={() => deleteNode()}
            >
              <MdDelete size={"1.2rem"} />
            </button>
          </div>
        )}
      </div>
    </NodeViewWrapper>
  )
}

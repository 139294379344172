import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactNode } from "react"

import { WagmiProvider as _WagmiProvider } from "wagmi"

import { getConfig } from "util/wagmiConfig"

const WagmiProvider = ({ children }: { children: ReactNode }) => {
  const queryClient = new QueryClient()

  const config = getConfig()

  return (
    <_WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </_WagmiProvider>
  )
}

export default WagmiProvider

import { axios, params } from "./resolve"

const API = "/loop"

export async function createLoopCheckoutSession(
  blogId: string,
  noteId: string | null,
  membershipId: string,
  itemId: string,
  entityId: string,
  wallet: string,
  discount: string | null
): Promise<string> {
  const res = await axios.post(
    `${API}/session`,
    { blogId, noteId, membershipId, itemId, entityId, wallet, discount },
    params
  )
  return res.data.refId
}

export async function createLoopAccount(wallet: string): Promise<void> {
  await axios.post(`${API}/account`, { wallet }, params)
}

export async function updateLoopAccount(
  wallet?: string,
  enabledNetworks?: string[],
  enabledTokens?: string[]
): Promise<void> {
  await axios.patch(
    `${API}/account`,
    { wallet, enabledNetworks, enabledTokens },
    params
  )
}

export async function disconnectLoopAccount(): Promise<void> {
  await axios.delete(`${API}/account`, params)
}

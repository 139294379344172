import { CHAINS } from "@/types/highlights"

import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid"
import { COLLECTIBLE_DATA } from "@/util/crypto"
import { formatEther } from "ethers/lib/utils"
import { useGetMintFee } from "hooks/collectibles/useGetMintFee"

export default function MintFeeText(props: {
  /**
   * Pass in the version of the collectible.
   * For version 0 there's no mint fee.
   *
   * If this is unspecified it's assumed to be the latest version
   * (eg, the user is minting a new post versus collecting an existing
   * one).
   */
  collectibleVersion?: number
  chain: CHAINS
}) {
  // No fee for version 0.
  if (props.collectibleVersion === 0) return null
  return <InnerMintText chain={props.chain} />
}

function InnerMintText(props: { chain: CHAINS }) {
  const { fee } = useGetMintFee({ chain: props.chain })
  const unit = COLLECTIBLE_DATA[props.chain].unit

  const feeEther = parseFloat(formatEther(fee || 0))

  return (
    <div className="flex justify-center flex-row w-full">
      <p className="text-gray-500 mt-5 text-xs inline">
        Collectors pay a {feeEther} {unit} mint fee.
        <a
          href="https://docs.paragraph.xyz/docs/nfts#fees-and-rewards"
          target="_blank"
          rel="noreferrer"
          className="inline-block"
        >
          <QuestionMarkCircleIcon className="ml-2 inline h-5 w-5 text-gray-300 hover:text-gray-400 align-middle" />
        </a>
      </p>
    </div>
  )
}

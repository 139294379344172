import { useSelector } from "react-redux"
import { ToastOptions, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  clearBannerToast,
  markBannerToastAsShown,
  selectBannerToasts,
} from "features/pageSlice"
import { useAppDispatch } from "store"
import ChildDetails from "./ChildDetails"
import { useEffect } from "react"
import { useAnalytics } from "hooks/useAnalytics"

const autoClose = 5000

const toastSettings: ToastOptions = {
  position: "bottom-right",
  autoClose,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
}

export default function useShowToast() {
  const dispatch = useAppDispatch()
  const toasts = useSelector(selectBannerToasts)
  const { track } = useAnalytics()

  console.log("Loading toasts", { toasts })

  const unsubscribeOnChangeListener = toast.onChange((toast) => {
    if (toast.status !== "removed") return
    if (typeof toast.id !== "string") return

    // Clear the toast from local storage a second after it's closed (to not interfere with the animation).
    dispatch(clearBannerToast(toast.id))
  })

  useEffect(() => {
    toasts.forEach((toastDetails) => {
      // If the toast is already shown, don't show it again.
      if (toastDetails.isShown) {
        return
      }

      const { id, type, text, title } = toastDetails

      // Also search for duplicate toasts. If there's a duplicate toast that is already marked as shown, don't show it again.
      const isDuplicateToast = toasts.find(
        (toast) => toast.text === text && toast.isShown
      )

      console.log("Toast: Got the toast", {
        toastDetails,
        isDuplicateToast,
        toasts,
      })

      if (isDuplicateToast) {
        console.log("Toast: Found duplicate")
        dispatch(clearBannerToast(toastDetails.id))
        return
      }

      track("Toast shown", {
        toastText: text,
        toastTitle: title,
      })

      // 1. There's no title. In this case just display a simple text toast.
      if (title === undefined) {
        toast(text, {
          ...toastSettings,
          toastId: id,
          type: type,
        })
      } else {
        // 2. There's a title. In this case display a toast with a title in a custom component.
        const childComponent = <ChildDetails text={text} title={title} />

        toast(childComponent, {
          ...toastSettings,
          toastId: id,
          type: type,
        })
      }

      // Mark the toast as shown in Redux.
      dispatch(markBannerToastAsShown(toastDetails.id))
    })

    // Cleanup: Remove the toast listener when the component unmounts or if toastDetails changes.
    return () => {
      unsubscribeOnChangeListener()
    }
  }, [toasts, dispatch])

  return null
}

import { CompletedCollectible } from "@/types/highlights"
import Button from "components/Button"
import { CollectibleAvatars } from "components/public/BlogTheme/AuthorAvatars"
import { BigNumber } from "ethers"
import { selectPostCollectible } from "features/pageSlice"
import useCollectors from "hooks/collectibles/useCollectors"
import useExistingHighlightMetadata from "hooks/collectibles/useExistingHighlightMetadata"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useAnalytics } from "hooks/useAnalytics"
import CollectPostPopover from "./Popover/CollectPostPopover"
import clsx from "clsx"
import useUserCollectingHighlight from "hooks/useUserCollectingHighlight"
import { User } from "@/types/users"
import CollectorModal from "./CollectorModal"

// Display fake collectors when loading the real collectors avatars.
const FakeCollectors = [{}, {}, {}] as User[]

function CollectorAvatars(props: {
  onClick: () => void
  collectible: CompletedCollectible
}) {
  const metricData = useExistingHighlightMetadata({
    collectible: props.collectible,
  })

  const { supply } = metricData

  const collectors = useCollectors(props.collectible, supply)

  const numCollectors = Math.max(
    BigNumber.from(supply).toNumber() || collectors.length
  )

  console.log("COLLECTORS AND NUM COLLECTORS", collectors.length, numCollectors)

  if (!numCollectors && collectors.length === 0) return null

  const combinedAndFakeCollectors = collectors
    .concat(FakeCollectors)
    .slice(0, Math.min(3, numCollectors))

  return (
    <div className="hidden xs:block" onClick={() => props.onClick()}>
      <CollectibleAvatars
        users={combinedAndFakeCollectors as User[]}
        size={8}
      />

      <div className="text-xs text-custom-500 text-center">
        {numCollectors} {numCollectors === 1 ? "collector" : "collectors"}
      </div>
    </div>
  )
}

export default function CollectPostBtn({
  disabled = false,
  hideAvatar = false,
  full = false,
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [collectorModalOpen, setCollectorModalOpen] = useState(false)
  const [referenceElement, setReferenceElement] = useState<
    HTMLElement | undefined | null
  >(null)

  const collectible = useSelector(selectPostCollectible)
  const collectors = useCollectors(collectible?.collectible)
  const { track } = useAnalytics()

  const isUserCollectingHighlight = useUserCollectingHighlight(
    collectible?.collectible
  )

  console.log("These are the collectors", collectors, collectors?.length > 0)
  useEffect(() => {
    if (!isOpen) return
    track("collect post popover opened")
  }, [isOpen])

  return (
    <>
      <CollectPostPopover
        referenceElement={referenceElement}
        isModalOpen={isOpen}
        setIsModalOpen={setIsOpen}
      />

      <CollectorModal
        open={collectorModalOpen}
        setOpen={setCollectorModalOpen}
      />

      <div className="flex items-center space-x-4">
        <div ref={setReferenceElement} className={clsx(full ? "w-full" : "")}>
          <Button
            type="button"
            onClick={() => !disabled && setIsOpen(!isOpen)}
            loading={isUserCollectingHighlight}
            replaceClassName={clsx(
              full ? "w-full" : "",

              "bg-primary hover:bg-primary-800 justify-self-center align-middle focus:ring-primary-500 justify-center px-4 py-2 text-sm font-medium text-white border border-transparent shadow-sm rounded-md focus:outline-none focus:ring-2 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-100 h-10"
            )}
          >
            {isUserCollectingHighlight ? "" : "Collect"}
          </Button>
        </div>

        {collectible?.collectible?.status === "COMPLETE" && !hideAvatar && (
          <CollectorAvatars
            onClick={() => setCollectorModalOpen(true)}
            //onClick={() => setIsOpen(!isOpen)}
            collectible={collectible?.collectible}
          />
        )}
      </div>
    </>
  )
}

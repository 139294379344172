import { axios } from "./resolve"
import { AxiosResponse } from "axios"
import {
  ErrorResponse,
  Subscription,
  Blog,
  isErrorResponse,
  SuccessResponse,
  SocialMediaLinks,
  BlogSubscriptionAndMembership,
} from "@types"
import { User as UserType, UserParams, UserParamsEmail } from "@/types/users"
import { SIWESession } from "connectkit"

const API = "/users"

export class User {
  // TODO: Need to implement the typescript types for this
  id = ""
  email = ""
  password = ""
  hasPassword = false
  createdAt = Date.now()

  // TODO: Remove this. It's causing FE console errors:
  // "A non-serializable value was detected in the state, in the path: `user.user`. Value: "
  constructor(props: UserType) {
    Object.assign(this, props)
  }
}

export async function newUser(
  userParams: UserParamsEmail
): Promise<User | ErrorResponse> {
  console.log("About to call NEW USER on " + API)
  try {
    const res: AxiosResponse<User> = await axios.post(API, userParams)
    return res.data
  } catch (err: any) {
    if (err?.response?.status === 400) {
      return {
        msg: err.response.data.msg,
      }
    }
  }
  return { msg: "An error occurred during registration." }
}

export async function loginUser(
  userParams: UserParams
): Promise<User | ErrorResponse> {
  try {
    const res: AxiosResponse<User> = await axios.post(
      `${API}/login`,
      userParams
    )
    return res.data
  } catch (err: any) {
    if (err?.response?.status === 400) {
      return {
        msg: "Email or password is incorrect",
      }
    }
  }
  return { msg: "An error occurred logging in." }
}

export async function loginUnstoppableDomainsUser(
  userParams: UserParams
): Promise<User | ErrorResponse> {
  try {
    const res: AxiosResponse<User> = await axios.post(
      `${API}/unstoppable-domains`,
      userParams
    )
    return res.data
  } catch (err: any) {
    if (err.response.status === 400) {
      return {
        msg: err.response.data.msg,
      }
    }
  }
  return { msg: "An error occurred logging in." }
}

export async function logoutUser(): Promise<boolean> {
  const res: AxiosResponse<boolean> = await axios.post(`${API}/logout`)
  return res.data
}

export async function changeAvatar(file: File): Promise<boolean> {
  const formData = new FormData()
  formData.append("file", file)

  const res: AxiosResponse<boolean> = await axios.post(
    `${API}/avatar`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  )

  return res.data
}

export async function removeAvatar(): Promise<boolean> {
  const res: AxiosResponse<boolean> = await axios.delete(`${API}/avatar`)

  return res.data
}

export async function refreshUser(): Promise<User> {
  const res: AxiosResponse<User> = await axios.get(`${API}/refresh`)
  return res?.data
}

export async function getUsersSubscriptionToBlog(
  blogId: string
): Promise<Subscription | ErrorResponse> {
  const res: AxiosResponse<Subscription | ErrorResponse> = await axios.get(
    `${API}/subscriptions/${blogId}`
  )

  return res.data
}

/**
 * This call doesn't require the standard JWT token, but instead uses a unique unsubscribe code
 * obtained from the user's email.
 * @param blogId ID of the blog to unsubscribe from.
 * @param manageSubscriptionCode Unique code obtained from the user's newsletter email.
 * @returns Subscription object if successful, ErrorResponse if not.
 */
export async function getUsersSubscriptionToBlogUsingmanageSubscriptionCode(
  blogId: string,
  manageSubscriptionCode: string
): Promise<Subscription | ErrorResponse> {
  const res: AxiosResponse<Subscription | ErrorResponse> = await axios.get(
    `${API}/subscriptions/${blogId}/manageSubscriptionCode/${manageSubscriptionCode}`
  )

  return res.data
}

export async function forgotPassword(
  email: string
): Promise<null | ErrorResponse> {
  const res: AxiosResponse = await axios.post(`${API}/forgot-password`, {
    email,
  })

  return res.data
}

export async function getTeamBlogs(): Promise<Array<Blog>> {
  const res: AxiosResponse = await axios.get(`${API}/team/blogs`)

  return res.data
}

export async function updateUserAuthorDetails(
  userId: string,
  authorName?: string,
  authorBio?: string,
  social?: SocialMediaLinks
): Promise<SuccessResponse | ErrorResponse> {
  try {
    const res: AxiosResponse<SuccessResponse | ErrorResponse> = await axios.put(
      `${API}/${userId}`,
      { authorName, authorBio, social }
    )

    return res.data
  } catch (err: any) {
    if (err?.response?.status === 400) {
      return {
        msg: err.response.data.msg,
      }
    }

    console.error("Error encountered", err)

    return { msg: "An unknown error occurred updating your blog" }
  }
}

// This function can only be called for a user that has some other way to login too,
// and not just their wallet! Moreover, they must be authenticated in that other way
// to prove that they own the account.
export async function disconnectWallet(
  wallet_address: string,
  userId: string
): Promise<null | ErrorResponse> {
  console.log(
    `About to disconnect wallet with address ${wallet_address} from user with ID ${userId}.`
  )

  try {
    const res: AxiosResponse<ErrorResponse> = await axios.delete(
      `${API}/disconnect-wallet`
    )

    if (isErrorResponse(res)) {
      console.error("Error disconnecting wallet:", res)

      return {
        msg: res.msg,
      }
    }

    console.log(
      `Successfully disconnected wallet with address ${wallet_address} from user with ID ${userId}.`
    )

    return null
  } catch (err: any) {
    console.error("Caught error disconnecting wallet:", err)

    if (err?.response?.status === 400) {
      return {
        msg: err.response.data.msg,
      }
    }
  }

  return { msg: "An error occurred disconnecting wallet." }
}

export async function resetPassword(
  uuid: string,
  password: string
): Promise<null | ErrorResponse> {
  const res: AxiosResponse = await axios.post(`${API}/reset-password/${uuid}`, {
    password,
  })

  return res.data
}

export async function initiateUpdateEmail(
  email: string
): Promise<null | ErrorResponse> {
  const res: AxiosResponse = await axios.post(`${API}/update-email`, { email })

  return res.data
}

export async function confirmUpdateEmail(
  uuid: string
): Promise<null | ErrorResponse> {
  const res: AxiosResponse = await axios.post(`${API}/update-email/${uuid}`, {})

  return res.data
}

export async function getAllUsersSubscriptions(): Promise<
  Array<BlogSubscriptionAndMembership>
> {
  const res: AxiosResponse<Array<BlogSubscriptionAndMembership>> =
    await axios.get(`${API}/subscriptions`)

  return res.data
}

export async function getWalletSession(): Promise<SIWESession> {
  const res: AxiosResponse = await axios.get(`${API}/siwe/session`)
  return res.data
}

export async function generateNonce(): Promise<string> {
  const res: AxiosResponse<{ nonce: string }> = await axios.post(
    `${API}/siwe/nonce`
  )
  return res.data.nonce
}

export async function verifyNonce({
  wallet_address,
  signature,
  ens,
  message,
}: {
  wallet_address: string
  signature: string
  message: string
  ens?: string
  referrerWalletAddress?: string
}): Promise<User> {
  const payload = {
    wallet_address,
    signature,
    ens,
    message,
  }

  const res: AxiosResponse<User> = await axios.post(
    `${API}/siwe/verify`,
    payload
  )

  return res.data
}

export async function permanentlyDeleteUserAccount(
  userId: string,
  feedbackMsg: string
): Promise<boolean | ErrorResponse> {
  const res: AxiosResponse<boolean> = await axios.delete(
    `${API}/user/${userId}?feedback=${feedbackMsg}`
  )

  return res.data
}

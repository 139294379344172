import { axios } from "./resolve"
import type { CommentAndRepliesWithUser, CommentWithUser } from "@types"

import type { CastThread } from "@/types/farcaster"
import { AxiosResponse } from "axios"

const API = "/comments"

export async function apiNewComment(
  noteId: string,
  text: string,
  replyToCommentId?: string
): Promise<CommentWithUser> {
  console.log("About to call NEW COMMENT on " + API)
  const res: AxiosResponse<CommentWithUser> = await axios.post(
    `${API}/${noteId}`,
    { text, replyToCommentId }
  )

  return res.data
}

export async function apiGetCommentsForNote(
  noteId: string
): Promise<Array<CommentAndRepliesWithUser>> {
  console.log("About to call GET COMMENTS on " + API)
  const res: AxiosResponse = await axios.get(`${API}/${noteId}`)

  return res.data
}

export async function apiGetFcCommentsForNote(
  noteId: string
): Promise<Array<CastThread>> {
  try {
    console.log("About to call GET COMMENTS on " + API)
    const res: AxiosResponse = await axios.get(`${API}/farcaster/${noteId}`)

    return res.data
  } catch (err) {
    console.error("Exception occurred fetching Farcaster comments.")
    return []
  }
}

export async function apiUpdateComment(
  commentId: string,
  text: string
): Promise<CommentWithUser> {
  console.log("About to call UPDATE COMMENT on " + API)
  const res: AxiosResponse<CommentWithUser> = await axios.put(
    `${API}/${commentId}`,
    { text }
  )

  return res.data
}

export async function apiDeleteComment(commentId: string): Promise<string> {
  console.log("About to call DELETE COMMENT on " + API)
  const res: AxiosResponse<string> = await axios.delete(`${API}/${commentId}`)

  return res.data
}

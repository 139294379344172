import { FormEvent, useEffect, useRef, useState } from "react"
import { XMarkIcon } from "@heroicons/react/24/solid"
import { useAutoAnimate } from "@formkit/auto-animate/react"

import { OnChainCryptoData } from "@types"

import {
  EnrichedGatingRuleGroup,
  GatingContentType,
  GatingRequirementGateType,
  ImportedTokenOnChainData,
} from "@/types/gatingRules"
import { useAppDispatch as useDispatch } from "store"
import { useSelector } from "react-redux"
import { selectLatestCurrentNote } from "features/noteSlice"
import { selectUsersBlog, selectUsersTokens } from "features/blogSlice"
import { refreshUser, selectUser } from "features/userSlice"

import GateModalStepMintOrImportToken from "./GateModalStepMintOrImportToken/Controller"
import GateModalStepChooseToken from "./GateModalStepChooseToken"
import { selectError } from "features/errorSlice"
import useDebounce from "hooks/useDebounce"
import useGatingRulesForEditingAuthor from "hooks/useGatingRulesForEditingAuthor"
import GateModalStepGroup from "./GateModalStepGroup"
import GateModalStepGateType from "./GateModalStepGateType"
import {
  CanChangeStep,
  createGate,
  FindStep,
  setAvailableSteps,
} from "./GateModalHelperFunctions"
import GateModalStepConnectWallet from "./GateModalStepConnectWallet"
import GateModalNavBar from "./GateModalNavBar"
import { GateModalStep, StepDefinitionGroup } from "./GateModalSteps"
import ButtonController from "./Buttons/ButtonController"
import GateModalStepEmailSettings from "./GateModalStepEmailSettings"
import { SharedProps } from "./SharedProps"
import GateModalStepChooseMembership from "./GateModalStepChooseMembership"

interface props {
  setOpen: (val: boolean) => void
  gateContentType: GatingContentType
  embedId?: string // Optional. ID of the embed you want to edit.
  callbackSetIsActive?: (isActive: boolean) => void // Optional. Callback function that gets fed an active state depending on if gating rules exist.
}

export default function GateModal({
  setOpen,
  gateContentType,
  embedId, // Optional. ID of the embed you want to edit.
  callbackSetIsActive, // Optional. Callback function that gets fed an active state depending on if gating rules exist.
}: props) {
  const user = useSelector(selectUser)
  const cancelButtonRef = useRef(null)
  const note = useSelector(selectLatestCurrentNote)
  const walletConnectError = useSelector(selectError)
  const blog = useSelector(selectUsersBlog)
  const [loading, setLoading] = useState(false)
  const [selectedGateType, setSelectedGateType] = useState<string>()
  const existingGateGroups = useGatingRulesForEditingAuthor(
    note?.id || "",
    gateContentType,
    embedId,
    "GateModalInnerSettings"
  )

  console.log("AFTER GATE :", embedId, existingGateGroups)

  if (callbackSetIsActive)
    callbackSetIsActive(
      existingGateGroups !== undefined && existingGateGroups.length > 0
    )

  // This is used to update certain buttons and text that is sensitive to UI changes.
  // For instance, since existingGates gets updated instantly when a gate is created or deleted,
  // before the modal has even had a change to close, without this, those buttons (e.g. delete)
  // get changed to (e.g. create) before the modal has closed, just for a split second.
  const uiSensitiveExistingGateGroups: EnrichedGatingRuleGroup[] | undefined =
    useDebounce(existingGateGroups, 500)

  const [selectedGateGroupId, setSelectedGateGroupId] = useState<
    string | undefined
  >()
  const [addingGate, setAddingGate] = useState(false)
  // Default steps, but this will likely be changed depending on whether the user's wallet is connected, and what type of gate they're creating.
  const [steps, setSteps] = useState<GateModalStep[]>()
  const [currentStep, setCurrentStep] = useState<GateModalStep>()
  const debouncedCurrentStep: GateModalStep = useDebounce(currentStep, 200) // This debounce value controls the duration of the opacity/blur change as we move between steps in the wizard.
  const [notMovedAStepYet, setNotMovedAStepYet] = useState(true)
  const userHasWallet = "wallet_address" in user && !!user.wallet_address
  const [mintError, setMintError] = useState("")
  const [createGateError, setCreateGateError] = useState("")
  const tokens = useSelector(selectUsersTokens)
  const dispatch = useDispatch()
  const [mintOrImportToken, setMintOrImportToken] = useState<
    "MINT" | "IMPORT" | ""
  >("")
  const [minQuantity, setMinQuantity] = useState(1) // Min. quantity of tokens required to access gated content.
  const [
    selectedExistingParagraphTokenId,
    setSelectedExistingParagraphTokenId,
  ] = useState("")
  const [selectedMembershipId, setSelectedMembershipId] = useState("")

  // Used to automatically animate modal wizard height and width changes.
  const [parent] = useAutoAnimate<HTMLDivElement>({
    duration: 400,
    easing: "ease-out",
  })

  const [onChainCryptoData, setOnChainCryptoData] = useState<OnChainCryptoData>(
    {
      name: "",
      totalQuantity: 100, // Placeholder of 100.
      maticPrice: 0,
    }
  )

  // Used when user is importing an existing token from outside Paragraph.
  const [importTokenData, setImportTokenData] =
    useState<ImportedTokenOnChainData>({
      contractAddress: "",
      name: "",
      network: "ethereum", // Just a default.
      learnMoreUrl: "",
    })

  // Just a debug logger.
  useEffect(() => {
    console.log("Existing gates steps changed", steps)
  }, [steps])

  // Update the steps in the flow based on what the user is doing.
  useEffect(() => {
    setAvailableSteps(
      setSteps,
      setCurrentStep,
      currentStep,
      selectedGateType,
      userHasWallet,
      existingGateGroups,
      addingGate,
      selectedGateGroupId
    )
  }, [selectedGateType, userHasWallet, user, existingGateGroups])

  useEffect(() => {
    console.log(
      `Current step is ${currentStep?.id} and available steps are:`,
      steps
    )

    if (currentStep && currentStep.id == "GATE_TYPE") {
      changeStep(FindStep("NEXT", currentStep, steps || []))
    }
  }, [currentStep, steps])

  useEffect(() => {
    // Need this to load the user's wallet address (if one is already on the record).
    dispatch(refreshUser())
  }, [dispatch])

  useEffect(() => {
    console.log("GatingRules: Current steps are: ", steps)
  }, [steps])

  const changeStep = (id: string, proposedSteps?: GateModalStep[]) => {
    if (!steps || !currentStep) return

    console.log("Changing step to (proposed)", { id, steps, proposedSteps })
    if (
      !CanChangeStep(
        id,
        steps,
        currentStep,
        selectedGateType,
        userHasWallet,
        mintOrImportToken,
        selectedExistingParagraphTokenId
      )
    ) {
      console.log("Changing step to (declined 1 permission refused)", {
        id,
        steps,
        proposedSteps,
      })
      return
    }

    // If there are no proposed steps supplied, and if we're moving back to the group step, change the
    // proposed steps to be just the group step.
    if (!proposedSteps && id == "GROUP") {
      proposedSteps = [StepDefinitionGroup]
    }

    const newSteps = proposedSteps || steps
    const step = newSteps.find((s) => s.id == id)
    if (!step) {
      console.log("Changing step to (declined 2 no step found)", { id, steps })
      return
    }

    console.log("Changing step to (approved)", id)

    // If the step we're moving back to is the GATE_TYPE step, deselect the currently selected gate type.
    if (id == "GATE_TYPE") {
      setSelectedGateType(undefined)
      setSelectedMembershipId("")
      setSelectedExistingParagraphTokenId("")
      setMintOrImportToken("")
      setMintError("")
      setCreateGateError("")
    }

    // If the step we're moving back to is the GATE_TYPE step and it's the first step,
    // then clear the selectedGateGroupId.
    if (id == "GATE_TYPE" && steps.findIndex((s) => s.id == id) == 0) {
      setSelectedGateGroupId("")
    }

    // If the step we're moving back to is the GROUP step, deselect the currently selected group.
    if (id == "GROUP") {
      setSelectedGateGroupId("")
      setAddingGate(false)
      setMintError("")
      setCreateGateError("")
    }

    // Reset state relating to token settings.
    if (id == "CHOOSE_TOKEN" || id == "GATE_TYPE" || id == "GROUP") {
      setMinQuantity(1)
      setOnChainCryptoData({
        name: "",
        totalQuantity: 100, // Placeholder of 100.
        maticPrice: 0,
      })
    }

    // Update the current step.
    step.status = "CURRENT"
    setCurrentStep(step)

    const currentStepIdx = newSteps.findIndex((s) => s.id == id)

    // And update all the other step statuses.
    for (let i = 0; i < newSteps.length; i++) {
      if (i == currentStepIdx) continue // Skip updating the current step as we already did that above.

      if (i < currentStepIdx) {
        newSteps[i].status = "COMPLETED"
      } else {
        newSteps[i].status = "UPCOMING"
      }
    }

    setSteps(newSteps)

    console.log("Changing step to (done)", id, newSteps, currentStep)
    setNotMovedAStepYet(false)
  }

  // Whenever the user clicks on an existing paragraph token, deselect the "Mint" or "Import" tab.
  useEffect(() => {
    if (selectedExistingParagraphTokenId == "") return

    setMintOrImportToken("")

    // Then navigate to the next settings tab.
    changeStep("SETTINGS")
  }, [selectedExistingParagraphTokenId])

  // Whenever the user clicks on a tab (e.g. to mint a token or import one), deselect
  // any previously selected existing paragraph tokens.
  useEffect(() => {
    if (mintOrImportToken == "") return

    setSelectedExistingParagraphTokenId("")

    // Then navigate to the next settings tab.
    changeStep("SETTINGS")
  }, [mintOrImportToken])

  const submitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
  }

  const handleCreateGate = async (
    gateType: GatingRequirementGateType,
    props: SharedProps
  ) => {
    return createGate(gateType, props)
  }

  if (!steps || !currentStep) return null

  const sharedProps: SharedProps = {
    dispatch,

    note,
    blog,
    user,
    userHasWallet,
    walletConnectError,

    selectedMembershipId,
    setSelectedMembershipId,

    selectedExistingParagraphTokenId,
    setSelectedExistingParagraphTokenId,

    onChainCryptoData,
    setOnChainCryptoData,

    mintOrImportToken,
    setMintOrImportToken,

    importTokenData,
    setImportTokenData,

    minQuantity,
    setMinQuantity,

    uiSensitiveExistingGateGroups,
    addingGate,
    selectedGateGroupId,
    selectedGateType,
    setSelectedGateType,

    mintError,
    setMintError,

    createGateError,
    setCreateGateError,

    currentStep,
    steps,

    existingGateGroups: existingGateGroups || [], // TODO: This might break something, test first.

    loading,
    setLoading,

    handleCreateGate,
    cancelButtonRef,
    setOpen,
    changeStep,

    setAddingGate,
    setSelectedGateGroupId,

    tokens,

    notMovedAStepYet,

    gateContentType,
    embedId,
  }

  return (
    <div>
      <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
        <button
          type="button"
          className="rounded-md bg-white text-gray-400 hover:text-gray-600"
          onClick={() => setOpen(false)}
        >
          <span className="sr-only">Close</span>
          {!steps ||
            (steps.length < 5 && (
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            ))}
        </button>
      </div>
      <GateModalNavBar {...sharedProps} />
      <div className="w-full">
        <form
          onSubmit={(e) => submitHandler(e)}
          className={
            // This ensures that as the form changes height to accommodate the contents (see above), instead
            // of immediately appearing and overflowing when the form is bigger than the height until the height
            // adjusts, it now eases in with opacity and de-blurring.
            debouncedCurrentStep?.id != currentStep?.id
              ? "opacity-0 blur-sm transition-none"
              : "opacity-100 blur-none duration-900"
          }
        >
          <div className="mt-5" ref={parent}>
            {/* Step 1.a. Select gate type. */}
            {currentStep.id == "GATE_TYPE" && (
              <GateModalStepGateType {...sharedProps} />
            )}

            {/* Step 1.b. If the user has already selected at least one gate,
                show the grouping window instead of the gate type choice window as the first step. */}
            {currentStep.id == "GROUP" && (
              <div className="mt-4">
                <GateModalStepGroup {...sharedProps} />
              </div>
            )}

            {/* Token Step 2. Wallet connection. Optional. Not displayed if user is already connected. */}
            {currentStep.id == "CONNECT_WALLET" &&
              selectedGateType == "TOKEN" && (
                <GateModalStepConnectWallet {...sharedProps} />
              )}

            {/* Token Step 3. Choose token. */}
            {currentStep.id == "CHOOSE_TOKEN" && (
              <GateModalStepChooseToken {...sharedProps} />
            )}

            {/* Memberships Step 3. Choose membership. */}
            {currentStep.id == "CHOOSE_MEMBERSHIP" && (
              <GateModalStepChooseMembership {...sharedProps} />
            )}

            {/* Token Step 4. a) ONLY Token settings. */}
            {currentStep.id == "SETTINGS" && selectedGateType == "TOKEN" && (
              <GateModalStepMintOrImportToken {...sharedProps} />
            )}

            {/* Memberships Step 4. b) ONLY email settings. */}
            {currentStep.id == "SETTINGS" &&
              selectedGateType == "MEMBERSHIP" && (
                <GateModalStepEmailSettings {...sharedProps} />
              )}
          </div>
        </form>
      </div>

      {/* Cancel, Back, Enable gate, and Delete gate buttons. */}
      <ButtonController {...sharedProps} />
    </div>
  )
}

// *****----------------------*****
// *****   SERVER CONSTANTS   *****
// *****----------------------*****

const VERCEL_ENVS = ["production"]

const isOnVercel = VERCEL_ENVS.includes(
  process.env.VERCEL_ENV || process.env.NEXT_PUBLIC_VERCEL_ENV || ""
)

/**
 * This determines if we are deployed on the Fly.io master server.
 *
 * This server handles all incoming API requests from paragraph.xyz.
 */
export const isMasterServer = process.env.FLY_APP_NAME == "paragraph-master"

/**
 * This determines if we are deployed on the Fly.io tasks server.
 *
 * If so, we may perform additional things, like sending email newsletters
 * or receiving other queued jobs from GCP Cloud Tasks.
 */
export const isTasksServer = process.env.FLY_APP_NAME == "paragraph-tasks"

/**
 * This determines if we are deployed on the Fly.io block processor server.
 *
 * If so, we want to listening to onchain events but not accept other API requests
 * like the main server.
 */
export const isBlockProcessorServer =
  process.env.FLY_APP_NAME == "paragraph-block-processor"

/**
 * This determines if we are deployed on paragraph master (eg not a preview/staging branch).
 * It needs to be the same for both the frontend and the backend.
 *
 * This also applies to the tasks and on-chain listener servers.
 */
export const isMaster =
  isOnVercel ||
  isMasterServer ||
  isTasksServer ||
  isBlockProcessorServer ||
  process.env.NEXT_PUBLIC_FORCE_PROD == "true"

import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as api from "api_routes/api"
import { isErrorResponse } from "@types"
import { DeepPartial } from "@/types/utilities"
import {
  GatingContentType,
  GatingRule,
  GatingRuleGroup,
} from "@/types/gatingRules"
import { Thunk } from "../store"
import { setError } from "./errorSlice"
import { HYDRATE } from "next-redux-wrapper"
import { RootState } from "store"

interface GatingRuleGroupsState {
  gatingRuleGroups: GatingRuleGroup[] | null
}

const initialState: GatingRuleGroupsState = {
  gatingRuleGroups: null,
}

export const gatingRuleGroupsSlice = createSlice({
  name: "gatingRuleGroups",
  initialState,
  reducers: {
    // Sets the entire post gating rules array in the local store.
    setPostGatingRuleGroups: (
      state,
      action: PayloadAction<GatingRuleGroup[]>
    ) => {
      state.gatingRuleGroups = action.payload
    },
    // Sets the gating rules array for all posts in the given blog in the local store.
    // Note that this and setPostGatingRuleGroups share the same array, and that's okay, because
    // they're used from different paths (e.g. a post's gating rules are typically retrieved
    // when a reader loads a single post, whereas an entire blog's gating rules)
    // are retrieved either by a reader loading a blog or by the author loading their /notes
    // dashboard page when editing posts).
    setBlogGatingRuleGroups: (
      state,
      action: PayloadAction<GatingRuleGroup[]>
    ) => {
      state.gatingRuleGroups = action.payload
    },
    // Adds one or more gating rule groups to a post in the local store.
    addPostGatingRuleGroups: (
      state,
      action: PayloadAction<GatingRuleGroup[]>
    ) => {
      let existingGatingRuleGroups = state.gatingRuleGroups
      // If no map of gating rules exists yet, initialize it.
      if (!existingGatingRuleGroups) {
        existingGatingRuleGroups = []
      }
      existingGatingRuleGroups.push(...action.payload)

      // Finally, update the local state with the newly set gating rules.
      state.gatingRuleGroups = existingGatingRuleGroups
    },
    // Adds one or more gating rules to a group of gating rules in the local store.
    addGatingRulesToGroup: (
      state,
      action: PayloadAction<{ groupId: string; gatingRules: GatingRule[] }>
    ) => {
      let existingGatingRuleGroups = state.gatingRuleGroups

      // If no map of gating rules exists yet, initialize it.
      if (!existingGatingRuleGroups) {
        existingGatingRuleGroups = []
      }

      const groupIdx = existingGatingRuleGroups.findIndex(
        (g) => g.id == action.payload.groupId
      )
      if (groupIdx < 0) return

      // If this is the first gating rule in the group, initialize the array.
      existingGatingRuleGroups[groupIdx].gatingRules ||= []

      // Push gating rules into array.
      existingGatingRuleGroups[groupIdx].gatingRules.push(
        ...action.payload.gatingRules
      )

      // Finally, update the local state with the newly set gating rules.
      state.gatingRuleGroups = existingGatingRuleGroups
    },
    deleteGatingRuleGroup: (state, action: PayloadAction<string>) => {
      const gatingRuleGroups = state.gatingRuleGroups
      if (!gatingRuleGroups) return

      const deleteIdx = gatingRuleGroups?.findIndex(
        (g) => g.id == action.payload
      )
      gatingRuleGroups.splice(deleteIdx, 1)
      state.gatingRuleGroups = gatingRuleGroups
    },
    deleteGatingRuleGroupByPostIdAndEmbedIdFromLocalStorage: (
      state,
      action: PayloadAction<{ postId: string; embedId: string }>
    ) => {
      const gatingRuleGroups = state.gatingRuleGroups
      if (!gatingRuleGroups) return

      if (
        !action.payload.postId ||
        !action.payload.embedId ||
        action.payload.postId == "" ||
        action.payload.embedId == ""
      )
        return

      // Find all groups that match this filter.
      const deleteGroups = gatingRuleGroups?.filter(
        (g) =>
          g.postId == action.payload.postId &&
          g.embedId == action.payload.embedId
      )

      // And delete them.
      for (const dg of deleteGroups) {
        const deleteIdx = gatingRuleGroups.findIndex((g) => g.id == dg.id)
        gatingRuleGroups.splice(deleteIdx, 1)
      }

      state.gatingRuleGroups = gatingRuleGroups
    },
    deleteGatingRuleFromGroup: (
      state,
      action: PayloadAction<{ groupId: string; gatingRuleIds: string[] }>
    ) => {
      const gatingRuleGroups = state.gatingRuleGroups
      if (!gatingRuleGroups) return

      // Find the group we're deleting gating rules from.
      const groupToDeleteRulesFromId = gatingRuleGroups?.findIndex(
        (g) => g.id == action.payload.groupId
      )

      const gatingRuleGroup = gatingRuleGroups[groupToDeleteRulesFromId]

      for (const gr of action.payload.gatingRuleIds) {
        const deleteIdx = gatingRuleGroup.gatingRules.findIndex(
          (g) => g.id == gr
        )

        gatingRuleGroup.gatingRules.splice(deleteIdx, 1)
      }

      // Update the group in the array.
      gatingRuleGroups[groupToDeleteRulesFromId] = gatingRuleGroup

      // And set it in the state.
      state.gatingRuleGroups = gatingRuleGroups
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      const nextState = {
        ...state,
        ...action.payload.gatingRuleGroups,
      }
      nextState.initialLoad = false
      return nextState
    },
  },
})

const {
  setBlogGatingRuleGroups,
  addGatingRulesToGroup,
  deleteGatingRuleFromGroup,
} = gatingRuleGroupsSlice.actions

export const {
  setPostGatingRuleGroups,
  deleteGatingRuleGroup,
  deleteGatingRuleGroupByPostIdAndEmbedIdFromLocalStorage,
  addPostGatingRuleGroups,
} = gatingRuleGroupsSlice.actions

// Retrieve gating rules for a given post ID from the API.
// This would mostly be used in public routes where we want to load gating rules for a reader.
export const getPostGatingRuleGroups =
  (postId: string): Thunk<Promise<GatingRuleGroup[] | null>> =>
  async (dispatch) => {
    try {
      const data = await api.getGatingRuleGroupsForPost(postId)

      if (isErrorResponse(data)) {
        dispatch(setError(data))
        return null
      }

      dispatch(setPostGatingRuleGroups(data))

      return data
    } catch (err) {
      console.error(
        `Some error occurred retrieving gating rules for post with ID ${postId}.`,
        err
      )

      return null
    }
  }

// Retrieve gating rules for all posts in a given blog ID from the API and update the local state.
export const getBlogGatingRuleGroups =
  (blogId: string): Thunk<Promise<GatingRuleGroup[] | null>> =>
  async (dispatch) => {
    if (!blogId) {
      console.log(
        `GatingRuleGroups: Could not retrieve gating rules for blog as no ID supplied ${blogId}.`
      )
      return null
    }

    try {
      const data = await api.getGatingRuleGroupsForBlog(blogId)

      if (isErrorResponse(data)) {
        dispatch(setError(data))
        return null
      }

      dispatch(setBlogGatingRuleGroups(data))

      return data
    } catch (err) {
      console.error(
        `Some error occurred retrieving gating rules for blog with ID ${blogId}.`,
        err
      )

      return null
    }
  }

// Call this when you just need to know what the local state at any given moment is.
// The assumption is that you would have called getPostGatingRuleGroups() at some point
// prior to make sure the local state is updated with the latest from the server.
export const selectPostGatingRuleGroups = (state: RootState) => {
  if (!state.gatingRuleGroups) return

  return state.gatingRuleGroups.gatingRuleGroups
}

// Call this when you just need to know what the local state at any given moment is.
// The assumption is that you would have called getPostGatingRuleGroups() at some point
// prior to make sure the local state is updated with the latest from the server.
export const selectPostGatingRuleGroupsForCurrentPost = (state: RootState) => {
  if (!state.gatingRuleGroups) return

  const currentPostId = state.notes.currentNoteId
  if (!currentPostId) return

  return state.gatingRuleGroups.gatingRuleGroups?.filter(
    (gr) => gr.postId == currentPostId
  )
}

// Call this when you just need to know what the local state at any given moment is.
// The assumption is that you would have called getPostGatingRuleGroups() at some point
// prior to make sure the local state is updated with the latest from the server.
export const selectPostGatingRuleGroupsForPostId =
  (
    postId: string,
    gateContentType: GatingContentType | null = null,
    embedId: string | null = null
  ) =>
  (state: RootState): GatingRuleGroup[] | undefined => {
    if (!state.gatingRuleGroups) return

    return state.gatingRuleGroups.gatingRuleGroups?.filter(
      (gr) =>
        gr.postId == postId &&
        (!gateContentType || gr.contentType === gateContentType) &&
        (!embedId || gr.embedId === embedId)
    )
  }

// Adds a gating rule group to the API and then, if that's successful, also adds it to the local state.
export const addGatingRuleGroupToPost =
  (
    gatingRuleGroupReq: DeepPartial<GatingRuleGroup>
  ): Thunk<Promise<string | undefined>> =>
  async (dispatch) => {
    if (!gatingRuleGroupReq.postId) {
      console.error(
        `For some reason attempt was made to add a gating rule group to a post with an empty postId.`
      )

      setError({
        msg: "An unknown error has occurred creating gating rule group.",
      })
      return
    }

    try {
      // Add gating rule to API.
      const gatingRuleGroup = await api.addGatingRuleGroupToPost(
        gatingRuleGroupReq
      )

      if (isErrorResponse(gatingRuleGroup)) {
        dispatch(setError(gatingRuleGroup))
        return
      }

      // Add gating rule to local store.
      dispatch(addPostGatingRuleGroups([gatingRuleGroup]))

      return gatingRuleGroup.id
    } catch (err) {
      console.error(
        `Some error occurred creating a gating rule group for the post with ID ${gatingRuleGroupReq.postId}.`,
        err
      )

      dispatch(
        setError({
          msg: "An unknown error has occurred creating group of gating rules.",
        })
      )
    }
  }

// Adds a gating rule to the API and then, if that's successful,
// also adds it to the appropriate group in the local state.
export const addGatingRuleToGroup =
  (
    gatingRuleGroupId: string,
    gatingRule: Partial<GatingRule>
  ): Thunk<Promise<string | undefined>> =>
  async (dispatch) => {
    try {
      // Add gating rules to group in the API.
      const gatingRuleId = await api.addGatingRuleToGroup(
        gatingRuleGroupId,
        gatingRule
      )

      if (isErrorResponse(gatingRuleId)) {
        dispatch(setError(gatingRuleId))
        return
      }

      // Add gating rule to local store.
      dispatch(
        addGatingRulesToGroup({
          groupId: gatingRuleGroupId,
          gatingRules: [{ ...(gatingRule as GatingRule), id: gatingRuleId.id }],
        })
      )

      return gatingRuleId.id
    } catch (err) {
      console.error(
        `Some error occurred creating a gating rule for the group with ID ${gatingRuleGroupId}.`,
        err
      )

      dispatch(
        setError({
          msg: "An unknown error has occurred creating gating rule.",
        })
      )
    }
  }

// Deletes a gating rule group from the server and then, if that's successful, also deletes it from the local state.
export const deleteGatingRuleGroupById =
  (gatingRuleGroupId: string): Thunk<Promise<void>> =>
  async (dispatch) => {
    try {
      // Delete gating rule from server.
      const res = await api.deleteGatingRuleGroup(gatingRuleGroupId)

      if (isErrorResponse(res)) {
        dispatch(setError(res))
        return
      }

      // Delete gating rule from local store.
      dispatch(deleteGatingRuleGroup(gatingRuleGroupId))
    } catch (err) {
      console.error(
        `Some error occurred deleting a gating rule with ID ${gatingRuleGroupId}.`,
        err
      )

      dispatch(
        setError({
          msg: "An unknown error has occurred deleting a gating rule.",
        })
      )
    }
  }

// Deletes gating rule groups that match a postId and embedId from the server and then, if that's successful, also deletes it from the local state.
export const deleteGatingRuleGroupByPostIdAndEmbedId =
  (postId: string, embedId: string): Thunk<Promise<void>> =>
  async (dispatch) => {
    if (!postId || !embedId || postId == "" || embedId == "") return

    try {
      // Delete gating rule from server.
      const res = await api.deleteGatingRuleGroupByPostIdAndEmbedId(
        postId,
        embedId
      )

      if (isErrorResponse(res)) {
        dispatch(setError(res))
        return
      }

      // Delete gating rule from local store.
      dispatch(
        deleteGatingRuleGroupByPostIdAndEmbedIdFromLocalStorage({
          postId,
          embedId,
        })
      )
    } catch (err) {
      console.error(
        `Some error occurred deleting gating rules by postId ${postId} and embedId ${embedId}.`,
        err
      )

      dispatch(
        setError({
          msg: "An unknown error has occurred deleting gating rule groups for an embed.",
        })
      )
    }
  }

// Deletes a gating rule from the server and then, if that's successful, also deletes it from the local state.
export const deleteGatingRuleById =
  (gatingRuleGroupId: string, gatingRuleId: string): Thunk<Promise<void>> =>
  async (dispatch) => {
    try {
      // Delete gating rule from server.
      const res = await api.deleteGatingRule(gatingRuleGroupId, gatingRuleId)

      if (isErrorResponse(res)) {
        dispatch(setError(res))
        return
      }

      // Delete gating rule from local store.
      dispatch(
        deleteGatingRuleFromGroup({
          groupId: gatingRuleGroupId,
          gatingRuleIds: [gatingRuleId],
        })
      )
    } catch (err) {
      console.error(
        `Some error occurred deleting a gating rule with ID ${gatingRuleId}.`,
        err
      )

      dispatch(
        setError({
          msg: "An unknown error has occurred deleting a gating rule.",
        })
      )
    }
  }

export default gatingRuleGroupsSlice.reducer

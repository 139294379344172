import {
  Cog6ToothIcon,
  CubeTransparentIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline"
import EditTokenModal from "components/EditTokenModal"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { getLoggedInUsersBlog, selectUsersTokens } from "features/blogSlice"
import { Token } from "@/types/gatingRules"
import Button from "./Button"
import { deleteToken } from "api_routes/blogs"
import { ChevronRightIcon } from "@heroicons/react/24/solid"
import clsx from "clsx"
import { useAppDispatch } from "store"

export default function ManageTokens({
  hideAddTokenButton,
  hideEditAndDeleteTokenButton,
  hideSelectButton,
  selectedId,
  setSelectedExistingParagraphTokenId,
  tokenIdsToDisable,
  overrideHeight,
}: {
  hideAddTokenButton: boolean
  hideEditAndDeleteTokenButton: boolean
  hideSelectButton: boolean
  selectedId?: string
  setSelectedExistingParagraphTokenId?: Dispatch<SetStateAction<string>>
  tokenIdsToDisable?: string[]
  overrideHeight?: string
}) {
  const tokens = useSelector(selectUsersTokens)
  const [sortedTokens, setSortedTokens] = useState<Token[] | undefined>()
  const [editToken, setEditToken] = useState<Token | undefined>()

  const [modalOpen, setModalOpen] = useState(false)
  const dispatch = useAppDispatch()

  const isDisabled = (token: Token) => {
    return tokenIdsToDisable?.find((t) => t == token.id)
  }

  useEffect(() => {
    console.log("MODAL IS NOT OPEN ANYMORE?", modalOpen)
    if (!modalOpen) {
      setEditToken(undefined)

      // Refresh the user's communities, just in case they added one.
      dispatch(getLoggedInUsersBlog())
    }
  }, [modalOpen, dispatch])

  useEffect(() => {
    if (!tokens) return

    const sortableArray = [...tokens]

    // Sort tokens by two conditions: first by whether they are disabled or not (disabled ones go at the bottom),
    // and then in alphabetical order.
    setSortedTokens(
      sortableArray.sort((a, b) => {
        // If token a is disabled and b is not, then move a down in the list.
        if (
          tokenIdsToDisable?.find((t) => t == a.id) &&
          !tokenIdsToDisable?.find((t) => t == b.id)
        ) {
          return 1
        }

        // If token b is disabled and a is not, then move a up in the list.
        if (
          !tokenIdsToDisable?.find((t) => t == a.id) &&
          tokenIdsToDisable?.find((t) => t == b.id)
        ) {
          return -1
        }

        // Otherwise, if both tokens are enabled or both tokens are disabled, compare alphabetically.
        if (
          a.onChainData.name.toLocaleLowerCase() <
          b.onChainData.name.toLocaleLowerCase()
        ) {
          return -1
        } else if (
          a.onChainData.name.toLocaleLowerCase() >
          b.onChainData.name.toLocaleLowerCase()
        ) {
          return 1
        } else {
          return 0
        }
      })
    )
  }, [tokens])

  return (
    <div
      className={
        overrideHeight
          ? overrideHeight
          : tokens && tokens.length > 1
          ? "h-44"
          : "h-22"
      }
    >
      {tokens && tokens.length > 0 && (
        <nav className="h-full overflow-y-auto" aria-label="Directory">
          <ul role="list" className="relative z-0">
            {sortedTokens?.map((token) => (
              <div
                key={token.id}
                className={clsx(
                  "relative group",
                  hideSelectButton || isDisabled(token) ? "" : "cursor-pointer", // If we're showing the select icon, make the entire row clickable.
                  isDisabled(token) ? "text-gray-300" : ""
                )}
                onClick={() => {
                  if (hideSelectButton) return // If we're hiding the select icon, do nothing.
                  if (isDisabled(token)) return // If we're disabling this particular token, also do nothing.

                  // Otherwise, call the props parent onSelected function.
                  if (setSelectedExistingParagraphTokenId) {
                    console.log("Selected ", token.id)
                    setSelectedExistingParagraphTokenId(token.id)
                  }
                }}
              >
                <li key={token.id} className="p-1">
                  <div
                    className={clsx(
                      "relative flex items-center space-x-3 px-4 py-5 outline-none rounded-md group",
                      selectedId == token.id
                        ? "ring-blue-500 ring-2 shadow-sm shadow-gray-400" // Selected.
                        : "dark:ring-gray-600 ring-gray-200 ring-2 shadow-sm shadow-gray-400" // Not selected.
                      // isDisabled(token)
                      //   ? "" // Token disabled.
                      //   : "cursor-pointer hover:ring-blue-500" // Token not disabled.
                    )}
                  >
                    <div className="flex-shrink-0">
                      {/* TODO: Add integration with API that fetches images. */}
                      {/* <img className="h-10 w-10 rounded-full" src={""} alt="" /> */}
                      <CubeTransparentIcon className="w-8 h-8 mx-2 dark:text-white" />
                    </div>
                    <div className="min-w-0 flex-1">
                      <div
                        className={clsx(
                          "focus:outline-none"
                          //    isDisabled(token)
                          //      ? "cursor-default" // Token disabled.
                          //     : "cursor-pointer" // Token not disabled.
                        )}
                      >
                        <p
                          className={clsx(
                            "text-sm font-medium",
                            isDisabled(token)
                              ? "text-gray-400"
                              : "text-gray-900 dark:text-white"
                          )}
                        >
                          {token.onChainData.name}
                        </p>
                      </div>
                    </div>
                    {!hideSelectButton && !isDisabled(token) && (
                      <div>
                        <ChevronRightIcon
                          className={`h-5 w-5 group-hover:text-blue-600 ${
                            selectedId == token.id
                              ? "text-blue-600"
                              : "text-gray-400"
                          }`}
                          aria-hidden="true"
                        />
                      </div>
                    )}
                    {/* We can only edit purchase links for imported (not Paragraph-minted) tokens. */}
                    {!hideEditAndDeleteTokenButton &&
                      !isDisabled(token) &&
                      !(
                        "mintedOnParagraph" in token.onChainData &&
                        token?.onChainData.mintedOnParagraph == true
                      ) && (
                        <div className="flex-shrink-0">
                          <Button
                            replaceClassName={clsx(
                              "text-gray-500 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-200",
                              "inline-flex align-middle pl-2",
                              isDisabled(token) ? "" : "cursor-pointer"
                            )}
                            onClick={async () => {
                              console.log("Editing token")
                              setModalOpen(true)
                              setEditToken(token)
                            }}
                          >
                            <Cog6ToothIcon className="w-5 h-5" />
                          </Button>
                        </div>
                      )}
                    {!hideEditAndDeleteTokenButton && !isDisabled(token) && (
                      <div className="flex-shrink-0">
                        <Button
                          replaceClassName={clsx(
                            "inline-flex align-middle",
                            isDisabled(token) ? "" : "cursor-pointer",
                            "flex items-center gap-2 px-1 py-1 rounded-md transition duration-150 ease-in-out focus:outline-none bg-white text-red-500 hover:bg-red-600 hover:text-white dark:bg-red-700 dark:text-gray-300 dark:hover:bg-red-600 border border-red-300 dark:border-none"
                          )}
                          onClick={async () => {
                            console.log("Deleting token")
                            await deleteToken(token.id)
                            setEditToken(undefined)

                            // Refresh the user's tokens, just in case they added one.
                            dispatch(getLoggedInUsersBlog())
                          }}
                        >
                          <XMarkIcon className="h-5 w-5" />
                        </Button>
                      </div>
                    )}
                  </div>
                </li>
              </div>
            ))}
          </ul>
        </nav>
      )}

      {(!tokens || tokens.length == 0) && (
        <div className="text-gray-500 dark:text-gray-400 text-sm">
          You don't have any tokens. Add one from any post by selecting "Gate
          post".
        </div>
      )}

      {!hideAddTokenButton && (
        <div className="flex justify-end mt-4">
          <Button
            type="button"
            text="Add token"
            onClick={() => {
              setModalOpen(true)
            }}
            replaceClassName="px-3 py-2 ml-5 text-sm font-medium text-gray-700 bg-white border border-gray-300 dark:border-gray-800 rounded-md shadow-sm leading-4 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 "
          />
        </div>
      )}

      <EditTokenModal
        editToken={editToken}
        open={modalOpen}
        setOpen={setModalOpen}
      />
    </div>
  )
}

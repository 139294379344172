import { Membership } from "@types"

export default function DescriptionSection({
  membership,
}: {
  membership: Membership
}) {
  return (
    <p className="text-sm leading-6 text-custom-600 mx-2 mt-5 mb-1 pb-6 sm:pb-0 sm:line-clamp-3 dark:text-gray-400">
      {membership.description || "Get access to exclusive content and perks."}
      {/* Placeholder name. */}
    </p>
  )
}

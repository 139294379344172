export function absoluteUrl(path: string) {
  const baseUrl =
    process.env.NEXT_PUBLIC_VERCEL_ENV === "development"
      ? "http://localhost:3001"
      : process.env.NEXT_PUBLIC_APP_URL
      ? // If not, then whether we're in production, on a vercel preview branch, or a custom domain, use the vercel URL to construct the API base URL.
        "https://" + process.env.NEXT_PUBLIC_APP_URL
      : "https://" + process.env.NEXT_PUBLIC_VERCEL_URL

  return `${baseUrl}${path}`
}

import { RadioGroup } from "@headlessui/react"
import { Frequency, frequencies } from "./SharedTypes"
import clsx from "clsx"
import { Dispatch, ReactElement, SetStateAction } from "react"
import { Membership, Subscription } from "@types"
import { CheckBadgeIcon } from "@heroicons/react/24/solid"
import { isSubscribedToThisMembershipAndFrequency } from "./SharedFunctions"

export function MembershipHeading({
  title,
  subtitle,
  description,
}: {
  title?: string
  subtitle?: string
  description?: string | ReactElement
}) {
  return (
    <div className="px-4 md:px-0">
      {(title || subtitle) && (
        <div className="mx-auto max-w-4xl text-center">
          {title && (
            <h2 className="text-base font-semibold leading-7 text-blue-600">
              {title}
            </h2>
          )}
          {subtitle && (
            <p className="mt-2 font-bold tracking-tight text-3xl text-custom-900 dark:text-white">
              {subtitle}
            </p>
          )}
        </div>
      )}
      {description && (
        <p className="mx-auto mt-2 max-w-2xl text-center text-base leading-8 text-custom-600 dark:text-gray-400">
          {description}
        </p>
      )}
    </div>
  )
}

export function FrequencySelector({
  frequency,
  setFrequency,
  disabled = false,
  subscription,
  currentMembership,
}: {
  frequency: Frequency
  setFrequency: Dispatch<SetStateAction<Frequency>>
  disabled?: boolean
  subscription?: Subscription
  currentMembership?: Membership
}) {
  return (
    <div className="flex justify-center">
      <RadioGroup
        value={frequency}
        onChange={setFrequency}
        className={clsx(
          "grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
        )}
      >
        <RadioGroup.Label className="sr-only">
          Payment frequency
        </RadioGroup.Label>
        {frequencies.map((frequency, index) => (
          <RadioGroup.Option
            key={frequency.id}
            value={frequency}
            className={({ checked }) =>
              clsx(
                checked ? "bg-primary" : "text-gray-500",
                "flex cursor-pointer rounded-full px-2.5 py-1",
                index === 0 ? "justify-end" : "justify-start"
              )
            }
            disabled={disabled}
          >
            <FrequencySelectorItem
              frequency={frequency}
              subscription={subscription}
              currentMembership={currentMembership}
            />
          </RadioGroup.Option>
        ))}
      </RadioGroup>
    </div>
  )
}

function FrequencySelectorItem({
  frequency,
  subscription,
  currentMembership,
}: {
  frequency: Frequency
  subscription?: Subscription
  currentMembership?: Membership
}) {
  const isShowSubscribedFrequencyBadge =
    isSubscribedToThisMembershipAndFrequency(
      frequency,
      subscription,
      currentMembership
    )

  return (
    <div className="flex justify-center items-center mx-auto">
      {frequency.label}

      {isShowSubscribedFrequencyBadge && (
        <CheckBadgeIcon className=" float-right h-4 w-4 ml-0.5 mt-0.5 text-yellow-400" />
      )}
    </div>
  )
}

export const AiSentence = ({ size = 50, color = "currentColor" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="h-full w-full"
    >
      <path
        d="M12.5231 19.4523C12.0469 15.5738 11.6611 15.2529 7 14.8568C11.6611 14.4608 12.0469 14.1396 12.5231 10.2614C12.9991 14.1398 13.3851 14.4608 18.046 14.8568C13.3849 15.2529 12.9989 15.574 12.5231 19.4523ZM23 16.8519C19.8071 16.5808 19.5429 16.3608 19.2166 13.7038C18.8905 16.3608 18.6262 16.5807 15.4331 16.8519C18.626 17.1234 18.8903 17.3433 19.2166 20C19.5427 17.3433 19.807 17.1234 23 16.8519ZM20.4298 11.5573C17.8362 11.3369 17.6213 11.1582 17.3564 9C17.0914 11.1581 16.8767 11.3369 14.2829 11.5573C16.8766 11.7778 17.0914 11.9564 17.3564 14.1146C17.6212 11.9564 17.8359 11.7778 20.4298 11.5573Z"
        fill={color}
      />
      <path
        d="M3 8C2.44772 8 2 8.44772 2 9C2 9.55228 2.44772 10 3 10H16.1687C16.1698 9.99654 16.1709 9.99303 16.1719 9.98947C16.2418 9.75869 16.2969 9.42302 16.3638 8.87814L18.3489 8.87815C18.4158 9.42305 18.471 9.75871 18.5408 9.98949C18.5419 9.99305 18.5429 9.99655 18.544 10H21C21.5523 10 22 9.55228 22 9C22 8.44772 21.5523 8 21 8H3Z"
        fill={color}
      />
      <path
        d="M13.8962 14H11.1498C11.5371 13.7411 11.7992 13.3936 11.9929 12.9099C12.0975 12.6488 12.1822 12.348 12.2559 12H12.7902C12.8639 12.348 12.9486 12.6488 13.0532 12.91C13.2469 13.3936 13.5089 13.7411 13.8962 14Z"
        fill={color}
      />
      <path
        d="M15.9869 12.8378C15.9955 12.8906 16 12.9448 16 13C16 13.2241 15.9263 13.431 15.8018 13.5977C15.6919 13.5788 15.5877 13.5591 15.4886 13.5383C14.8589 13.4065 14.5428 13.2527 14.3548 13.0963C14.1844 12.9545 14.0294 12.7358 13.8886 12.2704C13.863 12.1859 13.8387 12.0959 13.8154 12H14.1982L14.1982 12.5537C14.8512 12.6092 15.2937 12.6579 15.6151 12.7252C15.8134 12.7667 15.925 12.8078 15.9869 12.8378Z"
        fill={color}
      />
      <path
        d="M11.2307 12H3C2.44772 12 2 12.4477 2 13C2 13.5523 2.44772 14 3 14H6.91533V13.8604C8.08518 13.761 8.92597 13.6706 9.55746 13.5383C10.1872 13.4065 10.5033 13.2527 10.6913 13.0962C10.8617 12.9544 11.0167 12.7357 11.1575 12.2703C11.1831 12.1858 11.2074 12.0959 11.2307 12Z"
        fill={color}
      />
      <path
        d="M17.512 10C17.4518 9.72492 17.405 9.39574 17.3564 9C17.3078 9.39573 17.2609 9.72491 17.2007 10H17.512Z"
        fill={color}
      />
    </svg>
  )
}

import { GatingRequirementGateType } from "@/types/gatingRules"

export type GateTypeDescription = {
  name: string
  abbreviatedName: string
  description: string
  gateType: GatingRequirementGateType
}

/** Further details and options displayed to the user once they've selected a gate type.
 */
type GateTypeDescriptionExtras = {
  selectedOptionTitle: string
  selectedOptionDescription: string
  selectedOptionEditExistingDescription: string
}

export const GateTypeDescriptions: (GateTypeDescription &
  GateTypeDescriptionExtras)[] = [
  {
    name: "Token",
    abbreviatedName: "Token",
    description: "Must have a minimum number of tokens.",
    gateType: "TOKEN",

    selectedOptionTitle: "Select token",
    selectedOptionDescription: "Choose an existing token or add a new one.",
    //   selectedOptionTitle: "Mint or import token",
    // selectedOptionDescription:
    //   "Mint a new token or NFT on the Polygon network through Paragraph with zero fees, or import an existing token and set a minimum quantity,",
    selectedOptionEditExistingDescription:
      "You can delete your existing token gate.",
  },
  {
    name: "Membership",
    abbreviatedName: "Memb.",
    description: "Require readers to subscribe to your publication.",
    gateType: "MEMBERSHIP",

    selectedOptionTitle: "",
    selectedOptionDescription:
      "Readers will need to become members of your publication to see your content.",
    selectedOptionEditExistingDescription:
      "You can delete your newsletter membership gate.",
  },
]

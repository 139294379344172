import { Blog, GetNoteRespFullOrPreview } from "@types"
import { useEffect, useState } from "react"
import useBlogUrl from "./useBlogUrl"

interface Props {
  blog: Blog
  note?: GetNoteRespFullOrPreview | null
  forceSlug?: boolean
}

interface ReturnVals {
  fullNoteUrl: string
  relativeNoteUrl: string
}

// Hook that makes it easier to get the blog URL,
// for both CUSTOM domain names as well as non-custom domain names.
export default function useNoteUrl(props: Props): ReturnVals {
  const noteIdentifier = props.forceSlug
    ? props.note?.id || ""
    : props.note?.slug || props.note?.id || ""

  const { host, url, customDomainOrRelativeUrl, fullUrl } = useBlogUrl({
    blog: props.blog,
  })
  const [relativeNoteUrl, setRelativeNoteUrl] = useState(
    customDomainOrRelativeUrl + noteIdentifier
  ) // The URL of the blog, ie /@colinarms, OR, if it's a custom domain, just /

  const [fullNoteUrl, setFullNoteUrl] = useState(fullUrl + "/" + noteIdentifier)

  useEffect(() => {
    if (!props.note?.id) return
    setFullNoteUrl(`${host}${url}${noteIdentifier}`)
    setRelativeNoteUrl(`${customDomainOrRelativeUrl}${noteIdentifier}`)
  }, [host, props.note?.slug, props.note?.id, url, customDomainOrRelativeUrl])

  return { fullNoteUrl, relativeNoteUrl }
}

import clsx from "clsx"

export default function MostPopularSection({
  isRecommended,
  isFormPreview,
}: {
  isRecommended: boolean
  isFormPreview: boolean
}) {
  // Don't show the most popular badge if the membership is not recommended and we are not in the form preview.
  if (!isRecommended && !isFormPreview) return <div className="h-9"></div>

  return (
    <div>
      <p
        className={clsx(
          "rounded-full px-2.5 py-1 text-xs font-semibold leading-5",
          isFormPreview && !isRecommended
            ? "text-gray-400 bg-gray-100"
            : "bg-primary"
        )}
      >
        Most popular
      </p>
    </div>
  )
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as api from "api_routes/memberships"
import {
  ErrorResponse,
  isErrorResponse,
  Membership,
  MembershipDiscount,
} from "@types"
import { Thunk } from "../store"
import { setError } from "./errorSlice"
import { HYDRATE } from "next-redux-wrapper"
import { RootState } from "store"
import { isFreeMembership } from "components/Memberships/SharedFunctions"

// Memberships are used to monetize publications.
interface MembershipState {
  // Blog's memberships are the ones they created as part of their publication. These are memberships other users can subscribe to.
  blogMemberships: Membership[]
  // User's memberships are the ones they have subscribed to/become a member of as a reader.
  userMemberships: Membership[]
  // Upsell memberships are the ones that are available to the user to subscribe to when viewing a post.
  upsellMemberships: Membership[]
  // Upsell memberships are the ones that are available to the user to subscribe to when viewing a post.
  upsellMembershipsDiscounts: MembershipDiscount[]
}

const initialState: MembershipState = {
  blogMemberships: [],
  userMemberships: [],
  upsellMemberships: [],
  upsellMembershipsDiscounts: [],
}

export const membershipSlice = createSlice({
  name: "memberships",
  initialState,
  reducers: {
    setBlogMemberships: (state, action: PayloadAction<Membership[]>) => {
      state.blogMemberships = action.payload
    },
    setUserMemberships: (state, action: PayloadAction<Membership[]>) => {
      state.userMemberships = action.payload
    },
    setUpsellMemberships: (state, action: PayloadAction<Membership[]>) => {
      state.upsellMemberships = action.payload
    },
    setUpsellMembershipsDiscounts: (
      state,
      action: PayloadAction<MembershipDiscount[]>
    ) => {
      state.upsellMembershipsDiscounts = action.payload
    },

    addBlogMembership: (state, action: PayloadAction<Membership>) => {
      const oldState = [...state.blogMemberships]
      oldState.push(action.payload)

      state.blogMemberships = oldState
    },

    updateBlogMembership: (state, action: PayloadAction<Membership>) => {
      const oldState = [...state.blogMemberships]

      oldState.find((membership) =>
        membership.id === action.payload.id ? action.payload : membership
      )

      state.blogMemberships = oldState
    },

    removeBlogMembership: (state, action: PayloadAction<string>) => {
      const oldState = [...state.blogMemberships].filter(
        (membership) => membership.id !== action.payload
      )

      state.blogMemberships = oldState
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      const nextState = {
        ...state,
        ...action.payload.memberships,
      }
      nextState.initialLoad = false
      return nextState
    },
  },
})

const {
  setBlogMemberships,
  setUpsellMemberships,
  setUpsellMembershipsDiscounts,
  addBlogMembership,
  updateBlogMembership,
  removeBlogMembership,
} = membershipSlice.actions

export const selectBlogMemberships = (state: RootState) => {
  if (!state.memberships) return

  return state.memberships.blogMemberships
}

export const selectUserMemberships = (state: RootState) => {
  if (!state.memberships) return

  return state.memberships.userMemberships
}

export const selectUpsellMemberships = (state: RootState) => {
  if (!state.memberships) return

  return state.memberships.upsellMemberships
}

export const selectFreeUpsellMembership = (state: RootState) => {
  if (!state.memberships) return

  return state.memberships.upsellMemberships?.find((membership) =>
    isFreeMembership(membership)
  )
}

export const selectMembershipsDiscounts = (state: RootState) => {
  if (!state.memberships) return

  return state.memberships.upsellMembershipsDiscounts
}

/**
 * Retrieve this blog's (publication) membership details from API.
 * Blog's memberships are the ones they created as part of their publication. These are memberships other users can subscribe to.
 * @param blogId ID of the blog.
 * @returns An array of memberships. Also sets the memberships in the store.
 */
export const getBlogMemberships =
  (blogId: string): Thunk<Promise<Membership[]>> =>
  async (dispatch) => {
    try {
      const data = await api.getMemberships(blogId)

      if (isErrorResponse(data)) {
        dispatch(setError(data))
        return []
      }

      dispatch(setBlogMemberships(data))

      return data
    } catch (err) {
      console.error("Caught exception getting blog's memberships: ", err)

      return []
    }
  }

/**
 * Get upsell memberships. These are the ones that are available to the user to subscribe to when viewing a post.
 * @param blogId ID of the blog.
 * @returns An array of memberships. Also sets the memberships in the store.
 */
export const getUpsellMemberships =
  (blogId: string, coupon: string | null): Thunk<Promise<Membership[]>> =>
  async (dispatch) => {
    try {
      console.log("Memberships: 1")
      const data = await api.getMemberships(blogId)
      console.log("Memberships: 2")
      if (isErrorResponse(data)) {
        console.log("Memberships: 3")
        dispatch(setError(data))
        dispatch(setUpsellMemberships([]))
        console.log("Memberships: 4")
        console.error("Exception occurred fetching memberships", data)
        return []
      }

      console.log("Memberships: 5")
      dispatch(setUpsellMemberships(data))
      console.log("Memberships: 6", data)

      const planDiscountPromises: Promise<
        MembershipDiscount | ErrorResponse | null
      >[] = []
      data.map((membership) => {
        return membership.plans.map((plan) => {
          planDiscountPromises.push(
            api.getPlanDiscount(
              blogId,
              membership.id,
              plan.stripePriceId || plan.loopItemId || null,
              coupon
            )
          )
        })
      })

      const discounts = (await Promise.all(planDiscountPromises)).filter(
        (planDiscount) => planDiscount
      ) as MembershipDiscount[]
      console.log("PLAN DISCOUNTS: ", discounts)

      dispatch(setUpsellMembershipsDiscounts(discounts))

      return data
    } catch (err) {
      console.error("Caught exception getting upsell memberships: ", err)

      return []
    }
  }

export const createBlogMembership =
  (blogId: string, membership: Membership): Thunk<Promise<void>> =>
  async (dispatch) => {
    const dbMembershipOrError = await api.createMembership(blogId, membership)

    if (isErrorResponse(dbMembershipOrError)) {
      console.error("Error creating membership.", dbMembershipOrError)
      dispatch(setError(dbMembershipOrError))
      return
    }

    // Set the Firestore ID as the membership ID.
    membership.id = dbMembershipOrError.id

    dispatch(addBlogMembership(membership))

    return
  }

export const updateBlogMembershipThunk =
  (blogId: string, membership: Membership): Thunk<Promise<void>> =>
  async (dispatch) => {
    const data = await api.updateMembership(blogId, membership)

    dispatch(updateBlogMembership(membership))

    if (isErrorResponse(data)) {
      dispatch(setError(data))
    }

    return
  }

export const deleteBlogMembership =
  (blogId: string, membershipId: string): Thunk<Promise<void>> =>
  async (dispatch) => {
    const data = await api.deleteMembership(blogId, membershipId)

    dispatch(removeBlogMembership(membershipId))

    if (isErrorResponse(data)) {
      dispatch(setError(data))
    }

    return
  }

export default membershipSlice.reducer

import { useEffect, useState } from "react"
import {
  EnvelopeIcon,
  ArrowRightIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/solid"
import { useAnalytics } from "hooks/useAnalytics"
import useUser from "hooks/useUser"

import { selectBlog } from "features/blogSlice"
import { useSelector } from "react-redux"
import clsx from "clsx"
import Button from "components/Button"
import { selectActiveSubscription } from "features/userSlice"
import { selectLoading } from "features/pageSlice"
import Spinner from "components/Spinner"

import { getBlogsSubscribersCount } from "api_routes/blogs"
import useSubmitEmail from "hooks/useSubmitEmail"
import { selectError, setError } from "features/errorSlice"
import LoginEmailAndPassword from "components/Login/LoginEmailAndPassword"
import { useAppDispatch } from "store"

function SubscriberCount() {
  const blog = useSelector(selectBlog)
  const [count, setCount] = useState(0)

  useEffect(() => {
    getBlogsSubscribersCount(blog.id).then((count) => {
      setCount(count.active)
    })
  }, [])

  const getDisplayCount = () => {
    if (count < 100) return null
    if (count >= 100 && count <= 1000) return Math.floor(count / 100) * 100
    return Math.floor(count / 1000) * 1000
  }

  const displayCount = getDisplayCount()?.toLocaleString()

  if (!displayCount) return null

  return (
    <div className="mt-3 text-center sm:mt-5">
      <p className="text-gray-500 text-sm font-semibold">
        Over {displayCount} subscribers
      </p>
    </div>
  )
}

export default function EmailPrompt(props: {
  initialFocusRef?: React.Ref<any>
  initialPopup?: boolean
  appearOnScroll?: boolean
  /**
   * Pass this in as true if you want to not immediately subscribe the user
   * when the modal is opened, for instance when the modal contents is actually
   * being used as an embedded component.
   */
  doNotSubscribeImmediately?: boolean
  /**
   * If this is set, we'll prefill the email field with this value.
   */
  emailPrefilled?: string
}) {
  const user = useUser()
  const { track } = useAnalytics()

  const blog = useSelector(selectBlog)
  const subscription = useSelector(selectActiveSubscription)
  const isSubscribed = subscription ? true : false
  const loading = useSelector(selectLoading)
  const submitEmail = useSubmitEmail()
  const [email, setEmail] = useState(props.emailPrefilled || "")

  console.log("Loading state", loading)

  useEffect(() => {
    track("subscribe email prompt view")
  }, [])

  /** If the user is logged in but not already subscribed when the modal is open,
   * subscribe them immediately.
   */
  useEffect(() => {
    if (
      props.initialPopup ||
      props.appearOnScroll ||
      subscription ||
      // If the user has neither prefilled their email nor has an email in their account,
      // don't subscribe them immediately.
      (!props.emailPrefilled && (!user.hasEmail || !("email" in user))) ||
      props.doNotSubscribeImmediately
    ) {
      return
    }
    console.log(
      "Loading state in initial useEffect",
      loading,
      props.initialPopup,
      props.appearOnScroll,
      subscription,
      user.hasEmail
    )

    submitEmail(
      // @ts-ignore (above condition already checks that user has email but TS doesn't seem to know that)
      props.emailPrefilled || user.email,
      props.initialPopup,
      props.appearOnScroll
    )
  }, [])

  console.log("Loading state in bottom render", loading)

  if (loading) {
    console.log("Loading and sub", loading, subscription)
    return (
      <div className="mx-auto w-10 h-10 my-12">
        <Spinner height={25} width={25} />
      </div>
    )
  }

  /**
   * Base wants us to do special handling for their newsletter,
   * only on the /subscribe page
   */
  const isBase = blog.id === "JWnFwZ9eNIYr6ZEPP8m8"

  let title

  if (isSubscribed) {
    title = "You're subscribed!"
  } else if (!props.initialPopup) {
    // If this is Base
    if (isBase) {
      title = "Subscribe to Onchain Summer"
    } else {
      title = "What's your email?"
    }
  } else {
    title = blog.name
  }

  let description
  if (isSubscribed) {
    description =
      "You're receiving newsletters from " + blog.name + " in your inbox."
  } else if (!props.initialPopup || !blog.summary) {
    if (isBase) {
      description =
        "Stay up to date with Onchain Summer, our season-long showcase of daily experiences that make coming onchain more fun than ever."
    } else {
      description = "Subscribe to never miss a post."
    }
  } else {
    description = blog.summary
  }

  return (
    <div className="p-10">
      <div>
        {blog.logo_url && (
          <img
            className="mx-auto h-12 w-auto"
            src={blog.logo_url}
            alt={blog.name}
          />
        )}
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-3xl font-bold text-gray-900 ">{title}</h3>
          <div className="mt-2">
            <p className="text-gray-500 text-md">{description}</p>
          </div>

          {/* TODO: Dont display this for autoSubscribe
           * /
           */}
          <SubscriberCount />
        </div>
      </div>

      <EmailInputAndSubscribeButton {...{ ...props, email, setEmail }} />
    </div>
  )
}

function EmailInputAndSubscribeButton(props: {
  email: string
  setEmail: (email: string) => void
  initialFocusRef?: React.Ref<any>
  initialPopup?: boolean
  appearOnScroll?: boolean
}) {
  const user = useUser()
  const submitEmail = useSubmitEmail()
  const subscription = useSelector(selectActiveSubscription)
  const loading = useSelector(selectLoading)
  const dispatch = useAppDispatch()

  const error = useSelector(selectError)

  const isExistingUserButLoggedOutErrorOrIncorrectPassword =
    error.code === "SUBSCRIBING_USER_LOGGED_OUT_BUT_EMAIL_ALREADY_EXISTS" ||
    error.code === "INCORRECT_PASSWORD"

  const userEmail = "email" in user ? user.email : ""
  useEffect(() => {
    if (!("email" in user)) return
    if (user.email) props.setEmail(user.email)
  }, [userEmail])

  const userHasEmailAndSubscribed = user.hasEmail && !!subscription

  if (isExistingUserButLoggedOutErrorOrIncorrectPassword) {
    return (
      <LoginEmailAndPassword
        prefillEmailAddress={props.email}
        overrideSignInButtonText="Sign in and Subscribe"
        additionalLoginCallback={(isLoginSuccess: boolean) => {
          if (isLoginSuccess) {
            submitEmail(props.email, props.initialPopup, props.appearOnScroll)
          }
        }}
      />
    )
  }

  return (
    <form
      className="space-y-6"
      onSubmit={(e) => {
        if (!props.email) {
          dispatch(setError({ msg: "Please enter your email" }))
          return
        }

        e.preventDefault()
        submitEmail(props.email, props.initialPopup, props.appearOnScroll)
      }}
    >
      <div className={clsx("mb-6")}>
        <div
          className={clsx(
            "flex flex-col sm:flex-row shadow-sm rounded-md mt-9 space-y-3 sm:space-y-0"
          )}
        >
          <div className="relative flex items-stretch flex-grow focus-within:z-10">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              {userHasEmailAndSubscribed ? (
                <CheckCircleIcon className="w-5 h-5 text-green-400 absolute " />
              ) : (
                <EnvelopeIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              )}
            </div>
            <input
              type="email"
              name="email"
              ref={props.initialFocusRef}
              value={props.email}
              // Keep this disabled if the user has an email and is logged in.
              disabled={loading || user.hasEmail}
              id="email"
              className={
                "sm:rounded-l rounded-md sm:rounded-r-none sm:rounded-t-none text-sm sm:text-base block w-full pl-10 border-gray-300 focus:ring-primary-500 focus:border-primary-500 disabled:text-gray-400 focus:border-text-gray-500"
              }
              placeholder="Type your email..."
              onChange={(ev) => {
                props.setEmail(ev.target.value)
              }}
            />
          </div>
          <Button
            type="button"
            disabled={loading || userHasEmailAndSubscribed}
            onClick={(e) => {
              e.preventDefault()

              if (!props.email) {
                dispatch(setError({ msg: "Please enter your email" }))
                return
              }

              submitEmail(props.email)
            }}
            replaceClassName={
              "sm:rounded-r rounded-md sm:rounded-l-none sm:rounded-t-none inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
            }
          >
            <div className="inline-flex justify-center my-auto">
              {loading
                ? "Subscribing..."
                : userHasEmailAndSubscribed
                ? "Subscribed"
                : "Subscribe"}
              {(loading || !userHasEmailAndSubscribed) && (
                <ArrowRightIcon
                  className="w-5 h-5 ml-2 text-white-400"
                  aria-hidden="true"
                />
              )}
            </div>
          </Button>
        </div>
      </div>
    </form>
  )
}

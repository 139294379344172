import { Node } from "@tiptap/core"
import {
  NodeViewContent,
  NodeViewWrapper,
  ReactNodeViewRenderer,
} from "@tiptap/react"
import { Editor } from "@tiptap/core"
import { handlePromptSubmit } from "./util"
import Button from "components/Button"

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    askAi: {
      setAskAi: () => ReturnType
    }
  }
}

export default Node.create({
  name: "askAi",
  group: "block",
  content: "inline*",

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ]
  },

  renderHTML() {
    return ["div", { "data-type": this.name }]
  },

  addCommands() {
    return {
      setAskAi:
        () =>
        ({ commands }) => {
          return commands.toggleNode("paragraph", this.name)
        },
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(AskAiComponent)
  },

  addKeyboardShortcuts() {
    return {
      ArrowUp: () => {
        /* @ts-ignore */
        if (this.editor.state.selection.$anchor.parent.type.name !== this.name)
          return false

        this.editor.commands.deleteCurrentNode()
        return false
      },
      ArrowDown: () => {
        /* @ts-ignore */
        if (this.editor.state.selection.$anchor.parent.type.name !== this.name)
          return false

        this.editor.commands.deleteCurrentNode()
        return false
      },
      Enter: () => {
        return handlePromptSubmit(this.editor)
      },
      Space: () => {
        if (
          this.editor.state.selection.$anchor.parent.type.name ===
            "paragraph" &&
          this.editor.state.selection.$anchor.depth === 1 &&
          !this.editor.state.selection.$anchor.parent.textContent
        ) {
          this.editor.commands.toggleNode("paragraph", this.name)
          return true
        }
        return false
      },
      Backspace: () => {
        if (
          this.editor.state.selection.$anchor.parent.type.name === this.name &&
          !this.editor.state.selection.$anchor.parent.textContent
        ) {
          this.editor.commands.deleteCurrentNode()
          return true
        }
        return false
      },
    }
  },
})

type Props = {
  editor: Editor
  updateAttributes(attrs: any): void
  node: any
  deleteNode(): void
}

export function AskAiComponent(props: Props): JSX.Element {
  return (
    <NodeViewWrapper className="flex w-full rounded-md border border-gray-200">
      <NodeViewContent className="ask-ai-input flex items-center h-16 rounded-md rounded-r-none dark:text-white dark:bg-gray-700 w-full text-gray-500 p-2 placeholder:text-gray-400 ring-0 border-0 focus:outline-none" />
      <Button
        onClick={() => handlePromptSubmit(props.editor)}
        replaceClassName="rounded-md rounded-l-none p-4 h-16 max-w-60 justify-center items-center hover:bg-gray-100 border-gray-300 text-gray-400 dark:hover:bg-gray-700"
      >
        Ask
      </Button>
    </NodeViewWrapper>
  )
}

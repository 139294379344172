import { isSubscriptionToMembershipExpired } from "@/util/memberships"
import { Membership, Subscription } from "@types"

export default function MembershipExpirationSection({
  membership,
  subscription,
}: {
  membership: Membership
  subscription?: Subscription
}) {
  const isExpired = isSubscriptionToMembershipExpired(membership, subscription)

  if (isExpired === "NOT_APPLICABLE") return null

  const membershipExpiresIn = new Date(
    // @ts-ignore because "'subscription.toBeUnsubscribedAt' is possibly 'undefined'" is not a valid concern due to
    // the call to `isMembershipExpired` above. This is just a bug in TypeScript.
    subscription.toBeUnsubscribedAt * 1000
  ).toLocaleDateString()

  return (
    <div className="mt-4 text-sm text-gray-400">
      Membership {isExpired === "ACTIVE" ? "expires" : "expired"} on{" "}
      {membershipExpiresIn}
    </div>
  )
}

import { Membership } from "@types"

export function sortMembershipsByPriceAndMembershipId(
  memberships: Membership[]
) {
  // Sort memberships by price, with cheapest on the left, and free on the right (for upselling purposes).
  // First, sort just the paid memberships by price.
  const sortedMemberships = [
    ...memberships.filter(
      (m) => m.plans.length > 0 && m.plans.every((p) => p.price > 0)
    ),
  ]
  sortedMemberships
    .sort((a, b) => {
      return (
        a.plans.flatMap((p) => p.price).reduce((acc, val) => acc + val, 0) -
        b.plans.flatMap((p) => p.price).reduce((acc, val) => acc + val, 0)
      )
    })
    .sort((a, b) => {
      // Then sort by membership id, so that the order is consistent.
      return a.id.localeCompare(b.id)
    })
  // Then add the free membership to the end.
  const freeMembership = memberships.find((m) =>
    m.plans.every((p) => p.price == 0)
  )
  if (freeMembership) sortedMemberships.push(freeMembership)

  return sortedMemberships
}

import React, { Component, ErrorInfo, ReactNode } from "react"
import { analytics } from "hooks/useAnalytics"
import * as Sentry from "@sentry/nextjs"
import Link from "next/link"

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("ComponentDidCatch in the errorboundary! Uncaught error...")

    // Use a unique UUID to correlate logs across different reporting services.
    const sentryEventId = Sentry.captureException(error, {
      extra: { errorInfo: errorInfo.componentStack },
    })

    console.error(
      "Errorboundary uncaught error info:",
      error,
      errorInfo,
      sentryEventId
    )

    analytics.track("errorboundary 500 encountered", {
      error,
      errorInfo,
      sentryEventId,
    })

    Sentry.captureMessage("errorboundary 500 encountered", {
      extra: { error, sentryEventId },
      level: "error",
    })
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div className="max-w-max mx-auto">
            <main className="sm:flex">
              <p className="text-4xl font-extrabold text-blue-600 sm:text-5xl">
                500
              </p>
              <div className="sm:ml-6">
                <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                  <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                    Server error
                  </h1>
                  <p className="mt-1 text-base text-gray-500">
                    Something went wrong on our end. Sorry about that.
                  </p>
                </div>
                <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                  <Link
                    href="/"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Go back home
                  </Link>
                  <a
                    href="mailto:support@paragraph.xyz"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Contact support
                  </a>
                </div>
              </div>
            </main>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary

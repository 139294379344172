import { Dialog } from "@headlessui/react"
import { PlusCircleIcon } from "@heroicons/react/24/outline"
import {
  FindExistingGatesInGroupByGateType,
  ShouldShowAddGateButton,
} from "./GateModalHelperFunctions"
import { ReactElement, useState } from "react"
import { SharedProps } from "./SharedProps"
import Spinner from "components/Spinner"
import { useSelector } from "react-redux"
import { selectBlogMemberships } from "features/membershipSlice"
import { GateTypeDescription } from "./GateTypeDescriptions"

const gateTypeDescriptions: GateTypeDescription[] = [
  {
    name: "Token",
    abbreviatedName: "Token",
    description: "Must have a minimum number of tokens.",
    gateType: "TOKEN",
  },
  {
    name: "Membership",
    abbreviatedName: "Memb.",
    description: "Require readers to subscribe to your publication.",
    gateType: "MEMBERSHIP",
  },
]

export default function GateModalStepGateType(props: SharedProps) {
  const showIntro = !props.existingGateGroups?.length

  return (
    <div className="mt-4">
      {showIntro && <GatePostIntro />}

      <div>
        <ul
          role="list"
          className="mt-8 grid justify-center grid-rows-2 sm:grid-rows-1 sm:grid-cols-2 gap-4 sm:gap-6 container"
        >
          {/* List of gates available for creation/editing/deletion. */}
          {gateTypeDescriptions.map((gateTypeDesc) => (
            <div key={gateTypeDesc.name}>{GateEntry(props, gateTypeDesc)}</div>
          ))}
        </ul>
      </div>
    </div>
  )
}

function GatePostIntro() {
  return (
    <div className="container">
      <div className="mt-3 sm:mt-5">
        <Dialog.Title
          as="h3"
          className="text-2xl font-bold text-center text-gray-900 leading-6"
        >
          Gate your post
        </Dialog.Title>
        <div className="mt-6">
          <p className="text-gray-600 text-base w-56 sm:w-full">
            Setup a gate so that readers must meet some requirement to view this
            post.{" "}
            <a
              href="/settings/publication/memberships-token-gating"
              target="_blank"
              className=" text-blue-500 font-bold hover:underline cursor-pointer "
            >
              Read more or edit memberships and tokens here.
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

function GateEntryAddMoreBtn(
  props: SharedProps,
  gateTypeDesc: GateTypeDescription
) {
  function handleGateOnClick() {
    // If it's a subscription gate, then change to the next relevant step where we choose the
    // membership type.
    if (gateTypeDesc.gateType == "MEMBERSHIP") {
      props.setSelectedGateType("MEMBERSHIP")
    } else {
      // If it's a token gate, then change to the next relevant step (either choosing/minting/importing
      // a token or connecting a wallet).
      props.setSelectedGateType("TOKEN")
    }
  }

  return (
    <div className="grid grid-cols-1 divide-y divide-gray-200 border-t border-gray-300 py-1 bg-white rounded-b-lg">
      <div className="flex justify-center p-1 items-center text-green-600">
        <div
          className="border border-green-600 rounded-lg px-2 py-1 items-center justify-center flex hover:bg-green-600 hover:text-white shadow-sm cursor-pointer"
          onClick={async () => handleGateOnClick()}
        >
          <PlusCircleIcon className="w-5 h-5 mr-1" />
          <span className="text-sm">Add</span>
        </div>
      </div>
    </div>
  )
}

function GateEntry(props: SharedProps, gateTypeDesc: GateTypeDescription) {
  const { existingGateGroups, selectedGateGroupId } = props

  const memberships = useSelector(selectBlogMemberships)

  const [showAddMoreBtn, gateDoesntExistYet] = ShouldShowAddGateButton(
    existingGateGroups,
    gateTypeDesc.gateType,
    memberships,
    selectedGateGroupId
  )

  const [clicked, setClicked] = useState(false)

  let gateDescription: ReactElement | null = null

  // Show the standard description if this gate doesn't already exist.
  if (gateDoesntExistYet || gateTypeDesc.gateType == "TOKEN") {
    gateDescription = (
      <span className="text-sm text-gray-700">{gateTypeDesc.description}</span>
    )
  } else {
    // If the gate does exist, show a different description.
    gateDescription = (
      <span className="text-sm text-gray-500">
        {`A ${FindExistingGatesInGroupByGateType(
          existingGateGroups,
          gateTypeDesc.gateType,
          selectedGateGroupId
        )?.[0]
          ?.gatingRuleRequirementTypeDisplayMessageForAuthor} is already required to view this content.`}
      </span>
    )
  }

  return (
    <li className="col-span-1 flex flex-col rounded-md shadow-sm w-48 h-48">
      <div className="flex flex-1 items-center pt-1 justify-between rounded-md border-gray-200 shadow-md bg-white border border-1 border-gray-300">
        <div className="flex flex-col justify-between pt-2 h-full">
          <div className="font-medium text-base text-center mb-2 px-3">
            {gateTypeDesc.name}
          </div>
          <div className="h-full px-3">{gateDescription}</div>
          {showAddMoreBtn &&
            !clicked &&
            GateEntryAddMoreBtn(props, gateTypeDesc)}
          {clicked && (
            <div className="flex justify-center mb-3">
              <Spinner height={5} width={5} />
            </div>
          )}
        </div>
      </div>
    </li>
  )
}

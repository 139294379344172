import { Mark } from "@tiptap/core"
import { mergeAttributes, ReactNodeViewRenderer } from "@tiptap/react"
import Highlight from "components/Collectibles/Popover/Existing/ExistingCollect"

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    highlight: {
      setHighlight: () => ReturnType
    }
  }
}

export default Mark.create({
  name: "highlight",
  atom: true,

  // This is a higher priority than the Link component.
  // Without this, highlighting Links breaks this.
  priority: 2000,

  addAttributes() {
    return {
      highlightId: {
        default: "",
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-type="highlight"]',
      },
    ]
  },

  addCommands() {
    return {
      setHighlight:
        () =>
        ({ commands }) => {
          return commands.setHighlight()
        },
    }
  },

  renderHTML({ mark, HTMLAttributes }) {
    return [
      "span",
      {
        //...HTMLAttributes,
        class: "highlight-wrapper inline cursor-pointer",
        "data-type": "highlight",
        "data-highlight-id": mark.attrs.highlightId,
      },

      [
        "span",
        mergeAttributes(HTMLAttributes, {
          class: "highlight-inner",
        }),
        0,
      ],
    ]
  },

  addNodeView() {
    return ReactNodeViewRenderer(Highlight)
  },
})

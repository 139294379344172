import BasePopover from "../BasePopover"
import useNewHighlightMetadata from "hooks/collectibles/useNewHighlightMetadata"
import { useSelector } from "react-redux"
import { selectCurrentNote } from "features/noteSlice"

import CreateNewCollectibleBtn from "./CreateNewCollectibleBtn"
import ConnectWalletBtn from "@/components/ConnectWalletButton"
import { selectBlog } from "features/blogSlice"

import { useAccount } from "wagmi"
import useCollectPrecondition from "hooks/collectibles/useCollectPrecondition"
import useUser from "hooks/useUser"
import MintFeeText from "../MintFeeText"

type Props = {
  collectibleName: string
}

function CollectNewPostBtn(props: Props) {
  const blog = useSelector(selectBlog)
  const accountData = useAccount()
  const collectPrecondition = useCollectPrecondition()
  const { walletReadyAndLoggedIn } = useUser()
  const metricData = useNewHighlightMetadata()

  if (!walletReadyAndLoggedIn) {
    return (
      <div className="flex justify-center flex-col w-full">
        <ConnectWalletBtn text="Connect wallet" />
      </div>
    )
  }

  if (!collectPrecondition) return null

  const symbol = blog?.lowercase_url?.substring(1).toUpperCase() || "PARA"
  const minterAddress = accountData.address

  return (
    <CreateNewCollectibleBtn
      {...props}
      name={props.collectibleName}
      symbol={symbol}
      type="POST"
      ownerAddress={metricData.ownerAddress}
      // @ts-ignore We confirmed presence in collectPrecondition
      minterAddress={minterAddress}
      costWei={metricData.priceWei}
      supply={metricData.maxSupply}
      chain={metricData.chain}
      position={{ from: 0, to: 0 }}
      text={props.collectibleName}
      btnText="Collect post"
    />
  )
}

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export default function CollectPostInner() {
  const note = useSelector(selectCurrentNote)
  const metricData = useNewHighlightMetadata()
  const collectibleName = note?.title || note?.subtitle || ""

  const Body = (
    <>
      <p className="text-gray-500 mt-3">
        Collect this post to permanently own it on{" "}
        {capitalizeFirstLetter(metricData.chain)}.
      </p>
    </>
  )
  const Footer = (
    <>
      <CollectNewPostBtn collectibleName={collectibleName} />
      <MintFeeText chain={metricData.chain} />
    </>
  )

  // User is collecting a new highlight. It doesn't exist yet.
  console.log("Rendering new highlight collect popover...")
  return (
    <div className="z-50">
      <BasePopover
        body={Body}
        footer={Footer}
        imageText={collectibleName}
        metricData={metricData}
        collectibleType="POST"
      />
    </div>
  )
}

import * as Sentry from "@sentry/nextjs"
import type { BrowserOptions, ErrorEvent, EventHint } from "@sentry/nextjs"
import {
  DENY_URLS,
  IGNORE_ERRORS,
  matchesFilenameIgnorelist,
  tracesSampler,
} from "./SENTRY_CONSTANTS"

export const SENTRY_DSN =
  process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

export const isProd =
  process.env.NEXT_PUBLIC_VERCEL_ENV === "production" ||
  process.env.SENTRY_DEBUGGING === "true"

export function initSentry(options: Partial<BrowserOptions> = {}): void {
  if (!isProd) return

  Sentry.init({
    release: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
    denyUrls: DENY_URLS,
    dsn:
      SENTRY_DSN ||
      "https://bb6c39c632ef45069bd0c57bdcd2bc6c@o1337141.ingest.sentry.io/6606494",
    tracesSampler: (samplingContext) => tracesSampler(samplingContext, 0.35),
    profilesSampleRate: 1.0,
    debug: process.env.SENTRY_DEBUGGING === "true",
    ignoreErrors: IGNORE_ERRORS,
    beforeSend: beforeSendHandler,
    enabled: isProd,
    ...options,
  })
}

function beforeSendHandler(
  event: ErrorEvent,
  hint: EventHint
): ErrorEvent | null {
  try {
    const originalException = hint.originalException?.toString()

    if (shouldIgnoreString(originalException)) {
      return null
    }

    const syntheticException = hint.syntheticException?.toString()

    if (shouldIgnoreString(syntheticException)) {
      return null
    }

    const frames = event.exception?.values?.[0]?.stacktrace?.frames || []
    if (
      frames.some(
        (frame) =>
          shouldIgnoreString(frame.filename) ||
          shouldIgnoreString(frame.module) ||
          shouldIgnoreString(frame.function)
      )
    ) {
      return null
    }

    if (shouldIgnoreString(event?.exception?.values?.[0]?.value)) {
      return null
    }
  } catch (e) {
    console.error("Error filtering Sentry event", e)
    Sentry.addBreadcrumb({
      message: "Error filtering Sentry event",
      data: { error: e, event },
    })
    Sentry.captureMessage("Error filtering Sentry event")
  }

  return event
}

function shouldIgnoreString(str: string | undefined): boolean {
  if (!str) return false

  // Check if the string matches any pattern in IGNORE_ERRORS
  const matchesIgnoreErrors = IGNORE_ERRORS.some((ignore) => {
    if (typeof ignore === "string") {
      return str.toLowerCase().includes(ignore.toLowerCase())
    } else if (ignore instanceof RegExp) {
      return ignore.test(str)
    }
    return false
  })

  // Check if the string matches the filename ignore list
  const matchesFilenameIgnore = matchesFilenameIgnorelist(str)

  return matchesIgnoreErrors || matchesFilenameIgnore
}

import { Node } from "@tiptap/core"
import { ReactNodeViewRenderer, nodePasteRule } from "@tiptap/react"
import TwitterComponent from "./StaticTwitter"
import { constructHtml } from "./utils"
import { Plugin, PluginKey } from "@tiptap/pm/state"

// https://regex101.com/r/L61t9f/1
const twitterRegex =
  /^https?:\/\/(x|twitter)\.com\/(?:#!\/)?(\w+)\/status(?:es)?\/(\d+)(?:\/.*)?(?:\?.*)?/g
const twitterRegex2 =
  /^https?:\/\/(x|twitter)\.com\/(?:#!\/)?(\w+)\/status(?:es)?\/(\d+)(?:\/.*)?(?:\?.*)?/

export const getTweetId = (url: string) => {
  const match = url.match(twitterRegex2)
  if (match) return match[3]
  return null
}
export interface TwitterOptions {
  addPasteHandler: boolean
  HTMLAttributes: Record<string, any>
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    twitter: {
      setTweet: (options: { tweetURL: string }) => ReturnType
    }
  }
}

export const Twitter = Node.create<TwitterOptions>({
  name: "twitter",
  group: "block",
  draggable: true,
  atom: true,

  addOptions() {
    return {
      addPasteHandler: true,
      HTMLAttributes: {},
    }
  },

  addAttributes() {
    return {
      pos: {
        default: 0,
      },
      ast: {
        default: null,
        parseHTML: (element) => {
          const ast = element.getAttribute("ast")
          if (ast) return JSON.parse(ast)
          return null
        },
      },
      tweetData: {
        default: null,
        parseHTML: (element) => {
          const data = element.getAttribute("tweetData")
          if (data) return JSON.parse(data)
          return null
        },
      },
      tweetUrl: { default: null },
      tweetId: {
        default: null,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ]
  },

  addCommands() {
    return {
      setTweet:
        (options) =>
        ({ commands }) => {
          const tweetId = getTweetId(options.tweetURL)
          if (!tweetId) return false

          return commands.insertContent({
            type: this.name,
            attrs: { tweetUrl: options.tweetURL, tweetId },
          })
        },
    }
  },

  addPasteRules() {
    return [
      nodePasteRule({
        find: twitterRegex,
        type: this.type,
        getAttributes: (match) => {
          return { tweetUrl: match[0], tweetId: match[3] }
        },
      }),
    ]
  },

  renderHTML({ HTMLAttributes }) {
    if (!HTMLAttributes.tweetData)
      return [
        "div",
        {
          "data-type": this.name,
          tweetId: HTMLAttributes.tweetId,
        },
      ]
    let html = ""

    try {
      html = constructHtml(HTMLAttributes.tweetData, true).html
    } catch (e) {
      console.error(e)
    }

    const { tweetId, tweetData } = HTMLAttributes

    return [
      "div",
      {
        "data-type": this.name,
        tweetId,
        tweetData: JSON.stringify(tweetData),
        html,
      },
    ]
  },

  addStorage() {
    return {
      ex: 0,
    }
  },

  addNodeView() {
    const view = ReactNodeViewRenderer(TwitterComponent)
    this.storage.ex = TwitterComponent

    return view
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("twitterPlugin"),
        // Replace twitter embed with plain link when data is "error"
        appendTransaction: (transactions, oldState, newState) => {
          const tr = newState.tr
          let modified = false
          newState.doc.descendants((node, pos, _parent) => {
            if (node.type.name !== "twitter") return

            if (node.attrs.tweetData === "error") {
              tr.delete(pos, pos + node.nodeSize)
              if (node.attrs.tweetUrl)
                tr.insert(
                  pos,
                  this.editor.schema.node(
                    "paragraph",
                    null,
                    this.editor.schema.text(node.attrs.tweetUrl, [
                      this.editor.schema.mark("link", {
                        href: node.attrs.tweetUrl,
                        target: "_blank",
                      }),
                    ])
                  )
                )
              modified = true
            }
          })
          if (modified) return tr
        },
      }),
    ]
  },
})

export default Twitter

import { selectBlog } from "features/blogSlice"
import { useSelector } from "react-redux"

import { parseEther } from "viem"

import { selectCurrentNote } from "features/noteSlice"

import {
  getCollectibleChain,
  getCollectibleCost,
  getCollectibleSupply,
} from "@/util/crypto"
import { CollectibleMetricData, UNITS } from "@/types/highlights"
import { COLLECTIBLE_DATA } from "@/util/crypto"

/**
 * Retrieve the default metadata (supply, price, chain) for a new
 * highlight. This relies on the currently selected blog & note.
 */
export default function useNewHighlightMetadata(): CollectibleMetricData & {
  priceEth: number
  ownerAddress: string
  unit: UNITS
} {
  const blog = useSelector(selectBlog)
  const note = useSelector(selectCurrentNote)

  const supply: string = getCollectibleSupply(note, blog)
  const chain = getCollectibleChain(note, blog)
  const priceEth = getCollectibleCost(note, blog)
  const unit = COLLECTIBLE_DATA[chain].unit

  const ownerAddress =
    note?.collectibleWalletAddress ||
    blog.collectibleWalletAddress ||
    (blog.user && "wallet_address" in blog.user ? blog.user.wallet_address : "")

  // Need to deal with this as a string or BigNumber, NOT a number, since
  // this will overflow regular JS numbers.
  const priceWei = parseEther(priceEth.toString())

  console.log("This is the highlight data", chain, priceEth, supply, note, blog)

  return {
    priceWei: BigInt(priceWei),
    priceEth,
    maxSupply: BigInt(supply),
    remainingSupply: BigInt(supply),
    chain,
    ownerAddress,
    unit,
  }
}

import Link from "@tiptap/extension-link"

export const CustomLink = Link.extend({
  openOnClick: false,
  inclusive: false,

  // Ignore image links since they need to be handled by the ImageLink extension
  // @ts-ignore
  parseHTML() {
    return [
      {
        tag: "a[href]",
        getAttrs: (node) => {
          if (
            typeof node !== "string" &&
            node.firstElementChild &&
            node.firstElementChild.nodeName === "IMG"
          )
            return false
          else return true
        },
      },
    ]
  },

  // @ts-ignore
  // addKeyboardShortcuts() {
  //   return {
  //     "Mod-k": () => {
  //       // Unset active link
  //       if (this.editor.isActive("link")) {
  //         this.editor.commands.unsetLink()
  //       } else {
  //         linkToolbar.current = "show"
  //         textToolbar.current = "hide"
  //         // This will cancel out, but will trigger the toolbar
  //         this.editor.commands.toggleLink({ href: "" })
  //         this.editor.commands.toggleLink({ href: "" })
  //         linkToolbar.current = "hide"
  //         textToolbar.current = "show"
  //       }
  //     },
  //   }
  // },
})

export default CustomLink

import Button from "components/Button"
import { MutableRefObject } from "react"

type Props = {
  cancelButtonRef: MutableRefObject<null>
  setOpen: (val: boolean) => void
}

export default function DoneBtn(props: Props) {
  return (
    <div>
      <Button
        type="button"
        replaceClassName="w-48 px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:text-sm"
        onClick={() => props.setOpen(false)}
        ref={props.cancelButtonRef}
        text="Done"
      />
    </div>
  )
}

import Button from "components/Button"
import Spinner from "components/Spinner"
import { useAppDispatch } from "store"
import clsx from "clsx"
import AreYouSureConfirmationDialogue from "components/AreYouSureConfirmationDialogue"
import { useState } from "react"
import { deleteBlogMembership } from "features/membershipSlice"
import { Membership } from "@types"
import { isFreeMembership } from "../SharedFunctions"

/** Display subscriber count to authors only (and not when in form preview mode in the create/edit form). */
export default function AuthorOnlyDeleteMembershipBtn({
  isAuthorView,
  isFormPreview,
  membership,
}: {
  isAuthorView: boolean
  isFormPreview: boolean
  membership: Membership
}) {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [showDeleteMembershipDialogue, setShowDeleteMembershipDialogue] =
    useState(false)

  if (!isAuthorView || isFormPreview || isFreeMembership(membership))
    return null

  return (
    <div>
      {!showDeleteMembershipDialogue && (
        <Button
          type="button"
          replaceClassName={clsx(
            "text-red-500 text-sm font-semibold mt-4",
            "border border-1 border-red-300 py-2 px-4 rounded-md hover:bg-red-600 hover:text-white"
          )}
          onClick={async () => {
            setShowDeleteMembershipDialogue(true)
          }}
        >
          <div className="relative px-4">
            Delete membership
            {isLoading && (
              <div className="absolute -right-4 top-0.5">
                <Spinner height={4} width={4} />
              </div>
            )}
          </div>
        </Button>
      )}
      <div className="px-2 mt-3 flex justify-center text-center">
        <AreYouSureConfirmationDialogue
          isDestructiveAction={true}
          showDialogue={showDeleteMembershipDialogue}
          setShowDialogue={setShowDeleteMembershipDialogue}
          isDisabled={isLoading}
          onConfirmCallback={async () => {
            if (!membership.blogId) {
              return
            }

            setIsLoading(true)

            await dispatch(
              deleteBlogMembership(membership.blogId, membership.id)
            )

            setIsLoading(false)
          }}
          customPromptText="This will permanently delete this membership and any associated gates."
          customPromptTextStyling="text-sm text-gray-600"
          customConfirmButtonText="Delete"
          customConfirmButtonStyling="w-22"
          customCancelButtonStyling="w-22"
          confirmTrackingData={{
            blogId: membership.blogId,
            membershipId: membership.id,
          }}
        />
      </div>
    </div>
  )
}

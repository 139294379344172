import { Node, nodePasteRule } from "@tiptap/core"
import { Editor, NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react"
import { getSoundXyzEmbed } from "api_routes/published"
import { selectUsersBlog } from "features/blogSlice"
import { selectUser } from "features/userSlice"
import { useEffect } from "react"
import { useSelector } from "react-redux"

// https://regex101.com/r/C9mWxY/1
export const SOUNDXYZ_REGEX =
  /^(https?:\/\/)?(www\.)?sound\.xyz\/(.*)\/([^\?]*)/
export const SOUNDXYZ_REGEX_GLOBAL =
  /^(https?:\/\/)?(www\.)?sound\.xyz\/(.*)\/([^\?]*)/g

const extractEmbedID = (url: string) => {
  const match = url.match(SOUNDXYZ_REGEX)
  if (match && match[3] && match[4]) {
    return { artist: match[3], release: match[4] }
  }
  return
}

export interface SoundXyzOptions {
  HTMLAttributes: Record<string, any>
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    soundXyz: {
      setSoundXyzEmbed: (options: { src: string }) => ReturnType
    }
  }
}

export default Node.create<SoundXyzOptions>({
  name: "soundXyz",
  group: "block",
  draggable: true,
  atom: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  addAttributes() {
    return {
      artist: { default: null },
      release: { default: null },
      title: { default: null },
      artistName: { default: null },
      coverImageUrl: { default: null },
      embedId: { default: null },
      referral: { default: null },
    }
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ]
  },

  addCommands() {
    return {
      setSoundXyzEmbed:
        (options) =>
        ({ commands }) => {
          const { artist, release } = extractEmbedID(options.src) || {}
          if (!artist || !release) return false
          return commands.insertContent({
            type: this.name,
            attrs: { ...options, artist, release },
          })
        },
    }
  },

  addPasteRules() {
    return [
      nodePasteRule({
        find: SOUNDXYZ_REGEX_GLOBAL,
        type: this.type,
        getAttributes: (match) => {
          if (!match.input) return false
          const { artist, release } = extractEmbedID(match.input) || {}
          if (!artist || !release) return false
          return { artist, release }
        },
      }),
    ]
  },

  renderHTML({ HTMLAttributes }) {
    const {
      title,
      artistName,
      artist,
      release,
      coverImageUrl,
      embedId,
      referral,
    } = HTMLAttributes

    const html = `
    <div style="text-align:center;"><img src=${coverImageUrl} class="zora-embed" /></div>
    <div style="text-align: center; margin-top: 12px; margin-bottom: 24px;">
      <p style="font-weight: bold; margin-bottom: 0;">${title}</p>
      <p style="margin-top: 0;">${artistName}</p>
      <a  style="
    display: inline-block;
    border-radius: 4px;
    background-color: rgb(59, 130, 246);
    padding: 16px 24px;
    font-weight: 600;
    line-height: 100%;
    color: rgb(255, 255, 255);
    text-decoration: none;
    "
    href="https://www.sound.xyz/${artist}/${release}${
      referral ? `?referrer=${referral}` : ""
    }">
    Collect
        </a>
      </div>`

    return ["div", { "data-type": this.name, embedId, referral, html }]
  },

  addNodeView() {
    return ReactNodeViewRenderer(SoundXyzEmbedComponent)
  },
})

type Props = {
  editor: Editor
  node: any
  updateAttributes: (attrs: any) => void
}

const SoundXyzEmbedComponent = (props: Props): JSX.Element => {
  const { node, updateAttributes } = props
  const { artist, release, embedId, referral } = node.attrs
  const blog = useSelector(selectUsersBlog)
  const user = useSelector(selectUser)

  useEffect(() => {
    const getSoundXyz = async () => {
      try {
        let wallet = blog.collectibleWalletAddress
        if ("wallet_address" in user) wallet = user["wallet_address"]

        const embedData = await getSoundXyzEmbed(artist, release)

        updateAttributes({
          embedId: embedData.id,
          coverImageUrl: embedData.coverImage.url,
          title: embedData.title,
          artistName: embedData.artist.name,
          referral: wallet,
        })
      } catch (e) {
        console.error(
          `Failed to retrieve Sound.xyz embed: ${artist} - ${release}`,
          e
        )
      }
    }
    if (!embedId) getSoundXyz()
  }, [
    artist,
    blog.collectibleWalletAddress,
    embedId,
    release,
    updateAttributes,
    user,
  ])

  if (!embedId) return <SoundXyzSkeleton />

  const html = `<div class="embed">
    <iframe src="https://embed.sound.xyz/v1/release/${embedId}?${
    referral ? `referrer=${referral}&` : ""
  }referral_source=embed-sound" class="soundxyz" allow="clipboard-write" sandbox="allow-scripts allow-popups allow-popups-to-escape-sandbox"></iframe>
    </div>
  `

  return (
    <NodeViewWrapper className="react-component my-5" data-drag-handle>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </NodeViewWrapper>
  )
}

const SoundXyzSkeleton = () => (
  <NodeViewWrapper className="react-component not-prose my-5" data-drag-handle>
    <div
      role="status"
      className="mx-auto w-full max-w-[548px] rounded border border-gray-200 p-4 dark:border-gray-700 md:p-6 embed bg-white"
    >
      <div className="mb-2 flex items-center space-x-3 animate-pulse">
        <svg
          className="h-14 w-14 text-gray-200 dark:text-gray-700"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
            clipRule="evenodd"
          ></path>
        </svg>
        <div>
          <div className="animate-pulse mb-2 h-2.5 w-32 rounded-full bg-gray-200 dark:bg-gray-700"></div>
          <div className="animate-pulse h-2 w-48 rounded-full bg-gray-200 dark:bg-gray-700"></div>
        </div>
      </div>
      <div className="animate-pulse mb-2.5 h-2 rounded-full bg-gray-200 dark:bg-gray-700"></div>
      <div className="animate-pulse mb-2.5 h-2 rounded-full bg-gray-200 dark:bg-gray-700"></div>
      <div className="animate-pulse mr-16 h-2 rounded-full bg-gray-200 dark:bg-gray-700"></div>

      <span className="sr-only">Loading...</span>
    </div>
  </NodeViewWrapper>
)

export type GateModalStep = {
  id: string
  name: string
  status: string
}

// Group step is hidden initially. Only shown if the user has already selected at least one gate type.
export const StepDefinitionGroup: GateModalStep = {
  id: "GROUP",
  name: "Manage gates",
  status: "CURRENT",
}
export const StepDefinitionGateType: GateModalStep = {
  id: "GATE_TYPE",
  name: "Choose gate",
  status: "CURRENT",
}
export const StepDefinitionConnectWallet: GateModalStep = {
  id: "CONNECT_WALLET",
  name: "Connect wallet",
  status: "UPCOMING",
}
export const StepDefinitionChooseToken: GateModalStep = {
  id: "CHOOSE_TOKEN",
  name: "Choose token",
  status: "UPCOMING",
}
export const StepDefinitionChooseMembership: GateModalStep = {
  id: "CHOOSE_MEMBERSHIP",
  name: "Choose membership",
  status: "UPCOMING",
}
export const StepDefinitionSettings: GateModalStep = {
  id: "SETTINGS",
  name: "Settings",
  status: "UPCOMING",
}

import type { SamplingContext } from "@sentry/types"

export const IGNORE_ERRORS: (string | RegExp)[] = [
  /hard navigate/i,
  /Cannot redefine property: (ethereum|solana)/i,
  /Failed to execute 'send' on 'WebSocket': Still in CONNECTING state\./i,
  /Still in CONNECTING state/i,
  /Cannot read properties of (undefined|null) \(reading '.*'\)/i,
  /closeTransport called before connection was established/i,
  /wss:\/\/relay\.walletconnect/i,
  /WebSocket connection failed for host: wss:\/\/relay\.walletconnect\.org/i,
  /Permission denied to access property/i,
  /bytecode/i,
  /defineProperty/i,
  /^No RPC Url available for chainId*/,
  /^could not detect network*/,
  /^insufficient funds for intrinsic transaction cost*/,
  "call revert exception",
  "invalid contract address or ENS name",
  "missing provider",
  "Network Error",
  "same tag name",
  "missing response",
  "operation was aborted",
  "rejected the transaction",
  "timeout",
  "ResizeObserver loop limit exceeded",
  /websocket error 1006:/i,
  "Request aborted",
  "No matching key",
  "Database deleted by request of the user",
  "walletconnect",
  "can't redefine non-configurable property",
  "User rejected request",
  "wcm-button",
  "removeChild",
  "IDBDatabase",
  "Attempt to get a record from database",
  "Talisman",
  "privy",
  "metamask",
  "wagmi",
  "window.ethereum",
  "Maximum call stack size exceeded",
  "Database deleted by request of the user",
  "Blocked a frame with origin",
  "Telegram",
  "TelegramGameProxy",
  /TelegramGameProxy/i,
  "disconnected port object",
  "undefined is not an object (evaluating 'window.TelegramGameProxy.receiveEvent')",
  "null is not an object (evaluating 'nodeList[i].getAttribute('rel').startsWith')",
  "Suspense boundary received an update",
  /Suspense boundary received an update/i,
]

export const DENY_URLS: RegExp[] = [
  /extensions\//i,
  /^chrome:\/\//i,
  /.*inject\.chrome.*/i,
  /\/(gtm|ga|analytics)\.js/i,
  /.*assets\/injector\.js/i,
  /.*node_modules\@coinbase.*/i,
  /.*@coinbase\/wallet-sdk.*/i,
  /.*@walletconnect.*/i,
  /.*\/embed.*/i,
  /.*\/subscribe.*/i,
  /.*\/api\/highlight.*/i,
]

export const SENTRY_ROUTE_MAPPING: { [key: string]: number } = {
  "/[blogname]/subscribe": 0.005,
  "getServerSideProps (/[blogname]/subscribe)": 0.005,
  "getInitialProps (/_document)": 0.005,
  "GET /api/highlight": 0.005,
  "HEAD /api/highlight": 0.005,
}

export function matchesFilenameIgnorelist(name: string | undefined): boolean {
  if (!name) return false
  return DENY_URLS.some((regex) => regex.test(name))
}

export function tracesSampler(
  samplingContext: SamplingContext,
  defaultSampleRate: number
): number {
  if (process.env.SENTRY_DEBUGGING === "true") return 1
  return (
    SENTRY_ROUTE_MAPPING[samplingContext?.transactionContext?.name] ||
    defaultSampleRate
  )
}

import { Membership, Subscription } from "@types"
import { getUsersSubscriptionToBlog, refreshUser } from "features/userSlice"
import Button from "components/Button"
import Spinner from "components/Spinner"
import { Dispatch, SetStateAction } from "react"
import { useAppDispatch } from "store"
import { unsubscribeFromMembership } from "api_routes/memberships"
import {
  isFreeMembership,
  pollForSubscriptionCancellationStatus,
  pollForSubscriptionCreationStatus,
} from "../SharedFunctions"
import clsx from "clsx"

/**
 * Allows a reader to cancel their membership.
 * @param membership The membership to cancel.
 * @param subscription The subscription to cancel.
 * @returns A button allowing the reader to cancel their membership.
 */
export default function CancelMembershipButton({
  memberships,
  membership,
  subscription,
  isLoading,
  setCurrentActionItem,
}: {
  memberships: Membership[]
  membership: Membership
  subscription?: Subscription
  isLoading: boolean
  setCurrentActionItem: Dispatch<SetStateAction<string>>
}) {
  const dispatch = useAppDispatch()

  if (
    !subscription ||
    (subscription.status !== "ACTIVE" && subscription.status !== "PAST_DUE") ||
    subscription.membershipId !== membership.id ||
    // Can only cancel a subscription that has yet to be cancelled.
    // This field is only populated when a subscription is manually cancelled.
    "toBeUnsubscribedAt" in subscription ||
    subscription.toBeUnsubscribedAt !== undefined
  )
    return null

  return (
    <Button
      type="button"
      replaceClassName={clsx(
        "mt-4 text-red-500 text-sm font-semibold",
        `border border-1 border-red-300 py-2 px-4 rounded-md ${
          !isLoading && "hover:bg-red-600 hover:text-white"
        }`
      )}
      disabled={isLoading}
      onClick={async () => {
        if (isLoading) return

        setCurrentActionItem(membership.id)

        await unsubscribeFromMembership(
          subscription.blogId,
          membership.id,
          subscription.id
        )

        // Then retrieve the updated subscription status.
        // For Stripe, this will update the subscription to be cancellated at the end of the period
        if (subscription.planType === "stripe-subscription") {
          await pollForSubscriptionCancellationStatus(
            subscription.blogId,
            subscription.membershipId,
            dispatch
          )
        } else {
          // For Loop, this will immediately downgrade the subscription to free
          if (subscription.planType === "loop-subscription") {
            // Get Blog's free membership
            const freeMembershipId = memberships.find((m) =>
              m.plans.every((p) => p.price === 0)
            )?.id

            if (!freeMembershipId)
              throw Error("Missing free membership when trying to downgrade")

            // Poll for free subscription creation
            await pollForSubscriptionCreationStatus(
              subscription.blogId,
              freeMembershipId,
              dispatch
            )
          }
        }

        await Promise.all([
          dispatch(getUsersSubscriptionToBlog(subscription.blogId)),
          dispatch(refreshUser()),
        ])

        setCurrentActionItem("")
      }}
    >
      <div className="flex justify-center relative px-4">
        {isFreeMembership(membership) ? "Unsubscribe" : "Cancel subscription"}
        {isLoading && (
          <div className="absolute -right-4 top-0.5">
            <Spinner height={4} width={4} />
          </div>
        )}
      </div>
    </Button>
  )
}

import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  Ref,
} from "react"

const EmojiList = (props: any, ref: Ref<HTMLDivElement>) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const selectItem = (index: number) => {
    const item = props.items[index]

    if (item) {
      props.command({ name: item.name })
    }
  }

  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + props.items.length - 1) % props.items.length
    )
  }

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length)
  }

  const enterHandler = () => {
    selectItem(selectedIndex)
  }

  useEffect(() => setSelectedIndex(0), [props.items])

  useImperativeHandle(
    ref,
    // @ts-ignore
    () => {
      return {
        onKeyDown: (x: any) => {
          if (x.event.key === "ArrowUp") {
            upHandler()
            return true
          }

          if (x.event.key === "ArrowDown") {
            downHandler()
            return true
          }

          if (x.event.key === "Enter") {
            enterHandler()
            return true
          }

          return false
        },
      }
    },
    [upHandler, downHandler, enterHandler]
  )

  return (
    <div className="emoji-items">
      {props.items.map((item: any, index: number) => (
        <button
          className={`emoji-item ${
            index === selectedIndex ? "is-selected" : ""
          }`}
          key={index}
          onClick={() => selectItem(index)}
        >
          {item.fallbackImage ? <img src={item.fallbackImage} /> : item.emoji}:
          {item.name}:
        </button>
      ))}
    </div>
  )
}

export default forwardRef(EmojiList)

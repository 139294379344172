import { MembershipSubscriberCountResp } from "@types"

/** Display subscriber count to authors only (and not when in form preview mode in the create/edit form). */
export default function AuthorOnlySubscriberCount({
  isAuthorView,
  isFormPreview,
  subscriberCount,
}: {
  isAuthorView: boolean
  isFormPreview: boolean
  subscriberCount: MembershipSubscriberCountResp | undefined
}) {
  if (!isAuthorView || isFormPreview) return null

  const countDisplay = `${
    (subscriberCount === undefined && "Loading") ||
    subscriberCount?.active.toLocaleString()
  } subscribers ${subscriberCount === undefined ? "..." : ""}`

  return (
    <div className="flex justify-center border-b border-b-1 border-b-gray-200 pb-4 group relative">
      <span className="flex justify-center items-center gap-x-1.5 rounded-md bg-blue-100/75 px-3 py-1 text-sm font-medium text-blue-700 ring-1 ring-inset ring-blue-700/20 cursor-pointer">
        {countDisplay}
      </span>
      <span className="rounded-md pointer-events-none absolute top-7 left-16 w-max opacity-0 transition-opacity group-hover:opacity-100 bg-white border border-1 border-gray-300 px-3 py-2">
        {subscriberCount === undefined ? (
          "Loading..."
        ) : (
          <div className="flex flex-col">
            <div>
              <span className="font-semibold">Active: </span>
              <span className="text-gray-700">{subscriberCount?.active}</span>
            </div>
            <div>
              <span className="font-semibold">Canceled: </span>
              <span className="text-gray-700">{subscriberCount?.inactive}</span>
            </div>
          </div>
        )}
      </span>
    </div>
  )
}

import clsx from "clsx"
import { EnvelopeIcon, ArrowRightIcon } from "@heroicons/react/24/solid"
import { selectError } from "features/errorSlice"
import Link from "next/link"
import { useRouter } from "next/router"
import { useSelector } from "react-redux"
import Button from "./Button"
import useColor from "hooks/useColor"
import Banner from "./Banner"
import useUser from "hooks/useUser"

export default function SubscribeEmailInput({
  preFilledEmail,
  email,
  setEmail,
  loading,

  submitEmailCallback,
  initialFocusRef,
  minimal,
  vertical,
}: {
  preFilledEmail?: string
  email: string
  setEmail: (email: string) => void
  loading: boolean
  submitEmailCallback: () => void

  initialFocusRef?: React.Ref<any>
  minimal?: boolean

  // Vertically stacked rows, or horizontally stacked columns?
  // By default we use horizontal columns.
  vertical?: boolean
}) {
  const router = useRouter()
  const error = useSelector(selectError)
  const color = useColor()
  const user = useUser()

  const submitEmail = async (e: any) => {
    e.preventDefault()

    submitEmailCallback()
  }

  return (
    <div>
      <form className="space-y-6" onSubmit={submitEmail}>
        <div className={clsx(minimal ? "" : "mb-6")}>
          <div
            className={clsx(
              vertical ? "flex flex-col" : "flex flex-row",
              minimal ? "" : "mt-9",
              "shadow-sm rounded-md"
            )}
          >
            <div className="relative flex items-stretch flex-grow focus-within:z-10">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <EnvelopeIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="email"
                name="email"
                ref={initialFocusRef}
                value={preFilledEmail ? preFilledEmail : undefined}
                disabled={preFilledEmail ? true : false}
                id="email"
                className={clsx(
                  vertical ? "rounded-t" : "rounded-l",
                  "block w-full pl-10 border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                )}
                placeholder="Type your email..."
                onChange={(ev) => setEmail(ev.target.value)}
              />
            </div>
            <Button
              type="button"
              disabled={loading}
              style={{
                backgroundColor: color.primary,
                color: color.primaryText,
              }}
              onClick={(e) => submitEmail(e)}
              replaceClassName={clsx(
                vertical ? "rounded-b" : "rounded-r",
                "inline-flex justify-center px-4 py-2 text-base font-medium text-white bg-blue-600 border border-transparent shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
              )}
              text={loading ? "Subscribing..." : "Subscribe"}
            >
              <ArrowRightIcon
                className="w-5 h-5 ml-2 text-white-400"
                aria-hidden="true"
              />
            </Button>
          </div>
        </div>
        {error.msg && <Banner className="mb-2" type="error" text={error.msg} />}

        {!user.loggedIn && !minimal && (
          <div className="items-center justify-center text-sm text-center text-gray-400">
            Already a subscriber?{" "}
            <span className="font-bold underline">
              <Link href={`/login?redirect=${router.asPath}`}>Log in</Link>.
            </span>
          </div>
        )}
      </form>
    </div>
  )
}

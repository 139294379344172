import { Node } from "@tiptap/core"
import { Editor, NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react"
import { selectUsersBlog } from "features/blogSlice"
import { selectLatestCurrentNote } from "features/noteSlice"
import useNoteUrl from "hooks/useNoteUrl"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import ConnectWalletBtn from "../../ConnectWalletButton"
import Button from "components/Button"
import { selectUser } from "features/userSlice"

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    connectWalletButton: {
      setConnectWalletButton: () => ReturnType
    }
  }
}

export default Node.create({
  name: "connectWalletButton",
  group: "block",

  addAttributes() {
    return {
      href: {
        default: null,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ]
  },

  addCommands() {
    return {
      setConnectWalletButton:
        () =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
          })
        },
    }
  },

  renderHTML({ HTMLAttributes }) {
    const html = `<a class="email-subscribe-button" href="${HTMLAttributes.href}">Connect Wallet</a>`

    return ["div", { "data-type": this.name, class: "center-contents", html }]
  },

  addNodeView() {
    return ReactNodeViewRenderer(ConnectWalletButtonComponent)
  },
})

type Props = {
  editor: Editor | null
  node: any
  updateAttributes: any
}

function ConnectWalletButtonComponent({
  editor,
  node,
  updateAttributes,
}: Props): JSX.Element {
  const blog = useSelector(selectUsersBlog)
  const note = useSelector(selectLatestCurrentNote)
  const user = useSelector(selectUser)
  const { fullNoteUrl } = useNoteUrl({ blog, note, forceSlug: true })
  console.log(blog, note, fullNoteUrl)

  useEffect(() => {
    if (node.attrs.href) return

    updateAttributes({ href: fullNoteUrl })
  }, [fullNoteUrl, updateAttributes])

  return (
    <NodeViewWrapper>
      <div
        className={`flex justify-center my-5 ${
          editor &&
          editor.isEditable &&
          "disable-connect-wallet-button-embed-click"
        }`}
      >
        {editor && editor.isEditable ? (
          <Button>Connect Wallet</Button>
        ) : (
          <ConnectWalletBtn
            showConnectedButton={
              "wallet_address" in user && !!user.wallet_address
            }
          />
        )}
      </div>
    </NodeViewWrapper>
  )
}

import { EnrichedCollectRecord } from "@/types/highlights"
import { UserIcon } from "@heroicons/react/24/solid"
import useWalletName from "hooks/useWalletName"
// different import path!
import AuthorHoverCard from "components/AuthorHoverCard"

function Avatar(props: { record: EnrichedCollectRecord }) {
  const { record } = props

  if (!("avatar_url" in record.user)) {
    return (
      <UserIcon className="h-8 w-8 rounded-full p-1 text-gray-400 bg-gray-200 border-white" />
    )
  }

  return (
    <img
      src={record.user.avatar_url}
      alt=""
      className="h-8 w-8 rounded-full bg-gray-800"
    />
  )
}

function SubscriberBadge(props: { record: EnrichedCollectRecord }) {
  const { record } = props
  if (!record.subscribed) return null

  return (
    <span className="inline-flex flex-shrink-0 items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
      Subscriber
    </span>
  )
}

function NameAndWallet(props: { record: EnrichedCollectRecord }) {
  const { record } = props

  const walletNameOrEns = useWalletName(record.walletAddress)

  // If the custom author name is set, use it then display the wallet address.
  if ("authorName" in record.user && record.user.authorName) {
    return (
      <>
        <div className="truncate text-sm font-medium leading-6 text-gray-800">
          {record.user.authorName}
        </div>

        <span className="rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 font-mono  max-w-24 truncate ">
          {walletNameOrEns.name}
        </span>
      </>
    )
  }

  // If there's a wallet address, and NO ENS and NO author name, JUST display the wallet address.
  // Increase the truncation to display more of the wallet address.
  if (walletNameOrEns.type === "WALLET_ADDRESS") {
    return (
      <>
        <div className="truncate text-sm font-medium leading-6 text-gray-800 max-w-120">
          {walletNameOrEns.name}
        </div>
      </>
    )
  }

  // Otherwise, there's an ENS and a wallet address, so display both
  return (
    <>
      <div className="truncate text-sm font-medium leading-6 text-gray-800 max-w-60">
        {walletNameOrEns.name}
      </div>

      <span className="rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 font-mono max-w-64 truncate ">
        {record.walletAddress}
      </span>
    </>
  )
}

function CollectorRow(props: { item: EnrichedCollectRecord; index: number }) {
  const { item, index } = props

  return (
    <AuthorHoverCard wallet={item.walletAddress}>
      <tr className="hover:bg-gray-50 relative">
        <td className="py-4 pl-4 pr-8 sm:pl-6 lg:pl-8 w-9/12">
          <div className="flex items-center gap-x-4">
            <Avatar record={item} />
            <NameAndWallet record={item} />
            <SubscriberBadge record={item} />
          </div>
        </td>

        <td className="hidden py-4 pl-0 pr-4 text-right text-sm leading-6 text-gray-400 sm:table-cell sm:pr-6 lg:pr-8 w-3/12">
          #{index + 1}
        </td>
      </tr>
    </AuthorHoverCard>
  )
}

export default function CollectorTable(props: {
  records: EnrichedCollectRecord[]
}) {
  const { records } = props

  return (
    <div className="px-4  ">
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle ">
            <table className="min-w-full divide-y divide-gray-300">
              <tbody className="divide-y divide-gray-200 bg-white text-left">
                {records?.map((item, i) => (
                  <CollectorRow key={i} item={item} index={i} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

import {
  EnrichedGatingRule,
  EnrichedGatingRuleGroup,
  GatingRequirementMembership,
  Token,
} from "@/types/gatingRules"

import GatingRulePleaseSubscribe from "./GatingRulePleaseSubscribe"
import { Dispatch, SetStateAction } from "react"
import GatingRulePleaseBuyToken from "./GatingRulePleaseBuyToken"
import { isTokenGate } from "@/util/gatingRules"
import { GetTokenById } from "./ReaderGatesHelperFunctions"

import { Membership } from "@types"

export default function GatingRuleGroups({
  gatingRuleGroups,
  group,
  index,
  memberships,
  tokens,
  setIsError,
  setError,
  color,
}: {
  gatingRuleGroups: EnrichedGatingRuleGroup[]
  group: EnrichedGatingRuleGroup
  index: number
  memberships: Membership[] | undefined
  tokens: Token[] | undefined
  setIsError: Dispatch<SetStateAction<boolean>>
  setError: Dispatch<SetStateAction<Error | null>>
  color: any
}) {
  const gatingRules = group.enrichedGatingRules.sort(
    (
      gr1: EnrichedGatingRule,
      gr2: EnrichedGatingRule // This sorts by gate type so that subscriptions are consistently at the top and tokens below.
    ) =>
      gr1.gatingRequirement.gateType < gr2.gatingRequirement.gateType ? -1 : 1
  ) // TODO: Add a secondary sort to ensure we have purchased gates at the top and unpurchased at the bottom (like a check-list you have to go through)
  // TODO: Add a tertiary sort by alphabetical order to ensure consistency.

  return (
    <div>
      <div className="border-4 border-primary-50 rounded-md">
        <div className="py-2 px-4 rounded-md bg-secondary-200">
          {gatingRules.map((gr: EnrichedGatingRule, index: number) => (
            <div key={gr.id}>
              {gr.gatingRequirement.gateType == "MEMBERSHIP" && (
                <GatingRulePleaseSubscribe
                  gatingRule={gr}
                  membership={memberships?.find(
                    (m) =>
                      m.id ===
                      (gr.gatingRequirement as GatingRequirementMembership)
                        .membershipId
                  )}
                  setIsError={setIsError}
                  setError={setError}
                />
              )}
              {isTokenGate(gr) && (
                <GatingRulePleaseBuyToken
                  gatingRule={gr}
                  token={GetTokenById(gr, tokens)}
                  setIsError={setIsError}
                  setError={setError}
                />
              )}
              {index < group.enrichedGatingRules.length - 1 && (
                <div className="border-b-gray-300 border-b border-b-1 mx-4"></div>
              )}
            </div>
          ))}
        </div>
      </div>
      {index < gatingRuleGroups.length - 1 && (
        <div className="relative flex justify-center mt-2">
          <div className="absolute top-3 z-0 border-b border-dotted border-b-1 border-b-gray-600 w-56 sm:w-96 md:w-156"></div>
          <div
            className="z-10 w-12 flex justify-center"
            style={{
              backgroundColor: color.secondary || "white",
              color: color.fontColor || "text-gray-900",
            }}
          >
            OR
          </div>
        </div>
      )}
    </div>
  )
}

import { Node } from "@tiptap/core"
import { Editor, NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react"
import { selectUsersBlog } from "features/blogSlice"
import { selectLatestCurrentNote } from "features/noteSlice"
import useBlogUrl from "hooks/useBlogUrl"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import SubscribeButton from "../../../components/SubscribeButton"

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    subscribeButton: {
      setSubscribeButton: () => ReturnType
    }
  }
}

export default Node.create({
  name: "subscribeButton",
  group: "block",

  addAttributes() {
    return {
      href: {
        default: null,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ]
  },

  addCommands() {
    return {
      setSubscribeButton:
        () =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
          })
        },
    }
  },

  renderHTML({ HTMLAttributes }) {
    const html = `<a class="email-subscribe-button" href="${HTMLAttributes.href}">Subscribe</a>`

    return ["div", { "data-type": this.name, class: "center-contents", html }]
  },

  addNodeView() {
    return ReactNodeViewRenderer(SubscribeButtonComponent)
  },
})

type Props = {
  editor: Editor | null
  node: any
  updateAttributes: any
}

function SubscribeButtonComponent({
  editor,
  node,
  updateAttributes,
}: Props): JSX.Element {
  const blog = useSelector(selectUsersBlog)
  const note = useSelector(selectLatestCurrentNote)
  const { fullUrl } = useBlogUrl({ blog })

  useEffect(() => {
    if (node.attrs.href) return

    updateAttributes({ href: `${fullUrl}/memberships` })
  }, [fullUrl, updateAttributes])

  return (
    <NodeViewWrapper>
      <div className="flex justify-center my-5">
        <SubscribeButton isEditable={editor !== null && editor.isEditable} />
      </div>
    </NodeViewWrapper>
  )
}

import { axios, params } from "./resolve"
import { AxiosResponse } from "axios"
import type { NotificationResp } from "@types"

const API = "/notifications"

export async function getNotifications(): Promise<Array<NotificationResp>> {
  const res: AxiosResponse<Array<NotificationResp> | undefined> =
    await axios.get(API, params)
  return res.data || []
}

export async function markAsRead(): Promise<unknown> {
  const res: AxiosResponse = await axios.post(API + "/read", null, params)
  return res.data
}

import { DeepPartial } from "@/types/utilities"
import {
  EnrichedGatingRule,
  EnrichedGatingRuleGroup,
  GatingRequirementGateType,
  GatingRequirementMembership,
  GatingRule,
  GatingRuleGroup,
} from "@/types/gatingRules"
import { Dispatch, SetStateAction } from "react"
import {
  GateModalStep,
  StepDefinitionChooseMembership,
  StepDefinitionChooseToken,
  StepDefinitionConnectWallet,
  StepDefinitionGateType,
  StepDefinitionGroup as StepDefinitionGroup,
  StepDefinitionSettings,
} from "./GateModalSteps"
import { addToken } from "features/blogSlice"
import { SharedProps } from "./SharedProps"
import { Membership } from "@types"
import {
  addGatingRuleToGroup,
  addGatingRuleGroupToPost,
} from "features/gatingRuleGroupsSlice"
import { isFreeMembership } from "components/Memberships/SharedFunctions"

// Returns the previous or next step's ID based on the ordering of the current steps.
export function FindStep(
  direction: "PREVIOUS" | "NEXT",
  currentStep: GateModalStep,
  allSteps: GateModalStep[]
): string {
  const currentStepIdx = allSteps.findIndex((s) => s.id == currentStep.id)
  const directionIdx =
    direction == "PREVIOUS" ? currentStepIdx - 1 : currentStepIdx + 1

  console.log(`Changing step to ${direction} step.`, {
    currentStep,
    allSteps,
    currentStepIdx,
    directionIdx,
  })

  // If it's going to be out of bounds, just return an empty string.
  if (directionIdx >= allSteps.length || directionIdx < 0) {
    return ""
  }

  console.log(`Changing step to ${direction} step.`, {
    currentStep,
    allSteps,
    newStepId: allSteps[directionIdx].id,
  })

  return allSteps[directionIdx].id
}

export function DoesGateExistInGroup(
  groups: EnrichedGatingRuleGroup[],
  gateType: GatingRequirementGateType,
  groupId?: string,
  // When passing in gateType as "MEMBERSHIP", you can also pass in a list of paid membership IDs
  // to specifically look for paid membership gates.
  paidMembershipIds: string[] = []
): boolean {
  const existingGates = FindExistingGatesInGroupByGateType(
    groups,
    gateType,
    groupId,
    paidMembershipIds
  )

  return existingGates && existingGates.length > 0 ? true : false
}

// This could find more than one gating rule in a group if e.g. there's multiple token gating rules.
export function FindExistingGatesInGroupByGateType(
  groups: EnrichedGatingRuleGroup[],
  gateType: GatingRequirementGateType,
  groupId?: string,
  // When passing in gateType as "MEMBERSHIP", you can also pass in a list of paid membership IDs
  // to specifically look for paid membership gates and ignore free gates.
  paidMembershipIds: string[] = []
): EnrichedGatingRule[] | undefined {
  if (!groupId) return undefined

  const group = groups.find((g) => g.id == groupId)
  if (!group) return undefined

  const gatingRules = group.enrichedGatingRules.filter(
    (gr) => gr.gatingRequirement.gateType == gateType
  )

  if (gateType == "MEMBERSHIP" && paidMembershipIds.length > 0) {
    console.log("ShouldShowAddGateButton: paidMembershipIds", paidMembershipIds)

    return gatingRules.filter((gr) =>
      paidMembershipIds.includes(
        (gr.gatingRequirement as GatingRequirementMembership).membershipId
      )
    )
  }

  return gatingRules || undefined
}

export function FindExistingGateById(
  groups: EnrichedGatingRuleGroup[],
  gateId: string
): EnrichedGatingRule | undefined {
  if (gateId == "") return undefined

  return groups
    .flatMap((g) => g.enrichedGatingRules)
    .find((gr) => gr.id == gateId)
}

export function CanChangeStep(
  changeToStepId: string,
  steps: GateModalStep[],
  currentStep: GateModalStep,
  selectedGateType: string | undefined,
  userHasWallet: boolean,
  mintOrImportToken: string,
  selectedExistingParagraphTokenId: string
): boolean {
  if (!steps || !currentStep) return false

  console.log("GatingRules: canChangeStep ", changeToStepId)
  // Don't allow the user to change step from the first gate type selection step until they've selected a gate type.
  if (
    changeToStepId != "GROUP" &&
    changeToStepId != "GATE_TYPE" &&
    !selectedGateType
  ) {
    console.log(
      "GatingRules: canChangeStep exiting guard condition 1",
      changeToStepId
    )
    return false
  }

  // Don't allow a user to change step from the connect wallet step without first connecting a wallet.
  if (
    (currentStep.id == "CONNECT_WALLET" && changeToStepId == "SETTINGS") ||
    (changeToStepId == "CHOOSE_TOKEN" && !userHasWallet)
  ) {
    console.log(
      "GatingRules: canChangeStep exiting guard condition 2",
      changeToStepId
    )
    return false
  }

  // Don't allow user to change step from the choose token step to the settings step
  // without first choosing either an existing token or the mint/import flow.
  if (
    currentStep.id == "CHOOSE_TOKEN" &&
    changeToStepId == "SETTINGS" &&
    mintOrImportToken == "" &&
    selectedExistingParagraphTokenId == ""
  ) {
    console.log(
      "GatingRules: canChangeStep exiting guard condition 3",
      changeToStepId
    )
    return false
  }

  console.log("GatingRules: canChangeStep approved!", changeToStepId)

  return true
}

export function setAvailableSteps(
  setSteps: Dispatch<SetStateAction<GateModalStep[] | undefined>>,
  setCurrentStep: Dispatch<SetStateAction<GateModalStep | undefined>>,
  currentStep: GateModalStep | undefined,
  selectedGateType: string | undefined,
  userHasWallet: boolean,
  existingGateGroups: EnrichedGatingRuleGroup[] | undefined,
  addingGate: boolean,
  selectedGateGroupId: string | undefined
) {
  // If we're on any step other than the first gate type selection step, do nothing,
  // so steps doesn't just disappear from under our feet once we're on the journey.
  if (currentStep && currentStep.id != "GATE_TYPE" && currentStep.id != "GROUP")
    return

  // Most step lists start with either the step group and step gate type (when some gate groups already exist),
  // or start with just the gate type step when this is their first gate group they're creating.
  const stepsArrPrefix =
    existingGateGroups && existingGateGroups.length > 0
      ? [StepDefinitionGroup, StepDefinitionGateType]
      : [StepDefinitionGateType]

  // If we're at the first step and...
  // The user has selected to edit an existing token, then let's
  // just show the second settings step.
  if (
    !addingGate &&
    selectedGateType == "TOKEN" &&
    DoesGateExistInGroup(existingGateGroups || [], "TOKEN", selectedGateGroupId)
  ) {
    console.log(
      "setAvailableSteps: 1. Editing existing token. Setting steps to: ",
      currentStep?.id,
      [...stepsArrPrefix, StepDefinitionSettings]
    )

    setSteps([...stepsArrPrefix, StepDefinitionSettings])
  } else if (selectedGateType == "TOKEN" && !userHasWallet) {
    // The user has selected a token, then let's show the wallet connected step (for a total of 4 steps)
    // only when the user selects a Token type gate, AND doesn't have a wallet already connected.
    console.log(
      "setAvailableSteps: 2. Adding new token with connection step. Setting steps to: ",
      currentStep?.id,
      [
        ...stepsArrPrefix,
        StepDefinitionConnectWallet,
        StepDefinitionChooseToken,
        StepDefinitionSettings,
      ]
    )

    setSteps([
      ...stepsArrPrefix,
      StepDefinitionConnectWallet,
      StepDefinitionChooseToken,
      StepDefinitionSettings,
    ])
  } else if (selectedGateType == "TOKEN" && userHasWallet) {
    // If the wallet is connected but we're on the token gate type, then we still want to show the choose token step.
    console.log(
      "setAvailableSteps: 3. Adding new token without connection step. Setting steps to: ",
      currentStep?.id,
      [...stepsArrPrefix, StepDefinitionChooseToken, StepDefinitionSettings]
    )

    setSteps([
      ...stepsArrPrefix,
      StepDefinitionChooseToken,
      StepDefinitionSettings,
    ])
  } else if (selectedGateType == "MEMBERSHIP") {
    // Show the membership step.
    console.log(
      "setAvailableSteps: memberships 3. Adding new membership. Setting steps to: ",
      currentStep?.id,
      [...stepsArrPrefix, StepDefinitionChooseMembership]
    )

    setSteps([...stepsArrPrefix, StepDefinitionChooseMembership])
  } else if (addingGate && currentStep?.id == "GROUP") {
    // If they're trying to add a new gate and the current step is the group screen,
    // then let's make this into a two-step journey for now (we'll add more steps or not
    // depending on their selections in step 2).
    console.log(
      "setAvailableSteps: 4. Adding new gate (group step). Setting steps to: ",
      currentStep?.id,
      [...stepsArrPrefix]
    )

    setSteps([...stepsArrPrefix])
    setCurrentStep(StepDefinitionGateType)
  } else if (
    (currentStep?.id == "GATE_TYPE" && addingGate) ||
    (currentStep?.id == "GATE_TYPE" && existingGateGroups?.length == 0)
  ) {
    // If they're moving to the GATE_TYPE selection step, make sure they see whatever the initial steps are,
    // with or without the first group step.
    console.log(
      "setAvailableSteps: 6. Showing gate selection step. Setting steps to: ",
      currentStep?.id,
      [...stepsArrPrefix]
    )

    setSteps([...stepsArrPrefix])
  } else if (
    !currentStep ||
    currentStep?.id == "GROUP" ||
    currentStep?.id == "GATE_TYPE"
  ) {
    // If the user hasn't decided what to do yet, and since many of the flows are 1-step flows, just show the choose gate step
    // or the gate group step, depending on how many existing gates there are.
    const initialStep =
      existingGateGroups && existingGateGroups.length > 0
        ? StepDefinitionGroup
        : StepDefinitionGateType
    setSteps([initialStep])

    console.log(
      "setAvailableSteps: 5. Showing initial steps. Setting steps to: ",
      currentStep?.id,
      [initialStep]
    )

    setCurrentStep(initialStep)
  } else {
    console.log("setAvailableSteps: 7. Not setting steps.", currentStep?.id)
  }
}

async function importToken(props: SharedProps): Promise<string> {
  console.log("IMPORTing new token for user from Hogwarts!")
  props.setLoading(true)

  // Add the token referencing the existing contract address.
  const tokenId = await props.dispatch(
    addToken({
      onChainData: {
        name: props.importTokenData.name,
        network: props.importTokenData.network,
        contractAddress: props.importTokenData.contractAddress,
        learnMoreUrl: props.importTokenData.learnMoreUrl,
      },
    })
  )
  console.log(
    "Done importing token from Hogwarts School of Witchcraft and Wizardry!",
    tokenId
  )

  props.setLoading(false)

  return tokenId // Return token.id Firestore DB field.
}

export async function createGate(
  gateType: GatingRequirementGateType,
  props: SharedProps
) {
  if (!props.note) return

  props.setLoading(true)
  props.setCreateGateError("")

  let tokenId = ""

  // Step 1. a. If there is a new token to mint, mint it.
  // Step 1. b. If there is an existing off-Paragraph token to import, then import it.
  if (props.mintOrImportToken === "IMPORT") {
    tokenId = await importToken(props)
  }

  // Step 1. c. Otherwise, if we're reusing an existing on-Paragraph token, then reuse it.
  else if (props.selectedExistingParagraphTokenId != "") {
    const existingToken = props.tokens?.find(
      (t) => t.id == props.selectedExistingParagraphTokenId
    )

    if (!existingToken) {
      console.error(
        "Error finding token with ID during gate wizard",
        props.selectedExistingParagraphTokenId
      )
      return // If something went wrong here, this is probably an internal bug.
    }

    tokenId = existingToken.id

    props.setOnChainCryptoData({
      name: existingToken.onChainData.name,
      totalQuantity: 100, // TODO: These should come from form.
      maticPrice: 0.0001,
    })
  }

  // Step 2. Create the gating rule group with a gating rule inside it.
  //         Use the above minted/imported/existing token if appropriate.

  const partialGroup: DeepPartial<GatingRuleGroup> = {
    blogId: props.blog.id,
    postId: props.note.id,
    userId: props.user.id,
    contentType: props.gateContentType,
    embedId: props.embedId,
  }

  const partialGatingRule: Partial<GatingRule> = {}

  if (gateType == "MEMBERSHIP") {
    partialGatingRule.gatingRequirement = {
      gateType: "MEMBERSHIP",
      membershipId: props.selectedMembershipId,
    }
  } else if (gateType == "TOKEN") {
    partialGatingRule.gatingRequirement = {
      gateType: "TOKEN",
      tokenId: tokenId,
      tokenMinCount: props.minQuantity,
    }
  }

  partialGroup.gatingRules = partialGatingRule ? [partialGatingRule] : undefined

  try {
    if (props.selectedGateGroupId && props.selectedGateGroupId != "") {
      console.log("Adding rule to group", {
        selectedGateGroupId: props.selectedGateGroupId,
        addingGate: props.addingGate,
      })
      const gatingRuleId = await props.dispatch(
        addGatingRuleToGroup(props.selectedGateGroupId, partialGatingRule)
      )

      if (!gatingRuleId) {
        console.error(
          "The gating rule could not be created and is showing as undefined!",
          gatingRuleId
        )
        props.setCreateGateError(
          "Error creating gating rule. Please try again or contact customer support on Discord."
        )

        props.setLoading(false)
        return
      }
    } else {
      console.log("Adding new group with rule in it", {
        selectedGateGroupId: props.selectedGateGroupId,
        addingGate: props.addingGate,
      })
      const groupId = await props.dispatch(
        addGatingRuleGroupToPost(partialGroup)
      )

      if (!groupId) {
        console.error(
          "The gating rule group could not be created and is showing as undefined!",
          groupId
        )
        props.setCreateGateError(
          "Error creating group of gating rules. Please try again or contact customer support on Discord."
        )

        props.setLoading(false)
        return
      }

      console.log("AFTER GATE new gating rule group ID: ", groupId)

      // If someone passed in a callback function, let's honor it, honorably, in the way of Sir Francis of Honorability.
      if (props.callbackSetNewGroupId) {
        console.log("AFTER GATE Setting new gating rule group id: ", groupId)
        props.callbackSetNewGroupId(groupId)
      }
    }

    props.setSelectedGateType("")

    props.setSelectedMembershipId("")
    props.setSelectedExistingParagraphTokenId("")
    props.setMintOrImportToken("")
    props.changeStep("GATE_TYPE")
    props.setAddingGate(false)
  } catch (err) {
    console.error("Exception occurred creating gating rules.", err)
    props.setCreateGateError(
      "Error creating gating rules. Please try again or contact customer support on Discord."
    )
    props.setAddingGate(false)
  }

  props.setLoading(false)
}

/**
 * Select all memberships within auto-created OR group conditions,
 * so that any membership the user holds grants access.
 * @param membershipIds The membership IDs to create OR groups for.
 * @param props Shared props passed to the gate wizard.
 * @returns The IDs of the created OR groups.
 */
export async function CreateOrGroupsOfMemberships(
  membershipIds: string[],
  props: SharedProps
) {
  try {
    if (!props.note) return

    const noteId = props.note.id

    const groupPromises: Promise<string | undefined>[] = []

    membershipIds.forEach((membershipId) => {
      const partialGroup: DeepPartial<GatingRuleGroup> = {
        blogId: props.blog.id,
        postId: noteId,
        userId: props.user.id,
        contentType: props.gateContentType,
        embedId: props.embedId,
        gatingRules: [
          {
            gatingRequirement: {
              gateType: "MEMBERSHIP",
              membershipId: membershipId,
            },
          },
        ],
      }

      groupPromises.push(props.dispatch(addGatingRuleGroupToPost(partialGroup)))
    })

    const groupIds = await Promise.all(groupPromises)

    if (groupIds.some((id) => id === undefined)) {
      console.error(
        "Some of the gating rule groups could not be created and are showing as undefined!",
        groupIds
      )
      props.setCreateGateError(
        "Error creating group of gating rules. Please try again or contact customer support on Discord."
      )

      props.setLoading(false)
      return
    }

    console.log("AFTER GATE new gating rule group ID: ", groupIds)

    // If someone passed in a callback function, let's honor it, honorably, in the way of Sir Francis of Honorability.
    if (props.callbackSetNewGroupId) {
      console.log("AFTER GATE Setting new gating rule group id: ", groupIds)

      for (const groupId of groupIds) {
        // Shouldn't be necessary due to the previous error check but TypeScript is being silly so here we are.
        if (!groupId) return

        props.callbackSetNewGroupId(groupId)
      }
    }

    props.setSelectedGateType("")

    props.setSelectedMembershipId("")
    props.setSelectedExistingParagraphTokenId("")
    props.setMintOrImportToken("")
    props.changeStep("GATE_TYPE")
    props.setAddingGate(false)
  } catch (err) {
    console.error("Exception occurred creating gating rules.", err)
    props.setCreateGateError(
      "Error creating gating rules. Please try again or contact customer support on Discord."
    )
    props.setAddingGate(false)
  }

  props.setLoading(false)
}

/**
 * Figures out if a particular membership row should be disabled or not when trying to select it
 * from the gating wizard.
 * @param membership The membership to check.
 * @param memberships All memberships available to the user.
 * @param selectedMembershipIds The IDs of the memberships that have already been selected.
 * @param existingGateGroups The existing gate groups on the post.
 * @param selectedGateGroupId The ID of the gate group that is currently selected.
 * @returns A boolean - true if the membership should be disabled, false if not.
 */
export function DisableMembershipGateSelection(
  membership: Membership,
  memberships: Membership[],
  selectedMembershipIds: string[],
  existingGateGroups: EnrichedGatingRuleGroup[],
  selectedGateGroupId: string | undefined
) {
  // TODO: We can somewhat consolidate this function with the ShouldShowAddGateButton below. There are some differences
  // in the logic, but we can probably combine them in some way.

  const membershipWasAlreadySelected =
    selectedMembershipIds.find((mId) => mId == membership.id) !== undefined

  const membershipExistsInThisGroup = DoesGateExistInGroup(
    existingGateGroups,
    "MEMBERSHIP",
    selectedGateGroupId
  )

  const membershipIsFree = isFreeMembership(membership)

  const tokenGatesExistInAnyGroups = existingGateGroups?.some((group) =>
    DoesGateExistInGroup(existingGateGroups, "TOKEN", group.id)
  )

  const membershipIsPaidAndTokenGatesAlreadyAdded =
    !isFreeMembership(membership) && tokenGatesExistInAnyGroups

  const paidMembershipIds =
    memberships?.filter((m) => !isFreeMembership(m)).map((m) => m.id) || []
  const paidMembershipGateInAnyGroups = existingGateGroups?.some((group) =>
    DoesGateExistInGroup(
      existingGateGroups,
      "MEMBERSHIP",
      group.id,
      paidMembershipIds
    )
  )

  // Disable this membership from being able to be selected if either:
  // 1. It's a paid membership and was selected in any group.
  // 2. It's a free membership, and was already selected in THIS group.
  // 3. It's a free membership, was already selected, and there's already a paid membership in any group.
  // 4. It's a paid membership, and token gates already exist in any group.
  const isDisabled =
    (!membershipIsFree &&
      membershipWasAlreadySelected &&
      !membershipExistsInThisGroup) ||
    (membershipIsFree && membershipExistsInThisGroup) ||
    (membershipIsFree &&
      membershipWasAlreadySelected &&
      paidMembershipGateInAnyGroups) ||
    membershipIsPaidAndTokenGatesAlreadyAdded

  return isDisabled
}

/**
 * Figures out if the add gate button should be shown.
 * @param existingGateGroups Existing gate groups.
 * @param gateTypeDesc The gate type description.
 * @param memberships List of all memberships.
 * @param selectedGateGroupId The selected gate group ID.
 * @returns Returns [showAddMoreBtn, gateDoesntExistYet] booleans.
 */
export function ShouldShowAddGateButton(
  existingGateGroups: EnrichedGatingRuleGroup[],
  gateType: GatingRequirementGateType,
  memberships: Membership[] | undefined,
  selectedGateGroupId: string | undefined
) {
  const gateInGroup = DoesGateExistInGroup(
    existingGateGroups,
    gateType,
    selectedGateGroupId
  )

  const paidMembershipIds =
    memberships?.filter((m) => !isFreeMembership(m)).map((m) => m.id) || []

  // Check for paid memberships specifically.
  const paidMembershipGateInAnyGroups = existingGateGroups?.some((group) =>
    DoesGateExistInGroup(
      existingGateGroups,
      "MEMBERSHIP",
      group.id,
      paidMembershipIds
    )
  )

  const tokenGateInAnyGroups = existingGateGroups?.some((group) =>
    DoesGateExistInGroup(existingGateGroups, "TOKEN", group.id)
  )

  // If there's already either a PAID membership or a token, don't show the add more button for the other type of gate,
  // as token gates and paid membership gates are mutually exclusive.
  const mutuallyExclusiveGatesExist =
    paidMembershipGateInAnyGroups && gateType === "TOKEN"

  const gateDoesntExistYet = !existingGateGroups || !gateInGroup

  // Only show the add more button if there's no membership gate already in the group.
  const membershipGateAlreadyInGroup = gateInGroup && gateType == "MEMBERSHIP"

  const showAddMoreBtn =
    // If there's already either a PAID membership or a token, don't show the add more button for the other type of gate,
    // as token gates and paid membership gates are mutually exclusive.
    !mutuallyExclusiveGatesExist && !membershipGateAlreadyInGroup

  // Debug log.
  console.log("ShouldShowAddGateButton: " + gateType + " - " + showAddMoreBtn, {
    gateType,
    gateInGroup,
    paidMembershipGateInAnyGroups,
    tokenGateInAnyGroups,
    mutuallyExclusiveGatesExist,
    showAddMoreBtn,
    paidMembershipIds,
    existingGateGroups,
    selectedGateGroupId,
  })

  return [showAddMoreBtn, gateDoesntExistYet]
}

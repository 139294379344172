import { useEffect, useState } from "react"
import MembershipCard from "../MembershipCard"
import {
  Blog,
  BlogSubscriptionAndMembership,
  Membership,
  Plan,
  PlanPeriod,
  PlanType,
} from "@types"
import { getSubscriptions, subscribeToMembership } from "api_routes/memberships"
import {
  getUsersSubscriptionToBlog,
  refreshUser,
  selectActiveSubscription,
  selectUser,
} from "features/userSlice"
import { useSelector } from "react-redux"
import { RootState, Thunk, useAppDispatch } from "store"
import {
  isFreeMembership,
  pollForSubscriptionCreationStatus,
} from "../SharedFunctions"
import clsx from "clsx"
import { getStripePaymentMethods } from "api_routes/stripe"
import { StripePaymentMethodObj } from "@/types/stripe"
import Button from "components/Button"
import { ArrowLeftIcon } from "@heroicons/react/24/solid"
import { sortMembershipsByPriceAndMembershipId } from "./utils"
import MembershipRegister from "./MembershipRegister"
import StripePayment from "./StripePayment"
import { MembershipFrequencySelection } from "./SharedTypes"
import { selectMembershipsDiscounts } from "features/membershipSlice"
import { LoadingContainer } from "components/Discover/TextContainer"
import { selectCurrentNote } from "features/noteSlice"

/**
 * View all membership cards for a blog or user.
 * @getMemberships    Pass in the thunk to get memberships for a blog or user. If this is undefined,
 *                    that means the memberships should already be populated in Redux (ie in selectMemberships).
 * @selectMemberships Pass in the membership selector here. It could be the blog selector,
 *                    the user selector, or the upsell selector depending on your context.
 * @isAuthorView      Whether this is an author view or not. If it is, additional options like
 *                    delete membership will be shown.
 * @isOnDedicatedMembershipsPage Whether this is on a dedicated memberships page or not. If it is,
 *                               additional options specific to this appearing within a modal on a page might not be shown.
 * @buyCallback       Callback to run when a membership is purchased. Note that this component
 *                    handles the purchase itself already, so this is only for additional
 *                    actions you'd want to run after the purchase is taken care of (such as
 *                    closing a modal).
 * @returns           JSX element showing the membership cards.
 */
export default function ViewMemberships({
  selectAppropriateBlog,
  getMemberships,
  selectMemberships,
  isAuthorView,
  isOnDedicatedMembershipsPage,
  isOnUpsellPage,
  buyCallback,
  disabledDueToInsufficientPermissions = [],
  hideCancelButton,
  coupon,
}: {
  /** Select either the logged in user's blog (selectUsersBlog) or the blog the user is viewing as a reader (selectBlog). */
  selectAppropriateBlog: (state: RootState) => Blog
  getMemberships?: (
    blogId: string,
    coupon: string | null
  ) => Thunk<Promise<Membership[]>>
  selectMemberships: (state: RootState) => Membership[] | undefined
  isAuthorView: boolean
  isOnDedicatedMembershipsPage: boolean
  isOnUpsellPage: boolean
  buyCallback?: (membership: Membership) => void
  /** When gating a post or content in a post, if the card is below the level required to gain access,
   *  disable it. Defaults to false. */
  disabledDueToInsufficientPermissions?: string[]

  /**
   * Whether we want to hide the 'cancel' / 'unsubscribe' buttons.
   * For example, if a user just subscribed, we don't want to show the cancel button.
   */
  hideCancelButton?: boolean
  coupon?: string
}) {
  const dispatch = useAppDispatch()
  const blog = useSelector(selectAppropriateBlog)
  const user = useSelector(selectUser)
  const memberships = useSelector(selectMemberships)
  const discounts = useSelector(selectMembershipsDiscounts)
  /** This tracks the membership ID of the current action or "" if none */
  const [currentActionItem, setCurrentActionItem] = useState("")

  console.log("These are the memberships and discounts", memberships)

  const [isLoading, setIsLoading] = useState(false)

  const sortedMemberships = sortMembershipsByPriceAndMembershipId(
    memberships || []
  )

  const [isCheckingOutWithMembership, setIsCheckingOutWithMembership] =
    useState<{ membership: Membership; planType: PlanType } | undefined>()
  /**
   * Is used to track all the memberships and their selected plan periods/frequencies so that this data is persisted
   * when the user navigates between the membership card and the checkout/registration pages (e.g. they click buy on a
   * membership, and then click "Back", it should still show their selection).
   */
  const [selectedPlans, setSelectedPlans] =
    useState<MembershipFrequencySelection>(
      // Populate the selectedPlans with the yearly plan for each membership, if available. Otherwise, just choose the first plan.
      // Free memberships won't have any plans, so those would be undefined.
      memberships?.reduce(
        (acc, membership) => ({
          ...acc,
          [membership.id]:
            membership?.plans?.find((plan) => plan.period === "year") ||
            membership?.plans?.[0],
        }),
        {}
      ) || {}
    )

  const frequencyChangedCallback = (membershipId: string, plan?: Plan) => {
    setSelectedPlans((prev) => ({
      ...prev,
      [membershipId]: plan,
    }))
  }
  // /**
  //  * This is the selectedPlan that gets set via the buyCallback when the user clicks "Buy" on a membership card.
  //  */
  // const [selectedPlan, setSelectedPlan] = useState<Plan | undefined>()
  const [stripePaymentMethods, setStripePaymentMethods] = useState<
    StripePaymentMethodObj[]
  >([])

  const [subscriptions, setSubscriptions] = useState<
    BlogSubscriptionAndMembership[]
  >([])
  const subscription = useSelector(selectActiveSubscription)

  useEffect(() => {
    if (!getMemberships) return

    console.log("Loading memberships..")

    const loadMemberships = async () => {
      dispatch(getMemberships(blog.id, null))
    }

    loadMemberships()
  }, [dispatch])

  useEffect(() => {
    // If the user isn't logged in or isAuthorView, don't try to retrieve subscriptions and
    // payment methods which require authentication, otherwise we'd auto-redirect.
    if (!user.id || isAuthorView) return

    const retrieveSubscriptions = async () => {
      setSubscriptions(await getSubscriptions())
    }

    const retrieveStripePaymentMethods = async (
      blogId?: string,
      stripeAccount?: string
    ) => {
      setStripePaymentMethods(
        await getStripePaymentMethods(blogId, stripeAccount)
      )
    }

    retrieveSubscriptions()
    if (blog.id && blog.stripe_account_id)
      retrieveStripePaymentMethods(blog.id, blog.stripe_account_id)
  }, [blog, user, isLoading, isAuthorView])

  const handleBuyOrContinueFreeMembership = async (
    membership: Membership,
    planType: PlanType,
    noteId: string | null,
    setIsCheckingOutWithMembership: (
      bool:
        | {
            membership: Membership
            planType: PlanType
          }
        | undefined
    ) => void
  ) => {
    // If it's a free membership and the user is already logged in, just subscribe them to the membership.
    if (isFreeMembership(membership) && user.id) {
      console.log("Free membership detected!")

      // For free memberships, just add the membership ID to the subscription.
      await subscribeToMembership(
        blog.id,
        noteId,
        "",
        membership.id,
        "free",
        "",
        ""
      )

      // Then retrieve the updated subscription status.
      await pollForSubscriptionCreationStatus(blog.id, membership.id, dispatch)

      await dispatch(getUsersSubscriptionToBlog(blog.id))
      await dispatch(refreshUser())
    } else if (isFreeMembership(membership) && !user.id) {
      // For free memberships with unregistered user, show the registration page.
      console.log("Free membership detected for an unregistered user!")

      setIsCheckingOutWithMembership({
        membership,
        planType: "free" as PlanType,
      })
    } else {
      // For paid memberships, show the payment page.
      console.log("Paid membership detected!")

      // For paid memberships, show the payment modal.
      setIsCheckingOutWithMembership({ membership, planType })
    }
  }

  // When the user changes selected frequency, update the selected plan.
  useEffect(() => {
    if (isCheckingOutWithMembership) {
      const yearlyPlan = isCheckingOutWithMembership.membership.plans.find(
        (plan) => plan.period === "year"
      )

      // Default to previously selected plan (if any), otherwise the yearly plan if available, otherwise just whatever the first plan is.
      const newSelectedPlan =
        selectedPlans[isCheckingOutWithMembership.membership.id] ||
        yearlyPlan ||
        isCheckingOutWithMembership.membership.plans[0]

      setSelectedPlans((prev) => ({
        ...prev,
        [isCheckingOutWithMembership.membership.id]: newSelectedPlan,
      }))

      setShowRegistrationPage(
        user.id === "" &&
          isCheckingOutWithMembership !== undefined &&
          newSelectedPlan !== undefined &&
          sortedMemberships !== undefined
      )

      // If checking out with Stripe after registering inline, show Stripe checkout
      setShowCheckoutPage(
        user.id !== "" &&
          isCheckingOutWithMembership !== undefined &&
          newSelectedPlan !== undefined &&
          sortedMemberships !== undefined &&
          isCheckingOutWithMembership.planType === "stripe-subscription"
      )

      // If checking out with Loop after registering inline, show Loop checkout
      setShowLoopCheckout(
        user.id !== "" &&
          isCheckingOutWithMembership !== undefined &&
          newSelectedPlan !== undefined &&
          sortedMemberships !== undefined &&
          isCheckingOutWithMembership.planType === "loop-subscription"
      )

      setFreeSubComplete(
        user.id !== "" &&
          isCheckingOutWithMembership !== undefined &&
          newSelectedPlan !== undefined &&
          sortedMemberships !== undefined &&
          isCheckingOutWithMembership.planType === "free"
      )
    } else {
      setShowRegistrationPage(false)
      setShowCheckoutPage(false)
      setShowLoopCheckout(false)
      setFreeSubComplete(false)
    }
  }, [user.id, isCheckingOutWithMembership])

  const [showRegistrationPage, setShowRegistrationPage] = useState(false)
  const [showCheckoutPage, setShowCheckoutPage] = useState(false)
  const [showLoopCheckout, setShowLoopCheckout] = useState(false)
  const [freeSubComplete, setFreeSubComplete] = useState(false)

  const noteId = useSelector(selectCurrentNote)?.id || null

  const hasPaidMembership =
    memberships === undefined
      ? false
      : memberships.find(
          (m) => m.id === subscription?.membershipId && !isFreeMembership(m)
        ) !== undefined

  const isPaidMembership =
    isCheckingOutWithMembership === undefined
      ? false
      : !isFreeMembership(isCheckingOutWithMembership.membership)

  let discountedPrice, trialDays
  if (isCheckingOutWithMembership) {
    const planDiscount = discounts?.find(
      (discount) =>
        discount.planId ===
        selectedPlans[isCheckingOutWithMembership.membership.id]!.stripePriceId
    )

    if (planDiscount) {
      discountedPrice = planDiscount.discountedPrice
      trialDays = planDiscount.trialDays
    }
  }

  if (!sortedMemberships || isLoading) {
    return <LoadingContainer />
  }

  return (
    <div className="rounded-lg overflow-hidden ">
      <div
        className={clsx(
          "flex p-2 gap-x-4",
          showRegistrationPage || showCheckoutPage
            ? "flex-col justify-start"
            : "flex-wrap justify-center"
        )}
      >
        {/* Membership card selection. */}
        {(!isCheckingOutWithMembership ||
          showLoopCheckout ||
          freeSubComplete) &&
          sortedMemberships &&
          sortedMemberships.map((membership) => (
            <MembershipCard
              key={membership.id}
              blog={blog}
              user={user}
              membership={membership}
              memberships={sortedMemberships || []}
              isAuthorView={isAuthorView}
              isFormPreview={false}
              isOnDedicatedMembershipsPage={isOnDedicatedMembershipsPage}
              isOnUpsellPage={isOnUpsellPage}
              hideCancelButton={hideCancelButton}
              currentActionItem={currentActionItem}
              setCurrentActionItem={setCurrentActionItem}
              frequencyChangedCallback={frequencyChangedCallback}
              showLoopCheckout={showLoopCheckout}
              buyCallback={async (
                membership: Membership,
                selectedPlanFrequency: PlanPeriod,
                planType: PlanType
              ) => {
                // Handle buying a paid membership or continuing with a free membership.
                await handleBuyOrContinueFreeMembership(
                  membership,
                  planType,
                  noteId,
                  setIsCheckingOutWithMembership
                )

                // const updatedSelectedPlans = { ...selectedPlans }
                // updatedSelectedPlans[membership.id] = membership.plans.find(
                //   (plan) => plan.period === selectedPlanFrequency
                // )
                // setSelectedPlans(updatedSelectedPlans)
                setSelectedPlans((prev) => ({
                  ...prev,
                  [membership.id]: membership.plans.find(
                    (plan) => plan.period === selectedPlanFrequency
                  ),
                }))

                if (isFreeMembership(membership)) {
                  // If it's a free membership, just call the callback.
                  // If it's a paid membership, the callback will be called upon success of payment.
                  buyCallback?.(membership)
                }
              }}
              // If it's in the list of memberships that are disabled due to insufficient permissions,
              // disable it.
              disabledDueToInsufficientPermissions={
                disabledDueToInsufficientPermissions
              }
              selectedPlan={selectedPlans[membership.id]}
              coupon={coupon}
            />
          ))}
        {/* Appears on registration AND checkout page. */}
        {isCheckingOutWithMembership &&
          (showRegistrationPage || showCheckoutPage) && (
            <Button
              replaceClassName={clsx(
                "w-22 ring-1 ring-inset ring-gray-300 hover:ring-gray-500 text-gray-500 hover:text-gray-700 h-10 mb-4",
                "mx-2 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              )}
              onClick={() => {
                // Go back.
                setIsCheckingOutWithMembership(undefined)
                setShowRegistrationPage(false)
                setShowCheckoutPage(false)
              }}
            >
              <div className="flex justify-center items-center">
                <ArrowLeftIcon className="w-4 h-4 mr-2" />
                Back
              </div>
            </Button>
          )}
        {/* Appears on registration AND checkout page. */}
        {isCheckingOutWithMembership &&
          (showRegistrationPage || showCheckoutPage) && (
            <div className="flex flex-col md:flex md:flex-row justify-center items-center space-y-10 md:space-y-0 md:space-x-22">
              {/* Appears on registration AND checkout page. */}
              <div>
                {!showRegistrationPage && (
                  <h2 className="flex justify-center text-xl font-medium text-gray-900 mb-8">
                    Order summary
                  </h2>
                )}
                <MembershipCard
                  key={
                    isCheckingOutWithMembership.membership.id +
                    (selectedPlans[isCheckingOutWithMembership.membership.id]
                      ?.period || "free")
                  }
                  blog={blog}
                  user={user}
                  membership={isCheckingOutWithMembership.membership}
                  memberships={sortedMemberships || []}
                  isAuthorView={false}
                  isFormPreview={false}
                  isOnDedicatedMembershipsPage={isOnDedicatedMembershipsPage}
                  hideActionButton={
                    isCheckingOutWithMembership !== undefined &&
                    selectedPlans[isCheckingOutWithMembership.membership.id] !==
                      undefined &&
                    sortedMemberships !== undefined
                  }
                  selectedPlan={
                    selectedPlans[isCheckingOutWithMembership.membership.id]
                  }
                  currentActionItem={currentActionItem}
                  setCurrentActionItem={setCurrentActionItem}
                  isOnUpsellPage={isOnUpsellPage}
                  showLoopCheckout={showLoopCheckout}
                  frequencyChangedCallback={frequencyChangedCallback}
                />
              </div>

              {/* Appears on registration page only (embedded partial subscribe modal). */}
              {showRegistrationPage && (
                <div className="flex flex-col justify-between items-center w-96 h-full">
                  <div className="my-auto">
                    <MembershipRegister
                      membership={isCheckingOutWithMembership.membership}
                    />
                  </div>
                </div>
              )}

              {/* Appears on checkout page only. 
                Show payment methods for users first purchasing a membership. */}
              {showCheckoutPage &&
                selectedPlans[isCheckingOutWithMembership.membership.id] !==
                  undefined &&
                (!hasPaidMembership || !isPaidMembership) && (
                  <div className="flex flex-col justify-center items-center w-96 h-full">
                    <h2 className="text-xl font-medium text-gray-900 mb-2 ">
                      {isPaidMembership ? "Payment" : "Subscription"}
                    </h2>
                    {/* Normal or discounted checkout */}
                    {isPaidMembership && !trialDays && (
                      <p className="text-gray-500 ">
                        You'll be charged $
                        {discountedPrice !== undefined
                          ? discountedPrice
                          : selectedPlans[
                              isCheckingOutWithMembership.membership.id
                            ]!.price}{" "}
                        every{" "}
                        {
                          selectedPlans[
                            isCheckingOutWithMembership.membership.id
                          ]!.period
                        }
                        . You can cancel any time.
                      </p>
                    )}
                    {/* Trial checkout */}
                    {isPaidMembership && trialDays && (
                      <p className="text-gray-500 ">
                        You will have a {trialDays} day free trial and then
                        you'll be charged $
                        {
                          selectedPlans[
                            isCheckingOutWithMembership.membership.id
                          ]!.price
                        }{" "}
                        every {/* Already checked this is defined above. */}
                        {
                          selectedPlans[
                            isCheckingOutWithMembership.membership.id
                          ]!.period
                        }
                        . You can cancel any time.
                      </p>
                    )}
                    <div className="my-auto mt-3">
                      <StripePayment
                        membership={isCheckingOutWithMembership.membership}
                        selectedPlan={
                          // Already checked this is defined above.
                          selectedPlans[
                            isCheckingOutWithMembership.membership.id
                          ]!
                        }
                        coupon={
                          discounts?.find(
                            (discount) =>
                              discount.planId ===
                              selectedPlans[
                                isCheckingOutWithMembership.membership.id
                              ]!.stripePriceId
                          )?.discountId || null
                        }
                        subscriptions={subscriptions}
                        blog={blog}
                        user={user}
                        stripePaymentMethods={stripePaymentMethods}
                        successCallback={() => {
                          // After a successful checkout, go back to the previous screen.
                          setIsCheckingOutWithMembership(undefined)
                          buyCallback?.(isCheckingOutWithMembership.membership)
                        }}
                      />
                    </div>
                  </div>
                )}

              {/* Appears on checkout page only. 
                For readers switching from one paid membership to another, 
                we don't want to show existing payment methods as changing
                payment methods is not yet supported in the back-end.
                This shouldn't even appear to anyone as we shouldn't be able
                to get this this page with an existing paid membership.
                This is here just in case and as a TODO reminder for when
                we add support for changing payment methods on the back-end. */}
              {showCheckoutPage && hasPaidMembership && isPaidMembership && (
                <div className="flex flex-col justify-center items-center w-96 h-full">
                  <h2 className="text-xl font-medium text-gray-900 mb-8 h-12">
                    Changing payment methods not yet supported.
                  </h2>
                </div>
              )}
            </div>
          )}
        {/* Can uncomment when I've tested that this looks good in all interfaces that embed this component. */}
        {/* {isLoading && (
          <div className="text-lg text-gray-900 text-center my-44 flex justify-center space-x-8">
            <Placeholder width="200" height="300" />
            <Placeholder width="200" height="300" />
            <Placeholder width="200" height="300" />
          </div>
        )} */}
      </div>
    </div>
  )
}

import { LinkIcon } from "@heroicons/react/24/solid"
import { useAnalytics } from "hooks/useAnalytics"
import FarcasterIcon from "./icons/Farcaster"
import { makeToast } from "features/pageSlice"
import { useAppDispatch } from "store"

interface NavProps {
  social: Array<{
    active?: string | undefined
    name: string
    href?: string
    onClick?: () => void
    icon: (props: any) => JSX.Element | null
  }>
}

const buttons = (url: string, title: string, copyClickHandler: () => void) => {
  const navigation: NavProps = {
    social: [
      {
        name: "Farcaster",
        href: `https://warpcast.com/~/compose?embeds[]=${url}&text=${title}`,
        icon: (props: any) => (
          <FarcasterIcon
            {...props}
            className="w-6 h-6 fill-current text-gray-500 grayscale opacity-60 hover:opacity-100 hover:grayscale-0"
          />
        ),
      },
      {
        name: "Facebook",
        href: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
        icon: (props: any) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: "Twitter",
        href: `http://twitter.com/share?url=${url}`,
        icon: (props: any) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
          </svg>
        ),
      },

      {
        name: "Copy",
        onClick: () => {
          copyClickHandler()
        },
        icon: () => <LinkIcon className="w-6 h-6" />,
      },
    ],
  }

  return navigation
}

export default function ShareButtons({
  url,
  socialMediaShareText = "Check out this new post on Paragraph!",
}: {
  url: string
  socialMediaShareText?: string
}) {
  const dispatch = useAppDispatch()
  const { track } = useAnalytics()

  const copyClickHandler = () => {
    navigator.clipboard.writeText(url)

    dispatch(makeToast("success", "Link copied."))
  }

  const icons = buttons(url, socialMediaShareText, copyClickHandler)

  return (
    <div className="flex justify-center mt-3 space-x-6 items-center transition-all duration-100">
      {icons.social.map((item, i) => (
        <div key={i} className="block">
          <div key={item.name}>
            <a
              href={item.href}
              onClick={() => {
                track("share button clicked", {
                  social: item.name,
                  title: socialMediaShareText,
                })

                const onClick = item.onClick
                if (onClick) onClick()
              }}
              target="_blank"
              className="text-gray-400 hover:text-gray-500 cursor-pointer transition-all duration-100"
              rel="noreferrer"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon className="w-6 h-6" aria-hidden="true" />
            </a>
          </div>
        </div>
      ))}
    </div>
  )
}

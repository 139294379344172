import { uploadImage } from "api_routes/notes"
import { base64ImageRegex, validImageDomains } from "./Image"

export async function dataUrlToFile(dataUrl: string) {
  try {
    const res: Response = await fetch(dataUrl)
    const blob: Blob = await res.blob()
    return new File([blob], "dataUrlFile", { type: blob.type })
  } catch (e) {
    console.error("Failed to get file from dataUrl: ", dataUrl, e)
    throw e
  }
}

export const upload = async (file: File) => {
  try {
    const imgWithPlaceholder = await uploadImage(file)

    if (!imgWithPlaceholder) throw "Failed to upload image"

    console.log(
      "Uploaded image successfully, here are attrs",
      imgWithPlaceholder
    )

    return {
      src: imgWithPlaceholder.img.src,
      blurdataurl: imgWithPlaceholder?.base64,
      nextwidth: imgWithPlaceholder.img.width,
      nextheight: imgWithPlaceholder.img.height,
    }
  } catch (e) {
    console.error("Failed to upload image file: ", e)
    throw e
  }
}

export const isBase64Image = (src: string) => base64ImageRegex.test(src || "")
export const isSvgImage = (src: string) => src.includes("data:image/svg+xml")
export const isExternalImage = (src: string) =>
  !validImageDomains.some((domain: string) => src?.includes(domain))

export const isPlaceholderImage = (src: string) =>
  base64ImageRegex.test(src) || isExternalImage(src)

export const convertFileToBase64 = async (file: File) => {
  return new Promise((resolve, reject) => {
    const fr = new FileReader()
    fr.onerror = reject
    fr.onload = () => resolve(fr.result as string)
    fr.readAsDataURL(file)
  })
}

let throttlePause: boolean

export const throttle = (callback: () => void, time: number) => {
  if (throttlePause) return
  throttlePause = true

  setTimeout(() => {
    callback()
    throttlePause = false
  }, time)
}

import { UserAuthorDetails } from "@/types/users"
import { UserIcon } from "@heroicons/react/24/solid"
import clsx from "clsx"
import useAuthorName from "hooks/useAuthorName"
import { getInitials } from "util/format"

// Shows a placeholder instead of the user's avatar.
export default function AvatarPlaceholder(props: {
  author: UserAuthorDetails
  color?: "light" | "dark"
  isPrivate?: boolean
  border?: boolean
}) {
  const authorName = useAuthorName({
    user: props.author,
    isPrivate: props.isPrivate,
  })

  return (
    <span
      title={authorName.name}
      className={clsx(
        props.color === "light"
          ? "bg-gray-200 border-white"
          : "bg-gray-500 border-secondary",
        "inline-flex items-center justify-center rounded-full w-full h-full",
        props.border ? "border" : "border-none"
      )}
    >
      <span className="text-lg font-medium leading-none text-white">
        {authorName.name == "Anon" || authorName.isTruncated ? (
          // For "Anon" users or wallet addresses, show just a user icon.
          <UserIcon
            className={clsx(
              props.color === "light" ? "text-gray-400" : "text-gray-300",

              "p-1 w-full h-full rounded-full"
            )}
          />
        ) : (
          // Otherwise, get the initials from their name or ENS.
          getInitials(authorName.name.toUpperCase())
        )}
      </span>
    </span>
  )
}

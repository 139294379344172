import clsx from "clsx"
import Button from "components/Button"
import { MutableRefObject } from "react"
import { FindStep } from "../GateModalHelperFunctions"
import { GateModalStep } from "../GateModalSteps"

type Props = {
  currentStep: GateModalStep
  steps: GateModalStep[]
  cancelButtonRef: MutableRefObject<null>
  changeStep: (id: string, proposedSteps?: GateModalStep[]) => void
}

export default function BackBtn(props: Props) {
  return (
    <Button
      type="button"
      replaceClassName={clsx(
        "bg-white inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:text-sm",
        props.currentStep.id == "GATE_TYPE" && "w-48" // Fixed width only on GATE_TYPE step where this back button is the only button. Otherwise the width is adjusted dynamically with other buttons next to it.
      )}
      ref={props.cancelButtonRef}
      onClick={() => {
        props.changeStep(FindStep("PREVIOUS", props.currentStep, props.steps))
      }}
      text="Back"
    />
  )
}

import Button from "components/Button"
import WarningBanner from "components/Banner"
import Link from "next/link"
import { useAnalytics } from "hooks/useAnalytics"
import { useEffect, useState, FormEvent } from "react"
import { loginUser } from "../../features/userSlice"
import { selectError, setError } from "../../features/errorSlice"
import { useSelector } from "react-redux"
import { useAppDispatch } from "store"

export default function LoginEmailAndPassword({
  prefillEmailAddress,
  overrideSignInButtonText,
  additionalLoginCallback,
}: {
  prefillEmailAddress?: string
  overrideSignInButtonText?: string
  additionalLoginCallback?: (isLoginSuccess: boolean) => void
}) {
  const { track } = useAnalytics()
  const dispatch = useAppDispatch()

  const error = useSelector(selectError)
  const [email, setEmail] = useState(prefillEmailAddress || "")
  const [password, setPassword] = useState("")
  const [buttonLoading, setButtonLoading] = useState(false)

  useEffect(() => {
    if (error.msg) {
      setButtonLoading(false)

      track("login error", {
        msg: error.msg,
      })
    }
  }, [error.msg])

  useEffect(() => {
    return () => {
      setButtonLoading(false)
      dispatch(setError({ msg: "" }))
    }
  }, [])

  const formSubmitted = async (ev: FormEvent) => {
    setButtonLoading(true)
    ev.preventDefault()
    console.log("Login user..." + email)
    const isSuccess = await dispatch(loginUser({ email, password }))

    // Now that we've logged in, we can call the additional callback.
    additionalLoginCallback?.(isSuccess)
  }

  const isExistingUserButLoggedOutError =
    error.code === "SUBSCRIBING_USER_LOGGED_OUT_BUT_EMAIL_ALREADY_EXISTS"

  return (
    <form className="space-y-6" onSubmit={formSubmitted}>
      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700"
        >
          Email address
        </label>
        <div className="mt-1">
          <input
            id="email"
            name="email"
            type="email"
            value={email}
            onChange={(e) => {
              track("login email input changed", {
                email: e.target.value,
              })
              setEmail(e.target.value)
            }}
            autoComplete="email"
            required
            className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 appearance-none rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          />
        </div>
      </div>

      <div>
        <label
          htmlFor="password"
          className="block text-sm font-medium text-gray-700"
        >
          Password
        </label>
        <div className="mt-1">
          <input
            id="password"
            name="password"
            type="password"
            onChange={(e) => {
              track("login password input changed")
              setPassword(e.target.value)
            }}
            autoComplete="current-password"
            required
            className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 appearance-none rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          />
        </div>
      </div>

      <div className="flex items-center justify-end">
        <div className="text-sm">
          <Link
            href="/forgot-password"
            className="font-medium text-blue-600 hover:text-blue-500"
          >
            Forgot your password?
          </Link>
        </div>
      </div>
      {error.msg && (
        <WarningBanner
          type={!isExistingUserButLoggedOutError ? "error" : "info"}
          text={error.msg}
        />
      )}

      <div>
        <Button
          text={overrideSignInButtonText || "Sign in"}
          loading={buttonLoading}
          className="flex w-full ml-0"
          nonCustomizedColor={true}
        />
      </div>
    </form>
  )
}

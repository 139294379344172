import { ExclamationCircleIcon } from "@heroicons/react/24/solid"
import { OnChainCryptoData } from "@types"

import { Dispatch, SetStateAction, useState } from "react"
import clsx from "clsx"
import {
  isValidMinimumInteger,
  isValidMinimumWei,
  isValidString,
} from "./ValidationHelpers"

interface props {
  onChainCryptoData: OnChainCryptoData // Used when user is minting a new Paragraph token.
  setOnChainCryptoData: (val: OnChainCryptoData) => void // Set when user is minting a new Paragraph token.
  minQuantity: number
  setMinQuantity: Dispatch<SetStateAction<number>>
}

export default function MintNewToken({
  onChainCryptoData,
  setOnChainCryptoData,
}: props) {
  const [isNameAltered, setIsNameAltered] = useState(false)
  const [isPriceAltered, setIsPriceAltered] = useState(false)
  const [isTotalQuantityAltered, setIsTotalQuantityAltered] = useState(false)

  return (
    <div>
      <div className="mt-5 text-gray-500 text-sm">Token name</div>

      <div className="relative">
        <input
          type="text"
          name="token"
          id="token"
          defaultValue={onChainCryptoData.name}
          autoComplete="token"
          placeholder="Paragraph Token"
          className={clsx(
            "mt-2 block w-full rounded-md sm:text-sm",
            isNameAltered && !isValidString(onChainCryptoData.name)
              ? "border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500"
              : "border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
          )}
          onChange={async (e) => {
            setOnChainCryptoData({ ...onChainCryptoData, name: e.target.value })
            setIsNameAltered(true)
          }}
        />

        {isNameAltered && !isValidString(onChainCryptoData.name) && (
          <div className="pointer-events-none absolute inset-y-0 bottom-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>

      <div className="relative">
        <div className="mt-5 text-gray-500 text-sm">
          What's the price of the token (in MATIC)?
        </div>

        <input
          type="text"
          name="price"
          id="price"
          autoComplete="price"
          placeholder="1"
          className={clsx(
            "mt-2 block w-full sm:text-sm rounded-md",
            isPriceAltered && !isValidMinimumWei(onChainCryptoData.maticPrice)
              ? "border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500"
              : "border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
          )}
          onChange={async (e) => {
            setOnChainCryptoData({
              ...onChainCryptoData,
              maticPrice: parseFloat(e.target.value),
            })
            setIsPriceAltered(true)
          }}
        />

        {isPriceAltered && !isValidMinimumWei(onChainCryptoData.maticPrice) && (
          <div className="pointer-events-none absolute inset-y-0 bottom-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}

        {isPriceAltered && !isValidMinimumWei(onChainCryptoData.maticPrice) && (
          <p className="mt-2 text-sm text-red-600" id="min-quantity-error">
            Please enter at least 1 Wei.
          </p>
        )}
      </div>

      <div className="relative">
        <div className="mt-5 text-gray-500 text-sm">
          What's the total quantity of tokens in this collection? This is useful
          to limit the number of users that hold your memberships. Leave it
          blank for unlimited.
        </div>

        <input
          type="text"
          name="total-quantity"
          id="total-quantity"
          defaultValue={onChainCryptoData.totalQuantity}
          placeholder={onChainCryptoData.totalQuantity?.toString()}
          className={clsx(
            "mt-2 block w-full sm:text-sm rounded-md",
            isTotalQuantityAltered &&
              onChainCryptoData.totalQuantity !== undefined &&
              !isValidMinimumInteger(onChainCryptoData.totalQuantity)
              ? "border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500"
              : "border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 "
          )}
          onChange={async (e) => {
            setOnChainCryptoData({
              ...onChainCryptoData,
              totalQuantity:
                e.target.value != "" ? parseFloat(e.target.value) : undefined,
            })
            setIsTotalQuantityAltered(true)
          }}
        />

        {isTotalQuantityAltered &&
          onChainCryptoData.totalQuantity !== undefined &&
          !isValidMinimumInteger(onChainCryptoData.totalQuantity) && (
            <div className="pointer-events-none absolute inset-y-0 top-[4.25rem] right-0 flex items-center pr-3">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
      </div>
    </div>
  )
}

import Tippy from "@tippyjs/react"
import { motion, useSpring } from "framer-motion"
import { useState } from "react"
import AuthorCard from "./AuthorCard"
import { User } from "@/types/users"

type Props = {
  author?: User
  wallet?: string
  hideAuthorCard?: boolean
  children: JSX.Element
}

export default function AuthorHoverCard({
  author,
  wallet,
  hideAuthorCard,
  children,
}: Props) {
  // Animation configurations
  const springConfig = { damping: 20, stiffness: 150 }

  // Use framer-motion's useSpring for smooth transitions
  const opacity = useSpring(0, springConfig)
  const scale = useSpring(0.95, springConfig)
  const [mounted, setMounted] = useState(false)

  function onMount() {
    scale.set(1) // Animate scale to 1
    opacity.set(1) // Animate opacity to 1
    setMounted(true)
  }

  function onHide({ unmount }: { unmount: () => void }) {
    const cleanup = scale.onChange((value) => {
      if (value <= 0.95) {
        cleanup()
        unmount()
      }
    })
    setMounted(false)
    scale.set(0.95) // Return to initial scale
    opacity.set(0) // Fade out
  }

  const authorWallet =
    author && "wallet_address" in author ? author?.wallet_address : undefined

  return (
    <>
      <Tippy
        disabled={hideAuthorCard}
        interactive
        delay={300}
        placement="bottom-start"
        offset={[10, 10]}
        onMount={onMount}
        onHide={onHide}
        render={(attrs, content, instance) => (
          <motion.div
            className="h-32"
            style={{ scale, opacity }}
            tabIndex={-1}
            {...attrs}
          >
            {mounted && (
              <AuthorCard
                authorId={author?.id}
                wallet={wallet || authorWallet}
              />
            )}
          </motion.div>
        )}
      >
        {children}
      </Tippy>
    </>
  )
}
